import v4 from 'uuid/v4'
import { uorIdMst, lanId } from 'commons'
import { fetchKpiWithDefaults as fetchKpi } from 'api/kpi.js'
import { fetchSpendGraph } from 'api/graph'
import { getStartDate, getEndDate } from 'reducers/powerConsumption/loadCurve'
import {
  getKpiConsoUuid,
  getKpiExpenseUuid,
  getKpiPriceUuid,
  getGraphUuid,
} from 'reducers/expenses/detail'

const createKpiPayload = (state) => ({
  dateDebut: getStartDate(state),
  dateFin: getEndDate(state),
  type: 'Facture',
  uorIdMst: uorIdMst(state),
  lanId: lanId(state),
})

const isSuccess = (response) => response.status >= 200 && response.status < 300
const shouldIgnore = (response, uuid) => response && response.headers.get('X-REQUEST-ID') !== uuid

export const fetchKpiConso = (peeIds) => async (dispatch, getState) => {
  const uuid = v4()
  dispatch({ type: 'FETCH_DETAIL_KPI_CONSO_REQUEST', uuid })

  try {
    const payload = {
      uuid,
      perimetre: peeIds.join(';'),
      mesures: 'Volume',
      ...createKpiPayload(getState()),
    }

    const response = await fetchKpi(payload)
    if (shouldIgnore(response, getKpiConsoUuid(getState()))) return
    if (!isSuccess(response)) throw new Error(response.status)

    const {
      Principal: { Libelle, Valeur, Unite },
    } = await response.json()

    dispatch({
      type: 'FETCH_DETAIL_KPI_CONSO_SUCCESS',
      response: {
        Principal: { Libelle, Valeur, Unite },
      },
    })
  } catch (error) {
    dispatch({
      type: 'FETCH_DETAIL_KPI_CONSO_FAILURE',
      message: error.message || 'Something went wrong.',
    })
  }
}

export const fetchKpiExpense = (peeIds) => async (dispatch, getState) => {
  const uuid = v4()
  dispatch({ type: 'FETCH_DETAIL_KPI_EXPENSE_REQUEST', uuid })

  try {
    const payload = {
      uuid,
      perimetre: peeIds.join(';'),
      mesures: 'Depense',
      ...createKpiPayload(getState()),
    }

    const response = await fetchKpi(payload)
    if (shouldIgnore(response, getKpiExpenseUuid(getState()))) return
    if (!isSuccess(response)) throw new Error(response.status)

    const {
      Principal: { Libelle, Valeur, Unite },
    } = await response.json()

    dispatch({
      type: 'FETCH_DETAIL_KPI_EXPENSE_SUCCESS',
      response: {
        Principal: { Libelle, Valeur, Unite },
      },
    })
  } catch (error) {
    dispatch({
      type: 'FETCH_DETAIL_KPI_EXPENSE_FAILURE',
      message: error.message || 'Something went wrong.',
    })
  }
}

export const fetchKpiPrice = (peeIds) => async (dispatch, getState) => {
  const uuid = v4()
  dispatch({ type: 'FETCH_DETAIL_KPI_PRICE_REQUEST', uuid })

  try {
    const payload = {
      uuid,
      perimetre: peeIds.join(';'),
      mesures: 'Prix',
      ...createKpiPayload(getState()),
    }

    const response = await fetchKpi(payload)
    if (shouldIgnore(response, getKpiPriceUuid(getState()))) return
    if (!isSuccess(response)) throw new Error(response.status)

    const {
      Principal: { Libelle, Valeur, Unite },
    } = await response.json()

    dispatch({
      type: 'FETCH_DETAIL_KPI_PRICE_SUCCESS',
      response: {
        Principal: { Libelle, Valeur, Unite },
      },
    })
  } catch (error) {
    dispatch({
      type: 'FETCH_DETAIL_KPI_PRICE_FAILURE',
      message: error.message || 'Something went wrong.',
    })
  }
}

export const fetchKpis = (peeIds) => (dispatch, getState) => {
  fetchKpiConso(peeIds)(dispatch, getState)
  fetchKpiExpense(peeIds)(dispatch, getState)
  fetchKpiPrice(peeIds)(dispatch, getState)
}

export const fetchGraph = ({ peeIds, startDate, endDate }) => async (dispatch, getState) => {
  const uuid = v4()
  dispatch({ type: 'FETCH_DETAIL_GRAPH_REQUEST', uuid })

  try {
    const payload = {
      ids: peeIds,
      typeIds: 'PEE',
      debut: startDate,
      fin: endDate,
      pas: 5,
      agregation: 0,
    }

    const response = await fetchSpendGraph({ uuid, payload })
    if (shouldIgnore(response, getGraphUuid(getState()))) return
    if (!isSuccess(response)) throw new Error(response.status)

    dispatch({
      type: 'FETCH_DETAIL_GRAPH_SUCCESS',
      data: await response.json(),
    })
  } catch (error) {
    dispatch({
      type: 'FETCH_DETAIL_GRAPH_FAILURE',
      message: error.message || 'Something went wrong.',
    })
  }
}
