import { combineReducers } from 'redux'

const adminMarket = () => {
  const marketIds = (state = [], action) => {
    switch (action.type) {
      case 'DEL_ADMIN_MARKET_SUCCESS':
        if (action.response) {
          return state.filter((i) => i !== action.response)
        }
        return state
      case 'PUT_ADMIN_MARKET_SUCCESS':
      case 'POST_ADMIN_MARKET_SUCCESS':
        if (action.response) {
          const id = action.response.id
          return [...new Set([...state, id])]
        }
        return state
      case 'FETCH_ADMIN_MARKET_SUCCESS':
        return [...new Set([...state, ...action.response.result])]
      default:
        return state
    }
  }

  const marketUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_ADMIN_MARKET_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const adminMarketPostUuid = (state = '', action) => {
    switch (action.type) {
      case 'POST_ADMIN_MARKET_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const adminMarketPutUuid = (state = '', action) => {
    switch (action.type) {
      case 'PUT_ADMIN_MARKET_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const marketInfos = (state = {}, action) => {
    switch (action.type) {
      case 'DEL_ADMIN_MARKET_SUCCESS':
        if (action.response) {
          let newState = { ...state }
          delete newState[action.response]
          return newState
        }
        return state
      case 'PUT_ADMIN_MARKET_SUCCESS':
      case 'POST_ADMIN_MARKET_SUCCESS':
        if (action.response) {
          return {
            ...state,
            [action.response.id]: action.response,
          }
        }
        return state
      case 'FETCH_ADMIN_MARKET_SUCCESS':
        if (action.response) {
          return {
            ...state,
            ...action.response.entities.sidebarMarketInfo,
          }
        }
        return state
      default:
        return state
    }
  }

  return combineReducers({
    marketIds,
    marketUuid,
    adminMarketPostUuid,
    adminMarketPutUuid,
    marketInfos,
  })
}

export default adminMarket

export const getAdminMarketInfo = (state, id) => state.adminMarket.marketInfos[id]
export const getAdminMarketInfos = (state) =>
  state.adminMarket.marketIds.map((id) => getAdminMarketInfo(state, id))
export const getAdminMarketUuid = (state) => state.adminMarket.marketUuid
export const getAdminMarketPostUuid = (state) => state.adminMarket.adminMarketPostUuid
export const getAdminMarketPutUuid = (state) => state.adminMarket.adminMarketPutUuid
