import React from 'react'
import { withTranslation } from 'react-i18next'
import { Row, Navbar, Nav, NavItem } from 'reactstrap'

import ContractsNavLink from './ContractsNavLink'

const ContractsNav = ({ t }) => (
  <Row>
    <Navbar expand className="expenses-nav">
      <Nav navbar className="flex-row">
        <NavItem>
          <ContractsNavLink path="/contracts/energy-meter">
            <span>
              {t('contracts.nav.pees')}
              <div className="active-underline" />
            </span>
          </ContractsNavLink>
        </NavItem>
        <NavItem>
          <ContractsNavLink path="/contracts">
            <span>
              {t('contracts.nav.contracts')}
              <div className="active-underline" />
            </span>
          </ContractsNavLink>
        </NavItem>
      </Nav>
    </Navbar>
  </Row>
)

export default withTranslation()(ContractsNav)
