import React, { forwardRef } from 'react'
import { compose, shouldUpdate } from 'recompose'
import moment from 'moment'
import HighchartsReact from 'highcharts-react-official'
import { Highstock } from 'helpers/highcharts'

const startOfDay = (d) =>
  Number(
    moment
      .utc(d)
      .startOf('day')
      .format('x')
  )

const Chart = (props) => {
  const config = {
    ...props.config,
  }

  config.xAxis = {
    events: {
      setExtremes: function(e) {
        if (e.trigger === 'navigator') {
          const { chart } = props.innerRef.current
          setTimeout(() => {
            chart.xAxis[0].setExtremes(startOfDay(e.min), startOfDay(e.max))
          }, 100)
        }
      },
    },
  }

  return (
    <HighchartsReact
      highcharts={Highstock}
      constructorType="stockChart"
      options={config}
      ref={props.innerRef}
      immutable={true}
    />
  )
}

const PriceMonitoringGraph = compose(
  shouldUpdate(
    (props, nextProps) => JSON.stringify(props.config) !== JSON.stringify(nextProps.config)
  )
)(Chart)

export default forwardRef((props, ref) => <PriceMonitoringGraph innerRef={ref} {...props} />)
