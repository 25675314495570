import { combineReducers } from 'redux'

const gas = () => {
  const kpiExpTotalUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_EXP_TOTAL_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiExpEnergyUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_EXP_ENERGY_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiExpTransportUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_EXP_TRANSPORT_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiExpTaxesUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_EXP_TAXES_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiExpAltUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_EXP_ALT_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiPriceTotalUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_PRICE_TOTAL_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiPriceEnergyUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_PRICE_ENERGY_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiPriceTransportUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_PRICE_TRANSPORT_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiPriceTaxesUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_PRICE_TAXES_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiPriceAltUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_PRICE_ALT_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const pieGraphUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_PIE_GRAPH_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const colGraphUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_COL_GRAPH_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiExpTotal = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_EXP_TOTAL_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiExpTotalLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_EXP_TOTAL_REQUEST':
        return true
      case 'FETCH_EXPENSES_GAS_EXP_TOTAL_SUCCESS':
      case 'FETCH_EXPENSES_GAS_EXP_TOTAL_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiExpEnergy = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_EXP_ENERGY_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiExpEnergyLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_EXP_ENERGY_REQUEST':
        return true
      case 'FETCH_EXPENSES_GAS_EXP_ENERGY_SUCCESS':
      case 'FETCH_EXPENSES_GAS_EXP_ENERGY_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiExpTransport = (
    state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } },
    action
  ) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_EXP_TRANSPORT_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiExpTransportLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_EXP_TRANSPORT_REQUEST':
        return true
      case 'FETCH_EXPENSES_GAS_EXP_TRANSPORT_SUCCESS':
      case 'FETCH_EXPENSES_GAS_EXP_TRANSPORT_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiExpTaxes = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_EXP_TAXES_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiExpTaxesLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_EXP_TAXES_REQUEST':
        return true
      case 'FETCH_EXPENSES_GAS_EXP_TAXES_SUCCESS':
      case 'FETCH_EXPENSES_GAS_EXP_TAXES_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiExpAlt = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_EXP_ALT_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiExpAltLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_EXP_ALT_REQUEST':
        return true
      case 'FETCH_EXPENSES_GAS_EXP_ALT_SUCCESS':
      case 'FETCH_EXPENSES_GAS_EXP_ALT_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiPriceTotal = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_PRICE_TOTAL_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiPriceTotalLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_PRICE_TOTAL_REQUEST':
        return true
      case 'FETCH_EXPENSES_GAS_PRICE_TOTAL_SUCCESS':
      case 'FETCH_EXPENSES_GAS_PRICE_TOTAL_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiPriceEnergy = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_PRICE_ENERGY_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiPriceEnergyLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_PRICE_ENERGY_REQUEST':
        return true
      case 'FETCH_EXPENSES_GAS_PRICE_ENERGY_SUCCESS':
      case 'FETCH_EXPENSES_GAS_PRICE_ENERGY_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiPriceTransport = (
    state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } },
    action
  ) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_PRICE_TRANSPORT_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiPriceTransportLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_PRICE_TRANSPORT_REQUEST':
        return true
      case 'FETCH_EXPENSES_GAS_PRICE_TRANSPORT_SUCCESS':
      case 'FETCH_EXPENSES_GAS_PRICE_TRANSPORT_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiPriceTaxes = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_PRICE_TAXES_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiPriceTaxesLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_PRICE_TAXES_REQUEST':
        return true
      case 'FETCH_EXPENSES_GAS_PRICE_TAXES_SUCCESS':
      case 'FETCH_EXPENSES_GAS_PRICE_TAXES_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiPriceAlt = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_PRICE_ALT_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiPriceAltLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_PRICE_ALT_REQUEST':
        return true
      case 'FETCH_EXPENSES_GAS_PRICE_ALT_SUCCESS':
      case 'FETCH_EXPENSES_GAS_PRICE_ALT_FAILURE':
        return false
      default:
        return state
    }
  }

  const pieGraph = (state = {}, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_PIE_GRAPH_SUCCESS':
        return action.response
      default:
        return state
    }
  }

  const colGraph = (state = {}, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_GAS_COL_GRAPH_SUCCESS':
        return action.response
      default:
        return state
    }
  }

  return combineReducers({
    kpiExpTotalUuid,
    kpiExpEnergyUuid,
    kpiExpTransportUuid,
    kpiExpTaxesUuid,
    kpiExpAltUuid,
    kpiPriceTotalUuid,
    kpiPriceEnergyUuid,
    kpiPriceTransportUuid,
    kpiPriceTaxesUuid,
    kpiPriceAltUuid,
    pieGraphUuid,
    colGraphUuid,
    kpiExpTotal,
    kpiExpEnergy,
    kpiExpTransport,
    kpiExpTaxes,
    kpiExpAlt,
    kpiPriceTotal,
    kpiPriceEnergy,
    kpiPriceTransport,
    kpiPriceTaxes,
    kpiPriceAlt,
    kpiExpTotalLoading,
    kpiExpEnergyLoading,
    kpiExpTransportLoading,
    kpiExpTaxesLoading,
    kpiExpAltLoading,
    kpiPriceTotalLoading,
    kpiPriceEnergyLoading,
    kpiPriceTransportLoading,
    kpiPriceTaxesLoading,
    kpiPriceAltLoading,
    pieGraph,
    colGraph,
  })
}

export default gas

export const getKpiExpTotalUuid = (state) => state.expenses.gas.kpiExpTotalUuid
export const getKpiExpEnergyUuid = (state) => state.expenses.gas.kpiExpEnergyUuid
export const getKpiExpTransportUuid = (state) => state.expenses.gas.kpiExpTransportUuid
export const getKpiExpTaxesUuid = (state) => state.expenses.gas.kpiExpTaxesUuid
export const getKpiExpAltUuid = (state) => state.expenses.gas.kpiExpAltUuid
export const getKpiPriceTotalUuid = (state) => state.expenses.gas.kpiPriceTotalUuid
export const getKpiPriceEnergyUuid = (state) => state.expenses.gas.kpiPriceEnergyUuid
export const getKpiPriceTransportUuid = (state) => state.expenses.gas.kpiPriceTransportUuid
export const getKpiPriceTaxesUuid = (state) => state.expenses.gas.kpiPriceTaxesUuid
export const getKpiPriceAltUuid = (state) => state.expenses.gas.kpiPriceAltUuid
export const getPieGraphUuid = (state) => state.expenses.gas.pieGraphUuid
export const getColGraphUuid = (state) => state.expenses.gas.colGraphUuid

export const getKpiExpTotal = (state) => state.expenses.gas.kpiExpTotal
export const getKpiExpEnergy = (state) => state.expenses.gas.kpiExpEnergy
export const getKpiExpTransport = (state) => state.expenses.gas.kpiExpTransport
export const getKpiExpTaxes = (state) => state.expenses.gas.kpiExpTaxes
export const getKpiExpAlt = (state) => state.expenses.gas.kpiExpAlt
export const getKpiPriceTotal = (state) => state.expenses.gas.kpiPriceTotal
export const getKpiPriceEnergy = (state) => state.expenses.gas.kpiPriceEnergy
export const getKpiPriceTransport = (state) => state.expenses.gas.kpiPriceTransport
export const getKpiPriceTaxes = (state) => state.expenses.gas.kpiPriceTaxes
export const getKpiPriceAlt = (state) => state.expenses.gas.kpiPriceAlt

export const getKpiExpTotalLoading = (state) => state.expenses.gas.kpiExpTotalLoading
export const getKpiExpEnergyLoading = (state) => state.expenses.gas.kpiExpEnergyLoading
export const getKpiExpTransportLoading = (state) => state.expenses.gas.kpiExpTransportLoading
export const getKpiExpTaxesLoading = (state) => state.expenses.gas.kpiExpTaxesLoading
export const getKpiExpAltLoading = (state) => state.expenses.gas.kpiExpAltLoading
export const getKpiPriceTotalLoading = (state) => state.expenses.gas.kpiPriceTotalLoading
export const getKpiPriceEnergyLoading = (state) => state.expenses.gas.kpiPriceEnergyLoading
export const getKpiPriceTransportLoading = (state) => state.expenses.gas.kpiPriceTransportLoading
export const getKpiPriceTaxesLoading = (state) => state.expenses.gas.kpiPriceTaxesLoading
export const getKpiPriceAltLoading = (state) => state.expenses.gas.kpiPriceAltLoading

export const getPieGraph = (state) => state.expenses.gas.pieGraph
export const getColGraph = (state) => state.expenses.gas.colGraph
