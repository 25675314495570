import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'

class ContractsNavLink extends Component {
  isActive(path, location) {
    switch (path) {
      case '/contracts/energy-meter':
        return location.pathname.startsWith(path)
      case '/contracts':
        return (
          location.pathname.startsWith(path) &&
          !location.pathname.includes('/contracts/energy-meter')
        )
      default:
        return false
    }
  }

  render() {
    const { path, children, location } = this.props

    return (
      <NavLink
        to={path}
        className="nav-link"
        activeClassName="active"
        isActive={() => this.isActive(path, location)}
      >
        <span>{children}</span>
      </NavLink>
    )
  }
}

const paths = ['/contracts/energy-meter', '/contracts']

ContractsNavLink.propTypes = {
  path: PropTypes.oneOf(paths).isRequired,
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default withRouter(ContractsNavLink)
