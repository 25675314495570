import * as cot from '../api/quotations'
import {
  FETCH_QUOTATION_GRAPH_REQUEST,
  FETCH_QUOTATION_GRAPH_FAILURE,
  FETCH_QUOTATION_GRAPH_SUCCESS,
  FETCH_MY_QUOTATIONS_REQUEST,
  FETCH_MY_QUOTATIONS_SUCCESS,
  FETCH_MY_QUOTATIONS_FAILURE,
  ADD_MY_QUOTATIONS_REQUEST,
  ADD_MY_QUOTATIONS_SUCCESS,
  ADD_MY_QUOTATIONS_FAILURE,
  DELETE_MY_QUOTATION_REQUEST,
  DELETE_MY_QUOTATION_SUCCESS,
  DELETE_MY_QUOTATION_FAILURE,
  RESET_QUOTATION_GRAPH,
  FETCH_MY_AVAILABLE_QUOTATIONS_REQUEST,
  FETCH_MY_AVAILABLE_QUOTATIONS_SUCCESS,
  FETCH_MY_AVAILABLE_QUOTATIONS_FAILURE,
  TOGGLE_QUOTATION_BOLLINGER,
  TOGGLE_QUOTATION_TUNNEL,
} from './'

/**
 * Recupère les cotations disponibles pour le user connecte
 */
export const fetchMyAvailableQuotations = () => async (dispatch) => {
  dispatch({
    type: FETCH_MY_AVAILABLE_QUOTATIONS_REQUEST,
  })
  try {
    const rq = await cot.fetchMyAvailableQuotations()
    if (rq.status === 200) {
      dispatch({
        type: FETCH_MY_AVAILABLE_QUOTATIONS_SUCCESS,
        quotations: await rq.json(),
      })
    }
  } catch (e) {
    console.error(e)
    dispatch({
      type: FETCH_MY_AVAILABLE_QUOTATIONS_FAILURE,
      message: e.Message || 'Something went wrong.',
    })
  }
}

/**
 * Envoi un évenement de mise à null des cotations du user pour gérer le loader
 */
export const resetQuotationGraph = (quotationId) => (dispatch) => {
  dispatch({
    type: RESET_QUOTATION_GRAPH,
    quotationId,
  })
}

/**
 * Récupère les cotations suivies par l'utilisateur connecté
 */
export const fetchMyQuotations = () => async (dispatch) => {
  dispatch({
    type: FETCH_MY_QUOTATIONS_REQUEST,
  })

  try {
    const rq = await cot.fetchMyQuotations()
    if (rq.status === 200) {
      dispatch({
        type: FETCH_MY_QUOTATIONS_SUCCESS,
        quotations: await rq.json(),
      })
    }
  } catch (e) {
    console.error(e)
    dispatch({
      type: FETCH_MY_QUOTATIONS_FAILURE,
      message: e.Message || 'Something went wrong.',
    })
  }
}

/**
 * Ajoute des cotations suivies pour l'utilisateur connecté
 */
export const addQuotationsForUser = (quotations) => async (dispatch) => {
  dispatch({
    type: ADD_MY_QUOTATIONS_REQUEST,
  })

  try {
    const rq = await cot.addQuotationForUser(quotations)
    if (rq.status === 200) {
      dispatch({
        type: ADD_MY_QUOTATIONS_SUCCESS,
        quotations: await rq.json(),
      })
    }
  } catch (e) {
    console.error(e)
    dispatch({
      type: ADD_MY_QUOTATIONS_FAILURE,
      message: e.Message || 'Something went wrong.',
    })
  }
}

/**
 * Supprime une cotation suivie par l'utilisateur connecté
 */
export const deleteMyQuotation = (quotationId) => async (dispatch) => {
  dispatch({
    type: DELETE_MY_QUOTATION_REQUEST,
  })
  try {
    await cot.deleteMyQuotation(quotationId)
    dispatch({
      type: DELETE_MY_QUOTATION_SUCCESS,
      quotationId,
    })
  } catch (e) {
    console.error(e)
    dispatch({
      type: DELETE_MY_QUOTATION_FAILURE,
      message: e.Message || 'Something went wrong.',
    })
  }
}

/**
 * Charge le graph de cotation pour la cotation
 */
export const fetchQuotationGraph = (quotations) => async (dispatch) => {
  const quotationIds = quotations.map((q) => q.Id)

  dispatch({
    type: FETCH_QUOTATION_GRAPH_REQUEST,
    quotationIds,
  })

  try {
    const rq = await cot.fetchQuotationGraph(quotations)
    if (rq.status === 200) {
      dispatch({
        type: FETCH_QUOTATION_GRAPH_SUCCESS,
        graph: await rq.json(),
        quotationIds,
      })
    }
  } catch (e) {
    console.error(e)
    dispatch({
      type: FETCH_QUOTATION_GRAPH_FAILURE,
      message: e.Message || 'Something went wrong.',
    })
  }
}

export const toggleQuotationBollinger = (id) => ({ type: TOGGLE_QUOTATION_BOLLINGER, id })
export const toggleQuotationTunnel = (id) => ({ type: TOGGLE_QUOTATION_TUNNEL, id })
