import get from 'lodash/get'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import pick from 'lodash/pick'

import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Form, Label, Button } from 'reactstrap'
import Notifications from 'react-notification-system-redux'

import { Input } from './Input'
import { Select } from './Select'
import { DateInput } from './DateInput'
import { UorSelect } from './UorSelect'
import { useUpdateSite } from './useSite'

const notifySuccess = (t) =>
  Notifications.success({
    title: t('site.notif.success'),
    position: 'bl',
    autoDismiss: 3,
  })

const notifyError = (t) =>
  Notifications.error({
    title: t('site.notif.error'),
    position: 'bl',
    autoDismiss: 3,
  })

export const SiteGeneralInfos = ({ infos }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [data, setData] = React.useState(infos)
  const [touched, setTouched] = React.useState(false)
  const [mutate, { data: updatedData, status }] = useUpdateSite(infos.id)
  const loading = status === 'loading'

  React.useEffect(() => {
    if (updatedData) setData(updatedData)
  }, [updatedData])

  React.useEffect(() => {
    if (status === 'success') {
      dispatch(notifySuccess(t))
    } else if (status === 'error') {
      dispatch(notifyError(t))
    }
  }, [status, dispatch, t])

  const onChange = React.useCallback(
    (path, value) => {
      setTouched(true)
      const newData = cloneDeep(data)
      set(newData, path, value)
      setData(newData)
    },
    [data]
  )

  const handleSubmit = React.useCallback(
    async (e) => {
      e.preventDefault()

      const payload = pick(data, [
        'id',
        'SitClef',
        'SphNom',
        'DateEntree',
        'DateSortie',
        'SphAdresse',
        'SitFacAdresse',
        'Siret',
        'Organisation',
      ])

      try {
        await mutate(payload)
        setTouched(false)
      } catch (err) {
        console.error(err)
      }
    },
    [data, mutate]
  )

  return (
    <div
      css={{
        '.dates': {
          display: 'flex',
          '>*:not(:last-child)': { marginRight: '1rem' },
        },
        '.infos': {
          display: 'flex',
          '>*': {
            flex: 1,
            '&:not(:last-child)': { marginRight: '1rem' },
          },
        },
      }}
    >
      <div
        css={{
          marginBottom: '1.5rem',
          display: 'flex',
          alignItems: 'center',
          h5: { margin: 0 },
          label: {
            margin: '0 0 0 2rem',
            display: 'flex',
            alignItems: 'center',
            '>span': {
              marginLeft: '.6rem',
            },
          },
        }}
      >
        <h5>{t('site.admin.title')}</h5>
      </div>

      <Form onSubmit={handleSubmit}>
        <div className="dates mb-4">
          <Label>
            <div>{t('site.admin.dateIn')}</div>
            <DateInput
              value={get(data, 'DateEntree', '')}
              onChange={(value) => onChange('DateEntree', value)}
              disabled={loading}
            />
          </Label>

          <Label>
            <div>{t('site.admin.dateOut')}</div>
            <DateInput
              value={get(data, 'DateSortie', '')}
              onChange={(value) => onChange('DateSortie', value)}
              disabled={loading}
            />
          </Label>
        </div>

        <div className="infos">
          <div>
            <div className="h7">Informations</div>
            <div>
              <Label>
                <div>{t('site.admin.site-name')}</div>
                <Input
                  value={get(data, 'SphNom', '')}
                  onChange={(value) => onChange('SphNom', value)}
                  disabled={loading}
                />
              </Label>
              <Label>
                <div>{t('site.admin.legal-entity')}</div>
                <Input
                  value={get(data, 'PersonneMorale.RaisonSociale', '')}
                  onChange={(value) => onChange('PersonneMorale.RaisonSociale', value)}
                  disabled={loading}
                />
              </Label>
              <Label>
                <div>{t('site.admin.organisation')}</div>
                <UorSelect
                  value={get(data, 'Organisation.id', '')}
                  label={get(data, 'Organisation.UorNom', '')}
                  onChange={(label, value) => {
                    setTouched(true)
                    const newData = cloneDeep(data)
                    set(newData, 'Organisation.id', value)
                    set(newData, 'Organisation.UorNom', label)
                    setData(newData)
                  }}
                  disabled={loading}
                />
              </Label>

              <Label>
                <div>{t('site.admin.code')}</div>
                <Input
                  value={get(data, 'SitClef', '')}
                  onChange={(value) => onChange('SitClef', value)}
                  disabled={loading}
                />
              </Label>
              <Label>
                <div>{t('site.admin.siret')}</div>
                <Input
                  value={get(data, 'Siret', '')}
                  onChange={(value) => onChange('Siret', value)}
                  disabled={loading}
                />
              </Label>
            </div>
          </div>

          <div>
            <div className="h7">{t('site.admin.adress-delivery')}</div>
            <div>
              <Label>
                <div>{t('site.admin.address')}</div>
                <Input
                  value={get(data, 'SphAdresse.AdrAdresse', '')}
                  onChange={(value) => onChange('SphAdresse.AdrAdresse', value)}
                  disabled={loading}
                />
              </Label>
              <Label>
                <div>{t('site.admin.addAddress')}</div>
                <Input
                  value={get(data, 'SphAdresse.AdrCompl1', '')}
                  onChange={(value) => onChange('SphAdresse.AdrCompl1', value)}
                  disabled={loading}
                />
              </Label>
              <Label>
                <div>{t('site.admin.postalCode')}</div>
                <Input
                  value={get(data, 'SphAdresse.AdrCodePostal', '')}
                  onChange={(value) => onChange('SphAdresse.AdrCodePostal', value)}
                  disabled={loading}
                />
              </Label>
              <Label>
                <div>{t('site.admin.city')}</div>
                <Input
                  value={get(data, 'SphAdresse.AdrVille', '')}
                  onChange={(value) => onChange('SphAdresse.AdrVille', value)}
                  disabled={loading}
                />
              </Label>
              <Label>
                <div>{t('site.admin.country')}</div>
                <Select
                  value={get(data, 'SphAdresse.AdrPaysId', '')}
                  onChange={(label, value) => {
                    setTouched(true)
                    const newData = cloneDeep(data)
                    set(newData, 'SphAdresse.AdrPaysId', value)
                    set(newData, 'SphAdresse.AdrPays', label)
                    setData(newData)
                  }}
                  disabled={loading}
                />
              </Label>
            </div>
          </div>

          <div>
            <div className="h7">{t('site.admin.adress-bill')}</div>
            <div>
              <Label>
                <div>{t('site.admin.address')}</div>
                <Input
                  value={get(data, 'SitFacAdresse.AdrAdresse', '')}
                  onChange={(value) => onChange('SitFacAdresse.AdrAdresse', value)}
                  disabled={loading}
                />
              </Label>
              <Label>
                <div>{t('site.admin.addAddress')}</div>
                <Input
                  value={get(data, 'SitFacAdresse.AdrCompl1', '')}
                  onChange={(value) => onChange('SitFacAdresse.AdrCompl1', value)}
                  disabled={loading}
                />
              </Label>
              <Label>
                <div>{t('site.admin.postalCode')}</div>
                <Input
                  value={get(data, 'SitFacAdresse.AdrCodePostal', '')}
                  onChange={(value) => onChange('SitFacAdresse.AdrCodePostal', value)}
                  disabled={loading}
                />
              </Label>
              <Label>
                <div>{t('site.admin.city')}</div>
                <Input
                  value={get(data, 'SitFacAdresse.AdrVille', '')}
                  onChange={(value) => onChange('SitFacAdresse.AdrVille', value)}
                  disabled={loading}
                />
              </Label>
              <Label>
                <div>{t('site.admin.country')}</div>
                <Select
                  value={get(data, 'SitFacAdresse.AdrPaysId', '') || ''}
                  onChange={(label, value) => {
                    setTouched(true)
                    const newData = cloneDeep(data)
                    set(newData, 'SitFacAdresse.AdrPaysId', value)
                    set(newData, 'SitFacAdresse.AdrPays', label)
                    setData(newData)
                  }}
                  disabled={loading}
                />
              </Label>
              <Label>
                <div>Email</div>
                <Input
                  type="email"
                  value={get(data, 'SitFacAdresse.Email', '')}
                  onChange={(value) => onChange('SitFacAdresse.Email', value)}
                  disabled={loading}
                />
              </Label>
            </div>
          </div>
        </div>

        <footer className="mt-3 d-flex justify-content-end">
          <Button
            type="submit"
            color="primary"
            disabled={loading || !touched}
            size="sm"
            css={{ padding: '6px 12px' }}
          >
            <i className="icon-done" />
            <span>{t('site.form.validate')}</span>
          </Button>
        </footer>
      </Form>
    </div>
  )
}
