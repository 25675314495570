import {Button} from "reactstrap";
import React from "react";
import {RiArrowRightFill} from "react-icons/ri";
import {ConsentementsTable} from "./ConsentementsTable";
import {
    clientConnectBaseUrl, clientConnectNomTiers, clientConnectRedirectUrl, clientConnectState, grdfClientId
} from "../../commons/helpers/env";
import {useSelector} from "react-redux";
import {getCurrentVisiblePeesBySite} from "../../reducers/currentSelection";
import {useQuery} from "react-query";
import {getGrdfDroits} from "../../api/grdf";
import {useTranslation} from "react-i18next";


const useGrdfDroits = () => {
    const perimeter = useSelector(getCurrentVisiblePeesBySite)
    const peeNums = perimeter.flatMap(item => item.pees.map(pee => pee.PeeClef));
    return useQuery(["grdfDroits", {perimeter}], () => perimeter && perimeter.length > 0 ? getGrdfDroits(peeNums) : [() => Promise.resolve([])])
}

const useGrdfRows = () => {
    const perimeter = useSelector(getCurrentVisiblePeesBySite)
    const peesWithSite = perimeter.flatMap(item => item.pees.map(pee => ({
        id: pee.id, peeClef: pee.PeeClef, siteName: item.name
    })));
    const {t} = useTranslation();
    const {data} = useGrdfDroits();
    const getBooleanLabel = (value) => {
        return value ? t('bools.true') : t('bools.false');
    };

    const getEtatConsentementLabel = (value) => {
        const enumLabels = {
            1: t('consents.etats.avalider'),
            2: t('consents.etats.refuse'),
            3: t('consents.etats.actif'),
            4: t('consents.etats.revoque'),
            5: t('consents.etats.areverifier'),
            6: t('consents.etats.obsolete'),
        };
        return enumLabels[value] || t('consents.etats.unknown');
    };

    return React.useMemo(() => {
        if (!data) return [];

        return data.map(droit => {
            const peeWithSite = peesWithSite.find(p => p.id === droit.peeId);
            if (!peeWithSite) return null;

            return {
                peeClef: peeWithSite.peeClef,
                nomSite: peeWithSite.siteName,
                etatDroitAcces: getEtatConsentementLabel(droit.etatDroitAcces),
                dateDebutDroitAcces: droit.dateDebutDroitAcces,
                dateFinDroitAcces: droit.dateFinDroitAcces,
                perimDonneesInformatives: getBooleanLabel(droit.perimDonneesInformatives),
                perimDonneesPubliees: getBooleanLabel(droit.perimDonneesPubliees),
                perimDonneesTechniques: getBooleanLabel(droit.perimDonneesTechniques),
                perimDonneesContractuelles: getBooleanLabel(droit.perimDonneesContractuelles)
            };
        }).filter(Boolean);
    }, [data, peesWithSite]);
}

export const Consentements = () => {
    const grdfRows = useGrdfRows();

    const goToGrdfPortail = async () => {
        const baseUrl = clientConnectBaseUrl;
        const params = {
            client_id: grdfClientId,
            state: clientConnectState,
            scope: "openid",
            response_type: "code",
            redirect_uri: clientConnectRedirectUrl,
            login_hint: `Prénom;Nom;E-mail;${clientConnectNomTiers}`,
            env: "P1"
        };

        const queryString = new URLSearchParams(params).toString();
        window.location.href = `${baseUrl}?${queryString}`;
    }


    return (<>
            <div
                css={{
                    display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem', button: {
                        marginLeft: '1rem', svg: {
                            transform: 'scale(1.4) translateY(-1px)', marginRight: '0.8rem',
                        },
                    },
                }}
            >
                <Button
                    style={{color: 'black', background: '#98D2DD', border: '#98D2DD'}}
                    onClick={goToGrdfPortail}
                >
                    <RiArrowRightFill/>
                    Portail Grdf
                </Button>
            </div>
            <ConsentementsTable grdfRows={grdfRows}/>
        </>)
}
