import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { getFilteredPees, getFilteredSites, getFilteredUors } from '../../reducers/searchPanel'
import Pee from './Pee'
import Site from './Site'
import Uor from './Uor'

const ListeFiltree = ({ filteredPees, filteredSites, filteredUors, t }) => (
  <div className="liste-filtree">
    {filteredUors.length > 0 && (
      <div>
        <h5>{t('panel.uors')}:</h5>
        <ul>
          {filteredUors.map((uorId) => (
            <Uor key={uorId} uorId={uorId} />
          ))}
        </ul>
      </div>
    )}

    {filteredSites.length > 0 && (
      <div>
        <h5>{t('panel.sites')}:</h5>
        <ul>
          {filteredSites.map((siteId) => (
            <Site key={siteId} siteId={siteId} />
          ))}
        </ul>
      </div>
    )}

    {filteredPees.length > 0 && (
      <div>
        <h5>{t('panel.pees')}:</h5>
        <ul>
          {filteredPees.map((peeId) => (
            <Pee key={peeId} peeId={peeId} />
          ))}
        </ul>
      </div>
    )}

    {filteredPees.length === 0 && filteredSites.length === 0 && filteredUors.length === 0 && (
      <p>Aucun élément correspondant à votre recherche.</p>
    )}
  </div>
)

const mapStateToProps = (state) => ({
  filteredPees: getFilteredPees(state),
  filteredSites: getFilteredSites(state),
  filteredUors: getFilteredUors(state),
})

ListeFiltree.propTypes = {
  filteredPees: PropTypes.array.isRequired,
  filteredSites: PropTypes.array.isRequired,
  filteredUors: PropTypes.array.isRequired,
}

export default withTranslation()(connect(mapStateToProps)(ListeFiltree))
