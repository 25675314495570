import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Row, Col, CardGroup, Alert } from 'reactstrap'

import Kpi from '../kpi/Kpi'
import FormLoadCurveGraph from './FormLoadCurveGraph'
import LoadCurveGraph from './LoadCurveGraph'
import FormLoadCurvePees from './FormLoadCurvePees'
import * as actions from '../../actions/powerConsumption/loadCurve'
import { getCurrentVisiblePeesIds } from '../../reducers/currentSelection'
import {
  getKpiLoadCurveConso,
  getKpiLoadCurvePowerMin,
  getKpiLoadCurvePowerMax,
  getKpiLoadCurveExcess,
  getLoadCurveGraph,
} from '../../reducers/powerConsumption/loadCurve'

import { fetchHrs } from '../../store/hrs'
import { getColor } from '../../helpers/colors'

import styles from './LoadCurve.module.scss'

const LegendItem = ({ id, label }) => (
  <div className={styles.legendItem} style={{ backgroundColor: getColor(id * 3, 0.2) }}>
    {label}
  </div>
)

class LoadCurve extends Component {
  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    const {
      fetchKpiConso,
      fetchKpiPowerMin,
      fetchKpiPowerMax,
      fetchKpiExcess,
      fetchHrs,
    } = this.props
    fetchKpiConso()
    fetchKpiPowerMin()
    fetchKpiPowerMax()
    fetchKpiExcess()
    fetchHrs()
  }

  render() {
    const {
      plotBandsLegend,
      currentVisiblePeesIds,
      kpiConso,
      kpiPowerMin,
      kpiPowerMax,
      kpiExcess,
      t,
    } = this.props

    return (
      <div>
        {currentVisiblePeesIds.length ? (
          <div>
            <section style={{ marginBottom: '20px' }}>
              <FormLoadCurvePees />
            </section>
            <section style={{ marginBottom: '20px' }} className="pb-0">
              <Row>
                <Col>
                  <CardGroup>
                    <Kpi decimal={true} {...kpiConso} />
                    <Kpi decimal={true} {...kpiPowerMin} />
                    <Kpi decimal={true} {...kpiPowerMax} />
                    <Kpi decimal={true} {...kpiExcess} />
                  </CardGroup>
                </Col>
              </Row>
            </section>
            <section>
              <Row>
                <Col xs="10">
                  <Row>
                    <Col>
                      <LoadCurveGraph />
                    </Col>
                  </Row>
                  {plotBandsLegend && (
                    <Row>
                      <Col>
                        <div className={styles.legend}>
                          {plotBandsLegend.map(({ Id, Nom }) => (
                            <LegendItem key={Id} id={Id} label={Nom} />
                          ))}
                        </div>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col xs="2">
                  <FormLoadCurveGraph />
                </Col>
              </Row>
            </section>
          </div>
        ) : (
          <section>
            <Alert color="warning" className="mb-0">
              {t('errors.noSelection')}
            </Alert>
          </section>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const graph = getLoadCurveGraph(state)

  return {
    currentVisiblePeesIds: getCurrentVisiblePeesIds(state),
    kpiConso: getKpiLoadCurveConso(state),
    kpiPowerMin: getKpiLoadCurvePowerMin(state),
    kpiPowerMax: getKpiLoadCurvePowerMax(state),
    kpiExcess: getKpiLoadCurveExcess(state),
    plotBandsLegend: graph && graph.xAxis ? graph.xAxis.plotBandsLegend : false,
  }
}
LoadCurve.propTypes = {
  currentVisiblePeesIds: PropTypes.array.isRequired,
  kpiConso: PropTypes.object.isRequired,
  kpiPowerMin: PropTypes.object.isRequired,
  kpiPowerMax: PropTypes.object.isRequired,
  kpiExcess: PropTypes.object.isRequired,
  fetchKpiConso: PropTypes.func.isRequired,
  fetchKpiPowerMin: PropTypes.func.isRequired,
  fetchKpiPowerMax: PropTypes.func.isRequired,
  fetchKpiExcess: PropTypes.func.isRequired,
}

export default withTranslation()(connect(mapStateToProps, { ...actions, fetchHrs })(LoadCurve))
