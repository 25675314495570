import v4 from 'uuid/v4'
import * as user from 'commons'
import * as kpi from '../../api/kpi.js'
import * as graph from '../../api/graph.js'
import {
  getKpiExpTotalUuid,
  getKpiExpEnergyUuid,
  getKpiExpTransportUuid,
  getKpiExpTaxesUuid,
  getKpiExpAltUuid,
  getKpiPriceTotalUuid,
  getKpiPriceEnergyUuid,
  getKpiPriceTransportUuid,
  getKpiPriceTaxesUuid,
  getKpiPriceAltUuid,
  getPieGraphUuid,
  getColGraphUuid,
} from '../../reducers/expenses/electricity'
import { getStartDate, getEndDate } from '../../reducers/dateRange'
import { getCurrentVisibleElecPeesIds } from '../../reducers/currentSelection'
import { getTypeDonnee } from '../../reducers/typeDonnee'
import { getGraphUnite } from '../../reducers/expenses'

export const fetchKpiExpTotal = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_EXPENSES_ELEC_EXP_TOTAL_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisibleElecPeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Depense',
      type,
      12,
      21,
      '',
      'Total',
      'Poste',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        const resUuid = response && response.headers.get('X-REQUEST-ID')
        const reqUuid = getKpiExpTotalUuid(getState())
        if (resUuid === reqUuid) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_EXPENSES_ELEC_EXP_TOTAL_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_EXPENSES_ELEC_EXP_TOTAL_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchKpiExpEnergy = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_EXPENSES_ELEC_EXP_ENERGY_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisibleElecPeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Depense',
      type,
      12,
      21,
      '',
      'Energie',
      'Poste',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (response && response.headers.get('X-REQUEST-ID') === getKpiExpEnergyUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_EXPENSES_ELEC_EXP_ENERGY_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_EXPENSES_ELEC_EXP_ENERGY_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchKpiExpTransport = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_EXPENSES_ELEC_EXP_TRANSPORT_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisibleElecPeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Depense',
      type,
      12,
      21,
      '',
      'Transport',
      'Poste',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (
          response &&
          response.headers.get('X-REQUEST-ID') === getKpiExpTransportUuid(getState())
        ) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_EXPENSES_ELEC_EXP_TRANSPORT_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_EXPENSES_ELEC_EXP_TRANSPORT_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchKpiExpTaxes = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_EXPENSES_ELEC_EXP_TAXES_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisibleElecPeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Depense',
      type,
      12,
      21,
      '',
      'Taxes',
      'Poste',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (response && response.headers.get('X-REQUEST-ID') === getKpiExpTaxesUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_EXPENSES_ELEC_EXP_TAXES_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_EXPENSES_ELEC_EXP_TAXES_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchKpiExpAlt = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_EXPENSES_ELEC_EXP_ALT_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisibleElecPeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Depense',
      type,
      12,
      21,
      '',
      'Autres',
      'Poste',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (response && response.headers.get('X-REQUEST-ID') === getKpiExpAltUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_EXPENSES_ELEC_EXP_ALT_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_EXPENSES_ELEC_EXP_ALT_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchKpiPriceTotal = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_EXPENSES_ELEC_PRICE_TOTAL_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisibleElecPeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Prix',
      type,
      12,
      21,
      '',
      'Total',
      'Poste',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (response && response.headers.get('X-REQUEST-ID') === getKpiPriceTotalUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_EXPENSES_ELEC_PRICE_TOTAL_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_EXPENSES_ELEC_PRICE_TOTAL_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchKpiPriceEnergy = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_EXPENSES_ELEC_PRICE_ENERGY_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisibleElecPeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Prix',
      type,
      12,
      21,
      '',
      'Energie',
      'Poste',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (
          response &&
          response.headers.get('X-REQUEST-ID') === getKpiPriceEnergyUuid(getState())
        ) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_EXPENSES_ELEC_PRICE_ENERGY_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_EXPENSES_ELEC_PRICE_ENERGY_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchKpiPriceTransport = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_EXPENSES_ELEC_PRICE_TRANSPORT_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisibleElecPeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Prix',
      type,
      12,
      21,
      '',
      'Transport',
      'Poste',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (
          response &&
          response.headers.get('X-REQUEST-ID') === getKpiPriceTransportUuid(getState())
        ) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_EXPENSES_ELEC_PRICE_TRANSPORT_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_EXPENSES_ELEC_PRICE_TRANSPORT_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchKpiPriceTaxes = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_EXPENSES_ELEC_PRICE_TAXES_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisibleElecPeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Prix',
      type,
      12,
      21,
      '',
      'Taxes',
      'Poste',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (response && response.headers.get('X-REQUEST-ID') === getKpiPriceTaxesUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_EXPENSES_ELEC_PRICE_TAXES_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_EXPENSES_ELEC_PRICE_TAXES_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchKpiPriceAlt = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_EXPENSES_ELEC_PRICE_ALT_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisibleElecPeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Prix',
      type,
      12,
      21,
      '',
      'Autres',
      'Poste',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (response && response.headers.get('X-REQUEST-ID') === getKpiPriceAltUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_EXPENSES_ELEC_PRICE_ALT_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_EXPENSES_ELEC_PRICE_ALT_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchPieGraph = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_EXPENSES_ELEC_PIE_GRAPH_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisibleElecPeesIds(getState()).join(';')
  const unite = getGraphUnite(getState())
  const decomposition = ['Energie', 'Transport', 'Taxes', 'Autres'].join(';') // getDecomposition(getState()).join(";");
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return graph
    .fetchNontemporalGraph(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Depense',
      'Facture',
      '',
      unite,
      21,
      decomposition,
      'Poste',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (response && response.headers.get('X-REQUEST-ID') === getPieGraphUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_EXPENSES_ELEC_PIE_GRAPH_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_EXPENSES_ELEC_PIE_GRAPH_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchColGraph = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_EXPENSES_ELEC_COL_GRAPH_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisibleElecPeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const unite = getGraphUnite(getState())
  const decomposition = ['Energie', 'Transport', 'Taxes', 'Autres'].join(';') // getDecomposition(getState()).join(";");
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return graph
    .fetchTemporalGraph(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Depense',
      type,
      '',
      unite,
      21,
      decomposition,
      3,
      'Poste',
      '',
      uorIdMst,
      lanId,
      true
    )
    .then(
      (response) => {
        if (response && response.headers.get('X-REQUEST-ID') === getColGraphUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_EXPENSES_ELEC_COL_GRAPH_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_EXPENSES_ELEC_COL_GRAPH_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}
