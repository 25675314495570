import { useMemo } from 'react'
import { useQuery, useMutation } from 'react-query'
import { superFetch } from 'commons'

const ONE_DAY = 86400000

const mapKeys = (options) =>
  options.map((option) => ({ label: option.m_Item2, value: option.m_Item1 }))

export const usePreferencesReferentiel = () => {
  const { data: devises } = useQuery(
    ['devisesRef'],
    async () => {
      const res = await superFetch({
        url: `referentiel/devises`,
      })
      if (res.status !== 200) throw new Error(res.statusText)
      return res.json()
    },
    {
      staleTime: ONE_DAY,
      suspense: true,
      throwOnError: true,
      useErrorBoundary: true,
    }
  )

  return {
    devises: devises ? mapKeys(devises) : [],
  }
}

export const usePreferences = () => {
  return useQuery(
    'preferences',
    async () => {
      const res = await superFetch({
        url: `utilisateurs/preferences`,
      })
      if (res.status !== 200) throw new Error(res.statusText)
      return res.json()
    },
    {
      suspense: true,
      throwOnError: true,
      useErrorBoundary: true,
    }
  )
}

export const useUpdatePreferences = () => {
  return useMutation(
    async (payload) => {
      const res = await superFetch({
        url: `/utilisateurs/preferences`,
        method: 'PATCH',
        body: payload,
      })
      if (res.status !== 200) throw new Error(res.statusText)
    },
    {
      throwOnError: true,
      useErrorBoundary: false,
      suspense: false,
    }
  )
}

export const useDevise = () => {
  const pref = usePreferences()
  const deviseId = pref.data?.CurrencyUI
  const { devises } = usePreferencesReferentiel()
  return useMemo(() => devises.find(({ value }) => value === deviseId)?.label, [deviseId, devises])
}
