import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'
import HighchartsReact from 'highcharts-react-official'
import { Highstock } from 'helpers/highcharts'

import * as user from 'commons'

import Kpi from '../kpi/Kpi'
import { cotationChart } from '../../helpers/initCharts'
import { rangeSelectorMarket } from '../../helpers/highcharts'
import { getKpiMarketGas, getMarketGasGraph } from '../../reducers/market'
import * as actions from '../../actions/market'

class MarketGas extends Component {
  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    const { fetchKpiMarketGas, fetchMarketGasGraph } = this.props
    fetchKpiMarketGas()
    fetchMarketGasGraph()
  }

  render() {
    const { kpi, graph, lng } = this.props
    return (
      <Col xs="4">
        <Row>
          <Col>
            <Kpi decimal={true} {...kpi} />
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <HighchartsReact
              highcharts={Highstock}
              constructorType="stockChart"
              options={cotationChart(graph, rangeSelectorMarket(lng), '#926A14')}
              immutable
            />
          </Col>
        </Row>
      </Col>
    )
  }
}

const mapStateToProps = (state) => ({
  kpi: getKpiMarketGas(state),
  graph: getMarketGasGraph(state),
  lng: user.lanId(state),
})

MarketGas.propTypes = {
  kpi: PropTypes.object,
  graph: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, actions)(MarketGas)
