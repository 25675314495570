import React from 'react'
import { Input } from 'reactstrap'
import { useCountries } from './useCountries'

export const Select = ({ value, onChange = () => {}, disabled = false, ...props }) => {
  const countries = useCountries()
  return (
    <Input
      type="select"
      disabled={disabled}
      value={value}
      onChange={(e) => {
        const value = e.target.value
        const label = countries[value].libelle
        onChange(label, value)
      }}
      {...props}
    >
      {Object.values(countries).map((c) => (
        <option key={c.id} value={c.id}>
          {c.libelle}
        </option>
      ))}
    </Input>
  )
}
