import React, { Component } from 'react'
import { Row, Col, Form, FormGroup, Input, Label, Button } from 'reactstrap'
import { withTranslation } from 'react-i18next'

import { LangSelect } from './LangSelect'
import UserInfosInput from './UserInfosInput'

class UserInfos extends Component {
  constructor(props) {
    super(props)
    this.state = { formData: {}, isInit: false }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (!this.state.isInit && newProps.userInfo) {
      this.setState({ formData: newProps.userInfo, isInit: true })
    }
  }

  componentDidMount() {
    this.props.fetchUserInfo()
  }

  submit = (e) => {
    e.preventDefault()
    this.props.postUserForm(this.state.formData)
    this.props.setUserPanelOpen(!this.props.userPanelOpen)
  }

  cancel = (e) => {
    e.preventDefault()
    this.props.setUserPanelOpen(!this.props.userPanelOpen)
  }

  onChangeOfCivility = (UsrCiviliteId, UserCivilite) => () => {
    this.setState({ formData: { ...this.state.formData, UsrCiviliteId, UserCivilite: 'Mme' } })
  }
  onChangeOfLan = (v) => {
    this.setState({ formData: { ...this.state.formData, LanId: v } })
  }

  onChangeOfFirstName = (e) => {
    this.setState({ formData: { ...this.state.formData, UsrNom: e.target.value } })
  }
  onChangeOfLastName = (e) => {
    this.setState({ formData: { ...this.state.formData, UsrPrenom: e.target.value } })
  }
  onChangeOfEmail = (e) => {
    this.setState({ formData: { ...this.state.formData, UsrEMail: e.target.value } })
  }
  onChangeOfAddress = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        UsrAdresse: { ...this.state.formData.UsrAdresse, AdrAdresse: e.target.value },
      },
    })
  }
  onChangeOfAddressComp1 = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        UsrAdresse: { ...this.state.formData.UsrAdresse, AdrCompl1: e.target.value },
      },
    })
  }
  onChangeOfAddressComp2 = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        UsrAdresse: { ...this.state.formData.UsrAdresse, AdrCompl2: e.target.value },
      },
    })
  }
  onChangeOfPc = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        UsrAdresse: { ...this.state.formData.UsrAdresse, AdrCodePostal: e.target.value },
      },
    })
  }
  onChangeOfCity = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        UsrAdresse: { ...this.state.formData.UsrAdresse, AdrVille: e.target.value },
      },
    })
  }
  onChangeOfPhone = (e) => {
    this.setState({ formData: { ...this.state.formData, UsrNumTel: e.target.value } })
  }
  onChangeOfMobile = (e) => {
    this.setState({ formData: { ...this.state.formData, UsrNumMob: e.target.value } })
  }

  render() {
    return this.state.isInit ? (
      <Form className="user-infos" onSubmit={this.submit}>
        <Row className="form-inline panel-header radio-input-btn">
          <Col className="d-flex align-items-end">
            <LangSelect value={this.state.formData.LanId} onChange={this.onChangeOfLan} />

            <div className="ml-auto">
              <Button color="danger" onClick={this.props.logout}>
                <i className="icon-power" />
                {this.props.t('user.logout')}
              </Button>
            </div>
          </Col>
        </Row>

        <Row className="panel-flex">
          <Col>
            <Row className="radio-input form-inline">
              <Col className="d-flex">
                <FormGroup>
                  <Input
                    id="userM"
                    type="radio"
                    name="userGender"
                    defaultChecked={this.state.formData.UsrCiviliteId === 1}
                    onChange={this.onChangeOfCivility(1, this.props.t('user.userInfo.male'))}
                    value="0"
                  />
                  <Label for="userM">{this.props.t('user.userInfo.male')}</Label>
                </FormGroup>
                <FormGroup>
                  <Input
                    id="userF"
                    type="radio"
                    name="userGender"
                    defaultChecked={this.state.formData.UsrCiviliteId === 2}
                    onChange={this.onChangeOfCivility(2, this.props.t('user.userInfo.female'))}
                    value="1"
                  />
                  <Label for="userF">{this.props.t('user.userInfo.female')}</Label>
                </FormGroup>
              </Col>
            </Row>

            <UserInfosInput
              id="userFirstname"
              value={this.state.formData.UsrNom}
              onChange={this.onChangeOfFirstName}
              label={this.props.t('user.userInfo.firstName')}
            />
            <UserInfosInput
              id="userLastname"
              value={this.state.formData.UsrPrenom}
              onChange={this.onChangeOfLastName}
              label={this.props.t('user.userInfo.lastName')}
            />
            <UserInfosInput
              id="userEmail"
              value={this.state.formData.UsrEMail}
              onChange={this.onChangeOfEmail}
              label={this.props.t('user.userInfo.email')}
              required
            />
            <UserInfosInput
              id="userAddress"
              value={this.state.formData.UsrAdresse && this.state.formData.UsrAdresse.AdrAdresse}
              onChange={this.onChangeOfAddress}
              label={this.props.t('user.userInfo.address')}
            />
            <UserInfosInput
              id="userAddressComp1"
              value={this.state.formData.UsrAdresse && this.state.formData.UsrAdresse.AdrCompl1}
              onChange={this.onChangeOfAddressComp1}
              label={this.props.t('user.userInfo.addressComp1')}
            />
            <UserInfosInput
              id="userAddressComp2"
              value={this.state.formData.UsrAdresse && this.state.formData.UsrAdresse.AdrCompl2}
              onChange={this.onChangeOfAddressComp2}
              label={this.props.t('user.userInfo.addressComp2')}
            />
            <UserInfosInput
              id="userPc"
              value={this.state.formData.UsrAdresse && this.state.formData.UsrAdresse.AdrCodePostal}
              onChange={this.onChangeOfPc}
              label={this.props.t('user.userInfo.pc')}
            />
            <UserInfosInput
              id="userCity"
              value={this.state.formData.UsrAdresse && this.state.formData.UsrAdresse.AdrVille}
              onChange={this.onChangeOfCity}
              label={this.props.t('user.userInfo.city')}
            />
            <UserInfosInput
              id="userPhone"
              value={this.state.formData.UsrNumTel}
              onChange={this.onChangeOfPhone}
              label={this.props.t('user.userInfo.phone')}
            />
            <UserInfosInput
              id="userMobile"
              value={this.state.formData.UsrNumMob}
              onChange={this.onChangeOfMobile}
              label={this.props.t('user.userInfo.mobile')}
            />
          </Col>
        </Row>

        <Row className="panel-footer">
          <Col className="d-flex">
            <div className="ml-auto">
              <div className="push-right">
                <Button type="button" color="link" onClick={this.cancel}>
                  {this.props.t('user.userInfo.cancel')}
                </Button>
                <Button type="submit" color="primary">
                  {this.props.t('user.userInfo.save')}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    ) : (
      <Row className="form-inline panel-header radio-input-btn">
        <Col className="d-flex">
          <div className="ml-auto">
            <Button color="danger" onClick={this.props.logout}>
              <i className="icon-power" />
              {this.props.t('user.logout')}
            </Button>
          </div>
        </Col>
      </Row>
    )
  }
}

export default withTranslation()(UserInfos)
