import { getUor } from 'store/selectors'

import logoVinci from '../assets/images/logo_vinci.svg'
import logoStef from '../assets/images/logo_stef.png'

export const mainLogo = (state) => {
  switch (getUor(state)) {
    case '735':
      return logoVinci
    case '620':
      return logoStef
    default:
      return null
  }
}
