import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { FormGroup, Label, Input } from 'reactstrap'

import { toggleTypeDonnee } from '../../actions/powerConsumption/loadCurve'
import { getIsTypeDonneeChecked } from '../../reducers/powerConsumption/loadCurve'

const FormLoadCurveCheckbox = ({ value, text, isChecked, toggleTypeDonnee, dispatch }) => (
  <FormGroup check>
    <Label
      check
      className={[(isChecked && 'checked') || '', (value === 'Mesure' && 'disabled') || ''].join(
        ' '
      )}
    >
      <Input
        type="checkbox"
        value={value}
        checked={isChecked}
        onChange={(e) => dispatch(toggleTypeDonnee)}
        disabled={value === 'Mesure' ? true : false}
      />
      {text}
    </Label>
  </FormGroup>
)

const mapStateToProps = (state, { value }) => ({
  isChecked: getIsTypeDonneeChecked(state, value),
  toggleTypeDonnee: toggleTypeDonnee(value),
})

FormLoadCurveCheckbox.propTypes = {
  value: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  toggleTypeDonnee: PropTypes.func.isRequired,
}

export default connect(mapStateToProps)(FormLoadCurveCheckbox)
