import { combineReducers } from 'redux'

const gas = () => {
  const kpiGasConsoUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_GAS_KPI_CONSO_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiGasExpenseUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_GAS_KPI_EXPENSE_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiGasPriceUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_GAS_KPI_PRICE_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const gasGraphUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_GAS_GRAPH_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiGasConso = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_GAS_KPI_CONSO_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiGasConsoLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_GAS_KPI_CONSO_REQUEST':
        return true
      case 'FETCH_GAS_KPI_CONSO_SUCCESS':
      case 'FETCH_GAS_KPI_CONSO_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiGasExpense = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_GAS_KPI_EXPENSE_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiGasExpenseLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_GAS_KPI_EXPENSE_REQUEST':
        return true
      case 'FETCH_GAS_KPI_EXPENSE_SUCCESS':
      case 'FETCH_GAS_KPI_EXPENSE_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiGasPrice = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_GAS_KPI_PRICE_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiGasPriceLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_GAS_KPI_PRICE_REQUEST':
        return true
      case 'FETCH_GAS_KPI_PRICE_SUCCESS':
      case 'FETCH_GAS_KPI_PRICE_FAILURE':
        return false
      default:
        return state
    }
  }

  const gasGraph = (state = {}, action) => {
    switch (action.type) {
      case 'FETCH_GAS_GRAPH_SUCCESS':
        return action.response
      default:
        return state
    }
  }

  const graphAgregation = (state = '0', action) => {
    switch (action.type) {
      case 'UPDATE_GAS_GRAPH_AGREGATION':
        return action.value
      default:
        return state
    }
  }

  const tooMuchPees = (state = false, action) => {
    switch (action.type) {
      case 'SET_GAS_TOO_MUCH_PEES':
        return action.value
      default:
        return state
    }
  }

  return combineReducers({
    kpiGasConsoUuid,
    kpiGasExpenseUuid,
    kpiGasPriceUuid,
    gasGraphUuid,
    kpiGasConso,
    kpiGasExpense,
    kpiGasPrice,
    kpiGasConsoLoading,
    kpiGasExpenseLoading,
    kpiGasPriceLoading,
    gasGraph,
    graphAgregation,
    tooMuchPees,
  })
}

export default gas

export const getKpiGasConsoUuid = (state) => state.powerConsumption.gas.kpiGasConsoUuid
export const getKpiGasExpenseUuid = (state) => state.powerConsumption.gas.kpiGasExpenseUuid
export const getKpiGasPriceUuid = (state) => state.powerConsumption.gas.kpiGasPriceUuid
export const getGasGraphUuid = (state) => state.powerConsumption.gas.gasGraphUuid

export const getKpiGasConso = (state) => state.powerConsumption.gas.kpiGasConso
export const getKpiGasExpense = (state) => state.powerConsumption.gas.kpiGasExpense
export const getKpiGasPrice = (state) => state.powerConsumption.gas.kpiGasPrice

export const getKpiGasConsoLoading = (state) => state.powerConsumption.gas.kpiGasConsoLoading
export const getKpiGasExpenseLoading = (state) => state.powerConsumption.gas.kpiGasExpenseLoading
export const getKpiGasPriceLoading = (state) => state.powerConsumption.gas.kpiGasPriceLoading

export const getGasGraph = (state) => state.powerConsumption.gas.gasGraph
export const getGasGraphAgregation = (state) => state.powerConsumption.gas.graphAgregation
export const getGasGraphTooMuchPees = (state) => state.powerConsumption.gas.tooMuchPees
