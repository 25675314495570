import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as actions from '../../actions/contracts'
import { getContractsListIds } from '../../reducers/contracts'
import Contract from './Contract'

class ContractsContractsList extends Component {
  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    const { fetchContractsList } = this.props
    fetchContractsList()
  }

  render() {
    const { contractsIds } = this.props
    return (
      <section className="accordion">
        {contractsIds && contractsIds.map((i) => <Contract key={i} id={i} />)}
      </section>
    )
  }
}

const mapStateToProps = (state) => ({
  contractsIds: getContractsListIds(state),
})

ContractsContractsList.propTypes = {
  contractsIds: PropTypes.array.isRequired,
  fetchContractsList: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, actions)(ContractsContractsList)
