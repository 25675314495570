import v4 from 'uuid/v4'
import { normalize } from 'normalizr'

import * as user from 'commons'
import * as schema from './schema'
import * as api from '../api/organisation.js'
import { getOrganisationUuid } from '../reducers/organisation'

export const fetchOrganisation = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_ORGANISATION_REQUEST',
    uuid,
  })

  const usrId = user.usrId(getState())
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return api.fetchOrganisation(uuid, usrId, uorIdMst, lanId).then(
    (response) => {
      if (response && response.headers.get('X-REQUEST-ID') === getOrganisationUuid(getState())) {
        response.json().then((response) => {
          dispatch({
            type: 'FETCH_ORGANISATION_SUCCESS',
            response: normalize(response, schema.organisation),
          })
        })
      }
    },
    (error) => {
      dispatch({
        type: 'FETCH_ORGANISATION_FAILURE',
        message: error.message || 'Something went wrong.',
      })
    }
  )
}
