import { useQuery, useMutation, queryCache } from 'react-query'
import { request } from './request'

export const usePatrimonials = (id) => {
  const { data } = useQuery(
    id && ['patrimonials', { id }],
    () => request({ url: `sites/${id}/patrimonials/filtered` }),
    {
      suspense: true,
    }
  )
  return data
}

export const useSite = (id) => {
  const { data: infos } = useQuery(id && ['infos', { id }], () => request({ url: `sites/${id}` }), {
    suspense: true,
  })

  const { data: pees } = useQuery(
    id && ['pees', { id }],
    () => request({ url: `sites/${id}/pees` }),
    { suspense: true }
  )

  return { infos, pees }
}

export const useUpdateSite = (id) => {
  return useMutation(
    (payload) =>
      request({
        url: `sites/${id}`,
        method: 'PUT',
        body: payload,
      }),
    {
      suspense: false,
      useErrorBoundary: false,
      throwOnError: true,
      onSuccess: () => {
        queryCache.refetchQueries(['infos', { id }])
      },
    }
  )
}
