import React from 'react'
import { Input } from 'reactstrap'
import moment from 'moment'

// const startDate = (() => {
//   if (!Site.DateEntree) return '-'
//   const m = moment(Site.DateEntree)
//   return m.isValid() ? m.format(dateFormat()) : '-'
// })()

export const DateInput = ({ value, onChange = () => {}, disabled = false, ...props }) => {
  const [date, setDate] = React.useState('')

  React.useEffect(() => {
    const m = moment(value)
    setDate(m.isValid() ? m.format('YYYY-MM-DD') : '')
  }, [value])

  return (
    <Input
      type="date"
      disabled={disabled}
      value={date}
      onChange={(e) => {
        const value = e.target.value
        setDate(value)
        const m = moment(value, 'YYYY-MM-DD')
        if (m.isValid()) {
          onChange(value)
        }
      }}
      {...props}
    />
  )
}
