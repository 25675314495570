import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { Row, Col, Button } from 'reactstrap'

import { getMeter } from '../../reducers/contracts'
import { fetchMeter } from '../../actions/contracts'
import { getIconClassName } from '../../helpers/icon'
import MeterInfos from './MeterInfos'

class Meter extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
  }

  componentDidMount() {
    const { meter, match } = this.props
    if (match.params.peeids && match.params.peeids.split(';').includes('' + meter.id)) {
      this.fetchMeter()
    }
  }

  fetchMeter() {
    const { meter, dispatch } = this.props
    dispatch(fetchMeter(meter.id))
  }

  toggle() {
    const { meter, match, history } = this.props
    let to = ''

    if (match.params.peeids) {
      const params = match.params.peeids.split(';')
      if (!params.includes('' + meter.id)) {
        this.fetchMeter()
      }
      to = params.includes('' + meter.id)
        ? params.filter((e) => e !== '' + meter.id).join(';') ||
          match.url
            .split('/')
            .filter((e) => e !== '' + meter.id)
            .join('/')
        : [...params, meter.id].join(';')
    } else {
      this.fetchMeter()
      to = match.url + '/' + meter.id
    }

    history.push(to)
  }

  render() {
    const { meter, match, t } = this.props
    const isOpen = match.params.peeids && match.params.peeids.split(';').includes('' + meter.id)

    return (
      <Row
        className={[
          'accordion-item',
          meter.LcpNrjId === 2 ? 'elec' : 'gas',
          isOpen ? 'open' : '',
        ].join(' ')}
      >
        <Col>
          <Button onClick={this.toggle} className="d-flex">
            <i className={getIconClassName(meter.LcpNrjId)} />
            {meter.LcpNomGroupeContrat}
            <i
              title={isOpen ? t('contracts.collapse') : t('contracts.expand')}
              className={['ml-auto', 'icon-collapse-' + (isOpen ? 'moins' : 'plus')].join(' ')}
            />
          </Button>
          {isOpen && (
            <div className="current-pees collapse show">
              <MeterInfos id={meter.id} />
            </div>
          )}
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state, { id }) => ({
  meter: getMeter(state, id),
})

Meter.propTypes = {
  meter: PropTypes.object,
  match: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default withTranslation()(withRouter(connect(mapStateToProps)(Meter)))
