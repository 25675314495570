import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import ContractLot from './ContractLot'
import { getContractDetails } from '../../reducers/contracts'

const ContractInfo = ({ id, contract, t }) => (
  <div>
    {contract &&
      contract.CtrLots &&
      contract.CtrLots.map((i, index) => (
        <div key={i.id}>
          <ContractLot CtrVolumeUnite={contract.CtrVolumeUnite} {...i} />
          {contract.CtrLots.length - 1 > index && <hr />}
        </div>
      ))}
  </div>
)

const mapStateToProps = (state, { id }) => ({
  contract: getContractDetails(state, id),
})

ContractInfo.propTypes = {
  id: PropTypes.number.isRequired,
  contract: PropTypes.object.isRequired,
}

export default withTranslation()(connect(mapStateToProps)(ContractInfo))
