import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Alert, Table, Input, Button } from 'reactstrap'
import Select from 'react-select'
import matchSorter from 'match-sorter'
import debounce from 'lodash/debounce'
import { compose, withState, withPropsOnChange } from 'recompose'
import { withRouter } from 'react-router-dom'

import { decimalFormat } from '../../helpers/helpers'
import * as actions from '../../actions/bills'
import {
  getBillsList,
  getSortedBillsIds,
  getSortState,
  getFilters,
  getBillsLoading,
} from '../../reducers/bills'
import { getIconClassName } from '../../helpers/icon'
import { Loader } from 'components/common/Loader'
import { fetchPdf } from '../../actions/pdf'

const uniq = (arr) => [...new Set(arr)]
const mapToOption = (arr) => arr.map((name) => ({ value: name, label: name }))

const FilterSelect = withTranslation()(({ t, defaultValue, options, onChange }) => (
  <Select
    placeholder={t('bills.list.filter')}
    className="rs"
    classNamePrefix="rs"
    isClearable={false}
    isSearchable={true}
    isMulti={true}
    components={{
      DropdownIndicator: null,
      IndicatorSeparator: null,
    }}
    defaultValue={defaultValue}
    options={options}
    onChange={onChange}
  />
))

const FilterInput = compose(
  withTranslation(),
  withState('value', 'setValue', ''),
  withPropsOnChange('onChange', ({ onChange }) => ({
    onChange: debounce(onChange, 500),
  }))
)(({ t, value, setValue, onChange }) => (
  <Input
    placeholder={t('bills.list.filter')}
    className="bills-list_input-filter"
    type="text"
    value={value}
    onChange={(e) => {
      setValue(e.target.value)
      onChange(e.target.value)
    }}
  />
))

class BillsList extends Component {
  constructor(props) {
    super(props)

    this.onRowClick = this.onRowClick.bind(this)
    this.onSort = this.onSort.bind(this)
  }

  static contextTypes = {
    router: PropTypes.object,
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate() {
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }

  fetchData() {
    const { fetchBillsList } = this.props
    fetchBillsList()
  }

  onRowClick(id) {
    this.props.history.push('/bills/' + id)
  }

  onSort(col) {
    const { sortBillsList, sortState } = this.props
    sortBillsList({ col, dir: sortState.col === col ? -sortState.dir : 1 })
  }

  downloadInvoiceDocs(e, id) {
    e.preventDefault()
    // this.props.downloadInvoice(`facture/${billId}/documents`)
    fetchPdf(id)
  }

  render() {
    const { bills, sortedBillsIds, sortState, t, filters, setFilter, loading } = this.props

    let filteredBills = sortedBillsIds.reduce((acc, id) => {
      if (
        (!filters.site ||
          filters.site.length === 0 ||
          filters.site.includes(bills[id].FacSite.SphNom)) &&
        (!filters.nrj || filters.nrj.length === 0 || filters.nrj.includes(bills[id].FacEnergie)) &&
        (!filters.provider ||
          filters.provider.length === 0 ||
          filters.provider.includes(bills[id].FacFournisseur)) &&
        (!filters.date || filters.date.length === 0 || filters.date.includes(bills[id].FacDate))
      ) {
        acc.push(bills[id])
      }
      return acc
    }, [])

    if (!!filters.pee)
      filteredBills = matchSorter(filteredBills, filters.pee, {
        keys: ['FacPerimetre.PeeClef'],
        threshold: matchSorter.rankings.CONTAINS,
      })
    if (!!filters.billKey)
      filteredBills = matchSorter(filteredBills, filters.billKey, {
        keys: ['FacClef'],
        threshold: matchSorter.rankings.CONTAINS,
      })
    if (!!filters.price)
      filteredBills = matchSorter(filteredBills, filters.price, {
        keys: ['FacMontantHTVA'],
        threshold: matchSorter.rankings.CONTAINS,
      })

    const siteOptions = mapToOption(uniq(sortedBillsIds.map((id) => bills[id].FacSite.SphNom)))
    const nrjOptions = mapToOption(uniq(sortedBillsIds.map((id) => bills[id].FacEnergie)))
    const providerOptions = mapToOption(uniq(sortedBillsIds.map((id) => bills[id].FacFournisseur)))
    const dateOptions = mapToOption(uniq(sortedBillsIds.map((id) => bills[id].FacDate)))

    if (sortedBillsIds.length === 0 && !loading)
      return (
        <Alert color="warning" className="mb-0">
          {t('errors.noBill')}
        </Alert>
      )

    return (
      <section className="p-0 bills-list" style={{ position: 'relative', minHeight: 280 }}>
        <Table className="table table-striped table-hover table-clickable">
          <thead>
            <tr>
              <th
                onClick={() => this.onSort('SphNom')}
                className={[
                  'reactable-header-sortable',
                  (sortState.col === 'SphNom' &&
                    (sortState.dir === 1
                      ? 'reactable-header-sort-asc'
                      : 'reactable-header-sort-desc')) ||
                    '',
                ].join(' ')}
              >
                {t('bills.list.site')}
              </th>
              <th
                className={[
                  'reactable-header-sortable',
                  (sortState.col === 'FacNrjId' &&
                    (sortState.dir === 1
                      ? 'reactable-header-sort-asc'
                      : 'reactable-header-sort-desc')) ||
                    '',
                ].join(' ')}
                onClick={() => this.onSort('FacNrjId')}
              >
                {t('bills.list.energy')}
              </th>
              <th
                className={[
                  'reactable-header-sortable',
                  (sortState.col === 'FacFournisseur' &&
                    (sortState.dir === 1
                      ? 'reactable-header-sort-asc'
                      : 'reactable-header-sort-desc')) ||
                    '',
                ].join(' ')}
                onClick={() => this.onSort('FacFournisseur')}
              >
                {t('bills.list.provider')}
              </th>
              <th
                className={[
                  'reactable-header-sortable',
                  (sortState.col === 'FacPee' &&
                    (sortState.dir === 1
                      ? 'reactable-header-sort-asc'
                      : 'reactable-header-sort-desc')) ||
                    '',
                ].join(' ')}
                onClick={() => this.onSort('FacPee')}
              >
                {t('bills.list.pee')}
              </th>
              <th
                className={[
                  'reactable-header-sortable',
                  (sortState.col === 'FacClef' &&
                    (sortState.dir === 1
                      ? 'reactable-header-sort-asc'
                      : 'reactable-header-sort-desc')) ||
                    '',
                ].join(' ')}
                onClick={() => this.onSort('FacClef')}
              >
                {t('bills.list.billKey')}
              </th>
              <th
                className={[
                  'reactable-header-sortable',
                  (sortState.col === 'FacDate' &&
                    (sortState.dir === 1
                      ? 'reactable-header-sort-asc'
                      : 'reactable-header-sort-desc')) ||
                    '',
                ].join(' ')}
                onClick={() => this.onSort('FacDate')}
              >
                {t('bills.list.date')}
              </th>
              <th
                className={[
                  'reactable-header-sortable',
                  (sortState.col === 'FacMontantHTVA' &&
                    (sortState.dir === 1
                      ? 'reactable-header-sort-asc'
                      : 'reactable-header-sort-desc')) ||
                    '',
                ].join(' ')}
                onClick={() => this.onSort('FacMontantHTVA')}
              >
                {t('bills.list.price')}
              </th>
              <th
                className={[
                  'reactable-header-sortable',
                  'text-center',
                  (sortState.col === 'FacNoteExiste' &&
                    (sortState.dir === 1
                      ? 'reactable-header-sort-asc'
                      : 'reactable-header-sort-desc')) ||
                    'reactable-header-no-arrow',
                ].join(' ')}
                onClick={() => this.onSort('FacNoteExiste')}
              >
                <i className="icon-comment" />
              </th>
              <th>
                <i className="icon-file-download" />
              </th>
              <th>
                <i className="icon-eye" />
              </th>
            </tr>

            <tr className="bills-list_filters">
              <td>
                <FilterSelect
                  defaultValue={siteOptions.filter(
                    (o) => filters.site && filters.site.includes(o.value)
                  )}
                  options={siteOptions}
                  onChange={(options) => {
                    const value = options ? options.map((o) => o.value) : []
                    setFilter({ key: 'site', value })
                  }}
                />
              </td>
              <td>
                <FilterSelect
                  defaultValue={null}
                  options={nrjOptions}
                  onChange={(options) => {
                    const value = options ? options.map((o) => o.value) : []
                    setFilter({ key: 'nrj', value })
                  }}
                />
              </td>
              <td>
                <FilterSelect
                  defaultValue={null}
                  options={providerOptions}
                  onChange={(options) => {
                    const value = options ? options.map((o) => o.value) : []
                    setFilter({ key: 'provider', value })
                  }}
                />
              </td>
              <td>
                <FilterInput
                  value={filters.pee}
                  onChange={(value) => setFilter({ key: 'pee', value })}
                />
              </td>
              <td>
                <FilterInput
                  value={filters.billKey}
                  onChange={(value) => setFilter({ key: 'billKey', value })}
                />
              </td>
              <td>
                <FilterSelect
                  defaultValue={null}
                  options={dateOptions}
                  onChange={(options) => {
                    const value = options ? options.map((o) => o.value) : []
                    setFilter({ key: 'date', value })
                  }}
                />
              </td>
              <td>
                <FilterInput
                  value={filters.price}
                  onChange={(value) => setFilter({ key: 'price', value })}
                />
              </td>
            </tr>
          </thead>
          <tbody>
            {filteredBills.map((bill, index) => (
              <tr key={bill.id}>
                <td>{bill.FacSite.SphNom}</td>
                <td>
                  <span>
                    <i className={getIconClassName(bill.FacNrjId)} />
                    {bill.FacEnergie.length > 5
                      ? bill.FacEnergie.slice(0, 4) + '.'
                      : bill.FacEnergie}
                  </span>
                </td>
                <td>{bill.FacFournisseur}</td>
                <td>{bill.FacPerimetre.PeeClef}</td>
                <td>{bill.FacClef}</td>
                <td className="nbr">{bill.FacDate}</td>
                <td value={bill.FacMontantHTVA} className="nbr">
                  <span>
                    {decimalFormat(bill.FacMontantHTVA)} {bill.FacDevise}
                  </span>
                </td>
                {/*<td className="status" value={bill.FacEtat}>{t("bills.bill.stateType." + bill.FacEtat)}</td>*/}
                <td value={bill.FacNoteExiste}>
                  <span>{bill.FacNoteExiste && <i className="icon-comment" />}</span>
                </td>
                <td>
                  {bill.HasFacture === true ? (
                    <Button
                      color="link"
                      className="btn btn-link"
                      onClick={(e) => this.downloadInvoiceDocs(e, bill.PdfId)}
                    >
                      <i className="icon-file-download" />
                    </Button>
                  ) : null}
                </td>
                <td>
                  <Button
                    color="link"
                    className="btn btn-link"
                    onClick={(e) => this.onRowClick(bill.id)}
                  >
                    <i className="icon-eye" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {loading && (
          <div
            style={{
              position: 'absolute',
              background: 'rgba(255, 255, 255, 0.7)',
              top: 0,
              paddingTop: 160,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Loader />
          </div>
        )}
      </section>
    )
  }
}

const mapStateToProps = (state) => ({
  bills: getBillsList(state),
  loading: getBillsLoading(state),
  sortedBillsIds: getSortedBillsIds(state),
  sortState: getSortState(state),
  filters: getFilters(state),
})

BillsList.propTypes = {
  bills: PropTypes.object.isRequired,
  sortedBillsIds: PropTypes.array.isRequired,
  sortState: PropTypes.object.isRequired,
  fetchBillsList: PropTypes.func.isRequired,
  sortBillsList: PropTypes.func.isRequired,
}

export default compose(withRouter, withTranslation(), connect(mapStateToProps, actions))(BillsList)
