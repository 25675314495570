import React, { Suspense } from 'react'

import { ErrorBoundary } from 'commons'
import { Loader } from 'components/common/Loader'
import { Sites } from 'components/sites/Sites'

export const SitesView = () => {
  return (
    <div className="mx-3 mb-5">
      <ErrorBoundary>
        <Suspense
          fallback={
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          }
        >
          <Sites />
        </Suspense>
      </ErrorBoundary>
    </div>
  )
}
