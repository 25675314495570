import v4 from 'uuid/v4'

import { apiConf } from 'commons'
const { superFetch } = apiConf

const api = {
  fetchHrs: ({ uuid }) =>
    superFetch({
      url: 'referentiel/hrs',
      uuid,
    }),
}

const FETCH_HRS_REQUEST = 'esite/hrs/FETCH_HRS_REQUEST'
const FETCH_HRS_SUCCESS = 'esite/hrs/FETCH_HRS_SUCCESS'
const FETCH_HRS_FAILURE = 'esite/hrs/FETCH_HRS_FAILURE'

// Reducer
const hrs = (
  state = {
    referentiel: null,
    loading: false,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case FETCH_HRS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case FETCH_HRS_SUCCESS:
      return {
        referentiel: action.referentiel,
        loading: false,
        error: null,
      }
    case FETCH_HRS_FAILURE:
      return {
        referentiel: {},
        loading: false,
        error: action.message,
      }
    default:
      return state
  }
}

export default hrs

// Selectors
export const getHrsReferentiel = (state) => state.hrs.referentiel

// Action Creators
export const fetchHrs = () => async (dispatch, getState) => {
  if (getHrsReferentiel(getState()) !== null) return

  const uuid = v4()

  dispatch({
    type: FETCH_HRS_REQUEST,
    uuid,
  })

  try {
    const response = await api.fetchHrs({ uuid })

    const responseUuid = response.headers.get('X-REQUEST-ID')
    if (responseUuid !== uuid) return

    const referentiel = await response.json()
    dispatch({
      type: FETCH_HRS_SUCCESS,
      referentiel,
    })
  } catch (error) {
    dispatch({
      type: FETCH_HRS_FAILURE,
      message: error.message || 'Something went wrong.',
    })
  }
}
