import React from 'react'
import { Spinner } from 'reactstrap'

const Loader = () => (
  <Spinner
    style={{
      width: '40px',
      height: '40px',
      borderWidth: '6px',
      color: 'var(--gold-400)',
    }}
  />
)

const LoaderCenter = () => (
  <div css={{ display: 'flex', justifyContent: 'center', items: 'center', height: 80 }}>
    <Loader />
  </div>
)

const TableLoader = ({ loading = true } = {}) =>
  !loading ? null : (
    <div className="-loading -active">
      <div className="-loading-inner">
        <Loader />
      </div>
    </div>
  )

export { Loader, LoaderCenter, TableLoader }
