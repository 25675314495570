import React from 'react'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import DateTime from 'react-datetime'
import { InputGroup, Input, InputGroupAddon, Button } from 'reactstrap'
import * as user from 'commons'
import { dateFormat } from '../../../helpers/formatters'

const grayLighter = '#d7dee4'

const groupStyle = {
  width: '120px',
  margin: '0 8px',
}

const buttonStyle = {
  lineHeight: 0,
  background: grayLighter,
  borderColor: grayLighter,
}

const RenderInput = (props, openCalendar) => (
  <InputGroup size="sm" style={groupStyle}>
    <Input {...props} />
    <InputGroupAddon addonType="append">
      <Button style={buttonStyle} className="btn-primary" onClick={openCalendar}>
        <i className="icon-event" />
      </Button>
    </InputGroupAddon>
  </InputGroup>
)

const InputDatepicker = ({ locale, value, onDateChange }) => (
  <DateTime
    className="input-datepicker"
    renderInput={RenderInput}
    locale={locale}
    closeOnSelect
    dateFormat={dateFormat()}
    timeFormat={false}
    value={value}
    onChange={onDateChange}
  />
)

const mapStateToProps = (state) => ({
  lng: user.lngIso(state),
})

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onDateChange: (props) => (date) => {
      if (typeof date !== 'string') {
        props.onChange(date)
      }
    },
  })
)(InputDatepicker)
