import React from 'react'
import { Table, Button, Input } from 'reactstrap'
import flatMap from 'lodash/flatMap'
import { useTranslation } from 'react-i18next'

import { useCreateContact, useUpdateContact, useDeleteContact } from './useContacts'

const emptyContact = {
  Id: null,
  Nom: '',
  Prenom: '',
  Fonction: '',
  Telephone: '',
  Email: '',
}

export const SiteContacts = ({ siteId, contacts }) => {
  const { t } = useTranslation()
  const [edit, setEdit] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [createContact] = useCreateContact(siteId)
  const [updateContact] = useUpdateContact(siteId)
  const [deleteContact] = useDeleteContact(siteId)

  const [contact, setContact] = React.useState(emptyContact)
  const setContactProp = (prop, value) => setContact({ ...contact, [prop]: value.trim() })

  return (
    <div className="mt-3 mb-5">
      <div className="h7">{t('site.contacts.title')}</div>

      <Table
        striped
        bordered
        size="sm"
        css={{
          marginBottom: 15,
          tbody: {
            'th, td': {
              verticalAlign: 'middle !important',
            },
            'td:last-child': {
              width: 180,
              padding: '0.5rem 8px',
              button: { padding: '4px 8px', width: 76 },
            },
          },
        }}
      >
        <thead>
          <tr>
            <th>{t('site.contacts.lastname')}</th>
            <th>{t('site.contacts.firstname')}</th>
            <th>{t('site.contacts.function')}</th>
            <th>{t('site.contacts.phone')}</th>
            <th>{t('site.contacts.email')}</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {flatMap(contacts, (item) => Object.entries(item)).map(([k, v], index) => {
            const isEditing = edit === index + 1
            return (
              <tr key={index + '_' + v.Id}>
                <th scope="row">
                  {isEditing ? (
                    <Input
                      bsSize="sm"
                      type="text"
                      onChange={(e) => setContactProp('Nom', e.target.value)}
                      value={contact.Nom}
                    />
                  ) : (
                    v.Nom
                  )}
                </th>
                <td>
                  {isEditing ? (
                    <Input
                      bsSize="sm"
                      type="text"
                      onChange={(e) => setContactProp('Prenom', e.target.value)}
                      value={contact.Prenom}
                    />
                  ) : (
                    v.Prenom
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <Input
                      bsSize="sm"
                      type="text"
                      onChange={(e) => setContactProp('Fonction', e.target.value)}
                      value={contact.Fonction}
                    />
                  ) : (
                    v.Fonction
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <Input
                      bsSize="sm"
                      type="text"
                      onChange={(e) => setContactProp('Telephone', e.target.value)}
                      value={contact.Telephone}
                    />
                  ) : (
                    v.Telephone
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <Input
                      bsSize="sm"
                      type="text"
                      onChange={(e) => setContactProp('Email', e.target.value)}
                      value={contact.Email}
                    />
                  ) : (
                    v.Email
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <>
                      <Button
                        size="sm"
                        color="primary"
                        className="mr-1"
                        onClick={async () => {
                          try {
                            setLoading(true)
                            await updateContact(contact)
                            setEdit(null)
                            setContact(emptyContact)
                          } catch (error) {
                            console.error(error)
                          } finally {
                            setLoading(false)
                          }
                        }}
                        disabled={loading}
                      >
                        <span>{t('site.form.validate')}</span>
                      </Button>
                      <Button
                        size="sm"
                        color="secondary"
                        onClick={() => setEdit(null)}
                        disabled={loading}
                        className="bg-white"
                      >
                        <span>{t('site.form.cancel')}</span>
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        size="sm"
                        color="secondary"
                        className="mr-1 bg-white"
                        onClick={() => {
                          setContact(v)
                          setEdit(index + 1)
                        }}
                        disabled={loading}
                      >
                        <span>{t('site.form.modify')}</span>
                      </Button>
                      <Button
                        size="sm"
                        color="secondary"
                        className="bg-white"
                        onClick={async () => {
                          try {
                            setLoading(true)
                            await deleteContact(v.Id)
                          } catch (error) {
                            console.error(error)
                          } finally {
                            setLoading(false)
                          }
                        }}
                        disabled={loading}
                      >
                        <span>{t('site.form.delete')}</span>
                      </Button>
                    </>
                  )}
                </td>
              </tr>
            )
          })}
          {edit === 0 && (
            <tr>
              <th scope="row">
                <Input
                  bsSize="sm"
                  type="text"
                  value={contact.Nom}
                  onChange={(e) => setContactProp('Nom', e.target.value)}
                />
              </th>
              <td>
                <Input
                  bsSize="sm"
                  type="text"
                  value={contact.Prenom}
                  onChange={(e) => setContactProp('Prenom', e.target.value)}
                />
              </td>
              <td>
                <Input
                  bsSize="sm"
                  value={contact.Fonction}
                  onChange={(e) => setContactProp('Fonction', e.target.value)}
                />
              </td>
              <td>
                <Input
                  bsSize="sm"
                  value={contact.Telephone}
                  onChange={(e) => setContactProp('Telephone', e.target.value)}
                />
              </td>
              <td>
                <Input
                  bsSize="sm"
                  type="email"
                  value={contact.Email}
                  onChange={(e) => setContactProp('Email', e.target.value)}
                />
              </td>
              <td>
                <Button
                  size="sm"
                  color="primary"
                  className="mr-1"
                  disabled={loading || Object.values(contact).filter((s) => s !== '').length === 0}
                  onClick={async () => {
                    try {
                      setLoading(true)
                      await createContact(contact)
                      setEdit(null)
                      setContact(emptyContact)
                    } catch (error) {
                      console.error(error)
                    } finally {
                      setLoading(false)
                    }
                  }}
                >
                  <span>{t('site.form.validate')}</span>
                </Button>
                <Button
                  size="sm"
                  color="secondary"
                  disabled={loading}
                  onClick={() => {
                    setEdit(null)
                  }}
                >
                  <span>{t('site.form.cancel')}</span>
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <footer className="mt-3 d-flex justify-content-end">
        {edit !== 0 && (
          <Button
            size="sm"
            color="primary"
            onClick={() => {
              setContact(emptyContact)
              setEdit(0)
            }}
            css={{ padding: '6px 12px' }}
            disabled={loading}
          >
            <i className="icon-collapse-plus" />
            <span>{t('site.form.addContact')}</span>
          </Button>
        )}
      </footer>
    </div>
  )
}
