import React from 'react'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as user from 'commons'

import SidebarRoutes from './SidebarRoutes'
import { mainLogo } from '../../helpers/logo'
import { LogoEleneo } from 'components/common/LogoEleneo'

import { getUor } from 'store/selectors'

const Sidebar = (props) => {
  const { t, langugage } = props
  return (
    <Row>
      <Col className="sidebar">
        <Row>
          <Col
            className="px-0"
            css={{
              height: 138,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {props.logo ? (
              <>
              <Row>
                <NavLink to="/" css={{ paddingBottom: '12px' }} className="logo">
                  <img src={props.logo} alt={t('sidebar.logoAlt')} className="img-fluid" />
                </NavLink>
              </Row>
                { 
                  // Si VINCI
                  props.uor === '735' ?
                  <Row css={{
                        display: 'flex',
                        // justifyContent: 'left',
                        flexDirection: 'row',
                        fontSize: '.9rem',
                        // fontFamily: 'Merlo',
                        // color: '#ffffff',
                      }}>
                    <Col css={{flex: 2}}>
                      <a target="_blank" href={ langugage === "fr" ? "/GUIDE UTILISATEUR_VINCI_eView_FRANCAIS_V2.pdf" : "/GUIDE UTILISATEUR_VINCI_eView_ENGLISH_V2.pdf"}>
                        <span css={{ color: '#f8f9fa', fontSize : 11 }}>
                          {t('global.userGuide')}
                        </span>
                      </a>
                    </Col>
                  </Row>
                  : null
                }
              </>
            ) : (
              <div
                css={{
                  padding: '1.5rem 1rem',
                  height: '100%',
                }}
              >
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                  }}
                >
                  <NavLink
                    to="/"
                    css={{
                      fontFamily: 'Merlo',
                      color: '#f8f9fa',
                      fontSize: '3.6rem',
                      lineHeight: 1,
                      marginBottom: '.2rem',
                      '&:hover': {
                        textDecoration: 'none',
                        color: '#f8f9fa',
                      },
                    }}
                  >
                    <span>eView</span>
                  </NavLink>
                  <div>
                    <span
                      css={{
                        color: '#f8f9fa',
                        fontSize: '.7rem',
                        lineHeight: 1,
                        margin: '0 0.4rem 0 0.9rem',
                      }}
                    >
                      by
                    </span>

                    <a href="https://www.eleneo.fr/" target="_blank" rel="noopener noreferrer">
                      <LogoEleneo color="#f8f9fa" fill="#adb5bd" style={{ fontSize: '1.2rem' }} />
                    </a>
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <SidebarRoutes />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default compose(
  withTranslation(),
  connect((state) => ({ logo: mainLogo(state), uor: getUor(state), langugage: user.lngIso(state), }))
)(Sidebar)
