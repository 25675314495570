import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Row, Col, CardGroup, Alert } from 'reactstrap'
import HighchartsReact from 'highcharts-react-official'
import { Highcharts } from 'helpers/highcharts'

import { initDonutChart } from '../../helpers/initCharts'
import {
  getKpiExpTotal,
  getKpiExpElec,
  getKpiExpGas,
  getKpiPriceTotal,
  getKpiPriceElec,
  getKpiPriceGas,
  getKpiExpTotalLoading,
  getKpiExpElecLoading,
  getKpiExpGasLoading,
  getKpiPriceTotalLoading,
  getKpiPriceElecLoading,
  getKpiPriceGasLoading,
  getPieGraph,
} from '../../reducers/expenses/allAmenities'
import { getCurrentVisiblePeesIds } from '../../reducers/currentSelection'
import * as actions from '../../actions/expenses/allAmenities'
import Kpi from '../kpi/Kpi'

class AllAmenities extends Component {
  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    const {
      fetchKpiExpTotal,
      fetchKpiExpElec,
      fetchKpiExpGas,
      fetchKpiPriceTotal,
      fetchKpiPriceElec,
      fetchKpiPriceGas,
      fetchPieGraph,
    } = this.props
    fetchKpiExpTotal()
    fetchKpiExpElec()
    fetchKpiExpGas()
    fetchKpiPriceTotal()
    fetchKpiPriceElec()
    fetchKpiPriceGas()
    fetchPieGraph()
  }

  render() {
    const {
      currentVisiblePeesIds,
      kpiExpTotal,
      kpiExpElec,
      kpiExpGas,
      kpiPriceTotal,
      kpiPriceElec,
      kpiPriceGas,
      kpiExpTotalLoading,
      kpiExpElecLoading,
      kpiExpGasLoading,
      kpiPriceTotalLoading,
      kpiPriceElecLoading,
      kpiPriceGasLoading,
      pieGraph,
      t,
    } = this.props

    return (
      <div>
        <div>
          <section>
            {currentVisiblePeesIds.length ? (
              <Row>
                <Col xs="9">
                  <Row>
                    <Col>
                      <h5>{t('expenses.labels.expenses')}</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CardGroup>
                        <Kpi decimal={false} {...kpiExpTotal} loading={kpiExpTotalLoading} />
                        <Kpi decimal={false} {...kpiExpElec} loading={kpiExpElecLoading} />
                        <Kpi decimal={false} {...kpiExpGas} loading={kpiExpGasLoading} />
                      </CardGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h5>{t('expenses.labels.price')}</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <CardGroup>
                        <Kpi decimal={true} {...kpiPriceTotal} loading={kpiPriceTotalLoading} />
                        <Kpi decimal={true} {...kpiPriceElec} loading={kpiPriceElecLoading} />
                        <Kpi decimal={true} {...kpiPriceGas} loading={kpiPriceGasLoading} />
                      </CardGroup>
                    </Col>
                  </Row>
                </Col>
                <Col xs="3">
                  <Row>
                    <Col>
                      <h5 className="text-center">{t('expenses.labels.donut')}</h5>
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col className="expenses-chart">
                      <HighchartsReact highcharts={Highcharts} options={initDonutChart(pieGraph)} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <Alert color="warning" className="mb-0">
                {t('errors.noSelection')}
              </Alert>
            )}
          </section>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  currentVisiblePeesIds: getCurrentVisiblePeesIds(state),
  kpiExpTotal: getKpiExpTotal(state),
  kpiExpElec: getKpiExpElec(state),
  kpiExpGas: getKpiExpGas(state),
  kpiPriceTotal: getKpiPriceTotal(state),
  kpiPriceElec: getKpiPriceElec(state),
  kpiPriceGas: getKpiPriceGas(state),
  kpiExpTotalLoading: getKpiExpTotalLoading(state),
  kpiExpElecLoading: getKpiExpElecLoading(state),
  kpiExpGasLoading: getKpiExpGasLoading(state),
  kpiPriceTotalLoading: getKpiPriceTotalLoading(state),
  kpiPriceElecLoading: getKpiPriceElecLoading(state),
  kpiPriceGasLoading: getKpiPriceGasLoading(state),
  pieGraph: getPieGraph(state),
})

export default withTranslation()(connect(mapStateToProps, actions)(AllAmenities))
