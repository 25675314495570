import { store } from '../index'
import { updateView } from './views'

import {
  getCurrentVisibleElecPeesIds,
  getCurrentVisibleGasPeesIds,
} from '../reducers/currentSelection'

import { setElecTooMuchPees } from './powerConsumption/electricity'
import { setGasTooMuchPees } from './powerConsumption/gas'

const MAX_PEES = 10

let visiblePeeCounts = {
  elec: 0,
  gas: 0,
}

const updateAgregationInputs = () => {
  const state = store.getState()

  const nextVisiblePeeCounts = {
    elec: getCurrentVisibleElecPeesIds(state).length,
    gas: getCurrentVisibleGasPeesIds(state).length,
  }

  if (visiblePeeCounts.elec <= MAX_PEES && nextVisiblePeeCounts.elec > MAX_PEES) {
    store.dispatch(setElecTooMuchPees(true))
  } else if (visiblePeeCounts.elec > MAX_PEES && nextVisiblePeeCounts.elec <= MAX_PEES) {
    store.dispatch(setElecTooMuchPees(false))
  }

  if (visiblePeeCounts.gas <= MAX_PEES && nextVisiblePeeCounts.gas > MAX_PEES) {
    store.dispatch(setGasTooMuchPees(true))
  } else if (visiblePeeCounts.gas > MAX_PEES && nextVisiblePeeCounts.gas <= MAX_PEES) {
    store.dispatch(setGasTooMuchPees(false))
  }

  visiblePeeCounts = nextVisiblePeeCounts
}

export const removePeeFromSelection = (state, peeId) => (dispatch) => {
  dispatch({
    type: 'REMOVE_PEE_FROM_SELECTION',
    peeId,
    organisation: state.organisation.organisationEntities,
  })
  updateAgregationInputs()
  dispatch(updateView())
}

export const emptySelection = () => (dispatch) => {
  dispatch({
    type: 'EMPTY_SELECTION',
  })
  updateAgregationInputs()
  dispatch(updateView())
}

export const togglePeeInSelection = (state, peeId) => (dispatch) => {
  dispatch({
    type: 'TOGGLE_PEE_IN_SELECTION',
    peeId,
    organisation: state.organisation.organisationEntities,
  })
  updateAgregationInputs()
  dispatch(updateView())
}

export const toggleSiteInSelection = (state, siteId, filter = 'all', countryFilter = 'all') => (
  dispatch
) => {
  dispatch({
    type: 'TOGGLE_SITE_IN_SELECTION',
    siteId,
    countryFilter,
    filter,
    organisation: state.organisation.organisationEntities,
  })
  updateAgregationInputs()
  dispatch(updateView())
}

export const toggleUorInSelection = (state, uorId, filter = 'all', countryFilter = 'all') => (
  dispatch
) => {
  dispatch({
    type: 'TOGGLE_UOR_IN_SELECTION',
    uorId,
    countryFilter,
    filter,
    organisation: state.organisation.organisationEntities,
  })
  updateAgregationInputs()
  dispatch(updateView())
}

export const toggleSiteOpen = (siteId) => (dispatch) => {
  dispatch({
    type: 'TOGGLE_SITE_OPEN',
    siteId,
  })
}

export const togglePeeVisible = (peeId) => (dispatch) => {
  dispatch({
    type: 'TOGGLE_PEE_VISIBLE',
    peeId,
  })
  updateAgregationInputs()
  dispatch(updateView())
}

export const selectAll = (state, rootUor, filter, countryFilter) => (dispatch) => {
  dispatch({
    type: 'SELECT_ALL',
    filter,
    countryFilter,
    rootUor,
    organisation: state.organisation.organisationEntities,
  })
  updateAgregationInputs()
  dispatch(updateView())
}
