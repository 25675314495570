import { createSelector } from 'reselect'

import { getSites, getPees } from './organisation'

const currentSelection = (state = { pees: {}, sites: {}, uors: {} }, action) => {
  const initialState = { pees: {}, sites: {}, uors: {} }

  const toggleUor = (state, uor, organisation) => {
    let newState = { ...state }
    const uorSites =
      uor.UorSites !== undefined && uor.UorSites !== null
        ? uor.UorSites.filter((siteId) => newState.sites[siteId]).length
        : 0

    const uorSitesIndeterminate =
      uor.UorSites !== undefined && uor.UorSites !== null
        ? uor.UorSites.filter(
            (siteId) => newState.sites[siteId] === undefined || newState.sites[siteId].indeterminate
          ).length > 0
        : false

    const uorChildren =
      uor.UorEnfants !== undefined && uor.UorEnfants !== null
        ? uor.UorEnfants.filter((uorId) => newState.uors[uorId]).length
        : 0

    const uorChildrenIndeterminate =
      uor.UorEnfants !== undefined && uor.UorEnfants !== null
        ? uor.UorEnfants.filter(
            (uorId) => newState.uors[uorId] === undefined || newState.uors[uorId].indeterminate
          ).length > 0
        : false

    if (!uorSites && !uorChildren) {
      delete newState.uors[uor.id]
    } else if (uorSitesIndeterminate || uorChildrenIndeterminate) {
      newState.uors[uor.id] = { indeterminate: true }
    } else {
      newState.uors[uor.id] = { indeterminate: false }
    }

    if (uor.parentUor !== uor.id) {
      newState = toggleUor(newState, organisation.uors[uor.parentUor], organisation)
    }

    return newState
  }

  const toggleUors = (state, peeId, organisation) => {
    let newState = { ...state }
    const site = organisation.sites[organisation.pees[peeId].parentSite]
    const uor = organisation.uors[site.parentUor]

    newState = toggleUor(newState, uor, organisation)

    return newState
  }

  const toggleUorOff = (state, uorId, organisation) => {
    let newState = { ...state }
    const uorSites = organisation.uors[uorId].UorSites || null
    const uorChildren = organisation.uors[uorId].UorEnfants || null
    const uorParent = organisation.uors[organisation.uors[uorId].parentUor]

    if (uorSites !== null) {
      uorSites.map((siteId) => {
        const sitePees = organisation.sites[siteId].SitPees || null
        if (sitePees !== null) {
          sitePees.map((peeId) => delete newState.pees[peeId])
        }
        delete newState.sites[siteId]
        return ''
      })
    }

    delete newState.uors[uorId]

    if (uorChildren !== null) {
      uorChildren.map((uorId) => toggleUorOff(newState, uorId, organisation))
    }

    if (uorParent && uorParent.id !== uorId) {
      newState = toggleUor(newState, uorParent, organisation)
    }

    return newState
  }

  /**
   *
   * @param state
   * @param uorId
   * @param organisation
   * @param filter: searchPanel.filterType || 'all'
   * @returns {{}}
   */
  const toggleUorOn = (state, uorId, organisation, filter = 'all', countryFilter = 'all') => {
    let newState = { ...state }
    const uorSites = organisation.uors[uorId].UorSites || null
    const uorChildren = organisation.uors[uorId].UorEnfants || null
    const uorParent = organisation.uors[organisation.uors[uorId].parentUor]

    let anySiteSelected = false
    let allSiteSelected = true

    if (uorSites !== null) {
      uorSites.map((siteId) => {
        const sitePees = organisation.sites[siteId].SitPees || null //
        let anyPeeSelected = false
        let allPeeSelected = true
        if (sitePees !== null) {
          sitePees.forEach((peeId) => {
            if (
              (filter === 'all' || organisation.pees[peeId].PeeNrjId === filter) &&
              (countryFilter === 'all' || organisation.pees[peeId].IdPays === countryFilter)
            ) {
              anyPeeSelected = true
              newState.pees[peeId] = { visible: true, ...newState.pees[peeId] }
            } else {
              allPeeSelected = false
              allSiteSelected = false
            }
          })
        }
        if (anyPeeSelected) {
          anySiteSelected = true
          newState.sites[siteId] = {
            open: false,
            ...newState.sites[siteId],
            indeterminate: !allPeeSelected,
          }
        } else {
          allSiteSelected = false
        }
        return ''
      })
    }

    if (anySiteSelected) {
      newState.uors[uorId] = { indeterminate: !allSiteSelected }
    }

    if (uorChildren !== null) {
      uorChildren.map((uorId) => toggleUorOn(newState, uorId, organisation, filter, countryFilter))
    }

    if (uorParent && uorParent.id !== uorId) {
      newState = toggleUor(newState, uorParent, organisation)
    }

    return newState
  }

  const toggleSite = (state, peeId, organisation) => {
    let newState = { ...state }
    const site = organisation.sites[organisation.pees[peeId].parentSite]
    const nbSelectedSitePees = site.SitPees.filter((peeId) => newState.pees[peeId] !== undefined)
      .length

    if (nbSelectedSitePees === 0) {
      delete newState.sites[site.id]
    } else if (nbSelectedSitePees === site.SitPees.length) {
      newState.sites[site.id] = { open: false, ...newState.sites[site.id], indeterminate: false }
    } else {
      newState.sites[site.id] = { open: false, ...newState.sites[site.id], indeterminate: true }
    }

    return newState
  }

  let newState = {}

  switch (action.type) {
    case 'REMOVE_PEE_FROM_SELECTION':
      newState = { ...state }

      delete newState.pees[action.peeId]

      newState = toggleSite(newState, action.peeId, action.organisation)
      newState = toggleUors(newState, action.peeId, action.organisation)

      return newState
    case 'TOGGLE_PEE_IN_SELECTION':
      newState = { ...state }

      newState.pees[action.peeId] === undefined
        ? (newState.pees[action.peeId] = { visible: true })
        : delete newState.pees[action.peeId]

      newState = toggleSite(newState, action.peeId, action.organisation)
      newState = toggleUors(newState, action.peeId, action.organisation)

      return newState
    case 'TOGGLE_SITE_IN_SELECTION':
      newState = { ...state }

      const site = action.organisation.sites[action.siteId]

      if (
        newState.sites[action.siteId] !== undefined &&
        !newState.sites[action.siteId].indeterminate
      ) {
        site.SitPees.forEach((peeId) => delete newState.pees[peeId])
        delete newState.sites[action.siteId]
      } else {
        let indeterminate = false
        site.SitPees.forEach((peeId) => {
          const peeInfo = action.organisation.pees[peeId]
          if (
            (action.filter === 'all' || peeInfo.PeeNrjId === action.filter) &&
            (action.countryFilter === 'all' || peeInfo.IdPays === action.countryFilter)
          ) {
            newState.pees[peeId] = {
              visible: true,
              ...newState.pees[peeId],
            }
          } else {
            indeterminate = true
          }
        })
        newState.sites[action.siteId] = {
          open: false,
          ...newState.sites[action.sitePid],
          indeterminate: indeterminate,
        }
      }

      newState = toggleUors(
        newState,
        site.SitPees[Object.keys(site.SitPees)[0]],
        action.organisation
      )

      return newState
    case 'TOGGLE_UOR_IN_SELECTION':
      newState = { ...state }

      newState =
        newState.uors[action.uorId] && !newState.uors[action.uorId].indeterminate
          ? toggleUorOff(newState, action.uorId, action.organisation)
          : toggleUorOn(
              newState,
              action.uorId,
              action.organisation,
              action.filter,
              action.countryFilter
            )

      return newState
    case 'SET_FAVORITES':
      newState = initialState

      action.peesIds.map((peeId) => {
        newState.pees[peeId] = { visible: true }
        newState = toggleSite(newState, peeId, action.organisation)
        newState = toggleUors(newState, peeId, action.organisation)
        return ''
      })

      return newState
    case 'TOGGLE_SITE_OPEN':
      newState = { ...state }
      newState.sites[action.siteId].open = !newState.sites[action.siteId].open
      return newState
    case 'TOGGLE_PEE_VISIBLE':
      newState = { ...state }
      newState.pees[action.peeId].visible = !newState.pees[action.peeId].visible
      return newState
    case 'SELECT_ALL':
      newState = initialState
      newState = toggleUorOn(
        newState,
        action.rootUor,
        action.organisation,
        action.filter,
        action.countryFilter
      )
      return newState
    case 'EMPTY_SELECTION':
      return initialState
    case 'FETCH_ORGANISATION_SUCCESS':
      newState = { ...state }
      Object.keys(newState.pees).map((peeId) =>
        Object.keys(action.response.entities.pees).indexOf(peeId) === -1
          ? delete newState.pees[peeId]
          : null
      )
      return newState
    default:
      return state
  }
}

export default currentSelection

export const getIsCurrentPee = (state, peeId) => state.currentSelection.pees[peeId] !== undefined
export const getIsPeeVisible = (state, peeId) =>
  state.currentSelection.pees[peeId] !== undefined && state.currentSelection.pees[peeId].visible
export const getIsSiteIndeterminate = (state, siteId) =>
  state.currentSelection.sites[siteId] !== undefined &&
  state.currentSelection.sites[siteId].indeterminate
export const getIsSiteSelected = (state, siteId) =>
  state.currentSelection !== undefined && state.currentSelection.sites[siteId] ? true : false
export const getIsSiteOpen = (state, siteId) =>
  state.currentSelection.sites[siteId] !== undefined && state.currentSelection.sites[siteId].open
export const getIsUorIndeterminate = (state, uorId) =>
  state.currentSelection.uors[uorId] !== undefined &&
  state.currentSelection.uors[uorId].indeterminate
export const getIsUorSelected = (state, uorId) =>
  state.currentSelection !== undefined && state.currentSelection.uors[uorId] ? true : false
export const getCurrentPeesIds = (state) =>
  (state.currentSelection && Object.keys(state.currentSelection.pees)) || []
export const getObjectOfCurrentPeesIds = (state) => getCurrentPeesIds(state).map((e) => ({ id: e }))

export const getCurrentVisiblePeesIds = (state) =>
  (state.currentSelection &&
    Object.keys(state.currentSelection.pees).filter(
      (peeId) => state.currentSelection.pees[peeId].visible
    )) ||
  []
export const getCurrentElecPeesIds = (state) =>
  getCurrentPeesIds(state).filter(
    (peeId) =>
      state.organisation.organisationEntities.pees[peeId] &&
      state.organisation.organisationEntities.pees[peeId].PeeNrjId === 2
  ) || []
export const getCurrentVisibleElecPeesIds = (state) =>
  getCurrentVisiblePeesIds(state).filter(
    (peeId) =>
      state.organisation.organisationEntities.pees[peeId] &&
      state.organisation.organisationEntities.pees[peeId].PeeNrjId === 2
  ) || []
export const getCurrentGasPeesIds = (state) =>
  getCurrentPeesIds(state).filter(
    (peeId) =>
      state.organisation.organisationEntities.pees[peeId] &&
      state.organisation.organisationEntities.pees[peeId].PeeNrjId === 1
  ) || []
export const getCurrentVisibleGasPeesIds = (state) =>
  getCurrentVisiblePeesIds(state).filter(
    (peeId) =>
      state.organisation.organisationEntities.pees[peeId] &&
      state.organisation.organisationEntities.pees[peeId].PeeNrjId === 1
  ) || []
export const getCountSelected = (state) => getCurrentPeesIds(state).length || 0
export const getCurrentSitesIds = (state) =>
  Object.keys(state.currentSelection.sites).map(Number) || []
export const getCurrentSites = (state) =>
  getCurrentSitesIds(state).reduce((currentSites, siteId) => {
    currentSites[siteId] = state.organisation.organisationEntities.sites[siteId]
    return currentSites
  }, {})
export const getSiteCurrentPeesIds = (state, siteId) =>
  (state.organisation.organisationEntities.sites[siteId] !== undefined &&
    state.organisation.organisationEntities.sites[siteId].SitPees !== undefined &&
    state.organisation.organisationEntities.sites[siteId].SitPees.filter(
      (peeId) =>
        getCurrentPeesIds(state)
          .map(Number)
          .indexOf(peeId) > -1
    )) ||
  []
export const getSiteCurrentPees = (state, siteId) =>
  getSiteCurrentPeesIds(state, siteId).reduce((currentSitePees, peeId) => {
    currentSitePees[peeId] = state.organisation.organisationEntities.pees[peeId]
    return currentSitePees
  }, {})

export const getCurrentVisiblePeesBySite = createSelector(
  getSites,
  getPees,
  getCurrentVisiblePeesIds,
  (sites, pees, selection) => {
    const peesBySite = selection.reduce((acc, peeId) => {
      const pee = pees[peeId]
      if (!pee) return acc

      const siteId = pee.parentSite
      if (!siteId) return acc

      const siteName = sites[siteId].SphNom
      if (!siteName) return acc

      if (!acc[siteId]) {
        acc[siteId] = {
          id: siteId,
          name: siteName,
          pees: [pee],
        }
      } else {
        acc[siteId].pees.push(pee)
      }

      return acc
    }, {})

    return Object.values(peesBySite)
  }
)
