import React from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'

import PeeSelect from './PeeSelect'
import DetailKPI from './DetailKPI'
import DetailGraph from './DetailGraph'

import { fetchGraph } from 'actions/expenses/detail'
import { getStartDate, getEndDate } from 'reducers/powerConsumption/loadCurve'

const Detail = ({ startDate, endDate, fetchGraph }) => {
  const [selectedPee, setSelectedPee] = React.useState(null)

  React.useEffect(() => {
    if (!selectedPee) return
    const peeIds = [selectedPee]
    fetchGraph({ peeIds, startDate, endDate })
  }, [fetchGraph, selectedPee, startDate, endDate])

  return (
    <section>
      <Row>
        <Col>
          <PeeSelect selected={selectedPee} setSelected={setSelectedPee} />
        </Col>
      </Row>
      {selectedPee && (
        <>
          <Row className="mt-4">
            <Col>
              <DetailKPI />
            </Col>
          </Row>

          <Row>
            <Col>
              <DetailGraph />
            </Col>
          </Row>
        </>
      )}
    </section>
  )
}

const mapState = (state) => ({
  startDate: getStartDate(state),
  endDate: getEndDate(state),
})

const mapDispatch = {
  fetchGraph,
}

export default connect(mapState, mapDispatch)(Detail)
