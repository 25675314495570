import React, { Component } from 'react'
import { connect, ReactReduxContext } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import Notifications from 'react-notification-system-redux'

import * as actions from '../../actions/bills'
import {
  getSortedBillsIds,
  getCurrentBillForm,
  getBillPutSuccess,
  getBillPutError,
} from '../../reducers/bills'
import Bill from './Bill'
import BillNav from './BillNav'

class VisibleBill extends Component {
  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.billId !== this.props.match.params.billId) {
      this.fetchData()
    }
  }

  UNSAFE_componentWillReceiveProps({ billPutSuccess, billPutError }) {
    if (billPutSuccess !== this.props.billPutSuccess && billPutSuccess) {
      this.props.store.dispatch(
        Notifications.success({
          title: 'Bill has been saved successfully !',
          position: 'bl',
          autoDismiss: 4,
        })
      )
    }

    if (billPutError !== this.props.billPutError && billPutError) {
      this.props.store.dispatch(
        Notifications.error({
          title: 'Something went wrong !',
          position: 'bl',
          autoDismiss: 4,
        })
      )
    }
  }

  fetchData() {
    const { fetchBill, match } = this.props
    fetchBill(match.params.billId)
  }

  render() {
    const { bill, sortedBillsIds } = this.props
    return (
      <div>
        <Link className="bill-overlay" to="/bills" />
        {sortedBillsIds.length > 1 && (
          <BillNav
            prev={
              sortedBillsIds[sortedBillsIds.indexOf(bill.id) - 1] ||
              sortedBillsIds[sortedBillsIds.length - 1]
            }
            next={sortedBillsIds[sortedBillsIds.indexOf(bill.id) + 1] || sortedBillsIds[0]}
          />
        )}
        <div className="bill">
          <Bill {...this.props} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  bill: getCurrentBillForm(state),
  sortedBillsIds: getSortedBillsIds(state),
  billPutSuccess: getBillPutSuccess(state),
  billPutError: getBillPutError(state),
})

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )((props) => (
    <ReactReduxContext.Consumer>
      {({ store }) => <VisibleBill {...props} store={store} />}
    </ReactReduxContext.Consumer>
  ))
)
