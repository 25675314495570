const decomposition = (state = ['Energie', 'Transport', 'Taxes', 'Autres'], action) => {
  switch (action.type) {
    case 'LOAD_CURVE_TOGGLE_DECOMPOSITION':
      return state.indexOf(action.decomposition) === -1
        ? [...state, action.decomposition]
        : state.filter((decomposition) => decomposition !== action.decomposition)
    default:
      return state
  }
}

export default decomposition

export const getIsDecompositionChecked = (state, value) =>
  state.expenses.decomposition.indexOf(value) !== -1
export const getDecomposition = (state) => state.expenses.decomposition
