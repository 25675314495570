import { combineReducers } from 'redux'

const allAmenities = () => {
  const kpiExpTotalUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ALL_EXP_TOTAL_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiExpElecUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ALL_EXP_ELEC_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiExpGasUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ALL_EXP_GAS_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiPriceTotalUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ALL_PRICE_TOTAL_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiPriceElecUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ALL_PRICE_ELEC_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiPriceGasUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ALL_PRICE_GAS_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const pieGraphUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ALL_PIE_GRAPH_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiExpTotal = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ALL_EXP_TOTAL_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiExpTotalLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ALL_EXP_TOTAL_REQUEST':
        return true
      case 'FETCH_EXPENSES_ALL_EXP_TOTAL_SUCCESS':
      case 'FETCH_EXPENSES_ALL_EXP_TOTAL_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiExpElec = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ALL_EXP_ELEC_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiExpElecLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ALL_EXP_ELEC_REQUEST':
        return true
      case 'FETCH_EXPENSES_ALL_EXP_ELEC_SUCCESS':
      case 'FETCH_EXPENSES_ALL_EXP_ELEC_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiExpGas = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ALL_EXP_GAS_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiExpGasLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ALL_EXP_GAS_REQUEST':
        return true
      case 'FETCH_EXPENSES_ALL_EXP_GAS_SUCCESS':
      case 'FETCH_EXPENSES_ALL_EXP_GAS_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiPriceTotal = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ALL_PRICE_TOTAL_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiPriceTotalLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ALL_PRICE_TOTAL_REQUEST':
        return true
      case 'FETCH_EXPENSES_ALL_PRICE_TOTAL_SUCCESS':
      case 'FETCH_EXPENSES_ALL_PRICE_TOTAL_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiPriceElec = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ALL_PRICE_ELEC_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiPriceElecLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ALL_PRICE_ELEC_REQUEST':
        return true
      case 'FETCH_EXPENSES_ALL_PRICE_ELEC_SUCCESS':
      case 'FETCH_EXPENSES_ALL_PRICE_ELEC_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiPriceGas = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ALL_PRICE_GAS_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiPriceGasLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ALL_PRICE_GAS_REQUEST':
        return true
      case 'FETCH_EXPENSES_ALL_PRICE_GAS_SUCCESS':
      case 'FETCH_EXPENSES_ALL_PRICE_GAS_FAILURE':
        return false
      default:
        return state
    }
  }

  const pieGraph = (state = {}, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ALL_PIE_GRAPH_SUCCESS':
        return action.response
      default:
        return state
    }
  }

  return combineReducers({
    kpiExpTotalUuid,
    kpiExpElecUuid,
    kpiExpGasUuid,
    kpiPriceTotalUuid,
    kpiPriceElecUuid,
    kpiPriceGasUuid,
    pieGraphUuid,
    kpiExpTotal,
    kpiExpElec,
    kpiExpGas,
    kpiPriceTotal,
    kpiPriceElec,
    kpiPriceGas,
    kpiExpTotalLoading,
    kpiExpElecLoading,
    kpiExpGasLoading,
    kpiPriceTotalLoading,
    kpiPriceElecLoading,
    kpiPriceGasLoading,
    pieGraph,
  })
}

export default allAmenities

export const getKpiExpTotalUuid = (state) => state.expenses.allAmenities.kpiExpTotalUuid
export const getKpiExpElecUuid = (state) => state.expenses.allAmenities.kpiExpElecUuid
export const getKpiExpGasUuid = (state) => state.expenses.allAmenities.kpiExpGasUuid
export const getKpiPriceTotalUuid = (state) => state.expenses.allAmenities.kpiPriceTotalUuid
export const getKpiPriceElecUuid = (state) => state.expenses.allAmenities.kpiPriceElecUuid
export const getKpiPriceGasUuid = (state) => state.expenses.allAmenities.kpiPriceGasUuid
export const getPieGraphUuid = (state) => state.expenses.allAmenities.pieGraphUuid

export const getKpiExpTotal = (state) => state.expenses.allAmenities.kpiExpTotal
export const getKpiExpElec = (state) => state.expenses.allAmenities.kpiExpElec
export const getKpiExpGas = (state) => state.expenses.allAmenities.kpiExpGas
export const getKpiPriceTotal = (state) => state.expenses.allAmenities.kpiPriceTotal
export const getKpiPriceElec = (state) => state.expenses.allAmenities.kpiPriceElec
export const getKpiPriceGas = (state) => state.expenses.allAmenities.kpiPriceGas

export const getKpiExpTotalLoading = (state) => state.expenses.allAmenities.kpiExpTotalLoading
export const getKpiExpElecLoading = (state) => state.expenses.allAmenities.kpiExpElecLoading
export const getKpiExpGasLoading = (state) => state.expenses.allAmenities.kpiExpGasLoading
export const getKpiPriceTotalLoading = (state) => state.expenses.allAmenities.kpiPriceTotalLoading
export const getKpiPriceElecLoading = (state) => state.expenses.allAmenities.kpiPriceElecLoading
export const getKpiPriceGasLoading = (state) => state.expenses.allAmenities.kpiPriceGasLoading

export const getPieGraph = (state) => state.expenses.allAmenities.pieGraph
