import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import flatMap from 'lodash/flatMap'
import { Form, FormGroup, Label } from 'reactstrap'

import Select from 'components/common/Select'
import { getCurrentVisiblePeesBySite } from 'reducers/currentSelection'

const PeeSelect = ({ t, peesBySite, selected, setSelected }) => {
  const peesOptions = peesBySite.map(({ name, pees }) => ({
    label: name,
    options: pees.map(({ id, PeeClef, PeeNrjId }) => ({
      value: id,
      label: PeeClef,
      nrj: PeeNrjId,
      site: name,
    })),
  }))

  const flattenPeeOptions = flatMap(peesOptions, (o) => o.options)
  const value = flattenPeeOptions.filter((o) => selected === o.value)

  React.useEffect(() => {
    if (!selected && flattenPeeOptions.length > 0) {
      setSelected(flattenPeeOptions[0].value)
    }
  }, [flattenPeeOptions, setSelected, selected])

  return (
    <Form inline>
      <FormGroup>
        <Label>{t('expenses.detail.pee')} : </Label>
        <Select
          value={value}
          onChange={(option) => {
            setSelected(option.value)
          }}
          options={peesOptions}
          width={250}
          isClearable={false}
          isSearchable={true}
          isMulti={false}
          // menuIsOpen={true}
        />
      </FormGroup>
    </Form>
  )
}

const mapState = (state) => ({
  peesBySite: getCurrentVisiblePeesBySite(state),
})

export default compose(withTranslation(), connect(mapState))(PeeSelect)
