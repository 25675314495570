import { apiConf as api } from 'commons'

/**
 * Récupère les cotations disponibles pour le user connecté
 */
export const fetchMyAvailableQuotations = () => {
  return api.superFetch({ url: `cotations/me/market` })
}

/**
 * Récupère les cotations suivies par l'utilisateur connecté
 */
export const fetchMyQuotations = () => {
  return api.superFetch({ url: 'cotations/me' })
}

/**
 * Ajoute des cotations suivies pour l'utilisateur connecté
 */
export const addQuotationForUser = (quotations) => {
  return api.superFetch({ url: 'cotations/me', method: 'POST', body: quotations })
}

/**
 * Supprime une cotation suivie par l'utilisateur connecté
 */
export const deleteMyQuotation = (idCotation) => {
  return api.superFetch({ url: `cotations/me/${idCotation}`, method: 'DELETE' })
}

/**
 * Récupère le graph de cotation d'une quotation
 *
 * @param {*} quotationId
 */
export const fetchQuotationGraph = (quotationIds) => {
  return api.superFetch({ url: 'cotations/graphv2', method: 'POST', body: quotationIds })
}
