import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Form, FormGroup, Label, Input } from 'reactstrap'

import { getGraphUnite } from '../../reducers/powerConsumption'
import { updateGraphUnite } from '../../actions/powerConsumption'

const FormGraph = ({
  graphUnite,
  unitOptions = [
    { id: '11', label: 'kWh' },
    { id: '12', label: 'MWh' },
  ],
  showAgregationInput = true,
  agregation,
  handleAgregationChange,
  tooMuchPees,
  dispatch,
  t,
}) => {
  return (
    <Form inline className="form-graph">
      <FormGroup>
        <Label for="graphUnite">{t('powerConsumption.unit')}:</Label>
        <Input
          type="select"
          id="graphUnite"
          value={graphUnite}
          onChange={(e) => dispatch(updateGraphUnite(e.target.value))}
        >
          {unitOptions.map((unit) => {
            return (
              <option key={unit.id} value={unit.id}>
                {unit.label}
              </option>
            )
          })}
        </Input>
      </FormGroup>

      {showAgregationInput && (
        <FormGroup>
          <Label for="graphgregation">{t('powerConsumption.aggregation.label')}: </Label>
          <Input
            type="select"
            id="graphAgregation"
            value={tooMuchPees ? 2 : agregation}
            onChange={(e) => handleAgregationChange(e.target.value)}
          >
            <option value="0" disabled={tooMuchPees}>
              {t('powerConsumption.aggregation.byPee')}
            </option>
            {/* <option value="1" disabled={tooMuchPees}>{t("powerConsumption.aggregation.bySite")}</option> */}
            <option value="2">{t('powerConsumption.aggregation.all')}</option>
          </Input>
        </FormGroup>
      )}
    </Form>
  )
}

const mapStateToProps = (state, ownProps) => ({
  graphUnite: getGraphUnite(state),
})

export default withTranslation()(connect(mapStateToProps)(FormGraph))
