export const getIconClassName = function(id) {
  switch (id) {
    case 1:
      return 'icon-gaz'
    case 2:
      return 'icon-elec'
    default:
      return 'icon-gaz'
  }
}
