import { useMutation, queryCache } from 'react-query'
import { request } from './request'

const options = (siteId) => ({
  suspense: false,
  useErrorBoundary: false,
  throwOnError: true,
  onSuccess: () => {
    queryCache.refetchQueries(['infos', { id: siteId }], { force: true })
  },
})

export const useCreateContact = (siteId) => {
  return useMutation(
    (payload) =>
      request({
        url: `contacts/sites/${siteId}`,
        method: 'POST',
        body: payload,
      }),
    options(siteId)
  )
}

export const useUpdateContact = (siteId) => {
  return useMutation(
    (payload) =>
      request({
        url: `contacts/${payload.Id}`,
        method: 'PUT',
        body: payload,
      }),
    options(siteId)
  )
}

export const useDeleteContact = (siteId) => {
  return useMutation(
    (contactId) =>
      request(
        {
          url: `contacts/${contactId}/sites/${siteId}`,
          method: 'DELETE',
        },
        false
      ),
    options(siteId)
  )
}
