import * as userApi from '../api/user.js'
import * as user from '../reducers/user'
import i18n, { lngIso } from '../i18n/i18n'
import v4 from 'uuid/v4'

import {
  USER_LOGOUT_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  USER_INFO_REQUEST,
  USER_INFO_SUCCESS,
  USER_INFO_FAILURE,
  USER_FORM_POST_REQUEST,
  USER_FORM_POST_SUCCESS,
  USER_FORM_POST_FAILURE,
} from './'

export const logout = (connectedUser) => (dispatch) => {
  dispatch({
    type: USER_LOGOUT_REQUEST,
  })

  return userApi.logout(connectedUser.id_token)
}

export const fetchUserInfo = () => (dispatch, getState) => {
  dispatch({
    type: USER_INFO_REQUEST,
  })

  const platform = user.platform(getState())

  return userApi.userInfos(platform).then(
    (response) => {
      if (response) {
        if (response.status === 200) {
          response.json().then((response) =>
            dispatch({
              type: USER_INFO_SUCCESS,
              user: response,
            })
          )
        } else {
          dispatch({
            type: USER_INFO_FAILURE,
            message: 'Something went wrong : ' + response.status,
          })
        }
      }
    },
    (error) => {
      dispatch({
        type: USER_INFO_FAILURE,
        message: error.Message || 'Something went wrong.',
      })
    }
  )
}

export const postUserForm = (usr) => (dispatch, getState) => {
  dispatch({
    type: USER_FORM_POST_REQUEST,
  })

  const platform = user.platform(getState())

  return userApi.postUser(usr, platform).then(
    (response) => {
      if (response.status === 200) {
        response.json().then((response) => {
          dispatch({
            type: USER_FORM_POST_SUCCESS,
            user: response,
          })
          i18n.changeLanguage(lngIso(response.LanId))
          // highchartConf(response.LanId);
          document.body.classList.remove('no-scroll')
        })
      } else {
        response.json().then((response) =>
          dispatch({
            type: USER_FORM_POST_FAILURE,
            message: response.Message,
          })
        )
      }
    },
    (error) => {
      dispatch({
        type: USER_FORM_POST_FAILURE,
        message: error.Message || 'Something went wrong.',
      })
    }
  )
}

export const changePassword = (passwordChange) => (dispatch, getState) => {
  const uuid = v4()
  dispatch({
    type: CHANGE_PASSWORD_REQUEST,
    uuid,
  })

  const platform = user.platform(getState())

  return userApi.changePassword(uuid, passwordChange, platform).then(
    (response) => {
      if (response.status === 200) {
        dispatch({
          type: CHANGE_PASSWORD_SUCCESS,
        })
      } else {
        response.json().then((response) =>
          dispatch({
            type: CHANGE_PASSWORD_FAILURE,
            message: response.Message || 'Something went wrong.',
          })
        )
      }
    },
    (error) => {
      dispatch({
        type: CHANGE_PASSWORD_FAILURE,
        message: error.Message || 'Something went wrong.',
      })
    }
  )
}
