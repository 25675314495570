import React from 'react'
import ReactSelect, { components } from 'react-select'

import styles from './Select.module.scss'

const selectColor = 'rgb(81, 91, 179)'
const focusColor = 'rgba(81, 91, 179, .3)'

const multiStyles = (width) => ({
  control: (provided, state) => ({
    ...provided,
    minHeight: 'initial',
    borderRadius: '.2rem',
    borderColor: state.isFocused ? 'transparent' : '#d7dee4',
    boxShadow: state.isFocused ? `0 0 0 1px ${selectColor}` : 'unset',
    paddingTop: '1px',
    cursor: 'default',
    ':hover': {
      borderColor: state.isFocused ? 'transparent' : selectColor,
    },
  }),
  container: (provided) => ({
    ...provided,
    fontSize: '14px',
    // overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: width || 'unset',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    // display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '3px 8px',
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    alignItems: 'center',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 1,
  }),
  group: (provided) => ({
    ...provided,
    padding: '0 0 4px 0',
  }),
  groupHeading: (provided) => ({
    ...provided,
    borderTop: '1px solid #9995',
    paddingTop: 8,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '12px',
    padding: '6px 8px',
    cursor: 'default',
    ':active': {
      background: selectColor,
      color: '#fff',
    },
    ...(state.isFocused && {
      background: focusColor,
      color: 'unset',
    }),
    ...(state.isSelected && {
      background: selectColor,
      color: '#fff',
    }),
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    left: '220px',
    height: '32px',
    overflow: 'visible',
    width: 'calc(100vw - 690px)',
    flex: 'none',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    ':before': {
      content: `"${state.selectProps.value.length}/10 ${state.selectProps.selectedLabel}"`,
      position: 'absolute',
      left: '-210px',
      flex: 'none',
      color: '#495057',
    },
  }),
})

const simpleStyles = (width) => ({
  control: (provided, state) => ({
    ...provided,
    minHeight: 'initial',
    borderRadius: '.2rem',
    borderColor: state.isFocused ? 'transparent' : '#d7dee4',
    boxShadow: state.isFocused ? `0 0 0 1px ${selectColor}` : 'unset',
    paddingTop: '1px',
    cursor: 'default',
    ':hover': {
      borderColor: state.isFocused ? 'transparent' : selectColor,
    },
  }),
  container: (provided) => ({
    ...provided,
    fontSize: '14px',
    width: width || 'unset',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '3px 8px',
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    alignItems: 'center',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 1,
  }),
  group: (provided) => ({
    ...provided,
    padding: '0 0 4px 0',
  }),
  groupHeading: (provided) => ({
    ...provided,
    borderTop: '1px solid #9995',
    paddingTop: 8,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '12px',
    padding: '6px 8px',
    cursor: 'default',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    ':active': {
      background: selectColor,
      color: '#fff',
    },
    ...(state.isFocused && {
      background: focusColor,
      color: 'unset',
    }),
    ...(state.isSelected && {
      background: selectColor,
      color: '#fff',
    }),
  }),
})

const MultiValueLabel = (props) => (
  <components.MultiValueLabel {...props}>
    <span className={styles.label}>
      {props.data.nrj && (
        <span className={props.data.nrj === 2 ? styles.optionIconElec : styles.optionIconGaz}>
          <i className={props.data.nrj === 2 ? 'icon-elec' : 'icon-gaz'} />
        </span>
      )}
      {props.data.site} - {props.data.label}
    </span>
  </components.MultiValueLabel>
)

const GroupHeading = (props) => (
  <components.GroupHeading {...props}>
    <span>{props.children}</span>
  </components.GroupHeading>
)

const Option = (props) => (
  <components.Option {...props}>
    {props.data.nrj && (
      <span className={props.data.nrj === 2 ? styles.optionIconElec : styles.optionIconGaz}>
        <i className={props.data.nrj === 2 ? 'icon-elec' : 'icon-tint'} />
      </span>
    )}
    {props.label}
  </components.Option>
)

const Select = ({ size, width, ...rest }) => {
  return (
    <ReactSelect
      styles={size === 'multi' ? multiStyles(width) : simpleStyles(width)}
      className="react-select"
      classNamePrefix="react-select"
      components={{
        GroupHeading,
        Option,
        MultiValueLabel,
        IndicatorSeparator: null,
      }}
      {...rest}
    />
  )
}

export default Select
