import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import Datetime from 'react-datetime'
import moment from 'moment'

import * as user from 'commons'
import { dateFormat } from '../../helpers/helpers'
import { getDateRange } from '../../reducers/powerConsumption/loadCurve'
import { updateStartDate } from '../../actions/powerConsumption/loadCurve'

const DateRangeStartDateLoadCurve = ({ dispatch, dateRange, lng }) => (
  <Datetime
    id="startDate"
    locale={lng}
    closeOnSelect
    dateFormat={dateFormat()}
    timeFormat={false}
    value={moment(dateRange.startDate).toDate()}
    onChange={(date) => {
      if (!moment.isMoment(date)) return
      dispatch(updateStartDate(date))
    }}
  />
)

const mapStateToProps = (state) => ({
  dateRange: getDateRange(state),
  lng: user.lngIso(state),
})

DateRangeStartDateLoadCurve.propTypes = {
  dispatch: PropTypes.func.isRequired,
  dateRange: PropTypes.object.isRequired,
  lng: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(DateRangeStartDateLoadCurve)
