import PropTypes from 'prop-types'
import React from 'react'
import { Table } from 'reactstrap'
import { withTranslation } from 'react-i18next'

import { decimalFormat } from '../../helpers/helpers'

const icons = {
  'Volume contractuel': 'courbe-full',
  'Contract Volume': 'courbe-full',

  Energie: 'eclair',
  Energy: 'eclair',

  Transport: 'truck',
  'Grid Access': 'truck',

  Taxes: 'taxes',

  'Other(s)': 'vallet',
  'Autre(s)': 'vallet',
}

const BillTable = ({ FacGroupeLfa, FacDevise, t }) => (
  <Table bordered>
    <thead>
      <tr>
        <th>{t('bills.bill.poste')}</th>
        <th>{t('bills.bill.detail')}</th>
        <th>{t('bills.bill.periode')}</th>
        <th>{t('bills.bill.montant')}</th>
        {/*<th>{t("bills.bill.ecarts")}</th>*/}
      </tr>
    </thead>

    {FacGroupeLfa.map((i, iIndex) => (
      <tbody key={iIndex}>
        {i.IatAgrLignesFacture.map((j, jIndex) => (
          <tr key={j.id} className={'row-' + (iIndex % 2)}>
            {!jIndex && (
              <td rowSpan={i.IatAgrLignesFacture.length}>
                <i className={'icon-' + icons[i.IatAgrLibelle]} />
                <p>
                  <span>{i.IatAgrLibelle}</span>
                  <br />
                  <span className="price">
                    {decimalFormat(
                      i.IatAgrLignesFacture.reduce((cnt, l) => {
                        return cnt + l.LfaValeur
                      }, 0)
                    )}{' '}
                    {i.IatAgrUniteOuDevise}
                  </span>
                </p>
              </td>
            )}
            <td>{j.LfaLibelle}</td>
            <td>
              {j.LfaDateDebut}
              {' - '}
              {j.LfaDateFin}
            </td>
            <td className="text-right">
              {decimalFormat(j.LfaValeur)} {i.IatAgrUniteOuDevise}
            </td>
            {/*{!jIndex &&*/}
            {/*<td rowSpan={i.IatAgrLignesFacture.length}>*/}
            {/*<span className={(i.IatAgrEcart > 0 && "text-danger") || (i.IatAgrEcart < 0 && "text-primary") || ""}>*/}
            {/*{i.IatAgrEcart === null ? "-" : (i.IatAgrEcart === 0 ? <i className="icon-done" /> : ((i.IatAgrEcart > 0 && "+") || "") + decimalFormat(i.IatAgrEcart))}*/}
            {/*</span>*/}
            {/*</td>*/}
            {/*}*/}
          </tr>
        ))}
      </tbody>
    ))}
  </Table>
)

BillTable.propTypes = {
  FacGroupeLfa: PropTypes.array.isRequired,
  FacDevise: PropTypes.string.isRequired,
}

// export default BillTable;
export default withTranslation()(BillTable)
