import { useQuery } from 'react-query'
import { request } from './request'
import keyBy from 'lodash/keyBy'

export const usePatrimonialReferentiel = () => {
  const { data } = useQuery({
    queryKey: ['patrimonialReferentiel'],
    queryFn: () => request({ url: 'referentiel/kpi/patrimonial/my' }),
    suspense: true,
  })
  return keyBy(data, 'Id')
}
