import React, { Suspense } from 'react'
import { useParams } from 'react-router'
import { useQuery } from 'react-query'
import { superFetch } from 'commons'
import { useTranslation } from 'react-i18next'
import { Loader } from 'components/common/Loader'

export const Fdp = (props) => {
  return (
    <Suspense fallback={<Loader />}>
      <FdpContent {...props} />
    </Suspense>
  )
}

const FdpContent = ({ id }) => {
  const { peeids } = useParams()
  const { t } = useTranslation()

  const { data, error } = useQuery({
    queryKey: ['parcel-details', id, peeids],
    queryFn: async () => {
      const resp = await superFetch({ url: `contrats/${peeids}/lots/${id}` })
      const data = await resp.json()
      return data
    },
    suspense: true,
  })

  if (error) return <div>{t('global.error')}</div>

  return (
    <div>
      {data?.formulesPrix?.map((i, index) => (
        <div key={index}>{i}</div>
      ))}
    </div>
  )
}
