import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { FormGroup, Label } from 'reactstrap'
import Highlighter from 'react-highlight-words'

import { getUor } from '../../reducers/organisation'
import {
  getUorHasPeeOfFilterType,
  getFilterInput,
  getFilterType,
  getCountryFilter,
} from '../../reducers/searchPanel'
import { getIsUorSelected, getIsUorIndeterminate } from '../../reducers/currentSelection'
import { toggleUorInSelection } from '../../actions/currentSelection'
import Site from './Site'

class Uor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      opened: this.props.opened,
    }
  }
  render() {
    const {
      uorId,
      uor,
      isUorSelected,
      isUorIndeterminate,
      toggleUorInSelection,
      dispatch,
      uorHasPeeOfFilterType,
      filterInput,
      level,
    } = this.props
    const shouldDisplayChildren = this.state.opened && !filterInput && uor

    /**
     * This method will display the children of a root node.
     * Based on the type of the child node, a different renderer
     * method will be provided.
     */
    function displayChildren(children, rederer) {
      return (
        shouldDisplayChildren &&
        children &&
        children.length && <ul>{children.map((id) => rederer(id))}</ul>
      )
    }

    return (
      <span>
        {uorHasPeeOfFilterType ? (
          <li>
            <FormGroup check>
              <Label
                check
                className={
                  (isUorIndeterminate && 'indeterminate') || (isUorSelected && 'checked') || ''
                }
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={uorId}
                  checked={isUorSelected}
                  ref={(input) => {
                    if (input) {
                      input.indeterminate = isUorIndeterminate
                    }
                  }}
                  onChange={(e) => dispatch(toggleUorInSelection)}
                />
                <span className="left-pad">
                  {filterInput ? (
                    <Highlighter
                      highlightClassName="bg-secondary"
                      searchWords={[filterInput]}
                      textToHighlight={uor.UorNom}
                    />
                  ) : (
                    <button
                      className="reset"
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        this.setState({ opened: !this.state.opened })
                      }}
                    >
                      <i
                        className={
                          this.state.opened ? 'icon-smallarrow-down' : 'icon-smallarrow-right'
                        }
                      />
                      {uor.UorNom}
                    </button>
                  )}
                </span>
              </Label>
            </FormGroup>

            {displayChildren(uor.UorEnfants, (id) => (
              <UorWrapper key={id} uorId={id} level={level + 1} opened={false} />
            ))}
            {displayChildren(uor.UorSites, (id) => (
              <Site key={id} siteId={id} level={level + 1} opened={false} />
            ))}
          </li>
        ) : null}
      </span>
    )
  }
}

const mapStateToProps = (state, { uorId }) => ({
  uor: getUor(state, uorId),
  isUorSelected: getIsUorSelected(state, uorId),
  isUorIndeterminate: getIsUorIndeterminate(state, uorId),
  toggleUorInSelection: toggleUorInSelection(
    state,
    uorId,
    getFilterType(state),
    getCountryFilter(state)
  ),
  uorHasPeeOfFilterType: getUorHasPeeOfFilterType(state, uorId),
  filterInput: getFilterInput(state),
})

Uor.propTypes = {
  uorId: PropTypes.number.isRequired,
  uor: PropTypes.object.isRequired,
  isUorSelected: PropTypes.bool.isRequired,
  isUorIndeterminate: PropTypes.bool.isRequired,
  toggleUorInSelection: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  uorHasPeeOfFilterType: PropTypes.bool.isRequired,
  filterInput: PropTypes.string.isRequired,
  level: PropTypes.number,
  opened: PropTypes.bool,
}

Uor.defaultProps = {
  level: 0,
  opened: true,
}

const UorWrapper = connect(mapStateToProps)(Uor)
export default UorWrapper
