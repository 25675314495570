import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import isEmpty from 'lodash/isEmpty'

const organisation = () => {
  const organisationUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_ORGANISATION_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const errorMessageOrganisation = (state = null, action) => {
    switch (action.type) {
      case 'FETCH_ORGANISATION_FAILURE':
        return action.message
      case 'FETCH_ORGANISATION_REQUEST':
      case 'FETCH_ORGANISATION_SUCCESS':
        return null
      default:
        return state
    }
  }

  const organisationEntities = (state = { uors: {}, sites: {}, pees: {}, rootUor: 0 }, action) => {
    switch (action.type) {
      case 'FETCH_ORGANISATION_SUCCESS':
        return {
          ...state,
          ...action.response.entities,
          rootUor: action.response.result,
        }
      default:
        return state
    }
  }

  return combineReducers({
    organisationUuid,
    errorMessageOrganisation,
    organisationEntities,
  })
}

export default organisation

export const getRootUor = (state) => state.organisation.organisationEntities.rootUor || 0
export const getUor = (state, uorId) => state.organisation.organisationEntities.uors[uorId] || {}
export const getUors = (state) => state.organisation.organisationEntities.uors
export const getUorsIds = (state) =>
  Object.keys(state.organisation.organisationEntities.uors).map(Number)
export const getSite = (state, siteId) => state.organisation.organisationEntities.sites[siteId]
export const getSites = (state) => state.organisation.organisationEntities.sites
export const getSitesIds = (state) =>
  Object.keys(state.organisation.organisationEntities.sites).map(Number)
export const getSitePeesIds = (state, siteId) =>
  state.organisation.organisationEntities.sites[siteId].SitPees
export const getSiteNameByPeeId = (state, peeId) =>
  (state.organisation.organisationEntities.sites[peeId] &&
    state.organisation.organisationEntities.sites[peeId].SphNom) ||
  ''
export const getPee = (state, peeId) => state.organisation.organisationEntities.pees[peeId]
export const getPeeNrj = (state, peeId) =>
  state.organisation.organisationEntities.pees[peeId] &&
  state.organisation.organisationEntities.pees[peeId].PeeNrj
export const getPees = (state) => state.organisation.organisationEntities.pees
export const getPeesIds = (state) =>
  Object.keys(state.organisation.organisationEntities.pees).map(Number)
export const getOrganisationUuid = (state) => state.organisation.organisationUuid
export const getErrorMessageOrganisation = (state) => state.Organisation.errorMessageOrganisation
export const getUorPath = (state, uorId) =>
  state.organisation.organisationEntities.uors[uorId].parentUor !== uorId
    ? getUorPath(state, state.organisation.organisationEntities.uors[uorId].parentUor) +
      '/' +
      state.organisation.organisationEntities.uors[uorId].UorNom
    : state.organisation.organisationEntities.uors[uorId].UorNom
export const getSitePath = (state, siteId) =>
  getUorPath(state, state.organisation.organisationEntities.sites[siteId].parentUor) +
  '/' +
  state.organisation.organisationEntities.sites[siteId].SphNom
export const getPeePath = (state, peeId) =>
  getSitePath(state, state.organisation.organisationEntities.pees[peeId].parentSite) +
  '/' +
  state.organisation.organisationEntities.pees[peeId].PeeClef

export const getUorTree = (checkedId) => (state) =>
  createSelector(getUors, (uors) => {
    const createNode = (id) => {
      const uor = uors[id]
      if (isEmpty(uor)) return {}
      return {
        label: uor.UorNom,
        value: uor.id,
        expanded: true,
        checked: uor.id === checkedId,
        ...(!isEmpty(uor.UorEnfants) && {
          children: uor.UorEnfants.map(createNode),
        }),
      }
    }

    const mainUor = Object.values(uors).find((uor) => uor.id === uor.parentUor)
    return !mainUor
      ? {}
      : {
          ...createNode(mainUor.id),
          expanded: true,
        }
  })(state)
