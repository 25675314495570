import {ErrorBoundary} from "commons";
import React, {Suspense} from "react";
import {Loader} from "../common/Loader";
import {Consentements} from "../grdf/Consentements";


export const GrdfView = () => {
    return (<div className="mx-3 mb-5">
        <ErrorBoundary>
            <Suspense fallback={<div className="d-flex justify-content-center">
                <Loader/>
            </div>}>
                <Consentements/>
            </Suspense>
        </ErrorBoundary>
    </div>)
}