import React from 'react'
import { TabContent, TabPane } from 'reactstrap'

import SidebarCurrent from './SidebarCurrent'
import SidebarFavorites from './SidebarFavorites'

const SidebarTabs = ({ activeTab, ...rest }) => (
  <TabContent activeTab={activeTab}>
    <TabPane tabId="SELECTION" className="current-selection-tab">
      <SidebarCurrent />
    </TabPane>

    <TabPane tabId="FAVORITES" className="favorites-tab">
      <SidebarFavorites {...rest} />
    </TabPane>
  </TabContent>
)

export default SidebarTabs
