import React from 'react'
import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap'

import { decimalFormat, noDecimalFormat } from '../../helpers/helpers'
import KpiSecondary from './KpiSecondary'
import { Loader } from 'components/common/Loader'

const Kpi = ({ Principal, Secondaires, Decomposition, decimal, loading = false }) => {
  return (
    <Card className={['kpi', Principal.Libelle === '' ? 'empty' : ''].join(' ')}>
      {loading && (
        <div
          className="position-absolute w-100  h-100 d-flex justify-content-center align-items-center"
          style={{ zIndex: 1 }}
        >
          <Loader />
        </div>
      )}

      <CardBody style={{ opacity: loading ? 0.2 : 1 }}>
        <CardSubtitle>{Principal.Libelle}</CardSubtitle>
        <div className="title-wrapper">
          <CardTitle>
            {Principal.Libelle &&
              (decimal
                ? decimalFormat(Principal.Valeur)
                : noDecimalFormat(Principal.Valeur, 10))}{' '}
            <small>{Principal.Unite}</small>
          </CardTitle>
          {Principal.ValeurFacture ? (
            <div className="principal-additional">
              <div className="principal-additional-line">
                {Principal.LibelleFacture}:
                <span className="principal-additional-fac">
                  <span className="principal-additional-fac-value">
                    {decimal
                      ? decimalFormat(Principal.ValeurFacture)
                      : noDecimalFormat(Principal.ValeurFacture, 10)}
                  </span>
                  {Principal.Unite}
                </span>
              </div>
              <div className="principal-additional-line">
                {Principal.LibelleAFacturer}:
                <span className="principal-additional-fac">
                  <span className="principal-additional-fac-value">
                    {decimal
                      ? decimalFormat(Principal.ValeurAFacturer)
                      : noDecimalFormat(Principal.ValeurAFacturer, 10)}
                  </span>
                  {Principal.Unite}
                </span>
              </div>
            </div>
          ) : null}
        </div>
        <div className="secondary">
          {Secondaires &&
            Secondaires.map((secondaire, index) => (
              <KpiSecondary key={index} {...secondaire} decimal={decimal} />
            ))}
          {Decomposition &&
            Decomposition.map((decomposition, index) => (
              <KpiSecondary
                key={index}
                {...decomposition}
                decimal={decimal}
                isProgression={false}
              />
            ))}
        </div>
      </CardBody>
    </Card>
  )
}

export default Kpi
