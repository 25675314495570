import React from 'react'
import ReactTable from 'react-table'
import withFixedColumns from 'react-table-hoc-fixed-columns'
import moment from 'moment'
import flatten from 'lodash/flatten'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose, withHandlers, lifecycle } from 'recompose'

import * as user from 'commons'

import { fetchDepenses } from '../../actions/expenses'
import { getDepenses, getDepensesIsLoading } from '../../reducers/expenses'
import { getTypeDonnee } from '../../reducers/typeDonnee'
import { getStartDate, getEndDate } from '../../reducers/dateRange'
import {
  getCurrentVisibleElecPeesIds,
  getCurrentVisibleGasPeesIds,
} from '../../reducers/currentSelection'

import { decimalFormat } from '../../helpers/helpers'
import { adaptPageSizeOptions, translations } from '../../helpers/react-table'

import { TableLoader } from '../common/Loader'
import CSVButton from '../common/CSVButton/CSVButton'
import { Devise } from 'components/options/Devise'

import styles from './ExpenseTable.module.scss'

const sum = (...args) => args.reduce((acc, arg) => acc + (typeof arg === 'undefined' ? 0 : arg), 0)

const ReactTableFixedColumns = withFixedColumns(ReactTable)

const borderRightStyle = {
  borderRight: '1px solid #ccc',
}

function createCSV(t, columns, tableData) {
  const header1 = flatten(
    columns.map(({ Header, columns }) =>
      Array(columns.length).fill(typeof Header === 'string' ? Header.toUpperCase() : '')
    )
  )

  const header2 = flatten(columns.map(({ columns }) => columns.map(({ Header }) => Header)))

  const accessors = flatten(columns.map(({ columns }) => columns.map(({ accessor }) => accessor)))

  const body = tableData.map((line) => accessors.map((a) => line[a]))

  return [header1, header2, ...body]
}

function ExpenseTable({ t, data, isLoading, types, energy }) {
  const consos =
    data.length === 0 || Object.values(data[0].Compteurs).length === 0
      ? []
      : Object.values(data[0].Compteurs)[0].DepensesEnergie

  const periods = consos.map(({ Periode }) => {
    const m = moment(Periode)
    return {
      timestamp: m.format('x'),
      formatted: m.format(types.length > 1 ? 'MMMM YYYY' : 'MMM YYYY'),
    }
  })

  const columns = [
    {
      Header: () => <span>&nbsp;</span>,
      fixed: 'left',
      columns: [
        {
          Header: t('expenses.table.site'),
          accessor: 'site',
          width: 130,
        },
        {
          Header: t('expenses.table.pee'),
          accessor: 'pee',
          width: 190,
        },
        {
          Header: t('expenses.table.centreCout'),
          accessor: 'centreCout',
          width: 150,
        },
        {
          Header: t('expenses.table.decomposition'),
          accessor: 'decomposition',
          width: 110,
        },
      ],
    },
    ...periods.map(({ timestamp, formatted }) => ({
      Header: formatted,
      headerStyle: {
        ...borderRightStyle,
        textTransform: 'uppercase',
      },
      columns: types.map((type, index) => {
        const styles =
          index < types.length - 1
            ? {}
            : {
                style: borderRightStyle,
                headerStyle: borderRightStyle,
              }
        return {
          Header: t(`expenses.table.${type.toLowerCase()}`),
          accessor: `${timestamp}-${type}`,
          className: 'text-right',
          minWidth: 90,
          ...styles,
        }
      }),
    })),
  ]

  const tableData = flatten(
    data.map(({ Nom, Compteurs }) =>
      flatten(
        Object.values(Compteurs).map((cpt) => {
          const values = cpt.DepensesEnergie.reduce(
            (acc, { Periode }, index) => {
              const ts = moment(Periode).format('x')

              if (types.includes('FacturePrevu')) {
                const key = `${ts}-FacturePrevu`
                acc[0][key] = decimalFormat(cpt.DepensesEnergie[index].Depense)
                acc[1][key] = decimalFormat(cpt.DepensesTransport[index].Depense)
                acc[2][key] = decimalFormat(cpt.DepensesTaxes[index].Depense)
                acc[3][key] = decimalFormat(cpt.DepensesAutres[index].Depense)
                acc[4][key] = decimalFormat(
                  sum(
                    cpt.DepensesEnergie[index].Depense,
                    cpt.DepensesTransport[index].Depense,
                    cpt.DepensesTaxes[index].Depense,
                    cpt.DepensesAutres[index].Depense
                  )
                )
              }

              if (types.includes('Facture')) {
                const key = `${ts}-Facture`
                acc[0][key] = decimalFormat(cpt.DepensesEnergie[index].DepenseFac)
                acc[1][key] = decimalFormat(cpt.DepensesTransport[index].DepenseFac)
                acc[2][key] = decimalFormat(cpt.DepensesTaxes[index].DepenseFac)
                acc[3][key] = decimalFormat(cpt.DepensesAutres[index].DepenseFac)
                acc[4][key] = decimalFormat(
                  sum(
                    cpt.DepensesEnergie[index].DepenseFac,
                    cpt.DepensesTransport[index].DepenseFac,
                    cpt.DepensesTaxes[index].DepenseFac,
                    cpt.DepensesAutres[index].DepenseFac
                  )
                )
              }
              if (types.includes('FactureN1')) {
                const key = `${ts}-FactureN1`
                acc[0][key] = decimalFormat(cpt.DepensesEnergie[index].DepenseN1)
                acc[1][key] = decimalFormat(cpt.DepensesTransport[index].DepenseN1)
                acc[2][key] = decimalFormat(cpt.DepensesTaxes[index].DepenseN1)
                acc[3][key] = decimalFormat(cpt.DepensesAutres[index].DepenseN1)
                acc[4][key] = decimalFormat(
                  sum(
                    cpt.DepensesEnergie[index].DepenseN1,
                    cpt.DepensesTransport[index].DepenseN1,
                    cpt.DepensesTaxes[index].DepenseN1,
                    cpt.DepensesAutres[index].DepenseN1
                  )
                )
              }
              if (types.includes('Budget')) {
                const key = `${ts}-Budget`
                acc[0][key] = decimalFormat(cpt.DepensesEnergie[index].DepenseBudget)
                acc[1][key] = decimalFormat(cpt.DepensesTransport[index].DepenseBudget)
                acc[2][key] = decimalFormat(cpt.DepensesTaxes[index].DepenseBudget)
                acc[3][key] = decimalFormat(cpt.DepensesAutres[index].DepenseBudget)
                acc[4][key] = decimalFormat(
                  sum(
                    cpt.DepensesEnergie[index].DepenseBudget,
                    cpt.DepensesTransport[index].DepenseBudget,
                    cpt.DepensesTaxes[index].DepenseBudget,
                    cpt.DepensesAutres[index].DepenseBudget
                  )
                )
              }

              return acc
            },
            [{}, {}, {}, {}, {}]
          )

          return [
            {
              site: Nom,
              pee: cpt.PeeClef,
              centreCout: cpt.CodeSite,
              decomposition: t('expenses.decomposition.energy'),
              ...values[0],
            },
            {
              site: Nom,
              pee: cpt.PeeClef,
              centreCout: cpt.CodeSite,
              decomposition: t('expenses.decomposition.transport'),
              ...values[1],
            },
            {
              site: Nom,
              pee: cpt.PeeClef,
              centreCout: cpt.CodeSite,
              decomposition: t('expenses.decomposition.taxes'),
              ...values[2],
            },
            {
              site: Nom,
              pee: cpt.PeeClef,
              centreCout: cpt.CodeSite,
              decomposition: t('expenses.decomposition.other'),
              ...values[3],
            },
            {
              site: Nom,
              pee: cpt.PeeClef,
              centreCout: cpt.CodeSite,
              decomposition: 'Total',
              ...values[4],
            },
          ]
        })
      )
    )
  )

  const energyLibelle =
    energy === 'ELE'
      ? t('powerConsumption.loadCurve.energy.elec')
      : energy === 'GAZ'
      ? t('powerConsumption.loadCurve.energy.gas')
      : ''

  const csvFilename = `${t('powerConsumption.graphTitle')} - ${energyLibelle}.csv`

  return (
    <>
      <div className={styles.header}>
        <div>
          {t('powerConsumption.unit')} : <Devise />
        </div>
        <CSVButton filename={csvFilename} createContent={() => createCSV(t, columns, tableData)} />
      </div>
      <ReactTableFixedColumns
        data={tableData}
        columns={columns}
        defaultPageSize={10}
        pageSizeOptions={adaptPageSizeOptions(tableData)}
        loading={isLoading}
        LoadingComponent={TableLoader}
        className={`-striped -highlight ${styles.table}`}
        css={{
          '.rt-tbody': {
            maxHeight: '350px',
          },
        }}
        getTrProps={(state, rowInfo, column) => {
          return {
            style: {
              fontWeight: rowInfo && rowInfo.original.decomposition === 'Total' ? 700 : 400,
            },
          }
        }}
        {...translations(t)}
      />
    </>
  )
}

const mapState = (state, ownProps) => ({
  data: getDepenses(state),
  isLoading: getDepensesIsLoading(state),
  types: getTypeDonnee(state),
  ids:
    ownProps.energy === 'ELE'
      ? getCurrentVisibleElecPeesIds(state)
      : ownProps.energy === 'GAZ'
      ? getCurrentVisibleGasPeesIds(state)
      : [],
  startDate: getStartDate(state),
  endDate: getEndDate(state),
  hasInvoice: user.hasInvoice(state),
})

const mapDispatch = {
  fetchDepenses,
}

const createPayload = (props) => ({
  Type: 'CPT',
  Ids: props.ids,
  Debut: props.startDate,
  Fin: props.endDate,
  Commodite: props.energy,
  TypeDonnees: {
    Facture: props.hasInvoice ? true : false,
    FactureN1: props.hasInvoice ? true : false,
    Budget: true,
  },
})

export default compose(
  withTranslation(),
  connect(mapState, mapDispatch),
  withHandlers({
    fetchData: (props) => () => {
      props.fetchDepenses(createPayload(props))
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchData()
    },
    componentDidUpdate(prevProps) {
      if (JSON.stringify(createPayload(prevProps)) !== JSON.stringify(createPayload(this.props))) {
        this.props.fetchData()
      }
    },
  })
)(ExpenseTable)
