import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, Label, Input } from 'reactstrap'

import { toggleTypeDonnee } from '../../actions/typeDonnee'
import { getIsTypeDonneeChecked } from '../../reducers/typeDonnee'

const FormTypeDonneeCheckbox = ({ value, text }) => {
  const dispatch = useDispatch()
  const isChecked = useSelector((state) => getIsTypeDonneeChecked(state, value))

  useEffect(() => {
    if (value === 'FacturePrevu' && !isChecked) {
      dispatch(toggleTypeDonnee('FacturePrevu'))
    }
  }, [dispatch, isChecked, value])

  return (
    <FormGroup check>
      <Label
        check
        className={[
          (isChecked && 'checked') || '',
          ((value === 'FacturePrevu' || value === 'Mesure') && 'disabled') || '',
        ].join(' ')}
      >
        <Input
          type="checkbox"
          value={value}
          checked={isChecked}
          onChange={() => dispatch(toggleTypeDonnee(value))}
          disabled={value === 'FacturePrevu' || value === 'Mesure'}
        />
        {text}
      </Label>
    </FormGroup>
  )
}

export default FormTypeDonneeCheckbox
