import { combineReducers } from 'redux'

const allAmenities = () => {
  const kpiAllUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_ALL_AMENITIES_KPI_ALL_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiElecUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_ALL_AMENITIES_KPI_ELEC_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiGasUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_ALL_AMENITIES_KPI_GAS_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const graphUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_ALL_AMENITIES_GRAPH_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiAll = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_ALL_AMENITIES_KPI_ALL_NONE':
        return { Principal: { Libelle: '', Valeur: 0, Unite: '' } }
      case 'FETCH_ALL_AMENITIES_KPI_ALL_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiAllLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_ALL_AMENITIES_KPI_ALL_REQUEST':
        return true
      case 'FETCH_ALL_AMENITIES_KPI_ALL_SUCCESS':
      case 'FETCH_ALL_AMENITIES_KPI_ALL_FAILURE':
      case 'FETCH_ALL_AMENITIES_KPI_ALL_NONE':
        return false
      default:
        return state
    }
  }

  const kpiElec = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_ALL_AMENITIES_KPI_ELEC_NONE':
        return { Principal: { Libelle: '', Valeur: 0, Unite: '' } }
      case 'FETCH_ALL_AMENITIES_KPI_ELEC_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiElecLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_ALL_AMENITIES_KPI_ELEC_REQUEST':
        return true
      case 'FETCH_ALL_AMENITIES_KPI_ELEC_SUCCESS':
      case 'FETCH_ALL_AMENITIES_KPI_ELEC_FAILURE':
      case 'FETCH_ALL_AMENITIES_KPI_ELEC_NONE':
        return false
      default:
        return state
    }
  }

  const kpiGas = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_ALL_AMENITIES_KPI_GAS_NONE':
        return { Principal: { Libelle: '', Valeur: 0, Unite: '' } }
      case 'FETCH_ALL_AMENITIES_KPI_GAS_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiGasLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_ALL_AMENITIES_KPI_GAS_REQUEST':
        return true
      case 'FETCH_ALL_AMENITIES_KPI_GAS_SUCCESS':
      case 'FETCH_ALL_AMENITIES_KPI_GAS_FAILURE':
      case 'FETCH_ALL_AMENITIES_KPI_GAS_NONE':
        return false
      default:
        return state
    }
  }

  const graph = (state = {}, action) => {
    switch (action.type) {
      case 'FETCH_ALL_AMENITIES_GRAPH_SUCCESS':
        return action.response
      default:
        return state
    }
  }

  return combineReducers({
    kpiAllUuid,
    kpiElecUuid,
    kpiGasUuid,
    graphUuid,
    kpiAll,
    kpiElec,
    kpiGas,
    kpiAllLoading,
    kpiElecLoading,
    kpiGasLoading,
    graph,
  })
}

export default allAmenities

export const getKpiAllUuid = (state) => state.powerConsumption.allAmenities.kpiAllUuid
export const getKpiElecUuid = (state) => state.powerConsumption.allAmenities.kpiElecUuid
export const getKpiGasUuid = (state) => state.powerConsumption.allAmenities.kpiGasUuid
export const getGraphUuid = (state) => state.powerConsumption.allAmenities.graphUuid

export const getKpiAll = (state) => state.powerConsumption.allAmenities.kpiAll
export const getKpiElec = (state) => state.powerConsumption.allAmenities.kpiElec
export const getKpiGas = (state) => state.powerConsumption.allAmenities.kpiGas

export const getKpiAllLoading = (state) => state.powerConsumption.allAmenities.kpiAllLoading
export const getKpiElecLoading = (state) => state.powerConsumption.allAmenities.kpiElecLoading
export const getKpiGasLoading = (state) => state.powerConsumption.allAmenities.kpiGasLoading

export const getGraph = (state) => state.powerConsumption.allAmenities.graph
