import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'reactstrap'

import FormTypeDonneeCheckbox from './FormTypeDonneeCheckbox'

const FormTypeDonnee = ({ typeDonnees }) => (
  <Form inline>
    {Object.keys(typeDonnees).map((value) => (
      <FormTypeDonneeCheckbox key={value} value={value} text={typeDonnees[value]} />
    ))}
  </Form>
)

FormTypeDonnee.propTypes = {
  typeDonnees: PropTypes.object.isRequired,
}

export default FormTypeDonnee
