import React, { Component } from 'react'

import SidebarNav from './sidebarSelection/SidebarNav'
import SidebarTabs from './sidebarSelection/SidebarTabs'

class SideBarSelection extends Component {
  constructor(props) {
    super(props)

    this.toggleTab = this.toggleTab.bind(this)
    this.state = {
      activeTab: 'SELECTION',
    }
  }

  onNavClick(tab) {
    this.setState({
      activeTab: tab,
    })
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  render() {
    return (
      <div>
        <SidebarNav toggleTab={this.toggleTab} {...this.state} />
        <SidebarTabs toggleTab={this.toggleTab} {...this.state} />
      </div>
    )
  }
}

export default SideBarSelection
