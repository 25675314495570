import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Row, Col, Table, Badge, Input } from 'reactstrap'

class MeterAch extends Component {
  constructor(props) {
    super(props)
    this.state = { selectedFta: 0 }

    this.selectedChange = this.selectedChange.bind(this)
  }

  selectedChange(e) {
    this.setState({ selectedFta: e.target.value })
  }

  render() {
    const { AchGrd, AchListeFta, t } = this.props

    return (
      <div>
        <Row>
          <Col>
            <Badge>{t('contracts.meter.ach')}</Badge>
          </Col>
        </Row>
        <Row>
          <Col sm="8">
            <Table bordered>
              <tbody>
                <tr>
                  <td>{t('contracts.meter.grd')}</td>
                  <td className="text-right">{AchGrd}</td>
                </tr>
                <tr>
                  <td>{t('contracts.meter.priceform')}</td>
                  <td className="text-right">
                    {AchListeFta && AchListeFta.length > 0 ? (
                      AchListeFta[this.state.selectedFta].AchFtaLibelle
                    ) : (
                      <span>&ndash;</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>{t('contracts.meter.date')}</td>
                  <td className="text-right">
                    {AchListeFta && AchListeFta.length > 0 ? (
                      <Input type="select" onChange={this.selectedChange}>
                        {AchListeFta.map((i, index) => (
                          <option key={index} value={index}>
                            {i.AchFtaDateDerniereModification}
                          </option>
                        ))}
                      </Input>
                    ) : (
                      <span>&ndash;</span>
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col sm="4">
            <div className="ach-wrap">
              <Table striped>
                <thead>
                  <tr>
                    <th>{t('contracts.meter.item')}</th>
                    <th>{t('contracts.meter.val')}</th>
                  </tr>
                </thead>
                <tbody>
                  {AchListeFta &&
                    AchListeFta.length > 0 &&
                    AchListeFta[this.state.selectedFta].AchFtaParametres.map((i, index) => (
                      <tr key={index}>
                        <td>{i.FtaParamLibelle}</td>
                        <td>{i.FtaParamValeur}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

MeterAch.propTypes = {
  id: PropTypes.number,
  AchPeeId: PropTypes.number,
  AchPeeClef: PropTypes.string,
  AchPeeNrj: PropTypes.string,
  AchGrd: PropTypes.string,
  AchListeFta: PropTypes.arrayOf(
    PropTypes.shape({
      AchFtaLibelle: PropTypes.string,
      AchFtaDateDerniereModification: PropTypes.string,
      AchFtaParametres: PropTypes.arrayOf(
        PropTypes.shape({
          FtaParamLibelle: PropTypes.string,
          FtaParamValeur: PropTypes.string,
        })
      ),
    })
  ),
}

export default withTranslation()(MeterAch)
