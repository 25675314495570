import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { Row, Col, Table, Badge } from 'reactstrap'

import ContractLotPriceFix from './ContractLotPriceFix'
import ContractLotPerimetre from './ContractLotPerimetre'
import { noDecimalFormat } from '../../helpers/helpers'

const ContractLot = ({
  CtrVolumeUnite,
  LotDateDebut,
  LotDateFin,
  LotNom,
  LotType,
  LotDevise,
  LotTypeFormulePrix,
  LotVolume,
  LotFormulesPrix,
  Perimetre,
  Transactions,
  t,
}) => (
  <div>
    <Row>
      <Col>
        <Badge>{LotNom}</Badge>
      </Col>
    </Row>
    <Row>
      <Col xs="3">
        <h4 className="m-0">{t('contracts.contract.infos')}</h4>
        <Table bordered className="ctr-lot-infos">
          <tbody>
            <tr>
              <td>{t('contracts.contract.start')}</td>
              <td>{LotDateDebut}</td>
            </tr>
            <tr>
              <td>{t('contracts.contract.end')}</td>
              <td>{LotDateFin}</td>
            </tr>
            <tr>
              <td>{t('contracts.contract.currency')}</td>
              <td>{LotDevise}</td>
            </tr>
            <tr>
              <td>{t('contracts.contract.vol')}</td>
              <td>{noDecimalFormat(LotVolume) + ' ' + CtrVolumeUnite}</td>
            </tr>
            <tr>
              <td>{t('contracts.contract.lottype')}</td>
              <td>{LotType}</td>
            </tr>
            <tr>
              <td>{t('contracts.contract.lotprice')}</td>
              <td>{LotTypeFormulePrix}</td>
            </tr>
          </tbody>
        </Table>
      </Col>
      <Col xs="3">
        <h4 className="m-0">{t('contracts.contract.priceform')}</h4>
        <Table bordered>
          <tbody>
            {LotFormulesPrix.map((i, index) => (
              <tr key={index}>
                <td>{i}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
      <Col xs="6">
        {Transactions && (
          <div>
            <h4>{t('contracts.contract.pricefix')}</h4>
            <ContractLotPriceFix transactions={Transactions} />
          </div>
        )}
      </Col>
    </Row>
    <Row className="ctr-perim">
      <Col>
        <h4>{t('contracts.contract.perimeter')}</h4>
        <ContractLotPerimetre perimetre={Perimetre} />
      </Col>
    </Row>
  </div>
)

ContractLot.propTypes = {
  LotDateDebut: PropTypes.string.isRequired,
  LotDateFin: PropTypes.string.isRequired,
  LotNom: PropTypes.string.isRequired,
  LotType: PropTypes.string.isRequired,
  LotDevise: PropTypes.string.isRequired,
  LotTypeFormulePrix: PropTypes.string.isRequired,
  LotHorosaisonnier: PropTypes.string.isRequired,
  LotVolume: PropTypes.number.isRequired,
  LotFormulesPrix: PropTypes.arrayOf(PropTypes.string).isRequired,
  perimetre: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      SitClef: PropTypes.string.isRequired,
      SphNom: PropTypes.string.isRequired,
      SphAdresse: PropTypes.shape({
        id: PropTypes.number.isRequired,
        AdrAdresse: PropTypes.string.isRequired,
        AdrCodePostal: PropTypes.string.isRequired,
        AdrVille: PropTypes.string.isRequired,
        AdrPays: PropTypes.string.isRequired,
        AdrPaysId: PropTypes.number.isRequired,
      }).isRequired,
      SitPees: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          PeeClef: PropTypes.string.isRequired,
          PeeNrj: PropTypes.string.isRequired,
          PeeSegment: PropTypes.string.isRequired,
          PeeGrd: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ),
  Transactions: PropTypes.arrayOf(
    PropTypes.shape({
      TrsDate: PropTypes.string.isRequired,
      TrsPrix: PropTypes.number.isRequired,
      TrsProduit: PropTypes.string.isRequired,
      // TrsVolume: PropTypes.string.isRequired,
      TrsUnite: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ),
}

export default withTranslation()(ContractLot)
