import { schema } from 'normalizr'

export const sidebarMarketInfo = new schema.Entity('sidebarMarketInfo')
export const arrayOfSidebarMarketInfos = new schema.Array(sidebarMarketInfo)

export const cotProduct = new schema.Entity('cotProduct')
export const product = new schema.Entity('product', {
  CotProduit: cotProduct,
})
export const products = new schema.Array(product)

export const favPees = new schema.Entity('pees')
export const favItem = new schema.Entity('favoritesItem', {
  FavListePee: [favPees],
})
export const arrayOfFavItem = new schema.Array(favItem)

export const pee = new schema.Entity(
  'pees',
  {},
  {
    processStrategy: (entity, parent) => {
      return { ...entity, parentSite: parent.id }
    },
  }
)
export const uorSite = new schema.Entity(
  'sites',
  {
    SitPees: [pee],
  },
  {
    processStrategy: (entity, parent) => {
      return { ...entity, parentUor: parent.id }
    },
  }
)
export const uorSites = new schema.Array(uorSite)
export const organisation = new schema.Entity(
  'uors',
  {
    UorSites: uorSites,
  },
  {
    processStrategy: (entity, parent) => {
      return { ...entity, parentUor: parent.id }
    },
  }
)
organisation.define({
  UorEnfants: [organisation],
})
export const bill = new schema.Entity('bill')
export const arrayOfBills = new schema.Array(bill)
export const meters = new schema.Entity('meters')
export const arrayOfMeters = new schema.Array(meters)
export const contracts = new schema.Entity('contracts')
export const arrayOfContracts = new schema.Array(contracts)
export const lots = new schema.Entity('lots')
export const arrayOfLots = new schema.Array(lots)
