import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Row, Col, CardGroup, Alert } from 'reactstrap'
import HighchartsReact from 'highcharts-react-official'
import { Highcharts } from 'helpers/highcharts'

import { initDonutChart } from '../../helpers/initCharts'
import {
  getKpiAll,
  getKpiElec,
  getKpiGas,
  getKpiAllLoading,
  getKpiElecLoading,
  getKpiGasLoading,
  getGraph,
} from '../../reducers/powerConsumption/allAmenities'
import { getCurrentVisiblePeesIds } from '../../reducers/currentSelection'
import * as actions from '../../actions/powerConsumption/allAmenities'
import Kpi from '../kpi/Kpi'

class AllAmenities extends Component {
  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    const { fetchKpiAll, fetchKpiElec, fetchKpiGas, fetchGraph } = this.props
    fetchKpiAll()
    fetchKpiElec()
    fetchKpiGas()
    fetchGraph()
  }

  render() {
    const {
      currentVisiblePeesIds,
      kpiAll,
      kpiElec,
      kpiGas,
      kpiAllLoading,
      kpiElecLoading,
      kpiGasLoading,
      graph,
      t,
    } = this.props

    return (
      <section>
        {currentVisiblePeesIds.length ? (
          <div>
            <Row>
              <Col xs="9" className="all-amenities-kpis">
                <CardGroup>
                  <Kpi decimal={false} {...kpiAll} loading={kpiAllLoading} />
                  <Kpi decimal={false} {...kpiElec} loading={kpiElecLoading} />
                  <Kpi decimal={false} {...kpiGas} loading={kpiGasLoading} />
                </CardGroup>
              </Col>
              <Col xs="3">
                <Row>
                  <Col>
                    <h5 className="text-center">{t('powerConsumption.labels.donut')}</h5>
                  </Col>
                </Row>
                <Row noGutters>
                  <Col>
                    <HighchartsReact highcharts={Highcharts} options={initDonutChart(graph)} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        ) : (
          <Alert color="warning" className="mb-0">
            {t('errors.noSelection')}
          </Alert>
        )}
      </section>
    )
  }
}

const mapStateToProps = (state) => ({
  currentVisiblePeesIds: getCurrentVisiblePeesIds(state),
  kpiAll: getKpiAll(state),
  kpiElec: getKpiElec(state),
  kpiGas: getKpiGas(state),
  kpiAllLoading: getKpiAllLoading(state),
  kpiElecLoading: getKpiElecLoading(state),
  kpiGasLoading: getKpiGasLoading(state),
  graph: getGraph(state),
})

export default withTranslation()(connect(mapStateToProps, actions)(AllAmenities))
