import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Container, Button } from 'reactstrap'

import { closeSearchPanel } from '../../actions/searchPanel'
import { getSearchPanelOpen, getFilterInput } from '../../reducers/searchPanel'
import { getRootUor } from '../../reducers/organisation'
import Uor from './Uor'
import ListeFiltree from './ListeFiltree'
import Filtres from './Filtres'
import SelectButtons from './SelectButtons'
import { updateView } from '../../actions/views'

const SearchPanel = ({ searchPanelOpen, rootUor, dispatch, filterInput, closeSearchPanel }) => (
  <div>
    <Container className={['search-panel', searchPanelOpen ? 'open' : ''].join(' ')} fluid>
      {searchPanelOpen ? (
        <span>
          <Button
            className="close-panel"
            color="light"
            onClick={() => {
              dispatch(closeSearchPanel)
              dispatch(updateView())
            }}
          >
            <i className="icon-cross" />
          </Button>
          <Filtres />

          <Row>
            <Col className="d-flex">
              <SelectButtons rootUor={rootUor} />
            </Col>
          </Row>

          <Row className="panel-flex">
            <Col>
              {filterInput ? (
                <ListeFiltree />
              ) : (
                <ul>
                  <Uor uorId={rootUor} />
                </ul>
              )}
            </Col>
          </Row>
        </span>
      ) : null}
    </Container>
    <div
      className="search-panel-overlay"
      onClick={() => {
        dispatch(closeSearchPanel)
        dispatch(updateView())
      }}
    />
  </div>
)

const mapStateToProps = (state) => ({
  searchPanelOpen: getSearchPanelOpen(state),
  rootUor: getRootUor(state),
  filterInput: getFilterInput(state),
  closeSearchPanel: closeSearchPanel(),
})

SearchPanel.propTypes = {
  searchPanelOpen: PropTypes.bool.isRequired,
  rootUor: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  filterInput: PropTypes.string.isRequired,
  closeSearchPanel: PropTypes.func.isRequired,
}

export default connect(mapStateToProps)(SearchPanel)
