import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Form, FormGroup, Label, Input } from 'reactstrap'
import { compose } from 'recompose'
import moment from 'moment'

import {
  getLoadCurveGraphUnite,
  getLoadCurveGraphAgregation,
  getLoadCurveGraphPas,
  getLoadCurveGraphEnergy,
  getLoadCurveGraphHrs,
  getStartDate,
  getEndDate,
} from '../../reducers/powerConsumption/loadCurve'

import {
  updateLoadCurveGraphUnite,
  updateLoadCurveGraphAgregation,
  updateLoadCurveGraphPas,
  updateLoadCurveGraphEnergy,
  updateLoadCurveGraphHrs,
} from '../../actions/powerConsumption/loadCurve'

import { getHrsReferentiel } from '../../store/hrs'

const FormLoadCurveGraph = ({
  updateLoadCurveGraphUnite,
  updateLoadCurveGraphAgregation,
  updateLoadCurveGraphPas,
  updateLoadCurveGraphEnergy,
  updateLoadCurveGraphHrs,
  loadCurveGraphUnite,
  loadCurveGraphAgregation,
  loadCurveGraphPas,
  loadCurveGraphEnergy,
  loadCurveGraphHrs,
  hrsReferentiel,
  startDate,
  endDate,
  t,
}) => {
  const m1 = moment(startDate)
  const m2 = moment(endDate)
  const hours = m2.diff(m1, 'hours', true)
  const days = m2.diff(m1, 'days', true)
  const months = m2.diff(m1, 'months', true)

  return (
    <Form style={{ fontSize: 14 }}>
      <FormGroup>
        <Label for="loadCurveGraphUnite" style={{ fontWeight: 'bold' }}>
          {t('powerConsumption.loadCurve.unit.label')}
        </Label>
        <Input
          type="select"
          id="loadCurveGraphUnite"
          value={loadCurveGraphUnite}
          onChange={(e) => updateLoadCurveGraphUnite(e.target.value)}
          style={{ width: 'auto', fontSize: 14 }}
        >
          <option value="1">kW</option>
          <option value="11">kWh</option>
          <option value="2">MW</option>
          <option value="12">MWh</option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="loadCurveGraphAgregation" style={{ fontWeight: 'bold' }}>
          {t('powerConsumption.loadCurve.aggregation.label')}
        </Label>
        <Input
          type="select"
          id="loadCurveGraphAgregation"
          value={loadCurveGraphAgregation}
          onChange={(e) => {
            updateLoadCurveGraphAgregation(e.target.value)
          }}
          style={{ width: 'auto', fontSize: 14 }}
        >
          <option value="0">{t('powerConsumption.loadCurve.aggregation.byPee')}</option>
          <option value="1">{t('powerConsumption.loadCurve.aggregation.bySite')}</option>
          <option value="2">{t('powerConsumption.loadCurve.aggregation.all')}</option>
        </Input>
      </FormGroup>

      <FormGroup>
        <Label for="loadCurveGraphPas" style={{ fontWeight: 'bold' }}>
          {t('powerConsumption.loadCurve.step.label')}
        </Label>
        <Input
          type="select"
          id="loadCurveGraphPas"
          value={loadCurveGraphPas}
          onChange={(e) => updateLoadCurveGraphPas(e.target.value)}
          style={{ width: 'auto', fontSize: 14 }}
        >
          <option value="8">{t('powerConsumption.loadCurve.step.10mins')}</option>
          {hours > 1 && <option value="5">{t('powerConsumption.loadCurve.step.hourly')}</option>}
          {days > 1 && <option value="4">{t('powerConsumption.loadCurve.step.daily')}</option>}
          {months > 1 && <option value="3">{t('powerConsumption.loadCurve.step.monthly')}</option>}
          {months > 3 && (
            <option value="2">{t('powerConsumption.loadCurve.step.quarterly')}</option>
          )}
        </Input>
      </FormGroup>

      <FormGroup>
        <Label for="loadCurveGraphEnergy" style={{ fontWeight: 'bold' }}>
          {t('powerConsumption.loadCurve.energy.label')}
        </Label>
        <Input
          type="select"
          id="loadCurveGraphEnergy"
          value={loadCurveGraphEnergy}
          onChange={(e) => updateLoadCurveGraphEnergy(e.target.value)}
          style={{ width: 'auto', fontSize: 14 }}
        >
          <option value="">{t('powerConsumption.loadCurve.energy.all')}</option>
          <option value="2">{t('powerConsumption.loadCurve.energy.elec')}</option>
          <option value="1">{t('powerConsumption.loadCurve.energy.gas')}</option>
        </Input>
      </FormGroup>

      <FormGroup>
        <Label for="loadCurveGraphHrs" style={{ fontWeight: 'bold' }}>
          {t('powerConsumption.loadCurve.hrs')}
        </Label>
        <Input
          type="select"
          id="loadCurveGraphHrs"
          value={loadCurveGraphHrs || undefined}
          onChange={(e) => updateLoadCurveGraphHrs(e.target.value)}
          style={{ fontSize: 14 }}
          disabled={Number(loadCurveGraphPas) <= 4}
        >
          <option value={0} />
          {hrsReferentiel &&
            hrsReferentiel.map(({ Id, Libelle }) => (
              <option key={Id} value={Id}>
                {Libelle}
              </option>
            ))}
        </Input>
      </FormGroup>
    </Form>
  )
}

const mapStateToProps = (state) => ({
  loadCurveGraphUnite: getLoadCurveGraphUnite(state),
  loadCurveGraphAgregation: getLoadCurveGraphAgregation(state),
  loadCurveGraphPas: getLoadCurveGraphPas(state),
  loadCurveGraphEnergy: getLoadCurveGraphEnergy(state),
  loadCurveGraphHrs: getLoadCurveGraphHrs(state),
  hrsReferentiel: getHrsReferentiel(state),
  startDate: getStartDate(state),
  endDate: getEndDate(state),
})

const mapDispatch = {
  updateLoadCurveGraphUnite,
  updateLoadCurveGraphAgregation,
  updateLoadCurveGraphPas,
  updateLoadCurveGraphEnergy,
  updateLoadCurveGraphHrs,
}

FormLoadCurveGraph.propTypes = {
  loadCurveGraphUnite: PropTypes.string.isRequired,
  loadCurveGraphAgregation: PropTypes.string.isRequired,
  loadCurveGraphPas: PropTypes.string.isRequired,
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatch))(FormLoadCurveGraph)
