export const closeSearchPanel = () => (dispatch) => {
  dispatch({
    type: 'CLOSE_SEARCH_PANEL',
  })
  document.body.classList.remove('no-scroll')
}

export const openSearchPanel = () => (dispatch) => {
  dispatch({
    type: 'OPEN_SEARCH_PANEL',
  })
  document.body.classList.add('no-scroll')
}

export const toggleSearchPanel = () => (dispatch) => {
  dispatch({
    type: 'TOGGLE_SEARCH_PANEL',
  })
  document.body.classList.toggle('no-scroll')
}

export const toggleFilterType = (filterType) => (dispatch) => {
  dispatch({
    type: 'TOGGLE_FILTER_TYPE',
    filterType,
  })
}

export const countryFilterChange = (country) => (dispatch) => {
  dispatch({
    type: 'COUNTRY_FILTER_CHANGE',
    countryFilter: country,
  })
}

export const filterInputChange = (filterInput) => (dispatch) => {
  dispatch({
    type: 'FILTER_INPUT_CHANGE',
    filterInput,
  })
}
