import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

const BillNav = ({ prev, next }) => (
  <div className="bill-nav">
    <Link to={'/bills/' + prev}>
      <i className="icon-smallarrow-left" />
    </Link>
    <Link to={'/bills/' + next}>
      <i className="icon-smallarrow-right" />
    </Link>
  </div>
)

BillNav.propTypes = {
  prev: PropTypes.number.isRequired,
  next: PropTypes.number.isRequired,
}

export default BillNav
