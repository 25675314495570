import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import SidebarSelection from './SidebarSelection'

class SidebarRoutes extends Component {
  render() {
    const { match } = this.props
    switch (match.params.path) {
      case 'market-informations':
      case 'market-admin':
        //return <SidebarMarket />;
        return null
      case undefined:
      case 'power-consumption':
      case 'load-curve':
      case 'expenses':
      case 'bills':
      case 'contracts':
      default:
        return <SidebarSelection />
    }
  }
}

SidebarRoutes.propTypes = {
  match: PropTypes.object.isRequired,
}

SidebarRoutes = withRouter(SidebarRoutes)

export default SidebarRoutes
