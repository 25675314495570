import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { ButtonGroup, Button } from 'reactstrap'

import { removePeeFromSelection, togglePeeVisible } from '../../../actions/currentSelection'
import { getIsPeeVisible } from '../../../reducers/currentSelection'
import { getPeePath } from '../../../reducers/organisation'
import { getIconClassName } from '../../../helpers/icon'

const CurrentPee = function({
  dispatch,
  pee,
  isPeeVisible,
  togglePeeVisible,
  removePeeFromSelection,
  peePath,
  t,
}) {
  return (
    <li className={['d-flex', isPeeVisible ? 'visible' : ''].join(' ')}>
      <i className={getIconClassName(pee.PeeNrjId)} />
      <span className="w-100" title={peePath}>
        {pee.PeeClef}
      </span>
      <ButtonGroup className="ml-auto">
        <Button
          title={isPeeVisible ? t('sidebar.current.hide') : t('sidebar.current.show')}
          onClick={(e) => {
            e.preventDefault()
            dispatch(togglePeeVisible)
          }}
        >
          <i className="icon-eye" />
        </Button>
        <Button
          title={t('sidebar.current.delete')}
          onClick={(e) => {
            e.preventDefault()
            dispatch(removePeeFromSelection)
          }}
        >
          <i className="icon-cross" />
        </Button>
      </ButtonGroup>
    </li>
  )
}

const mapStateToProps = (state, { pee }) => ({
  isPeeVisible: getIsPeeVisible(state, pee.id),
  togglePeeVisible: togglePeeVisible(pee.id),
  removePeeFromSelection: removePeeFromSelection(state, pee.id),
  peePath: getPeePath(state, pee.id),
})

CurrentPee.propTypes = {
  dispatch: PropTypes.func.isRequired,
  pee: PropTypes.object.isRequired,
  isPeeVisible: PropTypes.bool.isRequired,
  togglePeeVisible: PropTypes.func.isRequired,
  removePeeFromSelection: PropTypes.func.isRequired,
  peePath: PropTypes.string.isRequired,
}

export default withTranslation()(connect(mapStateToProps)(CurrentPee))
