import React from 'react'
import { MdCheckBox as CheckIcon, MdCheckBoxOutlineBlank as UncheckIcon } from 'react-icons/md'

export const CheckButton = ({ checked, onClick }) => {
  const iconProps = {
    size: 16,
    style: {
      color: 'var(--gray-600)',
    },
  }

  return (
    <button
      style={{ border: 'none', outline: 'none', cursor: 'pointer', background: 'transparent' }}
      onClick={onClick}
    >
      {checked ? <CheckIcon {...iconProps} /> : <UncheckIcon {...iconProps} />}
    </button>
  )
}
