import { useMemo, useRef } from 'react'
import { useQuery } from 'react-query'
import { superFetch } from 'commons'
import keyBy from 'lodash/keyBy'

export const useProjection = () => {
  const previousData = useRef()

  const query = useQuery({
    staleTime: 5 * 60_000,
    queryKey: ['comex-projection'],
    queryFn: async () => {
      const response = await superFetch({
        url: `cotations/projection/pays`,
        method: 'GET',
      })
      if (!response.ok) throw new Error(response.statusText)
      const data = await response.json()
      return keyBy(data, 'Pays')
    },
  })

  // keep previous data
  const data = useMemo(() => {
    if (!query.data) return previousData.current
    previousData.current = query.data
    return query.data
  }, [query.data])

  // console.log('useProjection', { query: query.data, data })

  return {
    ...query,
    isLoading: query.isFetching && !data,
    data,
  }
}
