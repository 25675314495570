import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { ErrorBoundary } from 'commons'
import { YearToDate } from 'components/comex/YearToDate'
import { FullYear } from 'components/comex/FullYear'
import { Couverture } from 'components/comex/Couverture'
import { Projection } from 'components/comex/Projection'
import { getDate, getNrj, setDate, setNrj } from 'store/comex'

const getMonthStart = (m) =>
  m
    .clone()
    .startOf('year')
    .format('MMMM')

const getMonthEnd = (m) =>
  m
    .clone()
    .endOf('month')
    .format('MMMM')

export const ComexView = () => {
  const date = useSelector(getDate)
  const year = Number(date.slice(0, 4))
  const m = moment(date, 'YYYY-MM')

  return (
    <div css={{ margin: '0 15px 30px', display: 'flex', flexDirection: 'column', gap: '30px' }}>
      <section
        css={{ margin: '0 !important', display: 'flex', flexDirection: 'column', gap: '30px' }}
      >
        <form className="form-inline">
          <label className="form-label" htmlFor="comex-date">
            Date
          </label>
          <MonthInput />

          <label className="form-label" htmlFor="comex-nrj">
            Commodité
          </label>
          <NrjSelect />
        </form>

        <Section title={`Cumul de ${getMonthStart(m)} à ${getMonthEnd(m)}`}>
          <YearToDate />
        </Section>

        <Section title={`Année ${year}`}>
          <FullYear />
        </Section>

        <Section title={`Couverture ${year}`}>
          <Couverture />
        </Section>

        <Section title="Prix de marché">
          <Projection />
        </Section>
      </section>
    </div>
  )
}

const MonthInput = () => {
  const dispatch = useDispatch()
  const value = useSelector(getDate)
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1

  return isFirefox ? (
    <input
      type="date"
      value={`${value}-01`}
      onChange={(e) => dispatch(setDate(e.target.value.slice(0, -3)))}
      className="form-control form-control-sm"
      id="comex-date"
    />
  ) : (
    <input
      type="month"
      value={value}
      onChange={(e) => dispatch(setDate(e.target.value))}
      className="form-control form-control-sm"
      id="comex-date"
    />
  )
}

const NrjSelect = () => {
  const dispatch = useDispatch()
  const value = useSelector(getNrj)
  return (
    <select
      value={value}
      onChange={(e) => dispatch(setNrj(e.target.value))}
      className="form-control form-control-sm"
      id="comex-nrj"
    >
      <option value={2}>Électricité</option>
      <option value={1}>Gaz</option>
    </select>
  )
}

const Section = ({ children, title }) => {
  return (
    <details open css={{ position: 'relative' }}>
      <summary>
        <h5 css={{ display: 'inline', marginLeft: '.5rem' }}>{title}</h5>
      </summary>

      <div css={{ marginTop: '2rem' }}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </div>
    </details>
  )
}
