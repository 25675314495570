import React from 'react'
import { Provider } from 'react-redux'
import { OidcProvider } from 'redux-oidc'
import { Route, Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { ReactQueryConfigProvider } from 'react-query'

import { userManager } from 'commons'

import startAnalytics from './analytics'
import Root from './components/route/Root'

const history = createBrowserHistory()
startAnalytics(history)

const queryConfig = {
  retry: 1,
  staleTime: 2000,
  refetchAllOnWindowFocus: false,
  suspense: false,
  useErrorBoundary: true,
}

const App = ({ store }) => {
  return (
    <ReactQueryConfigProvider config={queryConfig}>
      <Provider store={store}>
        <OidcProvider store={store} userManager={userManager}>
          <Router history={history}>
            <Route path="/" component={Root} />
          </Router>
        </OidcProvider>
      </Provider>
    </ReactQueryConfigProvider>
  )
}

export default App
