import { combineReducers } from 'redux'

import allAmenities from './powerConsumption/allAmenities'
import electricity from './powerConsumption/electricity'
import gas from './powerConsumption/gas'
import loadCurve from './powerConsumption/loadCurve'

const powerConsumption = () => {
  const graphUnite = (state = '12', action) => {
    switch (action.type) {
      case 'UPDATE_GRAPH_UNITE':
        return action.value
      default:
        return state
    }
  }

  const conso = (
    state = {
      data: [],
      isLoading: false,
      error: false,
      agregation: '0',
    },
    action
  ) => {
    switch (action.type) {
      case 'FETCH_CONSO_REQUEST':
        return {
          ...state,
          data: [],
          isLoading: true,
          error: false,
        }
      case 'FETCH_CONSO_SUCCESS':
        return {
          ...state,
          data: action.data,
          isLoading: false,
          error: false,
        }
      case 'FETCH_CONSO_FAILURE':
        return {
          ...state,
          data: [],
          isLoading: false,
          error: true,
        }
      default:
        return state
    }
  }

  return combineReducers({
    allAmenities: allAmenities(),
    electricity: electricity(),
    gas: gas(),
    loadCurve: loadCurve(),
    graphUnite,
    conso,
  })
}

export default powerConsumption

export const getGraphUnite = (state) => state.powerConsumption.graphUnite
export const getConso = (state) => state.powerConsumption.conso.data
export const getConsoIsLoading = (state) => state.powerConsumption.conso.isLoading
export const getConsoError = (state) => state.powerConsumption.conso.error
