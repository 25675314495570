import React, { Suspense } from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { useSite, usePatrimonials } from './useSite'
import { usePatrimonialReferentiel } from './usePatrimonialReferentiel'

import { ErrorBoundary } from 'commons'
import { Loader } from './Loader'
import { SiteGeneralInfos } from './SiteGeneralInfos'
import { SiteContacts } from './SiteContacts'
import { PeeInfos } from './PeeInfos'
import { SitePatrimonials } from './SitePatrimonials'

const NrjIcon = ({ id }) => {
  const className = id === 2 ? 'icon-elec' : id === 1 ? 'icon-gaz' : 'icon-tint'
  const color = id === 2 ? '#31acea' : id === 1 ? '#c53ed1' : '#31acea'
  return <i className={className} css={{ color }} />
}

export const SiteInfos = ({ id }) => {
  const { t } = useTranslation()
  const [activePee, setActivePee] = React.useState(0)

  const { infos, pees } = useSite(id)
  const patrimonials = usePatrimonials(id)
  const patrimonialRef = usePatrimonialReferentiel()

  return (
    <div
      className="m-3"
      css={{
        button: {
          i: {
            position: 'relative',
            top: -1,
            marginRight: 8,
          },
        },
        h5: {
          fontWeight: 'bold',
          color: '#61747f',
          marginBottom: '1.5rem',
        },
        '.h7': {
          fontWeight: 'bold',
          color: '#8c99a5',
          textTransform: 'uppercase',
          marginBottom: '1rem',
        },
        'label:not(.toggle-label)': {
          display: 'flex',
          alignItems: 'center',
          marginBottom: '.5rem',
          '>div:first-of-type': {
            width: 120,
            fontWeight: 'bold',
            paddingRight: '0.6rem',
          },
          '.form-control': {
            width: 200,
            '&:disabled': {
              backgroundColor: '#fff',
            },
          },
        },
        'th, td, .form-control, .react-select': {
          fontSize: 13,
        },
      }}
    >
      <section className="mb-4">
        <SiteGeneralInfos infos={infos} />
        {infos.Contacts && <SiteContacts siteId={infos.id} contacts={infos.Contacts} />}
      </section>

      {pees.length > 0 && (
        <section className="mb-4">
          <h5>{t('site.pee-details.title')}</h5>

          <Nav tabs className="simple-nav-tab flex-wrap-reverse mb-3">
            {pees.map((pee, index) => (
              <NavItem key={pee.id}>
                <NavLink
                  className={classnames({ active: activePee === index })}
                  onClick={() => setActivePee(index)}
                >
                  <NrjIcon id={pee.PeeNrjId} />
                  {pee.PeeClef}
                </NavLink>
              </NavItem>
            ))}
          </Nav>

          <div css={{ minHeight: 200 }}>
            <ErrorBoundary>
              <Suspense fallback={<Loader height={200} />}>
                <PeeInfos id={pees[activePee].id} />
              </Suspense>
            </ErrorBoundary>
          </div>
        </section>
      )}

      {Object.keys(patrimonialRef).length > 0 && (
        <section className="mb-4">
          <h5>{t('site.patrimonials.title')}</h5>
          <SitePatrimonials
            patrimonials={patrimonials}
            referentiel={patrimonialRef}
            siteId={infos.id}
          />
        </section>
      )}
    </div>
  )
}
