import { apiConf as api } from 'commons'
const { superFetch } = api

export const checkStatus = (resp) => {
  const { status, statusText } = resp
  if (status < 200 || status >= 300) throw new Error(`Erreur ${status}: ${statusText}`)
}

export const fetchPdfs = async ({ pdl, uorId, invoiceNumber }) => {
  const resp = await superFetch({
    url: `pdfs/pdls/${pdl}/${uorId}/invoices/${invoiceNumber}`,
    api: 'invoice',
  })

  checkStatus(resp)
  return resp
}

export const deletePdf = async (id) => {
  const resp = await superFetch({
    url: `pdfs/${id}`,
    method: 'DELETE',
    api: 'invoice',
  })

  checkStatus(resp)
  return resp
}

export const postBillPdf = async ({
  file,
  customerId,
  supplierCode,
  meterName,
  invoiceNumber,
  invoiceDate,
}) => {
  const body = new FormData()
  body.append('file', file)
  body.append('customerId', customerId)
  body.append('supplierCode', supplierCode)
  body.append('meterName', meterName)
  body.append('invoiceNumber', invoiceNumber)
  body.append('invoiceDate', invoiceDate)

  const resp = await superFetch({
    url: 'pdfs/upload',
    method: 'POST',
    body,
    stringify: false,
    contentType: null,
    api: 'invoice',
  })

  checkStatus(resp)
  return resp
}
