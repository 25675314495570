import {genericFetch} from "../commons/api/apiConf";
import {apiUrlEmcm} from "../commons/helpers/env";



export const getGrdfDroits = (peeNums) => {
    return genericFetch({
        baseUrl: apiUrlEmcm,
        url: 'grdf/droits-acces/find-by-nums',
        method: 'POST',
        body: peeNums
    }).then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    });
}