import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form } from 'reactstrap'

import FormLoadCurveCheckbox from './FormLoadCurveCheckbox'
import { getCountSelected } from '../../reducers/currentSelection'
import { toggleTypeDonnee } from '../../actions/powerConsumption/loadCurve'

class FormLoadCurve extends Component {
  componentDidMount() {
    const { countSelected, dispatch } = this.props
    if (countSelected === 1) {
      dispatch(toggleTypeDonnee('MesureN1'))
    }
  }

  render() {
    const { typeDonnees } = this.props

    return (
      <Form inline>
        {Object.keys(typeDonnees).map((value) => (
          <FormLoadCurveCheckbox key={value} value={value} text={typeDonnees[value]} />
        ))}
      </Form>
    )
  }
}

const mapStateToProps = (state) => ({
  countSelected: getCountSelected(state),
})

FormLoadCurve.propTypes = {
  typeDonnees: PropTypes.object.isRequired,
  countSelected: PropTypes.number.isRequired,
}

export default connect(mapStateToProps)(FormLoadCurve)
