import v4 from 'uuid/v4'
import * as user from 'commons'
import * as kpi from '../../api/kpi.js'
import * as graph from '../../api/graph.js'
import {
  getKpiExpTotalUuid,
  getKpiExpElecUuid,
  getKpiExpGasUuid,
  getKpiPriceTotalUuid,
  getKpiPriceElecUuid,
  getKpiPriceGasUuid,
  getPieGraphUuid,
} from '../../reducers/expenses/allAmenities'
import { getStartDate, getEndDate } from '../../reducers/dateRange'
import { getCurrentVisiblePeesIds } from '../../reducers/currentSelection'
import { getTypeDonnee } from '../../reducers/typeDonnee'

export const fetchKpiExpTotal = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_EXPENSES_ALL_EXP_TOTAL_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisiblePeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Depense',
      type,
      12,
      21,
      '',
      'Total',
      'Commodite',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (response && response.headers.get('X-REQUEST-ID') === getKpiExpTotalUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_EXPENSES_ALL_EXP_TOTAL_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_EXPENSES_ALL_EXP_TOTAL_FAILURE',
          message: error.Message || 'Something went wrong.',
        })
      }
    )
}

export const fetchKpiExpElec = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_EXPENSES_ALL_EXP_ELEC_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisiblePeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Depense',
      type,
      12,
      21,
      '',
      '2',
      'Commodite',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (response && response.headers.get('X-REQUEST-ID') === getKpiExpElecUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_EXPENSES_ALL_EXP_ELEC_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_EXPENSES_ALL_EXP_ELEC_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchKpiExpGas = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_EXPENSES_ALL_EXP_GAS_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisiblePeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Depense',
      type,
      12,
      21,
      '',
      '1',
      'Commodite',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (response && response.headers.get('X-REQUEST-ID') === getKpiExpGasUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_EXPENSES_ALL_EXP_GAS_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_EXPENSES_ALL_EXP_GAS_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchKpiPriceTotal = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_EXPENSES_ALL_PRICE_TOTAL_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisiblePeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Prix',
      type,
      12,
      21,
      '',
      '',
      '',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (response && response.headers.get('X-REQUEST-ID') === getKpiPriceTotalUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_EXPENSES_ALL_PRICE_TOTAL_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_EXPENSES_ALL_PRICE_TOTAL_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchKpiPriceElec = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_EXPENSES_ALL_PRICE_ELEC_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisiblePeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  // fetchKpi = (uuid, dateDebut, dateFin, perimetre, mesures, type, unite, devise, commodite, decomposition, axe_decomposition, uorIdMst, lanId)

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Prix',
      type,
      12,
      21,
      '2',
      '',
      '',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (response && response.headers.get('X-REQUEST-ID') === getKpiPriceElecUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_EXPENSES_ALL_PRICE_ELEC_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_EXPENSES_ALL_PRICE_ELEC_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchKpiPriceGas = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_EXPENSES_ALL_PRICE_GAS_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisiblePeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Prix',
      type,
      12,
      21,
      '1',
      '',
      '',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (response && response.headers.get('X-REQUEST-ID') === getKpiPriceGasUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_EXPENSES_ALL_PRICE_GAS_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_EXPENSES_ALL_PRICE_GAS_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchPieGraph = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_EXPENSES_ALL_PIE_GRAPH_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisiblePeesIds(getState()).join(';')
  const axeDecomposition = 'Commodite'
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return graph
    .fetchNontemporalGraph(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Depense',
      'Facture',
      '',
      12,
      21,
      '2;1',
      axeDecomposition,
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (response && response.headers.get('X-REQUEST-ID') === getPieGraphUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_EXPENSES_ALL_PIE_GRAPH_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_EXPENSES_ALL_PIE_GRAPH_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}
