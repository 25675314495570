import PropTypes from 'prop-types'
import React from 'react'
import {NavLink} from 'react-router-dom'

const TopNavLink = ({path, children, isActive}) => (
    <NavLink to={path} className="nav-link" activeClassName="active" isActive={isActive}>
        <span>{children}</span>
        <div className="active-nav"/>
    </NavLink>
)

const paths = [
    '/power-consumption/all-amenities',
    '/expenses/all-amenities',
    '/bills',
    '/contracts/energy-meter',
    '/market-informations',
    '/market-admin',
    '/sites',
    '/comex',
    "/grdf"
]

TopNavLink.propTypes = {
    path: PropTypes.oneOf(paths).isRequired,
    children: PropTypes.node.isRequired,
}

export default TopNavLink
