import v4 from 'uuid/v4'
import { normalize } from 'normalizr'
import * as schema from './schema'

import * as api from '../api/favorites.js'
import {
  getFavoritesUuid,
  getFavoritesItemPees,
  getPostFavoriteUuid,
  getDelFavoriteUuid,
} from '../reducers/favorites'
import { getPeesIds } from '../reducers/organisation'
import * as user from 'commons'
import { getObjectOfCurrentPeesIds } from '../reducers/currentSelection'
import { updateView } from './views'

export const fetchFavorites = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_FAVORITES_REQUEST',
    uuid,
  })

  const usrId = user.usrId(getState())

  return api.fetchFavorites(uuid, usrId).then(
    (response) => {
      if (response && response.headers.get('X-REQUEST-ID') === getFavoritesUuid(getState())) {
        response.json().then((response) =>
          dispatch({
            type: 'FETCH_FAVORITES_SUCCESS',
            response: normalize(response, schema.arrayOfFavItem),
          })
        )
      }
    },
    (error) => {
      dispatch({
        type: 'FETCH_FAVORITES_FAILURE',
        message: error.Message || 'Something went wrong.',
      })
    }
  )
}

export const setFavorites = (state, favId) => (dispatch) => {
  const peesIds = favId === -1 ? getPeesIds(state) : getFavoritesItemPees(state, favId)
  dispatch({
    type: 'SET_FAVORITES',
    peesIds,
    organisation: state.organisation.organisationEntities,
  })
  dispatch(updateView())
}

export const delFavorite = (state, favId) => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'DEL_FAVORITE_REQUEST',
    uuid,
  })

  return api.delFavorite(uuid, favId).then(
    (response) => {
      if (response.status === 200) {
        if (response && response.headers.get('X-REQUEST-ID') === getDelFavoriteUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'DEL_FAVORITE_SUCCESS',
              response,
            })
          )
        }
      } else {
        if (response && response.headers.get('X-REQUEST-ID') === getDelFavoriteUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'DEL_FAVORITE_ERROR',
              message: response.Message,
            })
          )
        }
      }
    },
    (error) => {
      dispatch({
        type: 'DEL_FAVORITE_FAILURE',
        message: error.Message || 'Something went wrong.',
      })
    }
  )
}

export const postFavorite = (FavNom) => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'POST_FAVORITE_REQUEST',
    uuid,
  })

  const usrId = user.usrId(getState())
  const peesIds = getObjectOfCurrentPeesIds(getState())

  return api.postFavorite(uuid, usrId, FavNom, peesIds).then(
    (response) => {
      if (response.status === 200) {
        if (response && response.headers.get('X-REQUEST-ID') === getPostFavoriteUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'POST_FAVORITE_SUCCESS',
              response: normalize(response, schema.favItem),
            })
          )
        }
      } else {
        if (response && response.headers.get('X-REQUEST-ID') === getPostFavoriteUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'POST_FAVORITE_ERROR',
              message: response.Message,
            })
          )
        }
      }
    },
    (error) => {
      dispatch({
        type: 'POST_FAVORITE_FAILURE',
        message: error.Message || 'Something went wrong.',
      })
    }
  )
}
