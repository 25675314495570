import 'react-app-polyfill/ie11'
import 'typeface-muli'
import 'moment/locale/fr'

import React from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import moment from 'moment'

// CSS
import 'assets/css/index.scss'

// Config
import { i18n, init } from 'commons'
import configureStore from './configureStore'
import * as serviceWorker from './serviceWorker'
import { setHighchartsOptions } from './helpers/highcharts'
import * as env from './helpers/env'

// Components
import App from './App'

import { lngIso } from 'commons/i18n/i18n'
import fr from './locales/fr.json'
import en from './locales/en.json'
import de from './locales/de.json'
import pl from './locales/pl.json'

// Init before configure store
init(env)

i18n.addResourceBundle('fr', 'translation', fr)
i18n.addResourceBundle('en', 'translation', en)
i18n.addResourceBundle('de', 'translation', de)
i18n.addResourceBundle('pl', 'translation', pl)

// !!! Call init before configureStore
export const store = configureStore()
const getLanId = (state) => state && state.user && state.user.userInfo && state.user.userInfo.LanId
let lanId = getLanId(store.getState())

// Configure Moment & Hichcharts locales
moment.locale('fr')
store.subscribe(() => {
  const newLanId = getLanId(store.getState())
  if (newLanId !== lanId) {
    lanId = newLanId
    moment.locale(lngIso(lanId))
    setHighchartsOptions(lanId)
  }
})

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App store={store} />
  </I18nextProvider>,
  document.getElementById('root')
)

serviceWorker.register()
