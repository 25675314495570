import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { Row, Col, Form, FormGroup, Input, Label, Button } from 'reactstrap'

class Security extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    }
  }

  updatePassword = (e) => {
    this.setState({ currentPassword: e.target.value })
  }

  updateNewPassword = (e) => {
    this.setState({ newPassword: e.target.value })
  }

  updateNewPasswordConfirmation = (e) => {
    this.setState({ newPasswordConfirmation: e.target.value })
  }

  render() {
    const { t } = this.props
    const { currentPassword, newPassword, newPasswordConfirmation } = this.state

    return (
      <Fragment>
        <Row className="form-inline panel-header radio-input-btn">
          <Col className="d-flex">
            <div className="ml-auto">
              <Button color="danger" onClick={this.props.logout}>
                <i className="icon-power" />
                {this.props.t('user.logout')}
              </Button>
            </div>
          </Col>
        </Row>
        <Form
          className="user-infos user-security"
          onSubmit={(e) => {
            e.preventDefault()
            this.props.changePassword({
              Password: this.state.currentPassword,
              NewPassword: this.state.newPassword,
              NewPasswordConfirm: this.state.newPasswordConfirmation,
            })
          }}
        >
          <Row className="panel-flex">
            <Col>
              <FormGroup className="text-input">
                <Input
                  id="currentPassword"
                  value={currentPassword}
                  type="password"
                  onChange={this.updatePassword}
                  className={currentPassword === '' ? '' : 'has-value'}
                />
                <Label for="currentPassword">{t('user.security.currentPassword')}</Label>
              </FormGroup>
              <FormGroup className="text-input">
                <Input
                  id="newPassword"
                  value={newPassword}
                  type="password"
                  onChange={this.updateNewPassword}
                  className={newPassword === '' ? '' : 'has-value'}
                />
                <Label for="newPassword">{t('user.security.newPassword')}</Label>
              </FormGroup>
              <FormGroup className="text-input">
                <Input
                  id="newPasswordConfirm"
                  value={newPasswordConfirmation}
                  type="password"
                  onChange={this.updateNewPasswordConfirmation}
                  className={newPasswordConfirmation === '' ? '' : 'has-value'}
                />
                <Label for="newPasswordConfirm">{t('user.security.newPasswordConfirmation')}</Label>
              </FormGroup>
            </Col>
          </Row>

          <Row className="panel-footer">
            <Col className="d-flex">
              <div className="ml-auto">
                <div className="push-right">
                  <Button
                    type="submit"
                    color="primary"
                    disabled={
                      currentPassword === '' || newPassword === '' || newPasswordConfirmation === ''
                    }
                  >
                    {t('user.userInfo.save')}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </Fragment>
    )
  }
}

export default withTranslation()(Security)
