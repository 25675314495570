import { combineReducers } from 'redux'

const market = () => {
  const kpiMarketElecUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_MARKET_KPI_ELEC_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiMarketGasUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_MARKET_KPI_GAS_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiMarketUKBrentUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_MARKET_KPI_UKBRENT_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const marketElecGraphUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_MARKET_GRAPH_ELEC_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const marketGasGraphUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_MARKET_GRAPH_GAS_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const marketUKBrentGraphUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_MARKET_GRAPH_UKBRENT_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const marketProductsUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_MARKET_PRODUCTS_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const marketProductsGraphUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_MARKET_PRODUCTS_GRAPH_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiMarketElec = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_MARKET_KPI_ELEC_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiMarketGas = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_MARKET_KPI_GAS_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiMarketUKBrent = (
    state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } },
    action
  ) => {
    switch (action.type) {
      case 'FETCH_MARKET_KPI_UKBRENT_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const marketElecGraph = (state = {}, action) => {
    switch (action.type) {
      case 'FETCH_MARKET_GRAPH_ELEC_SUCCESS':
        return action.response
      default:
        return state
    }
  }

  const marketGasGraph = (state = {}, action) => {
    switch (action.type) {
      case 'FETCH_MARKET_GRAPH_GAS_SUCCESS':
        return action.response
      default:
        return state
    }
  }

  const marketUKBrentGraph = (state = {}, action) => {
    switch (action.type) {
      case 'FETCH_MARKET_GRAPH_UKBRENT_SUCCESS':
        return action.response
      default:
        return state
    }
  }

  const marketProductsGraph = (state = {}, action) => {
    switch (action.type) {
      case 'FETCH_MARKET_PRODUCTS_GRAPH_SUCCESS':
        return action.response
      default:
        return state
    }
  }

  const marketProducts = (state = {}, action) => {
    switch (action.type) {
      case 'FETCH_MARKET_PRODUCTS_SUCCESS':
        return action.response.entities.product || {}
      default:
        return state
    }
  }

  const marketProductsVisibleIds = (state = [], action) => {
    switch (action.type) {
      case 'FETCH_MARKET_PRODUCTS_SUCCESS':
        return [action.response.result[0]] || []
      case 'MARKET_PRODUCT_TOGGLE_VISIBILITY':
        const id = parseInt(action.id, 10)
        return state.includes(id) ? state.filter((i) => i !== id) : [...state, id]
      default:
        return state
    }
  }

  const marketCotProducts = (state = {}, action) => {
    switch (action.type) {
      case 'FETCH_MARKET_PRODUCTS_SUCCESS':
        return action.response.entities.cotProduct || {}
      default:
        return state
    }
  }

  return combineReducers({
    kpiMarketElecUuid,
    kpiMarketGasUuid,
    kpiMarketUKBrentUuid,
    marketElecGraphUuid,
    marketGasGraphUuid,
    marketUKBrentGraphUuid,
    marketProductsUuid,
    marketProductsGraphUuid,
    kpiMarketElec,
    kpiMarketGas,
    kpiMarketUKBrent,
    marketElecGraph,
    marketGasGraph,
    marketUKBrentGraph,
    marketProductsGraph,
    marketProducts,
    marketProductsVisibleIds,
    marketCotProducts,
  })
}

export default market

export const getKpiMarketElecUuid = (state) => state.market.kpiMarketElecUuid
export const getKpiMarketGasUuid = (state) => state.market.kpiMarketGasUuid
export const getKpiMarketUKBrentUuid = (state) => state.market.kpiMarketUKBrentUuid
export const getMarketElecGraphUuid = (state) => state.market.marketElecGraphUuid
export const getMarketGasGraphUuid = (state) => state.market.marketGasGraphUuid
export const getMarketUKBrentGraphUuid = (state) => state.market.marketUKBrentGraphUuid
export const getMarketProductsUuid = (state) => state.market.marketProductsUuid
export const getMarketProductsGraphUuid = (state) => state.market.marketProductsGraphUuid
export const getKpiMarketElec = (state) => state.market.kpiMarketElec
export const getKpiMarketGas = (state) => state.market.kpiMarketGas
export const getKpiMarketUKBrent = (state) => state.market.kpiMarketUKBrent
export const getMarketElecGraph = (state) => state.market.marketElecGraph
export const getMarketGasGraph = (state) => state.market.marketGasGraph
export const getMarketUKBrentGraph = (state) => state.market.marketUKBrentGraph
export const getMarketProductsGraph = (state) => state.market.marketProductsGraph
export const getMarketProducts = (state) => state.market.marketProducts
export const getMarketProductsIds = (state) => Object.keys(state.market.marketProducts)
export const getMarketProductsVisibleIds = (state) => state.market.marketProductsVisibleIds
export const getMarketVisibleProductsGraph = (state) => ({
  series:
    (state.market.marketProductsGraph.series &&
      state.market.marketProductsGraph.series.filter((i) =>
        state.market.marketProductsVisibleIds.includes(i.id)
      )) ||
    [],
})
export const getMarketCotProducts = (state) => state.market.marketCotProducts
