import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import FavoritesList from './FavoritesList'
import * as actions from '../../../actions/favorites'
import { getErrorMessageFavorites, getFavoritesList } from '../../../reducers/favorites'

class SidebarFavorites extends Component {
  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    const { fetchFavorites } = this.props
    fetchFavorites()
  }

  render() {
    const { errorMessageFavorites, favoritesList, ...rest } = this.props

    if (errorMessageFavorites && !favoritesList.length) {
      return <p>{errorMessageFavorites}</p>
    }

    return <FavoritesList favoritesList={favoritesList} {...rest} />
  }
}

const mapStateToProps = (state) => ({
  errorMessageFavorites: getErrorMessageFavorites(state),
  favoritesList: getFavoritesList(state),
})

SidebarFavorites.propTypes = {
  errorMessageFavorites: PropTypes.string,
  favoritesList: PropTypes.array.isRequired,
  fetchFavorites: PropTypes.func.isRequired,
}

SidebarFavorites = connect(mapStateToProps, actions)(SidebarFavorites)

export default SidebarFavorites
