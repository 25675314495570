import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Form, FormGroup, Label } from 'reactstrap'
import { compose, lifecycle, withHandlers } from 'recompose'
import flatten from 'lodash/flatten'

import { getSelectedPees } from '../../reducers/powerConsumption/loadCurve'
import { setSelectedPees } from '../../actions/powerConsumption/loadCurve'
import {
  getCurrentVisiblePeesBySite,
  getCurrentVisiblePeesIds,
} from '../../reducers/currentSelection'

import Select from '../common/Select/Select'

const FormLoadCurveGraph = ({ selectedPees, currentVisiblePeesBySite, setSelectedPees, t }) => {
  const peesOptions = currentVisiblePeesBySite.map(({ name, pees }) => ({
    label: name,
    options: pees.map(({ id, PeeClef, PeeNrjId }) => ({
      value: id,
      label: PeeClef,
      nrj: PeeNrjId,
      site: name,
    })),
  }))

  const flatPeesOptions = flatten(peesOptions.map((o) => o.options))

  return (
    <Form inline style={{ marginBottom: '7rem' }}>
      <FormGroup>
        <Label>{t('powerConsumption.loadCurve.pees')}: </Label>
        <Select
          placeholder={''}
          value={flatPeesOptions.filter(({ value }) => selectedPees.includes(value))}
          onChange={(options, ...rest) => {
            if (options.length > 10) return
            setSelectedPees(options.map((o) => o.value))
          }}
          options={peesOptions}
          size="multi"
          width={210}
          isClearable={false}
          isSearchable={true}
          isMulti={true}
          selectedLabel={t('powerConsumption.loadCurve.selected')}
          // menuIsOpen={true}
        />
      </FormGroup>
    </Form>
  )
}

const mapStateToProps = (state) => ({
  currentVisiblePeesBySite: getCurrentVisiblePeesBySite(state),
  currentVisiblePeesIds: getCurrentVisiblePeesIds(state),
  selectedPees: getSelectedPees(state),
})

const mapDispatch = {
  setSelectedPees,
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatch),
  withHandlers({
    initSelectedPees: (props) => () => {
      const { setSelectedPees, currentVisiblePeesIds } = props
      setSelectedPees(currentVisiblePeesIds.slice(0, 10).map((id) => Number(id)))
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.initSelectedPees()
    },
    componentDidUpdate(prevProps) {
      if (
        prevProps.currentVisiblePeesIds.join(',') !== this.props.currentVisiblePeesIds.join(',')
      ) {
        this.props.initSelectedPees()
      }
    },
  })
)(FormLoadCurveGraph)
