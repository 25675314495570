import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Container, Row, Col, InputGroup, Form, FormGroup, Button, Label, Input } from 'reactstrap'
import Datetime from 'react-datetime'
import moment from 'moment'

import { dateFormat } from '../../helpers/helpers'
import * as user from 'commons'
import * as actions from '../../actions/bills'

import BillTable from './BillTable'
import BillHeader from './BillHeader'

import BillDocuments from './BillDocuments'
import BillFileUpload from './BillFileUpload'

import BillPdfList from './BillPdfList'
class Bill extends Component {
  constructor(props) {
    super(props)

    this.putBill = this.putBill.bind(this)
  }

  putBill() {
    const { dispatch } = this.props
    dispatch(actions.putBill())
  }

  updateInput(k, v) {
    const { dispatch } = this.props
    const keys = k.split('.')
    let o = v

    keys.reverse().map((i) => (o = { [i]: o }))

    dispatch(actions.updateBillForm(o))
  }

  render() {
    const { bill, t, lng } = this.props

    return (
      <div>
        <Link to="/bills" className="btn btn-close btn-light">
          <i className="icon-cross" />
        </Link>
        <Container fluid={true} style={{ width: '100%' }}>
          <Form>
            <BillHeader {...bill} />

            <Row className="addresses">
              <Col xs="9">
                <Row>
                  <Col>
                    <h4>{t('bills.bill.address.site')}</h4>
                    <FormGroup>
                      <Input
                        value={
                          (bill.FacSite &&
                            bill.FacSite.SphAdresse &&
                            bill.FacSite.SphAdresse.AdrAdresse) ||
                          ''
                        }
                        placeholder={t('bills.bill.address.address')}
                        onChange={(e) =>
                          this.updateInput('FacSite.SphAdresse.AdrAdresse', e.target.value)
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        value={
                          (bill.FacSite &&
                            bill.FacSite.SphAdresse &&
                            bill.FacSite.SphAdresse.AdrCompl1) ||
                          ''
                        }
                        placeholder={t('bills.bill.address.comp1')}
                        onChange={(e) =>
                          this.updateInput('FacSite.SphAdresse.AdrCompl1', e.target.value)
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        value={
                          (bill.FacSite &&
                            bill.FacSite.SphAdresse &&
                            bill.FacSite.SphAdresse.AdrCompl2) ||
                          ''
                        }
                        placeholder={t('bills.bill.address.comp2')}
                        onChange={(e) =>
                          this.updateInput('FacSite.SphAdresse.AdrCompl2', e.target.value)
                        }
                      />
                    </FormGroup>
                    <div className="form-inline">
                      <FormGroup>
                        <Input
                          className="postal-code"
                          value={
                            (bill.FacSite &&
                              bill.FacSite.SphAdresse &&
                              bill.FacSite.SphAdresse.AdrCodePostal) ||
                            ''
                          }
                          placeholder={t('bills.bill.address.pc')}
                          onChange={(e) =>
                            this.updateInput('FacSite.SphAdresse.AdrCodePostal', e.target.value)
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Input
                          value={
                            (bill.FacSite &&
                              bill.FacSite.SphAdresse &&
                              bill.FacSite.SphAdresse.AdrVille) ||
                            ''
                          }
                          placeholder={t('bills.bill.address.city')}
                          onChange={(e) =>
                            this.updateInput('FacSite.SphAdresse.AdrVille', e.target.value)
                          }
                        />
                      </FormGroup>
                    </div>
                    <FormGroup>
                      <Input
                        value={
                          (bill.FacSite &&
                            bill.FacSite.SphAdresse &&
                            bill.FacSite.SphAdresse.AdrPays) ||
                          ''
                        }
                        placeholder={t('bills.bill.address.country')}
                        onChange={(e) =>
                          this.updateInput('FacSite.SphAdresse.AdrPays', e.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <h4>{t('bills.bill.address.billing')}</h4>
                    <FormGroup>
                      <Input
                        value={
                          (bill.FacSite &&
                            bill.FacSite.SitFacAdresse &&
                            bill.FacSite.SitFacAdresse.AdrAdresse) ||
                          ''
                        }
                        placeholder={t('bills.bill.address.address')}
                        onChange={(e) =>
                          this.updateInput('FacSite.SitFacAdresse.AdrAdresse', e.target.value)
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        value={
                          (bill.FacSite &&
                            bill.FacSite.SitFacAdresse &&
                            bill.FacSite.SitFacAdresse.AdrCompl1) ||
                          ''
                        }
                        placeholder={t('bills.bill.address.comp1')}
                        onChange={(e) =>
                          this.updateInput('FacSite.SitFacAdresse.AdrCompl1', e.target.value)
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        value={
                          (bill.FacSite &&
                            bill.FacSite.SitFacAdresse &&
                            bill.FacSite.SitFacAdresse.AdrCompl2) ||
                          ''
                        }
                        placeholder={t('bills.bill.address.comp2')}
                        onChange={(e) =>
                          this.updateInput('FacSite.SitFacAdresse.AdrCompl2', e.target.value)
                        }
                      />
                    </FormGroup>
                    <div className="form-inline">
                      <FormGroup>
                        <Input
                          className="postal-code"
                          value={
                            (bill.FacSite &&
                              bill.FacSite.SitFacAdresse &&
                              bill.FacSite.SitFacAdresse.AdrCodePostal) ||
                            ''
                          }
                          placeholder={t('bills.bill.address.pc')}
                          onChange={(e) =>
                            this.updateInput('FacSite.SitFacAdresse.AdrCodePostal', e.target.value)
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Input
                          value={
                            (bill.FacSite &&
                              bill.FacSite.SitFacAdresse &&
                              bill.FacSite.SitFacAdresse.AdrVille) ||
                            ''
                          }
                          placeholder={t('bills.bill.address.city')}
                          onChange={(e) =>
                            this.updateInput('FacSite.SitFacAdresse.AdrVille', e.target.value)
                          }
                        />
                      </FormGroup>
                    </div>
                    <FormGroup>
                      <Input
                        value={
                          (bill.FacSite &&
                            bill.FacSite.SitFacAdresse &&
                            bill.FacSite.SitFacAdresse.AdrPays) ||
                          ''
                        }
                        placeholder={t('bills.bill.address.country')}
                        onChange={(e) =>
                          this.updateInput('FacSite.SitFacAdresse.AdrPays', e.target.value)
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>

            {bill.FacGroupeLfa && (
              <BillTable FacGroupeLfa={bill.FacGroupeLfa} FacDevise={bill.FacDevise} />
            )}

            <Row className="bill-more">
              <Col xs="7">
                <Row>
                  <Col xs="5">
                    <Label>{t('bills.bill.paidOn')}:</Label>
                    <InputGroup>
                      <Datetime
                        id="paid-on"
                        ref={(input) => {
                          this.dateInput = input
                        }}
                        locale={lng}
                        closeOnSelect
                        dateFormat={dateFormat()}
                        timeFormat={false}
                        className="form-control"
                        value={
                          (bill.FacDatePaiement &&
                            moment(bill.FacDatePaiement, 'DD/MM/YYYY').toDate()) ||
                          ''
                        }
                        onChange={(date) => {
                          this.updateInput(
                            'FacDatePaiement',
                            date && date._isValid ? date.format('DD/MM/YYYY') : null
                          )
                        }}
                        inputProps={{ className: '' }}
                        css={{
                          'input:focus': {
                            outline: 'none',
                          },
                        }}
                      />
                      {/* <InputGroupAddon
                        addonType="append"
                        className="calendar-ico"
                        onClick={() => {
                          this.dateInput.openCalendar()
                        }}
                      >
                        <i className="icon-event" style={{ verticalAlign: 'middle' }} />
                      </InputGroupAddon> */}
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      type="textarea"
                      rows="3"
                      value={bill.FacNoteContenu || ''}
                      placeholder={t('bills.bill.note')}
                      onChange={(e) => this.updateInput('FacNoteContenu', e.target.value)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs="5" className="bill-docs">
                <Row>
                  <Col>
                    <h4>{t('bills.bill.pdfAttachments')}</h4>

                    <BillPdfList />

                    <h4>{t('bills.bill.attachments')}</h4>

                    {bill.FacDocsJoints && (
                      <ul>
                        {bill.FacDocsJoints.map((doc) => (
                          <BillDocuments key={doc.Id} billId={bill.id} {...doc} />
                        ))}
                      </ul>
                    )}

                    <BillFileUpload />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="bill-bottom">
              <Col className="text-right">
                <Link className="btn btn-link" to="/bills">
                  {t('bills.bill.cancel')}
                </Link>
                <Button
                  color="link"
                  onClick={(e) => {
                    e.preventDefault()
                    this.putBill()
                  }}
                >
                  {t('bills.bill.save')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  lng: user.lngIso(state),
})

Bill.propTypes = {
  bill: PropTypes.object.isRequired,
  putBill: PropTypes.func.isRequired,
  updateBillForm: PropTypes.func.isRequired,
  lng: PropTypes.string.isRequired,
}

export default withTranslation()(connect(mapStateToProps)(Bill))
