import React from 'react'
import { Input } from 'reactstrap'

const CustomInput = ({ value, onChange = () => {}, disabled = false, ...props }) => {
  return (
    <Input
      disabled={disabled}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      {...props}
    />
  )
}

export { CustomInput as Input }
