export const FETCH_MY_AVAILABLE_QUOTATIONS_REQUEST = 'FETCH_MY_AVAILABLE_QUOTATIONS_REQUEST'
export const FETCH_MY_AVAILABLE_QUOTATIONS_SUCCESS = 'FETCH_MY_AVAILABLE_QUOTATIONS_SUCCESS'
export const FETCH_MY_AVAILABLE_QUOTATIONS_FAILURE = 'FETCH_MY_AVAILABLE_QUOTATIONS_FAILURE'

export const FETCH_MY_QUOTATIONS_REQUEST = 'FETCH_MY_QUOTATIONS_REQUEST'
export const FETCH_MY_QUOTATIONS_SUCCESS = 'FETCH_MY_QUOTATIONS_SUCCESS'
export const FETCH_MY_QUOTATIONS_FAILURE = 'FETCH_MY_QUOTATIONS_FAILURE'

export const ADD_MY_QUOTATIONS_REQUEST = 'ADD_MY_QUOTATIONS_REQUEST'
export const ADD_MY_QUOTATIONS_SUCCESS = 'ADD_MY_QUOTATIONS_SUCCESS'
export const ADD_MY_QUOTATIONS_FAILURE = 'ADD_MY_QUOTATIONS_FAILURE'

export const DELETE_MY_QUOTATION_REQUEST = 'DELETE_MY_QUOTATION_REQUEST'
export const DELETE_MY_QUOTATION_SUCCESS = 'DELETE_MY_QUOTATION_SUCCESS'
export const DELETE_MY_QUOTATION_FAILURE = 'DELETE_MY_QUOTATION_FAILURE'

export const RESET_QUOTATION_GRAPH = 'RESET_QUOTATION_GRAPH'

export const FETCH_QUOTATION_GRAPH_REQUEST = 'FETCH_QUOTATION_GRAPH_REQUEST'
export const FETCH_QUOTATION_GRAPH_FAILURE = 'FETCH_QUOTATION_GRAPH_FAILURE'
export const FETCH_QUOTATION_GRAPH_SUCCESS = 'FETCH_QUOTATION_GRAPH_SUCCESS'

export const TOGGLE_QUOTATION_BOLLINGER = 'TOGGLE_QUOTATION_BOLLINGER'
export const TOGGLE_QUOTATION_TUNNEL = 'TOGGLE_QUOTATION_TUNNEL'
