import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { getMeterDetails } from '../../reducers/contracts'
import MeterAch from './MeterAch'
import MeterLot from './MeterLot'

const MeterInfos = ({ id, meter, t }) => (
  <div>
    <MeterAch {...meter.LcpAcheminement} />
    <hr />
    {meter.LcpTabLot &&
      meter.LcpTabLot.map((i, index) => (
        <div key={i.id}>
          <MeterLot {...i} />
          {meter.LcpTabLot.length - 1 > index && <hr />}
        </div>
      ))}
  </div>
)

const mapStateToProps = (state, { id }) => ({
  meter: getMeterDetails(state, id),
})

MeterInfos.propTypes = {
  id: PropTypes.number.isRequired,
  meter: PropTypes.shape({
    LcpNomGroupeContrat: PropTypes.string,
    LcpNrj: PropTypes.string,
    LcpNrjId: PropTypes.number,
    LcpAcheminement: PropTypes.shape({
      id: PropTypes.number,
      AchPeeId: PropTypes.number,
      AchPeeClef: PropTypes.string,
      AchPeeNrj: PropTypes.string,
      AchGrd: PropTypes.string,
      AchListeFta: PropTypes.arrayOf(
        PropTypes.shape({
          AchFtaLibelle: PropTypes.string,
          AchFtaDateDerniereModification: PropTypes.string,
          AchFtaParametres: PropTypes.arrayOf(
            PropTypes.shape({
              FtaParamLibelle: PropTypes.string,
              FtaParamValeur: PropTypes.string,
            })
          ),
        })
      ),
    }),
    LcpTabLot: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        LotNrj: PropTypes.string,
        LotDateDebut: PropTypes.string,
        LotDateFin: PropTypes.string,
        LotNom: PropTypes.string,
        LotType: PropTypes.string,
        LotTypeFormulePrix: PropTypes.string,
        LotHorosaisonnier: PropTypes.string,
        LotVolume: PropTypes.number,
        LotFournisseur: PropTypes.string,
        ParametresPrix: PropTypes.arrayOf(
          PropTypes.shape({
            ParamPrixLibelle: PropTypes.string,
            ParamPrixValeur: PropTypes.string,
          })
        ),
        LotPeeRef: PropTypes.object,
      })
    ),
  }).isRequired,
}

export default withTranslation()(connect(mapStateToProps)(MeterInfos))
