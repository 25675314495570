import v4 from 'uuid/v4'
import { apiConf as api } from 'commons'

export const fetchCountries = () => {
  const uuid = v4()

  return api.superFetch({
    url: 'pays',
    uuid: uuid,
  })
}
