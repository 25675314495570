import { apiConf as api } from 'commons'
import download from 'downloadjs'

export const downloadInvoice = (invoiceUrl) => {
  return api.superFetch({ url: invoiceUrl })
}

export const fetchBillsList = (uuid, dateDebut, dateFin, perimetre, lanId) => {
  if (!perimetre) {
    return Promise.resolve()
  }

  return api.superFetch({
    url: `listefactures?date_debut=${dateDebut}&date_fin=${dateFin}&lanId=${lanId}`,
    method: 'POST',
    body: perimetre,
    uuid: uuid,
  })
}

export const fetchBill = (uuid, billId, lanId) => {
  return api.superFetch({
    url: `facture?facid=${billId}&lanId=${lanId}`,
    uuid: uuid,
  })
}

export const putBill = (uuid, bill) => {
  const billUpdate = {
    id: bill.id,
    facClef: bill.FacClef,
    cpfCpfId: bill.CpfCpfId,
    datePaiement: bill.FacDatePaiement,
    commentaire: bill.FacNoteContenu,
    etat: bill.FacStatut,
  }

  return api.superFetch({
    url: 'facture',
    method: 'PUT',
    body: billUpdate,
    uuid: uuid,
  })
}

export const postBillFile = (uuid, billId, file) => {
  let doc = new FormData()
  doc.append('file', file)

  return api.superFetch({
    url: `facture/document/${billId}`,
    method: 'POST',
    body: doc,
    stringify: false,
    contentType: null,
    uuid: uuid,
  })
}

export const deleteBillFile = (uuid, fileId) => {
  return api.superFetch({
    url: `facture/document/${fileId}`,
    method: 'DELETE',
    uuid: uuid,
  })
}

export const postExtractBillList = (listBillIds) => {
  return api
    .superFetch({
      url: `facture/export/csv`,
      method: 'POST',
      body: listBillIds,
    })
    .then(function(resp) {
      return resp.blob()
    })
    .then(function(blob) {
      const now = Date.now()
      const filename = `InvoiceExtract_${now}.csv`
      download(blob, filename, 'text/csv')
    })
}
