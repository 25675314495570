import React from 'react'
import { Switch, Route } from 'react-router-dom'

import AllAmenities from '../expenses/AllAmenities'
import Electricity from '../expenses/Electricity'
import Gas from '../expenses/Gas'
import Detail from '../expenses/Detail'

const ExpensesView = () => (
  <Switch>
    <Route path="/expenses/all-amenities" component={AllAmenities} />
    <Route path="/expenses/electricity" component={Electricity} />
    <Route path="/expenses/gas" component={Gas} />
    <Route path="/expenses/detail" component={Detail} />
  </Switch>
)

export default ExpensesView
