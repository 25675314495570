import v4 from 'uuid/v4'
import * as graph from '../api/graph'
import { updateView } from './views'

export const updateGraphUnite = (value) => (dispatch) => {
  dispatch({
    type: 'UPDATE_GRAPH_UNITE',
    value,
  })
  dispatch(updateView())
}

export const fetchConso = (payload) => async (dispatch) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_CONSO_REQUEST',
    uuid,
  })

  try {
    const response = await graph.fetchConso(uuid, payload)
    if (response.headers.get('X-REQUEST-ID') !== uuid) return

    const data = await response.json()

    dispatch({
      type: 'FETCH_CONSO_SUCCESS',
      data,
    })
  } catch (error) {
    dispatch({
      type: 'FETCH_CONSO_FAILURE',
      message: error.message || 'Something went wrong.',
    })
  }
}
