import Highcharts from 'highcharts'
import Highstock from 'highcharts/highstock'

import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsExportData from 'highcharts/modules/export-data'
import Boost from 'highcharts/modules/boost'
import xrange from 'highcharts/modules/xrange'

import {
  highchartsFr,
  rangeSelectorMarketFr,
  rangeSelectorLoadCurveFr,
} from '../locales/highcharts-fr'

import {
  highchartsEn,
  rangeSelectorMarketEn,
  rangeSelectorLoadCurveEn,
} from '../locales/highcharts-en'

import {
  highchartsDe,
  rangeSelectorMarketDe,
  rangeSelectorLoadCurveDe,
} from '../locales/highcharts-de'

HighchartsExporting(Highcharts)
HighchartsExportData(Highcharts)
HighchartsExporting(Highstock)
HighchartsExportData(Highstock)
Boost(Highcharts)
Boost(Highstock)
xrange(Highcharts)

const getOptions = (lanId) => {
  switch (lanId) {
    case 4:
      return highchartsDe
    case 2:
      return highchartsEn
    case 1:
    default:
      return highchartsFr
  }
}

export const setHighchartsOptions = (lanId) => {
  const options = getOptions(lanId)
  Highcharts.setOptions(options)
  Highstock.setOptions(options)
}

export const rangeSelectorMarket = (lanId) => {
  switch (lanId) {
    case 4:
      return rangeSelectorMarketDe
    case 2:
      return rangeSelectorMarketEn
    case 1:
    default:
      return rangeSelectorMarketFr
  }
}

export const rangeSelectorLoadCurve = (lanId) => {
  switch (lanId) {
    case 4:
      return rangeSelectorLoadCurveDe
    case 2:
      return rangeSelectorLoadCurveEn
    case 1:
    default:
      return rangeSelectorLoadCurveFr
  }
}

export { Highcharts, Highstock }
