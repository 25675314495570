import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'

import { toggleDecomposition } from '../../actions/expenses/decomposition'
import { getIsDecompositionChecked } from '../../reducers/expenses/decomposition'

const FormDecompositionCheckbox = ({
  value,
  text,
  onClick,
  isChecked,
  toggleDecomposition,
  dispatch,
}) => (
  <Button
    color={value}
    className={isChecked ? 'btn-selected' : ''}
    size="sm"
    onClick={() => {
      onClick()
      dispatch(toggleDecomposition)
    }}
  >
    {text}
  </Button>
)

const mapStateToProps = (state, { value }) => ({
  isChecked: getIsDecompositionChecked(state, value),
  toggleDecomposition: toggleDecomposition(value),
})

FormDecompositionCheckbox.propTypes = {
  value: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  toggleDecomposition: PropTypes.func.isRequired,
}

export default connect(mapStateToProps)(FormDecompositionCheckbox)
