import React, { Component } from 'react'
import { fetchVersion } from '../../api/version'
import { version } from '../../../../package.json'

class Version extends Component {
  constructor() {
    super()
    this.state = {
      backVersion: null,
    }
  }
  componentDidMount() {
    let wsCall = async () => {
      const callRes = await fetchVersion()
      const versionNumber = await callRes.json()
      this.setState({
        backVersion: versionNumber.version,
      })
    }
    wsCall()
  }
  render() {
    return (
      <div className="version">
        {this.state.backVersion ? `${this.state.backVersion} / ` : ''} {version}
      </div>
    )
  }
}

export default Version
