import React from 'react'
import { Row, Col } from 'reactstrap'
import { withTranslation } from 'react-i18next'

import MarketElec from '../market/MarketElec'
import MarketGas from '../market/MarketGas'
import MarketUKBrent from '../market/MarketUKBrent'
import PriceMonitoring from '../market/priceMonitoring/PriceMonitoring'

const MarketInformationsView = ({ t }) => (
  <div>
    <section>
      <Row>
        <Col>
          <h5>{t('market.titles.productRef')}</h5>
        </Col>
      </Row>
      <Row noGutters className="market-kpis">
        <MarketElec />
        <MarketGas />
        <MarketUKBrent />
      </Row>
    </section>
    <PriceMonitoring />
  </div>
)

export default withTranslation()(MarketInformationsView)
