import moment from 'moment-timezone'

import { colors } from 'helpers/initCharts'
import { decimalFormat } from 'helpers/helpers'

export default (data, { stacked = false } = {}) => {
  const series = data.series.map((serie) => ({
    ...serie,
    data: serie.data.map(({ x, y }) => [x, y]),
  }))

  return {
    ...data,
    series,
    colors,
    chart: {
      type: 'line',
      zoomType: 'x',
      resetZoomButton: {
        position: {
          x: -70,
          y: 10,
        },
        relativeTo: 'chart',
      },
    },
    time: {
      useUTC: true,
      getTimezoneOffset: (timestamp) => -moment.tz(timestamp, 'Europe/Paris').utcOffset(),
    },
    plotOptions: {
      line: {
        lineWidth: 0.6,
        ...(stacked && {
          stacking: 'stacked',
        }),
      },
    },
    title: {
      text: '',
    },
    yAxis: {
      // title: '',
      opposite: false,
    },
    xAxis: {
      type: 'datetime',
    },
    tooltip: {
      formatter: function() {
        return `${this.series.name}<br/><b>${decimalFormat(
          this.point.y
        )}</b><br/><br/>Total : <b>${decimalFormat(this.point.stackTotal)}</b>`
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          verticalAlign: 'top',
        },
      },
    },
  }
}
