import { combineReducers } from 'redux'

const electricity = () => {
  const kpiElecConsoUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_ELECTRICITY_KPI_CONSO_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiElecExpenseUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_ELECTRICITY_KPI_EXPENSE_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiElecPriceUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_ELECTRICITY_KPI_PRICE_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const elecGraphUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_ELECTRICITY_GRAPH_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const elecCumulativeUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_ELECTRICITY_GRAPH_CUMULATIVE_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const elecCumulativeTemporalUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_ELECTRICITY_GRAPH_CUMULATIVE_TEMPORAL_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiElecConso = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_ELECTRICITY_KPI_CONSO_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiElecConsoLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_ELECTRICITY_KPI_CONSO_REQUEST':
        return true
      case 'FETCH_ELECTRICITY_KPI_CONSO_SUCCESS':
      case 'FETCH_ELECTRICITY_KPI_CONSO_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiElecExpense = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_ELECTRICITY_KPI_EXPENSE_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiElecExpenseLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_ELECTRICITY_KPI_EXPENSE_REQUEST':
        return true
      case 'FETCH_ELECTRICITY_KPI_EXPENSE_SUCCESS':
      case 'FETCH_ELECTRICITY_KPI_EXPENSE_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiElecPrice = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_ELECTRICITY_KPI_PRICE_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiElecPriceLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_ELECTRICITY_KPI_PRICE_REQUEST':
        return true
      case 'FETCH_ELECTRICITY_KPI_PRICE_SUCCESS':
      case 'FETCH_ELECTRICITY_KPI_PRICE_FAILURE':
        return false
      default:
        return state
    }
  }

  const elecGraph = (state = {}, action) => {
    switch (action.type) {
      case 'FETCH_ELECTRICITY_GRAPH_SUCCESS':
        return action.response
      default:
        return state
    }
  }

  const elecGraphCumulative = (state = {}, action) => {
    switch (action.type) {
      case 'FETCH_ELECTRICITY_GRAPH_CUMULATIVE_SUCCESS':
        return action.response
      default:
        return state
    }
  }

  const elecGraphCumulativeTemporal = (state = {}, action) => {
    switch (action.type) {
      case 'FETCH_ELECTRICITY_GRAPH_CUMULATIVE_TEMPORAL_SUCCESS':
        return action.response
      default:
        return state
    }
  }

  const graphAgregation = (state = '0', action) => {
    switch (action.type) {
      case 'UPDATE_ELECTRICITY_GRAPH_AGREGATION':
        return action.value
      default:
        return state
    }
  }

  const tooMuchPees = (state = false, action) => {
    switch (action.type) {
      case 'SET_ELECTRICITY_TOO_MUCH_PEES':
        return action.value
      default:
        return state
    }
  }

  return combineReducers({
    kpiElecConsoUuid,
    kpiElecExpenseUuid,
    kpiElecPriceUuid,
    elecGraphUuid,
    elecCumulativeUuid,
    elecCumulativeTemporalUuid,
    kpiElecConso,
    kpiElecExpense,
    kpiElecPrice,
    kpiElecConsoLoading,
    kpiElecExpenseLoading,
    kpiElecPriceLoading,
    elecGraph,
    elecGraphCumulative,
    elecGraphCumulativeTemporal,
    graphAgregation,
    tooMuchPees,
  })
}

export default electricity

export const getKpiElecConsoUuid = (state) => state.powerConsumption.electricity.kpiElecConsoUuid
export const getKpiElecExpenseUuid = (state) =>
  state.powerConsumption.electricity.kpiElecExpenseUuid
export const getKpiElecPriceUuid = (state) => state.powerConsumption.electricity.kpiElecPriceUuid
export const getElecGraphUuid = (state) => state.powerConsumption.electricity.elecGraphUuid
export const getElecCumulativeUuid = (state) =>
  state.powerConsumption.electricity.elecCumulativeUuid
export const getElecCumulativeTemporalUuid = (state) =>
  state.powerConsumption.electricity.elecCumulativeTemporalUuid

export const getKpiElecConso = (state) => state.powerConsumption.electricity.kpiElecConso
export const getKpiElecExpense = (state) => state.powerConsumption.electricity.kpiElecExpense
export const getKpiElecPrice = (state) => state.powerConsumption.electricity.kpiElecPrice

export const getKpiElecConsoLoading = (state) =>
  state.powerConsumption.electricity.kpiElecConsoLoading
export const getKpiElecExpenseLoading = (state) =>
  state.powerConsumption.electricity.kpiElecExpenseLoading
export const getKpiElecPriceLoading = (state) =>
  state.powerConsumption.electricity.kpiElecPriceLoading

export const getElecGraph = (state) => state.powerConsumption.electricity.elecGraph
export const getElecGraphCumulative = (state) =>
  state.powerConsumption.electricity.elecGraphCumulative
export const getElecGraphCumulativeTemporal = (state) =>
  state.powerConsumption.electricity.elecGraphCumulativeTemporal
export const getElecGraphAgregation = (state) => state.powerConsumption.electricity.graphAgregation
export const getElecGraphTooMuchPees = (state) => state.powerConsumption.electricity.tooMuchPees
