import { fetchCountries } from '../api/countries'

export const countries = (state) => (dispatch) => {
  dispatch({
    type: 'FETCH_COUNTRY_LIST_REQUEST',
  })

  return fetchCountries().then(
    (response) => {
      if (response.status === 200) {
        response.json().then((response) => {
          dispatch({
            type: 'FETCH_COUNTRY_LIST_SUCCESS',
            countries: response,
          })
        })
      } else {
        dispatch({
          type: 'FETCH_COUNTRY_LIST_FAILURE',
        })
      }
    },
    (error) => {
      dispatch({
        type: 'FETCH_COUNTRY_LIST_FAILURE',
        message: error.Message || 'Something went wrong.',
      })
    }
  )
}
