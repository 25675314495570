import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { FormGroup, Label } from 'reactstrap'
import Highlighter from 'react-highlight-words'
import { getSite } from '../../reducers/organisation'
import {
  getSiteHasPeeOfFilterType,
  getFilterInput,
  getFilterType,
  getCountryFilter,
} from '../../reducers/searchPanel'
import { getIsSiteIndeterminate, getIsSiteSelected } from '../../reducers/currentSelection'
import { toggleSiteInSelection } from '../../actions/currentSelection'
import Pee from './Pee'

class Site extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      opened: props.opened,
    }
  }

  render() {
    const {
      siteId,
      site,
      isSiteIndeterminate,
      isSiteSelected,
      toggleSiteInSelection,
      dispatch,
      siteHasPeeOfFilterType,
      filterInput,
    } = this.props
    return (
      <span>
        {siteHasPeeOfFilterType ? (
          <li>
            <FormGroup check>
              <Label
                check
                className={
                  (isSiteIndeterminate && 'indeterminate') || (isSiteSelected && 'checked') || ''
                }
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={siteId}
                  checked={isSiteSelected}
                  ref={(input) => {
                    if (input) {
                      input.indeterminate = isSiteIndeterminate
                    }
                  }}
                  onChange={(e) => dispatch(toggleSiteInSelection)}
                />
                <span className="left-pad">
                  {filterInput ? (
                    <Highlighter
                      highlightClassName="bg-primary"
                      searchWords={[filterInput]}
                      textToHighlight={site.SphNom}
                    />
                  ) : (
                    <button
                      className="reset"
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        this.setState({ opened: !this.state.opened })
                      }}
                    >
                      <i
                        className={
                          this.state.opened ? 'icon-smallarrow-down' : 'icon-smallarrow-right'
                        }
                      />
                      {site.SphNom}
                    </button>
                  )}
                </span>
              </Label>
            </FormGroup>

            {this.state.opened && !filterInput && (
              <ul>
                {site.SitPees && site.SitPees.map((peeId) => <Pee key={peeId} peeId={peeId} />)}
              </ul>
            )}
          </li>
        ) : null}
      </span>
    )
  }
}

const mapStateToProps = (state, { siteId }) => ({
  site: getSite(state, siteId),
  isSiteIndeterminate: getIsSiteIndeterminate(state, siteId),
  isSiteSelected: getIsSiteSelected(state, siteId),
  toggleSiteInSelection: toggleSiteInSelection(
    state,
    siteId,
    getFilterType(state),
    getCountryFilter(state)
  ),
  siteHasPeeOfFilterType: getSiteHasPeeOfFilterType(state, siteId),
  filterInput: getFilterInput(state),
})

Site.propTypes = {
  siteId: PropTypes.number.isRequired,
  site: PropTypes.object.isRequired,
  isSiteIndeterminate: PropTypes.bool.isRequired,
  isSiteSelected: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  toggleSiteInSelection: PropTypes.func.isRequired,
  siteHasPeeOfFilterType: PropTypes.bool.isRequired,
  filterInput: PropTypes.string.isRequired,
  level: PropTypes.number,
  opened: PropTypes.bool,
}

Site.defaultProps = {
  level: 0,
  opened: true,
}

export default connect(mapStateToProps)(Site)
