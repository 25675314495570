import React from 'react'
import { Switch, Route } from 'react-router-dom'

import ContractsMetersList from '../contracts/ContractsMetersList'
import ContractsContractsList from '../contracts/ContractsContractsList'

const ContractsView = () => (
  <Switch>
    <Route path="/contracts/energy-meter/:peeids?" component={ContractsMetersList} />
    <Route path="/contracts/:ctrids?" component={ContractsContractsList} />
  </Switch>
)

export default ContractsView
