import React, { Component } from 'react'
import { Table, Thead, Tr, Th, Td } from 'reactable'
import { Button } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { connect, useDispatch, useSelector } from 'react-redux'
import memoize from 'fast-memoize'

import { CheckButton } from 'components/common/CheckButton'
import {
  deleteMyQuotation,
  toggleQuotationBollinger,
  toggleQuotationTunnel,
} from 'actions/quotations'
import { getUserQuotationBollingerOption, getUserQuotationTunnelOption } from 'reducers/quotations'

const i18n = new Intl.NumberFormat(navigator.language, {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
})

class QuotationBoard extends Component {
  selectQuotation = (selectedQuotation) => () => {
    this.props.setSelectedQuotation(selectedQuotation)
    if (this.props.setSelectedParcel) {
      this.props.setSelectedParcel(selectedQuotation.IdLot)
    }
  }

  deleteMyQuotation = memoize((deletedQuotation) => (e) => {
    this.props.deleteMyQuotation(deletedQuotation)
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
  })

  render() {
    let selectedQuotations = Array.isArray(this.props.selectedQuotation)
      ? this.props.selectedQuotation
      : [this.props.selectedQuotation]
    let shouldDisplay = (field) =>
      this.props.rowToDisplay ? this.props.rowToDisplay.includes(field) : false
    return (
      <div>
        <div className="transaction-view">
          <div
            className="transaction-table"
            css={{
              thead: {
                th: {
                  verticalAlign: 'middle',
                  '&.th-small': {
                    width: 75,
                    fontSize: 10,
                    lineHeight: 1.2,
                  },
                },
              },
              tbody: {
                tr: {
                  cursor: 'pointer',
                },
                td: {
                  '&.td-center': {
                    textAlign: 'center',
                    '>*': {
                      verticalAlign: 'middle',
                    },
                  },
                },
              },
            }}
          >
            <Table
              className="table table-striped table-bordered"
              noDataText={this.props.t('market.quotationBoard.noDataText')}
              sortable={['product', 'market', 'country']}
              itemsPerPage={10}
            >
              <Thead>
                <Th column="type"> </Th>
                <Th column="product">{this.props.t('market.quotationBoard.product')}</Th>
                {shouldDisplay('contract') ? (
                  <Th column="contract">{this.props.t('market.quotationBoard.contract')}</Th>
                ) : null}
                {shouldDisplay('parcel') ? (
                  <Th column="parcel">{this.props.t('market.quotationBoard.parcel')}</Th>
                ) : null}
                <Th column="market">{this.props.t('market.quotationBoard.market')}</Th>
                {shouldDisplay('country') ? (
                  <Th column="country">{this.props.t('market.quotationBoard.country')}</Th>
                ) : null}
                {shouldDisplay('price') ? (
                  <Th column="price" className="text-right">
                    {this.props.t('market.quotationBoard.price')}
                  </Th>
                ) : null}
                {shouldDisplay('currency') ? (
                  <Th column="currency">{this.props.t('market.quotationBoard.currency')}</Th>
                ) : null}
                {shouldDisplay('date') ? (
                  <Th column="date">{this.props.t('market.quotationBoard.date')}</Th>
                ) : null}
                {shouldDisplay('prevDay') ? (
                  <Th column="prevDay" className="text-right">
                    {this.props.t('market.quotationBoard.prevDay')}
                  </Th>
                ) : null}
                {shouldDisplay('MoyYtd') ? (
                  <Th column="MoyYtd" className="text-right">
                    {this.props.t('market.quotationBoard.MoyYtd')}
                  </Th>
                ) : null}
                {shouldDisplay('MinYtd') ? (
                  <Th column="MinYtd" className="text-right">
                    {this.props.t('market.quotationBoard.MinYtd')}
                  </Th>
                ) : null}
                {shouldDisplay('MaxYtd') ? (
                  <Th column="MaxYtd" className="text-right">
                    {this.props.t('market.quotationBoard.MaxYtd')}
                  </Th>
                ) : null}
                <Th column="bollinger" className="th-small">
                  {this.props.t('market.quotationBoard.bollingerBand')}
                </Th>
                <Th column="tunnel" className="th-small">
                  {this.props.t('market.quotationBoard.monthlyTunnel')}
                </Th>
                {this.props.isEditable && <Th column="delete"> </Th>}
              </Thead>
              {this.props.quotations
                ? this.props.quotations.map((i, index) => (
                    <Tr
                      key={i.Id}
                      className={
                        selectedQuotations &&
                        selectedQuotations.length > 0 &&
                        selectedQuotations.filter((quot) => i.Id === quot.Id).length > 0
                          ? 'active'
                          : ''
                      }
                      onClick={this.selectQuotation(i)}
                    >
                      <Td column="type" value={i.CtrNrjId}>
                        <i
                          className={`${
                            i.Cotation.CotProduit.PdtNrjId === 2 ? 'icon-elec' : 'icon-gaz'
                          } left-pad`}
                        />
                      </Td>
                      <Td column="product" value={i.Cotation.CotProduit.PdtNom}>
                        <strong>{i.Cotation.CotProduit.PdtNom}</strong>
                      </Td>
                      {shouldDisplay('contract') ? (
                        <Td column="contract">{i.Contrat.NomCourt}</Td>
                      ) : null}
                      {shouldDisplay('parcel') ? <Td column="parcel">{i.LotNom}</Td> : null}
                      <Td column="market">{i.Cotation.CotMarche}</Td>
                      {shouldDisplay('country') ? (
                        <Td column="country">{i.Cotation.CotPays}</Td>
                      ) : null}
                      {shouldDisplay('price') ? (
                        <Td column="price" className="text-right">
                          {i18n.format(i.Cotation.CotValeur)}
                        </Td>
                      ) : null}
                      {shouldDisplay('currency') ? (
                        <Td column="currency">{i.Cotation.CotUnite}</Td>
                      ) : null}
                      {shouldDisplay('date') ? <Td column="date">{i.Cotation.CotDate}</Td> : null}
                      {shouldDisplay('prevDay') ? (
                        <Td column="prevDay" className="text-right">{`${
                          i.Cotation.CotEcartPourcentJmoins1 > 0 ? '+' : ''
                        }${i18n.format(i.Cotation.CotEcartPourcentJmoins1)}`}</Td>
                      ) : null}
                      {shouldDisplay('MoyYtd') ? (
                        <Td column="MoyYtd" className="text-right">
                          {i18n.format(i.Cotation.CotValeurMoyYtd)}
                        </Td>
                      ) : null}
                      {shouldDisplay('MinYtd') ? (
                        <Td column="MinYtd" className="text-right">
                          {i18n.format(i.Cotation.CotValeurMinYtd)}
                        </Td>
                      ) : null}
                      {shouldDisplay('MaxYtd') ? (
                        <Td column="MaxYtd" className="text-right">
                          {i18n.format(i.Cotation.CotValeurMaxYtd)}
                        </Td>
                      ) : null}
                      <Td column="tunnel" className="td-center">
                        <TunnelCheckbox id={i.IdCotation} />
                      </Td>
                      <Td column="bollinger" className="td-center">
                        <BollingerCheckbox id={i.IdCotation} />
                      </Td>
                      {this.props.isEditable && (
                        <Td column="delete" className="text-center">
                          <Button onClick={this.deleteMyQuotation(i)} className="btn-table active">
                            <i className={'icon-ico-trash'} />
                          </Button>
                        </Td>
                      )}
                    </Tr>
                  ))
                : null}
            </Table>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
  deleteMyQuotation: (quotation) => {
    dispatch(deleteMyQuotation(quotation.Id))
  },
})

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(QuotationBoard)

const BollingerCheckbox = ({ id }) => {
  const dispatch = useDispatch()
  const checked = useSelector(getUserQuotationBollingerOption(id))
  return (
    <CheckButton
      checked={checked}
      onClick={(e) => {
        e.stopPropagation()
        dispatch(toggleQuotationBollinger(id))
      }}
    />
  )
}

const TunnelCheckbox = ({ id }) => {
  const dispatch = useDispatch()
  const checked = useSelector(getUserQuotationTunnelOption(id))

  return (
    <CheckButton
      checked={checked}
      onClick={(e) => {
        e.stopPropagation()
        dispatch(toggleQuotationTunnel(id))
      }}
    />
  )
}
