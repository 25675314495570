import { createStore, applyMiddleware, compose } from 'redux'
import { loadUser } from 'redux-oidc'
import thunk from 'redux-thunk'
import ReactGA from 'react-ga'
import get from 'lodash/get'

import { persist } from 'persist'

// REDUCERS
import rootReducer from './reducers'

// MIDDLEWARE
import { userManager, accessTokenMiddleware } from 'commons'

// const getEid = (state) => get(state, ['oidc', 'user', 'profile', 'eid'], false)
const getName = (state) => get(state, ['oidc', 'user', 'profile', 'name'], false)
const getUor = (state) => get(state, ['oidc', 'user', 'profile', 'uor'], false)

export default () => {
  const middlewares = [thunk, accessTokenMiddleware]
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middlewares)))

  persist(store)
  loadUser(store, userManager)

  let prevUserId = ''
  store.subscribe(() => {
    const state = store.getState()
    const userId = `${getName(state)}_${getUor(state)}`
    if (!!userId && prevUserId !== userId) {
      prevUserId = userId
      ReactGA.set({ userId })
    }
  })

  return store
}
