export const colors = [
  [81, 91, 179],
  [146, 106, 20],
  [33, 33, 90],
  [255, 70, 105],
  [237, 114, 80],
  [243, 152, 75],
  [100, 88, 146],
]

export const getColor = (index, alpha = 1) => {
  const [r, g, b] = colors[index % colors.length]
  return `rgba(${r},${g},${b},${alpha})`
}
