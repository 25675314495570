import { apiConf as api } from 'commons'

export const fetchSpendGraph = ({ uuid, payload }) => {
  return api.superFetch({
    url: 'sites/graph/spend',
    method: 'POST',
    body: payload,
    uuid,
  })
}

// POST /sites/graph/spend
// Ex de payload :
// {
//     "sites": [11175,11176],
//     "debut": "01/01/2018",
//     "fin": "10/01/2018"
// }

export const fetchNontemporalGraph = (
  uuid,
  dateDebut,
  dateFin,
  perimetre,
  mesures,
  type,
  commodite,
  unite,
  devise,
  decomposition,
  axeDecomposition,
  uorIdMst,
  lanId
) => {
  if (!perimetre) {
    return Promise.resolve()
  }

  let url = 'graphiquenontemporel?'

  url += 'date_debut=' + dateDebut
  url += '&date_fin=' + dateFin
  url += '&mesures=' + mesures
  url += '&type_donnee=' + type
  url += commodite ? '&commodite=' + commodite : ''
  url += unite ? '&unite=' + unite : ''
  url += devise ? '&devise=' + devise : ''
  url += decomposition ? '&decomposition=' + decomposition : ''
  url += axeDecomposition ? '&axe_decomposition=' + axeDecomposition : ''
  url += '&uorIdMst=' + uorIdMst
  url += '&lanId=' + lanId

  return api.superFetch({
    url: url,
    method: 'POST',
    body: perimetre,
    uuid: uuid,
  })
}

export const fetchTemporalGraph = (
  uuid,
  dateDebut,
  dateFin,
  perimetre,
  mesures,
  type,
  commodite,
  unite,
  devise,
  decomposition,
  pas,
  axeDecomposition,
  agregation,
  uorIdMst,
  lanId,
  stacked = false
) => {
  if (!perimetre) {
    return Promise.resolve()
  }

  let url = stacked ? 'graphiquetemporelstack?' : 'graphiquetemporel?'

  url += 'date_debut=' + dateDebut
  url += '&date_fin=' + dateFin
  url += '&mesures=' + mesures
  url += '&type_donnee=' + type
  url += commodite ? '&commodite=' + commodite : ''
  url += unite ? '&unite=' + unite : ''
  url += devise ? '&devise=' + devise : ''
  url += decomposition ? '&decomposition=' + decomposition : ''
  url += pas ? '&pas=' + pas : ''
  url += axeDecomposition ? '&axe_decomposition=' + axeDecomposition : ''
  url += agregation ? '&agregation=' + agregation : ''
  url += '&uorIdMst=' + uorIdMst
  url += '&lanId=' + lanId

  return api.superFetch({
    url: url,
    method: 'POST',
    body: perimetre,
    uuid: uuid,
  })
}

export const fetchCotationGraph = (
  uuid,
  dateDebut,
  dateFin,
  perimetre,
  type,
  unite,
  devise,
  pays,
  lanId
) => {
  if (!perimetre) {
    return Promise.resolve()
  }

  let url = 'graphiquetemporelCotation?'

  url += 'date_debut=' + dateDebut
  url += '&date_fin=' + dateFin
  url += '&type=' + type
  url += unite ? '&unite=' + unite : ''
  url += devise ? '&devise=' + devise : ''
  url += pays ? '&pays=' + pays : ''
  url += '&lanId=' + lanId

  return api.superFetch({
    url: url,
    method: 'POST',
    body: perimetre,
    uuid: uuid,
  })
}

export const fetchConso = async (uuid, payload) => {
  if (payload.Ids.length === 0) return

  return api.superFetch({
    url: 'consommations/compteurs/search',
    method: 'POST',
    body: payload,
    uuid: uuid,
  })
}

export const fetchConsoGraph = async (uuid, payload) => {
  if (payload.Ids.length === 0) return

  return api.superFetch({
    url: 'consommations/compteurs/graph/mensuel',
    method: 'POST',
    body: payload,
    uuid: uuid,
  })
}

export const fetchDepenses = async (uuid, payload) => {
  if (payload.Ids.length === 0) return

  return api.superFetch({
    url: 'depenses/compteurs/search',
    method: 'POST',
    body: payload,
    uuid: uuid,
  })
}

export const fetchDepensesGraph = async (uuid, payload) => {
  if (payload.Ids.length === 0) return

  return api.superFetch({
    url: 'depenses/compteurs/graph/mensuel',
    method: 'POST',
    body: payload,
    uuid: uuid,
  })
}

export const fetchLoadCurveGraph = ({ uuid, ...payload }) =>
  api.superFetch({
    url: 'sites/graph/cdc',
    method: 'POST',
    body: payload,
    uuid,
  })
