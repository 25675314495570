import v4 from 'uuid/v4'
import { normalize } from 'normalizr'
import * as schema from './schema'

import * as user from 'commons'

import * as contracts from '../api/contracts.js'
import {
  getContractUuid,
  getContractsListUuid,
  getMetersListUuid,
  getMeterUuid,
} from '../reducers/contracts'
import { getStartDate, getEndDate } from '../reducers/dateRange'
import { getCurrentVisiblePeesIds } from '../reducers/currentSelection'

export const fetchContractsList = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_CONTRACTS_LIST_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisiblePeesIds(getState()).join(';')
  const lanId = user.lanId(getState())

  return contracts.fetchContractsList(uuid, dateDebut, dateFin, perimetre, lanId).then(
    (response) => {
      if (response && response.headers.get('X-REQUEST-ID') === getContractsListUuid(getState())) {
        response.json().then((response) =>
          dispatch({
            type: 'FETCH_CONTRACTS_LIST_SUCCESS',
            response: normalize(response, schema.arrayOfContracts),
          })
        )
      }
    },
    (error) => {
      dispatch({
        type: 'FETCH_CONTRACTS_LIST_FAILURE',
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchContract = (ctrid) => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_CONTRACT_REQUEST',
    uuid,
    ctrid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const lanId = user.lanId(getState())

  return contracts.fetchContract(uuid, ctrid, dateDebut, dateFin, lanId).then(
    (response) => {
      if (response && response.headers.get('X-REQUEST-ID') === getContractUuid(getState(), ctrid)) {
        response.json().then((response) =>
          dispatch({
            type: 'FETCH_CONTRACT_SUCCESS',
            response: normalize(response, schema.contracts),
          })
        )
      }
    },
    (error) => {
      dispatch({
        type: 'FETCH_CONTRACT_FAILURE',
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchMetersList = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_METERS_LIST_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisiblePeesIds(getState()).join(';')
  const lanId = user.lanId(getState())

  return contracts.fetchMetersList(uuid, dateDebut, dateFin, perimetre, lanId).then(
    (response) => {
      if (response && response.headers.get('X-REQUEST-ID') === getMetersListUuid(getState())) {
        response.json().then((response) =>
          dispatch({
            type: 'FETCH_METERS_LIST_SUCCESS',
            response: normalize(response, schema.arrayOfMeters),
          })
        )
      }
    },
    (error) => {
      dispatch({
        type: 'FETCH_METERS_LIST_FAILURE',
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchMeter = (peeid) => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_METER_REQUEST',
    uuid,
    peeid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const lanId = user.lanId(getState())

  return contracts.fetchMeter(uuid, peeid, dateDebut, dateFin, lanId).then(
    (response) => {
      if (response && response.headers.get('X-REQUEST-ID') === getMeterUuid(getState(), peeid)) {
        response.json().then((response) =>
          dispatch({
            type: 'FETCH_METER_SUCCESS',
            response: normalize(response, schema.meters),
            peeid,
          })
        )
      }
    },
    (error) => {
      dispatch({
        type: 'FETCH_METER_FAILURE',
        message: error.message || 'Something went wrong.',
      })
    }
  )
}
