import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'

import { setFavorites, delFavorite } from '../../../actions/favorites'

const FavoritesItem = ({ favId, favNom, dispatch, setFavorites, delFavorite, toggleTab, t }) => (
  <div className="d-flex">
    <Button
      color="link"
      onClick={(e) => {
        e.preventDefault()
        dispatch(setFavorites)
        toggleTab('SELECTION')
      }}
    >
      {favNom}
    </Button>

    {favId !== -1 ? (
      <Button
        title={t('sidebar.favorites.delete')}
        className="ml-auto"
        onClick={(e) => {
          e.preventDefault()
          dispatch(delFavorite)
        }}
      >
        <i className="icon-cross" />
      </Button>
    ) : (
      ''
    )}
  </div>
)

const mapStateToProps = (state, { favId }) => ({
  setFavorites: setFavorites(state, favId),
  delFavorite: delFavorite(state, favId),
})

FavoritesItem.propTypes = {
  favId: PropTypes.number.isRequired,
  favNom: PropTypes.string.isRequired,
  setFavorites: PropTypes.func.isRequired,
  delFavorite: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  toggleTab: PropTypes.func,
}

export default withTranslation()(connect(mapStateToProps)(FavoritesItem))
