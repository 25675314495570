import moment from 'moment-timezone'
import isNil from 'lodash/isNil'
import isArray from 'lodash/isArray'
import sortBy from 'lodash/sortBy'

import { getDecomposition } from '../reducers/expenses/decomposition'
import { store } from '../index'
import { decimalFormat } from '../helpers/helpers'
import downloadFile from '../helpers/downloadFile'

export const colors = ['#515BB3', '#926A14', '#21215A', '#B9525A', '#ED7250', '#F3984B', '#645892']

export const nrjColors = ['#926A14', '#21215A'] // ['#31acea', '#c53ed1']

const rgbColors = [
  [0, 159, 227],
  [90, 87, 160],
  [81, 91, 179],
  [255, 70, 105],
  [237, 114, 80],
  [243, 152, 75],
  [100, 88, 146],
]

const getColor = (index, alpha = 1) => {
  const [r, g, b] = rgbColors[index % rgbColors.length]
  return `rgba(${r},${g},${b},${alpha})`
}

const uniq = (l) => [...new Set(l)]

export const initDonutChart = (data) =>
  data.series
    ? {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false,
          height: '100%',
        },
        time: {
          useUTC: true,
          getTimezoneOffset: (timestamp) => -moment.tz(timestamp, 'Europe/Paris').utcOffset(),
        },
        exporting: {
          enabled: false,
        },
        colors: nrjColors,
        title: {
          text: '',
        },
        tooltip: {
          pointFormat: '{point.y:.2f} - <b>{point.percentage:.0f}%</b>',
        },
        legend: {
          floating: true,
          verticalAlign: 'middle',
          layout: 'vertical',
          itemStyle: {
            fontWeight: 'normal',
          },
          labelFormatter: function() {
            return this.percentage > 50
              ? '<b>' + this.name + ': ' + parseInt(this.percentage, 10) + '%</b><br/>'
              : this.name + ': ' + parseInt(this.percentage, 10) + '%<br/>'
          },
        },
        plotOptions: {
          pie: {
            borderWidth: 0,
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
          },
        },
        series: [
          {
            type: 'pie',
            innerSize: '90%',
            ...data.series[0],
          },
        ],
      }
    : {}

export const initColumnChart = (data, stacked = false, types = []) => {
  let series = data.series
  if (types.length > 0 && data.series) {
    series = data.series.filter(
      ({ typeDonnee }) =>
        (types.includes('FacturePrevu') && typeDonnee === 'FacturePrevu') ||
        (types.includes('Facture') && typeDonnee === 'Facture') ||
        (types.includes('FactureN1') && typeDonnee === 'FactureN1') ||
        (types.includes('Budget') && typeDonnee === 'Budget')
    )
  }

  if (series) {
    Object.keys(series).map((i) =>
      Object.keys(series[i].data).map(
        (j) => (series[i].data[j].x = parseFloat(series[i].data[j].x))
      )
    )
    return {
      ...data,
      series,
      chart: {
        type: 'column',
        animation: false,
      },
      time: {
        useUTC: true,
        getTimezoneOffset: (timestamp) => -moment.tz(timestamp, 'Europe/Paris').utcOffset(),
      },
      plotOptions: {
        column: {
          animation: false,
          stacking: stacked ? 'normal' : '',
        },
      },
      colors,
      title: {
        text: '',
      },
      tooltip: {
        formatter: function() {
          return stacked
            ? `<span style="color:${this.point.color}">\u25CF</span> ${
                this.series.name
              }: <b>${decimalFormat(this.point.y)}</b><br/>Total: <b>${decimalFormat(
                this.point.stackTotal
              )}</b>`
            : `<span style="color:${this.point.color}">\u25CF</span> ${
                this.series.name
              }: <b>${decimalFormat(this.point.y)}</b><br/>`
        },
      },
      yAxis: {
        title: '',
      },
      xAxis: {
        type: 'datetime',
      },
    }
  } else {
    return {}
  }
}

export const initBarChart = (data) => {
  if (data.series) {
    const series = [
      {
        name: data.series[0].name,
        data: data.series[0].data.map((i, index) => ({
          y: i.y,
          color: colors[index % 16],
        })),
      },
    ]

    return {
      ...data,
      series,
      chart: {
        type: 'bar',
      },
      time: {
        useUTC: true,
        getTimezoneOffset: (timestamp) => -moment.tz(timestamp, 'Europe/Paris').utcOffset(),
      },
      title: {
        text: '',
      },
      tooltip: {
        pointFormat:
          '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}</b><br/>',
      },
      yAxis: {
        title: '',
      },
      legend: {
        enabled: false,
      },
    }
  } else {
    return {}
  }
}

export const initLineChart = (data, rangeSelector) => {
  if (data.series) {
    Object.keys(data.series).map((i) => {
      data.series[i].lineWidth = 0.6
      return Object.keys(data.series[i].data).map(
        (j) => (data.series[i].data[j].x = parseFloat(data.series[i].data[j].x))
      )
    })
    return {
      ...data,
      chart: {
        zoomType: 'x',
        type: 'line',
        resetZoomButton: {
          position: {
            // align: 'right', // by default
            // verticalAlign: 'top', // by default
            x: -70,
            y: 10,
          },
          relativeTo: 'chart',
        },
      },
      time: {
        useUTC: true,
        getTimezoneOffset: (timestamp) => -moment.tz(timestamp, 'Europe/Paris').utcOffset(),
      },
      colors,
      plotOptions: {
        line: {
          turboThreshold: 0,
        },
      },
      title: {
        text: '',
      },
      tooltip: {
        pointFormat:
          '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}</b><br/>',
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        opposite: false,
      },
      legend: {
        enabled: true,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        maxHeight: 80,
        itemWidth: 300,
        margin: 8,
        y: 0,
        navigation: {
          animation: true,
          arrowSize: 12,
          style: {
            fontWeight: 'bold',
            fontSize: '12px',
          },
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            verticalAlign: 'top',
          },
        },
      },
    }
  } else {
    return {}
  }
}

export const cotationChart = (data, rangeSelector, color) => {
  if (data.series) {
    data.series = data.series.map((e) => ({
      ...e,
      data: e.data.filter((i) => i.y !== null),
    }))

    rangeSelector.navigator.series = {
      includeInCSVExport: false,
    }

    return {
      ...data,
      ...rangeSelector,
      chart: {
        type: 'line',
      },
      time: {
        useUTC: true,
        getTimezoneOffset: (timestamp) => -moment.tz(timestamp, 'Europe/Paris').utcOffset(),
      },
      colors: color ? [color] : colors,
      title: {
        text: '',
      },
      tooltip: {
        pointFormat:
          '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}</b><br/>',
      },
      plotOptions: {
        series: {
          turboThreshold: 0,
        },
      },
      yAxis: {
        opposite: false,
        /*min,
					max*/
      },
    }
  } else {
    return {}
  }
}

export const initColChart = (col) => {
  const decomposition = getDecomposition(store.getState())

  if (col.series) {
    let colSeries = col.series
    // Object.keys(col.series).map((i) =>
    //   Object.keys(col.series[i].data).map(
    //     (j) => (col.series[i].data[j].x = parseFloat(col.series[i].data[j].x)),
    //   ),
    // );
    const stackCount = uniq(col.series.map((s) => s.stack)).length
    const decompositonCount = col.series.length / stackCount

    colSeries = Object.keys(col.series).map((i, index) => {
      const { name } = col.series[i]

      const colorIndex =
        name.includes('Energy') || name.includes('Energie')
          ? 0
          : name.includes('Grid') || name.includes('Transport')
          ? 1
          : name.includes('Taxes')
          ? 2
          : 3

      const series = {
        ...col.series[i],
        visible:
          (colorIndex === 0 && decomposition.includes('Energie')) ||
          (colorIndex === 1 && decomposition.includes('Transport')) ||
          (colorIndex === 2 && decomposition.includes('Taxes')) ||
          (colorIndex === 3 && decomposition.includes('Autres')),
        stacking: 'normal',
        type: 'column',
        color: getColor(colorIndex, 1 - Math.floor(index / decompositonCount) * 0.3),
        borderColor: getColor(colorIndex, 1),
      }

      return series
    })

    return {
      title: {
        text: '',
      },
      chart: {
        spacingTop: 50,
      },
      time: {
        useUTC: true,
        getTimezoneOffset: (timestamp) => -moment.tz(timestamp, 'Europe/Paris').utcOffset(),
      },
      tooltip: {
        formatter: function() {
          return `${this.series.name}<br/><b>${decimalFormat(
            this.point.y
          )}</b><br/><br/>Total : <b>${decimalFormat(this.point.stackTotal)}</b>`
        },
      },
      yAxis: {
        title: '',
        stackLabels: {
          formatter: function() {
            return this.isNegative
              ? ''
              : this.stack === 'Fac N'
              ? 'F'
              : this.stack === 'Année N'
              ? 'N'
              : this.stack === 'Année N-1'
              ? 'N-1'
              : this.stack === 'Year Y'
              ? 'Y'
              : this.stack === 'Year-1'
              ? 'Y-1'
              : this.stack === 'Budget'
              ? 'Bud.'
              : this.stack
          },
          enabled: true,
          allowOverlap: false,
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0,
          groupPadding: 0.05,
          borderWidth: 0,
          shadow: false,
        },
      },
      legend: {
        enabled: false,
      },
      series: colSeries,
      xAxis: {
        type: 'datetime',
      },
    }
  } else {
    return {}
  }
}

export const initPieChart = (pie) => {
  const decomposition = getDecomposition(store.getState())

  if (pie.series) {
    return {
      exporting: false,
      title: {
        text: '',
      },
      chart: {
        spacingTop: 25,
      },
      time: {
        useUTC: true,
        getTimezoneOffset: (timestamp) => -moment.tz(timestamp, 'Europe/Paris').utcOffset(),
      },
      tooltip: {
        formatter: function() {
          return `${this.point.name}<br/><b>${decimalFormat(this.point.y)}</b>`
        },
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          data: pie.series[0].data.map((point, i) => {
            const { name } = point
            const colorIndex =
              name.includes('Energy') || name.includes('Energie')
                ? 0
                : name.includes('Grid') || name.includes('Transport')
                ? 1
                : name.includes('Taxes')
                ? 2
                : 3
            return {
              ...point,
              visible:
                (colorIndex === 0 && decomposition.includes('Energie')) ||
                (colorIndex === 1 && decomposition.includes('Transport')) ||
                (colorIndex === 2 && decomposition.includes('Taxes')) ||
                (colorIndex === 3 && decomposition.includes('Autres')),
              color: getColor(colorIndex, 1),
            }
          }),
          type: 'pie',
          includeInCSVExport: false,
          innerSize: '75%',
          center: ['60%', '30%'],
          size: 150,
          showInLegend: false,
          dataLabels: {
            enabled: false,
          },
        },
      ],
    }
  } else {
    return {}
  }
}

export const marketCotationChart = (data, color) => {
  if (!data || !data.series) return { title: { text: '' } }

  data.series = data.series.map((serie) => {
    const { data } = serie

    const firstPoint = data.find((p) => !isNil(p))
    if (!isNaN(firstPoint) || isArray(firstPoint)) {
      return serie
    }

    const [a, b] = data
    if (!a || !b) return serie

    const pointInterval = b.x - a.x

    const DAY = 86400000

    return pointInterval < DAY
      ? {
          ...serie,
          data: data.map((p) => (isNil(p) ? null : p.y)),
          pointStart: a.x,
          pointInterval,
          connectNulls: true,
        }
      : {
          ...serie,
          data: data.map((p) => [p.x, p.y]),
          connectNulls: true,
        }
  })

  return {
    ...data,
    chart: {
      type: 'line',
      height: '45%',
    },
    time: {
      useUTC: true,
      getTimezoneOffset: (timestamp) => -moment.tz(timestamp, 'Europe/Paris').utcOffset(),
    },
    colors: color ? [color] : colors,
    title: {
      text: '',
    },
    tooltip: {
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}</b><br/>',
    },
    plotOptions: {
      series: {
        lineWidth: 0.7,
        states: {
          hover: {
            lineWidth: 0.7,
          },
        },
        dataGrouping: {
          enabled: false,
        },
      },
    },
    yAxis: {
      opposite: false,
    },
    legend: {
      enabled: true,
      align: 'center',
      verticalAlign: 'bottom',
    },
    exporting: {
      sourceWidth: 1200,
      sourceHeight: 600,
      scale: 1,
      buttons: {
        contextButton: {
          menuItems: [
            {
              textKey: 'downloadJPEG',
              onclick: function() {
                this.exportChart({ type: 'image/jpeg' })
              },
            },
            {
              textKey: 'downloadPDF',
              onclick: function() {
                this.exportChart({ type: 'application/pdf' })
              },
            },
            {
              textKey: 'downloadCSV',
              onclick: function() {
                const { min, max } = this.xAxis[0].getExtremes()
                const { series } = data

                const head = ['', ...series.map((item) => item.name)].join(';')

                const body = {}
                series.forEach(({ data, pointStart, pointInterval, originalData }, index) => {
                  data.forEach((point, j) => {
                    const [x, y] = isArray(point) ? point : [pointStart + pointInterval * j, point]

                    if (x < min || x > max) return

                    if (!Array.isArray(body[x])) {
                      body[x] = [
                        x,
                        `"${moment(x).format('YYYY-MM-DD HH:mm:ss')}"`,
                        ...Array(series.length).fill('"-"'),
                      ]
                    }
                    body[x][index + 2] = `"${decimalFormat(y)}"`
                  })
                })

                const lines = sortBy(Object.values(body), (a) => a[0]).map(([_, ...rest]) =>
                  rest.join(';')
                )

                const content = [head, ...lines].join('\n')
                downloadFile('export.csv', content, { type: 'text/csv' })
              },
            },
          ],
        },
      },
    },
  }
}
