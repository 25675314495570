import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { Form, FormGroup, Label } from 'reactstrap'

import DateRangePeriodGlobal from './DateRangePeriodGlobal'
import DateRangePeriodLoadCurve from './DateRangePeriodLoadCurve'
import DateRangeStartDateGlobal from './DateRangeStartDateGlobal'
import DateRangeStartDateLoadCurve from './DateRangeStartDateLoadCurve'
import DateRangeEndDateGlobal from './DateRangeEndDateGlobal'
import DateRangeEndDateLoadCurve from './DateRangeEndDateLoadCurve'

const pathsWithShortPeriod = ['/expenses/detail', '/power-consumption/load-curve']

const DateRange = ({ location, t }) => (
  <Form tag="div" inline className="date-range">
    <div
      className={[
        'date-range-wrapper',
        pathsWithShortPeriod.includes(location.pathname) ? 'flipped' : '',
      ].join(' ')}
    >
      <div className="date-range-global">
        <FormGroup>
          <Label for="period">
            {t('header.daterange.period')}
            <i className="icon-smallarrow-down" />
          </Label>
          <DateRangePeriodGlobal />
        </FormGroup>
        <FormGroup>
          <Label for="startDate">
            {location.pathname === '/market-informations'
              ? t('header.daterange.from')
              : t('header.daterange.fromAlt')}
            <i className="icon-event" />
          </Label>
          <DateRangeStartDateGlobal />
        </FormGroup>
        <FormGroup>
          <Label for="endDate">
            {location.pathname === '/market-informations'
              ? t('header.daterange.to')
              : t('header.daterange.toAlt')}
            <i className="icon-event" />
          </Label>
          <DateRangeEndDateGlobal />
        </FormGroup>
      </div>
      <div className="date-range-load-curve">
        <FormGroup>
          <Label for="period">
            {t('header.daterange.period')}
            <i className="icon-smallarrow-down" />
          </Label>
          <DateRangePeriodLoadCurve />
        </FormGroup>
        <FormGroup>
          <Label for="startDate">
            {t('header.daterange.from')}
            <i className="icon-event" />
          </Label>
          <DateRangeStartDateLoadCurve />
        </FormGroup>
        <FormGroup>
          <Label for="endDate">
            {t('header.daterange.to')}
            <i className="icon-event" />
          </Label>
          <DateRangeEndDateLoadCurve />
        </FormGroup>
      </div>
    </div>
  </Form>
)

DateRange.propTypes = {
  location: PropTypes.object.isRequired,
}

export default withTranslation()(withRouter(DateRange))
