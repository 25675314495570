import moment from 'moment'
import { combineReducers } from 'redux'
import get from 'lodash/get'

const loadCurve = () => {
  const typeDonnee = (state = ['Mesure'], action) => {
    switch (action.type) {
      case 'LOAD_CURVE_TOGGLE_TYPE_DONNEE':
        return state.indexOf(action.typeDonnee) === -1
          ? [...state, action.typeDonnee]
          : state.filter((typeDonnee) => typeDonnee !== action.typeDonnee)
      default:
        return state
    }
  }

  const initDateRange = {
    period: 'LOAD_CURVE_DATE_RANGE_LAST_MONTH',
    startDate: moment()
      .subtract(1, 'months')
      .format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  }

  const dateRange = (state = initDateRange, action) => {
    switch (action.type) {
      case 'LOAD_CURVE_DATE_RANGE_LAST_WEEK':
        return initDateRange
      case 'LOAD_CURVE_DATE_RANGE_LAST_MONTH':
        return {
          period: 'LOAD_CURVE_DATE_RANGE_LAST_MONTH',
          startDate: moment()
            .subtract(1, 'months')
            .format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
        }
      case 'LOAD_CURVE_DATE_RANGE_LAST_3_MONTHS':
        return {
          period: 'LOAD_CURVE_DATE_RANGE_LAST_3_MONTHS',
          startDate: moment()
            .subtract(3, 'months')
            .format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
        }
      case 'LOAD_CURVE_DATE_RANGE_CUSTOM':
        return { ...state, period: 'LOAD_CURVE_DATE_RANGE_CUSTOM' }
      case 'LOAD_CURVE_DATE_RANGE_UPDATE_START_DATE':
        return action.date.isSameOrAfter(state.endDate)
          ? state
          : {
              ...state,
              period: 'LOAD_CURVE_DATE_RANGE_CUSTOM',
              startDate: action.date.format('YYYY-MM-DD'),
            }
      case 'LOAD_CURVE_DATE_RANGE_UPDATE_END_DATE':
        return action.date.isSameOrBefore(state.startDate)
          ? state
          : {
              ...state,
              period: 'LOAD_CURVE_DATE_RANGE_CUSTOM',
              endDate: action.date.format('YYYY-MM-DD'),
            }
      default:
        return state
    }
  }

  const kpiLoadCurveConsoUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_LOAD_CURVE_KPI_CONSO_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiLoadCurvePowerMinUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_LOAD_CURVE_KPI_POWER_MIN_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiLoadCurvePowerMaxUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_LOAD_CURVE_KPI_POWER_MAX_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiLoadCurveExcessUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_LOAD_CURVE_KPI_EXCESS_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const loadCurveGraphUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_LOAD_CURVE_GRAPH_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiLoadCurveConso = (
    state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } },
    action
  ) => {
    switch (action.type) {
      case 'FETCH_LOAD_CURVE_KPI_CONSO_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiLoadCurvePowerMin = (
    state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } },
    action
  ) => {
    switch (action.type) {
      case 'FETCH_LOAD_CURVE_KPI_POWER_MIN_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiLoadCurvePowerMax = (
    state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } },
    action
  ) => {
    switch (action.type) {
      case 'FETCH_LOAD_CURVE_KPI_POWER_MAX_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiLoadCurveExcess = (
    state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } },
    action
  ) => {
    switch (action.type) {
      case 'FETCH_LOAD_CURVE_KPI_EXCESS_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const loadCurveGraph = (state = {}, action) => {
    switch (action.type) {
      case 'FETCH_LOAD_CURVE_GRAPH_SUCCESS':
        return action.response
      default:
        return state
    }
  }

  const graphLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_LOAD_CURVE_GRAPH_REQUEST':
        return true
      case 'FETCH_LOAD_CURVE_GRAPH_SUCCESS':
      case 'FETCH_LOAD_CURVE_GRAPH_FAILURE':
        return false
      default:
        return state
    }
  }

  const graphError = (state = null, action) => {
    switch (action.type) {
      case 'FETCH_LOAD_CURVE_GRAPH_REQUEST':
      case 'FETCH_LOAD_CURVE_GRAPH_SUCCESS':
        return null
      case 'FETCH_LOAD_CURVE_GRAPH_FAILURE':
        return action.message
      default:
        return state
    }
  }

  const loadCurveGraphUnite = (state = '1', action) => {
    switch (action.type) {
      case 'LOAD_CURVE_TOGGLE_GRAPH_UNITE':
        return action.value
      default:
        return state
    }
  }

  const loadCurveGraphAgregation = (state = '0', action) => {
    switch (action.type) {
      case 'LOAD_CURVE_TOGGLE_GRAPH_AGREGATION':
        return action.value
      default:
        return state
    }
  }

  const loadCurveGraphPas = (state = '5', action) => {
    switch (action.type) {
      case 'LOAD_CURVE_TOGGLE_GRAPH_PAS':
        return action.value
      default:
        return state
    }
  }

  const loadCurveGraphEnergy = (state = '', action) => {
    switch (action.type) {
      case 'LOAD_CURVE_TOGGLE_GRAPH_ENERGY':
        return action.value
      default:
        return state
    }
  }

  const loadCurveGraphHrs = (state = '', action) => {
    switch (action.type) {
      case 'LOAD_CURVE_TOGGLE_GRAPH_HRS':
        return action.value
      default:
        return state
    }
  }

  const selectedPees = (state = [], action) => {
    switch (action.type) {
      case 'LOAD_CURVE_SET_SELECTED_PEES':
        return action.value
      default:
        return state
    }
  }

  return combineReducers({
    typeDonnee,
    dateRange,
    kpiLoadCurveConsoUuid,
    kpiLoadCurvePowerMinUuid,
    kpiLoadCurvePowerMaxUuid,
    kpiLoadCurveExcessUuid,
    loadCurveGraphUuid,
    kpiLoadCurveConso,
    kpiLoadCurvePowerMin,
    kpiLoadCurvePowerMax,
    kpiLoadCurveExcess,
    loadCurveGraph,
    graphLoading,
    graphError,
    loadCurveGraphUnite,
    loadCurveGraphAgregation,
    loadCurveGraphPas,
    loadCurveGraphEnergy,
    loadCurveGraphHrs,
    selectedPees,
  })
}

export default loadCurve

export const getDateRange = (state) => state.powerConsumption.loadCurve.dateRange
export const getStartDate = (state) =>
  (state.powerConsumption.loadCurve.dateRange &&
    state.powerConsumption.loadCurve.dateRange.startDate) ||
  '2017-1-1'
export const getEndDate = (state) =>
  (state.powerConsumption.loadCurve.dateRange &&
    state.powerConsumption.loadCurve.dateRange.endDate) ||
  '2017-1-1'
export const getIsTypeDonneeChecked = (state, value) =>
  state.powerConsumption.loadCurve.typeDonnee.indexOf(value) !== -1
export const getTypeDonnee = (state) => state.powerConsumption.loadCurve.typeDonnee
export const getKpiLoadCurveConsoUuid = (state) =>
  state.powerConsumption.loadCurve.kpiLoadCurveConsoUuid
export const getKpiLoadCurvePowerMinUuid = (state) =>
  state.powerConsumption.loadCurve.kpiLoadCurvePowerMinUuid
export const getKpiLoadCurvePowerMaxUuid = (state) =>
  state.powerConsumption.loadCurve.kpiLoadCurvePowerMaxUuid
export const getKpiLoadCurveExcessUuid = (state) =>
  state.powerConsumption.loadCurve.kpiLoadCurveExcessUuid
export const getLoadCurveGraphUuid = (state) => state.powerConsumption.loadCurve.loadCurveGraphUuid
export const getKpiLoadCurveConso = (state) => state.powerConsumption.loadCurve.kpiLoadCurveConso
export const getKpiLoadCurvePowerMin = (state) =>
  state.powerConsumption.loadCurve.kpiLoadCurvePowerMin
export const getKpiLoadCurvePowerMax = (state) =>
  state.powerConsumption.loadCurve.kpiLoadCurvePowerMax
export const getKpiLoadCurveExcess = (state) => state.powerConsumption.loadCurve.kpiLoadCurveExcess
export const getLoadCurveGraph = (state) => state.powerConsumption.loadCurve.loadCurveGraph || {}
export const getGraphLoading = (state) => state.powerConsumption.loadCurve.graphLoading
export const getGraphError = (state) => state.powerConsumption.loadCurve.graphError
export const getLoadCurveGraphUnite = (state) =>
  state.powerConsumption.loadCurve.loadCurveGraphUnite
export const getLoadCurveGraphAgregation = (state) =>
  state.powerConsumption.loadCurve.loadCurveGraphAgregation
export const getLoadCurveGraphPas = (state) => state.powerConsumption.loadCurve.loadCurveGraphPas
export const getLoadCurveGraphEnergy = (state) =>
  state.powerConsumption.loadCurve.loadCurveGraphEnergy
export const getLoadCurveGraphHrs = (state) => state.powerConsumption.loadCurve.loadCurveGraphHrs
export const getSelectedPees = (state) => state.powerConsumption.loadCurve.selectedPees

export const getPlotBandsLegend = (state) =>
  get(state, 'powerConsumption.loadCurveGraph.graph.xAxis.plotBandsLegend', false)
