import React from 'react'
import { withTranslation } from 'react-i18next'
import { Row, Navbar, Nav, NavItem } from 'reactstrap'

import PowerConsumptionNavLink from './PowerConsumptionNavLink'

const PowerConsumptionNav = ({ t }) => (
  <Row>
    <Navbar expand className="power-consumption-nav">
      <Nav navbar className="flex-row">
        <NavItem>
          <PowerConsumptionNavLink path="/power-consumption/all-amenities">
            <span>
              {t('powerConsumption.nav.allAmenities')}
              <div className="active-underline" />
            </span>
          </PowerConsumptionNavLink>
        </NavItem>
        <NavItem>
          <PowerConsumptionNavLink path="/power-consumption/electricity">
            <span>
              {t('powerConsumption.nav.elec')}
              <div className="active-underline" />
            </span>
          </PowerConsumptionNavLink>
        </NavItem>
        <NavItem>
          <PowerConsumptionNavLink path="/power-consumption/gas">
            <span>
              {t('powerConsumption.nav.gas')}
              <div className="active-underline" />
            </span>
          </PowerConsumptionNavLink>
        </NavItem>
        <NavItem>
          <PowerConsumptionNavLink path="/power-consumption/load-curve">
            <span>
              {t('powerConsumption.nav.loadCurve')}
              <div className="active-underline" />
            </span>
          </PowerConsumptionNavLink>
        </NavItem>
      </Nav>
    </Navbar>
  </Row>
)

export default withTranslation()(PowerConsumptionNav)
