import { apiConf as api } from 'commons'

const UNITE_MWH = 12
const DEVISE_EUR = 21

const mandatory = () => {
  throw new Error('Missing parameter')
}

export const fetchKpiWithDefaults = ({
  uuid = mandatory(),
  dateDebut = mandatory(),
  dateFin = mandatory(),
  perimetre = mandatory(),
  mesures = mandatory(),
  type = mandatory(),
  unite = UNITE_MWH,
  devise = DEVISE_EUR,
  commodite = '',
  decomposition = '',
  axe_decomposition = '',
  uorIdMst = mandatory(),
  lanId = mandatory(),
} = {}) =>
  fetchKpi(
    uuid,
    dateDebut,
    dateFin,
    perimetre,
    mesures,
    type,
    unite,
    devise,
    commodite,
    decomposition,
    axe_decomposition,
    uorIdMst,
    lanId
  )

export const fetchKpi = (
  uuid,
  dateDebut,
  dateFin,
  perimetre,
  mesures,
  type,
  unite,
  devise,
  commodite,
  decomposition,
  axe_decomposition,
  uorIdMst,
  lanId
) => {
  if (!perimetre) {
    return Promise.resolve()
  }

  let url = 'kpi?'

  url += 'date_debut=' + dateDebut
  url += '&date_fin=' + dateFin
  url += '&mesure=' + mesures
  url += '&type_donnee=' + type
  url += unite ? '&unite=' + unite : ''
  url += devise ? '&devise=' + devise : ''
  url += commodite ? '&commodite=' + commodite : ''
  url += decomposition ? '&decomposition=' + decomposition : ''
  url += axe_decomposition ? '&axe_decomposition=' + axe_decomposition : ''
  url += '&uorIdMst=' + uorIdMst
  url += '&lanId=' + lanId

  return api.superFetch({
    url: url,
    method: 'POST',
    body: perimetre,
    uuid: uuid,
  })
}

export const fetchKpiMarket = (uuid, lanId, type, perimetre, pays, unite, devise) => {
  if (!perimetre) {
    return Promise.resolve()
  }

  let url = 'kpicotation?'

  url += 'type=' + type
  url += '&perimetre=' + perimetre
  url += pays ? '&pays=' + pays : ''
  url += unite ? '&unite=' + unite : ''
  url += devise ? '&devise=' + devise : ''
  url += '&lanId=' + lanId

  return api.superFetch({
    url: url,
    uuid: uuid,
  })
}
