import React from 'react'
import { Spinner } from 'reactstrap'

export const Loader = ({ height = 300 }) => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height }}>
      <Spinner
        style={{
          width: '30px',
          height: '30px',
          borderWidth: '4px',
          color: 'rgba(0,199,147, 0.8)',
        }}
      />
    </div>
  )
}
