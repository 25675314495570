import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import CurrentSite from './CurrentSite'
import { getCurrentSitesIds, getCurrentSites } from '../../../reducers/currentSelection'

class CurrentSelection extends Component {
  shouldComponentUpdate(nextProps) {
    /**
     * We update the view only if the filter pqnel is closed
     * or if we reset the complete list
     */
    return (
      !nextProps.isSearchOpened ||
      (!nextProps.isSearchOpened && nextProps.currentSitesIds.length === 0)
    )
  }
  render() {
    const { currentSitesIds, currentSites } = this.props
    return (
      <div>
        {currentSitesIds.map((siteId) => (
          <CurrentSite
            key={siteId}
            site={currentSites[siteId] && currentSites[siteId].SphNom}
            siteId={(currentSites[siteId] && currentSites[siteId].id) || { id: 0 }}
          />
        ))}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  currentSitesIds: getCurrentSitesIds(state),
  currentSites: getCurrentSites(state),
  isSearchOpened: state.searchPanel.open,
})

CurrentSelection.propTypes = {
  currentSitesIds: PropTypes.array.isRequired,
  currentSites: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(CurrentSelection)
