import React from 'react'
import { withTranslation } from 'react-i18next'
import { Row, Navbar, Nav, NavItem } from 'reactstrap'

import ExpensesNavLink from './ExpensesNavLink'

const ExpensesNav = ({ t }) => (
  <Row>
    <Navbar expand className="expenses-nav">
      <Nav navbar className="flex-row">
        <NavItem>
          <ExpensesNavLink path="/expenses/all-amenities">
            <span>
              {t('expenses.nav.allAmenities')}
              <div className="active-underline" />
            </span>
          </ExpensesNavLink>
        </NavItem>
        <NavItem>
          <ExpensesNavLink path="/expenses/electricity">
            <span>
              {t('expenses.nav.elec')}
              <div className="active-underline" />
            </span>
          </ExpensesNavLink>
        </NavItem>
        <NavItem>
          <ExpensesNavLink path="/expenses/gas">
            <span>
              {t('expenses.nav.gas')}
              <div className="active-underline" />
            </span>
          </ExpensesNavLink>
        </NavItem>
        <NavItem>
          <ExpensesNavLink path="/expenses/detail">
            <span>
              {t('expenses.nav.detail')}
              <div className="active-underline" />
            </span>
          </ExpensesNavLink>
        </NavItem>
      </Nav>
    </Navbar>
  </Row>
)

export default withTranslation()(ExpensesNav)
