import v4 from 'uuid/v4'
import { normalize } from 'normalizr'
import download from 'downloadjs'

import * as user from 'commons'
import * as schema from './schema'
import * as bills from '../api/bills.js'
import {
  getBillsListUuid,
  getBillUuid,
  getPutBillUuid,
  getCurrentBillForm,
  getCurrentBillFormId,
  getPostBillFileUuid,
  getDeleteBillFileUuid,
} from '../reducers/bills'
import { getStartDate, getEndDate } from '../reducers/dateRange'
import { getCurrentVisiblePeesIds } from '../reducers/currentSelection'

export const downloadInvoice = (invoiceUrl) => async (dispatch, getState) => {
  try {
    const resp = await bills.downloadInvoice(invoiceUrl)

    if (resp.status === 200) {
      const disposition = resp.headers.get('content-disposition')
      let filename = ''
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '')
        }
      }

      const blob = await resp.blob()
      const contentType = resp.headers.get('Content-Type')
      download(blob, filename, contentType)
    } else {
      console.warn('Unable to download invoices documents')
    }
  } catch (error) {
    console.error('Error downloading invoice', error.message)
  }
}

export const fetchBillsList = () => async (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_BILLS_LIST_REQUEST',
    uuid,
  })

  const state = getState()

  const perimetre = getCurrentVisiblePeesIds(state).join(';')

  if (!perimetre) {
    dispatch({
      type: 'FETCH_BILLS_LIST_SUCCESS',
      response: 'empty',
    })
    return
  }

  const lanId = user.lanId(state)
  const dateDebut = getStartDate(state)
  const dateFin = getEndDate(state)
  try {
    const resp = await bills.fetchBillsList(uuid, dateDebut, dateFin, perimetre, lanId)

    if (resp && resp.headers.get('X-REQUEST-ID') !== getBillsListUuid(getState())) return

    const billsData = await resp.json()

    dispatch({
      type: 'FETCH_BILLS_LIST_SUCCESS',
      response: normalize(billsData, schema.arrayOfBills),
    })
  } catch (error) {
    dispatch({
      type: 'FETCH_BILLS_LIST_FAILURE',
      message: error.message || 'Something went wrong.',
    })
  }
}

export const sortBillsList = (sort) => (dispatch, getState) => {
  dispatch({
    type: 'SORT_BILLS_LIST',
    sort,
    list: getState().bills.list,
  })
}

export const fetchBill = (billId) => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_BILL_REQUEST',
    uuid,
  })

  const lanId = user.lanId(getState())

  return bills.fetchBill(uuid, billId, lanId).then(
    (response) => {
      if (response && response.headers.get('X-REQUEST-ID') === getBillUuid(getState())) {
        response.json().then((response) => {
          let newResponse = {
            ...response,
            FacDatePaiement:
              response.FacDatePaiement && response.FacDatePaiement !== '01/01/0001'
                ? response.FacDatePaiement
                : null,
          }
          dispatch({
            type: 'FETCH_BILL_SUCCESS',
            response: newResponse,
            billId,
          })
        })
      }
    },
    (error) => {
      dispatch({
        type: 'FETCH_BILL_FAILURE',
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const putBill = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'PUT_BILL_REQUEST',
    uuid,
  })

  const bill = getCurrentBillForm(getState())

  return bills.putBill(uuid, bill).then(
    (response) => {
      if (response.status === 200) {
        if (response && response.headers.get('X-REQUEST-ID') === getPutBillUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'PUT_BILL_SUCCESS',
              response,
              billId: bill.id,
            })
          )
        }
      } else {
        if (response && response.headers.get('X-REQUEST-ID') === getPutBillUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'PUT_BILL_ERROR',
              message: response.Message,
            })
          )
        }
      }
    },
    (error) => {
      dispatch({
        type: 'PUT_BILL_FAILURE',
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const updateBillForm = (update) => (dispatch) => {
  dispatch({
    type: 'BILL_FORM_UPDATE',
    update,
  })
}

export const updateBillStatus = (FacStatut) => (dispatch) => {
  dispatch({
    type: 'BILL_FORM_UPDATESTATUS',
    FacStatut,
  })
  dispatch(putBill())
}

export const postBillFile = (file) => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'BILL_FILE_POST_REQUEST',
    uuid,
  })

  const billId = getCurrentBillFormId(getState())

  return bills.postBillFile(uuid, billId, file).then(
    (response) => {
      if (response.status === 200) {
        if (response && response.headers.get('X-REQUEST-ID') === getPostBillFileUuid(getState())) {
          response.json().then((response) => {
            dispatch({
              type: 'BILL_FILE_POST_SUCCESS',
              response,
              billId,
            })
          })
        }
      } else {
        if (response && response.headers.get('X-REQUEST-ID') === getPostBillFileUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'BILL_FILE_POST_ERROR',
              message: response.Message,
            })
          )
        }
      }
    },
    (error) => {
      dispatch({
        type: 'BILL_FILE_POST_FAILURE',
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const deleteBillFile = (fileId) => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'BILL_FILE_DELETE_REQUEST',
    uuid,
  })

  return bills.deleteBillFile(uuid, fileId).then(
    (response) => {
      if (response.status === 200) {
        if (
          response &&
          response.headers.get('X-REQUEST-ID') === getDeleteBillFileUuid(getState())
        ) {
          dispatch({
            type: 'BILL_FILE_DELETE_SUCCESS',
            fileId,
          })
        }
      } else {
        if (
          response &&
          response.headers.get('X-REQUEST-ID') === getDeleteBillFileUuid(getState())
        ) {
          response.json().then((response) =>
            dispatch({
              type: 'BILL_FILE_DELETE_ERROR',
              message: response.Message,
            })
          )
        }
      }
    },
    (error) => {
      dispatch({
        type: 'BILL_FILE_DELETE_FAILURE',
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const postExtractCsv = (file) => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'EXTRACT_CSV_BILLS_LIST_REQUEST',
    uuid,
  })

  const billId = getCurrentBillFormId(getState())

  return bills.postBillFile(uuid, billId, file).then(
    (response) => {
      if (response.status === 200) {
        if (response && response.headers.get('X-REQUEST-ID') === getPostBillFileUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'EXTRACT_CSV_BILLS_LIST_SUCCESS',
              response,
              billId,
            })
          )
        }
      } else {
        if (response && response.headers.get('X-REQUEST-ID') === getPostBillFileUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'EXTRACT_CSV_BILLS_LIST_ERROR',
              message: response.Message,
            })
          )
        }
      }
    },
    (error) => {
      dispatch({
        type: 'EXTRACT_CSV_BILLS_LIST_FAILURE',
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const setFilter = ({ key, value }) => ({ type: 'SET_BILLS_FILTER', key, value })
