import React from 'react'
import { Switch, Route } from 'react-router-dom'

import AllAmenities from '../powerConsumption/AllAmenities'
import Electricity from '../powerConsumption/Electricity'
import Gas from '../powerConsumption/Gas'
import LoadCurve from '../powerConsumption/LoadCurve'

const PowerConsumptionView = () => (
  <Switch>
    <Route path="/power-consumption/all-amenities" component={AllAmenities} />
    <Route path="/power-consumption/electricity" component={Electricity} />
    <Route path="/power-consumption/gas" component={Gas} />
    <Route path="/power-consumption/load-curve" component={LoadCurve} />
  </Switch>
)

export default PowerConsumptionView
