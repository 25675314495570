export const pathsWithConsumptionParams = [
  '/power-consumption/all-amenities',
  '/power-consumption/electricity',
  '/power-consumption/gas',
]

export const pathsWithExpensesParams = [
  '/expenses/all-amenities',
  '/expenses/electricity',
  '/expenses/gas',
]

export const pathsWithDateRange = [
  '/power-consumption/all-amenities',
  '/power-consumption/electricity',
  '/power-consumption/gas',
  '/power-consumption/load-curve',
  '/expenses/all-amenities',
  '/expenses/electricity',
  '/expenses/gas',
  '/expenses/detail',
  '/market-informations',
  '/bills',
  '/contracts',
]
