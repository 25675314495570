import React, { Suspense, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { queryCache } from 'react-query'
import { useDispatch } from 'react-redux'
import { ErrorBoundary } from 'react-error-boundary'
import { Spinner } from 'reactstrap'

import { usePreferences, usePreferencesReferentiel, useUpdatePreferences } from './preferences'
import Notifications from 'react-notification-system-redux'

const ErrorFallback = () => {
  const { t } = useTranslation()
  return (
    <div role="alert" style={{ fontSize: '0.8rem', width: '22rem' }}>
      {t('global.error')}
    </div>
  )
}

export const Options = () => {
  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        color: '#fff',
        fontSize: '1rem',
        marginRight: '1rem',
      }}
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Suspense
          fallback={<Spinner style={{ width: '1.5rem', height: '1.5rem' }} color="light" />}
        >
          <OptionsContent />
        </Suspense>
      </ErrorBoundary>
    </div>
  )
}

const OptionsContent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [devise, setDevise] = useState(null)

  const { devises } = usePreferencesReferentiel()
  const { data: pref } = usePreferences()
  const [mutate, { status }] = useUpdatePreferences()

  useEffect(() => {
    pref && setDevise(pref.CurrencyUI)
  }, [pref])

  if (devises.length === 0) return null

  return (
    <Select
      label={t('global.currency')}
      value={devise}
      options={devises}
      disabled={status === 'loading' || devise === null}
      onChange={async (value) => {
        setDevise(value)
        try {
          await mutate([{ op: 'replace', path: 'CurrencyUI', value }])
          queryCache.refetchQueries(['preferences', { force: true }])
          window.location.reload()
        } catch (error) {
          setDevise(pref?.CurrencyUI ?? null)
          dispatch(
            Notifications.error({
              title: t('global.error'),
              position: 'bl',
              autoDismiss: 4,
            })
          )
        }
      }}
    />
  )
}

const Select = ({ label, value, options, disabled = false, onChange }) => {
  return (
    <label
      css={{
        margin: 0,
        '>span': { marginRight: '.5rem' },
        select: { outline: 'none', border: 'none' },
      }}
    >
      <span>{label}</span>
      <select value={value ?? ''} onChange={(e) => onChange(e.target.value)} disabled={disabled}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </label>
  )
}
