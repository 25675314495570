import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Row, Col /*, Input*/ } from 'reactstrap'

import { LogoEleneo } from 'components/common/LogoEleneo'
import { decimalFormat } from '../../helpers/helpers'
//import { updateBillStatus } from '../../actions/bills';

const BillHeader = ({
  dispatch,
  FacLogoFournisseur,
  FacFournisseur,
  FacClef,
  FacDate,
  FacPerimetre,
  FacSite,
  FacEnergie,
  FacStatut,
  FacEtat,
  FacEtatLibelle,
  FacMontantHTVA,
  FacDevise,
  t,
}) => (
  <div className="bill-header">
    <Row>
      <Col>
        <h3>{FacFournisseur}</h3>
      </Col>
      <Col className="text-right">
        <LogoEleneo colorful style={{ fontSize: '3rem' }} />
      </Col>
    </Row>
    <Row>
      <Col>
        <h2>{t('bills.bill.billId') + ' ' + FacClef}</h2>
      </Col>
    </Row>

    <Row className="bill-top">
      <Col xs="9" className="bill-infos">
        <Row>
          <Col>{t('bills.bill.date')}</Col>
          <Col className="text-right">{FacDate}</Col>
        </Row>
        <Row>
          <Col>{t('bills.bill.site')}</Col>
          <Col className="text-right">{FacSite && FacSite.SphNom}</Col>
        </Row>
        <Row>
          <Col>{t('bills.bill.pee')}</Col>
          <Col className="text-right">{FacPerimetre && FacPerimetre.PeeClef}</Col>
        </Row>
        <Row>
          <Col>{t('bills.bill.energy')}</Col>
          <Col className="text-right">{FacPerimetre && FacPerimetre.PeeNrj}</Col>
        </Row>
        {/*
				<Row>
					<Col>{t("bills.bill.status")}</Col>
					<Col className="text-right">
						<Input type="select" value={FacStatut} onChange={e => dispatch(updateBillStatus(e.target.value))}>
							<option	value="0">{t("bills.bill.statusType.0")}</option>
							<option	value="1">{t("bills.bill.statusType.1")}</option>
							<option	value="2">{t("bills.bill.statusType.2")}</option>
						</Input>
					</Col>
				</Row>
				*/}
        {/*<Row>*/}
        {/*<Col>{t("bills.bill.state")}</Col>*/}
        {/*<Col className="text-right">*/}
        {/*{FacEtatLibelle}*/}
        {/*</Col>*/}
        {/*</Row>*/}
      </Col>
      <Col xs="3" className="d-flex align-items-center bill-total">
        <p>
          <span>{t('bills.bill.price')}</span>
          <br />
          <span className="price">
            {decimalFormat(FacMontantHTVA)} {FacDevise}
            <small>{t('bills.bill.htva')}</small>
          </span>
        </p>
      </Col>
    </Row>
  </div>
)

BillHeader.propTypes = {
  dispatch: PropTypes.func.isRequired,
  FacFournisseur: PropTypes.string,
  FacClef: PropTypes.string,
  FacDate: PropTypes.string,
  FacPerimetre: PropTypes.object,
  FacSite: PropTypes.object,
  FacStatut: PropTypes.number,
  FacEtat: PropTypes.number,
  FacMontantHTVA: PropTypes.number,
  FacDevise: PropTypes.string,
}

export default withTranslation()(connect()(BillHeader))
