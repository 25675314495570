import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import Notifications from 'react-notification-system-redux'

const Notifs = ({ notifications }) => <Notifications notifications={notifications} />

Notifs.contextTypes = {
  store: PropTypes.object,
}

Notifs.propTypes = {
  notifications: PropTypes.array,
}

export default connect((state) => ({ notifications: state.notifications }))(Notifs)
