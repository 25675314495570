import { useMemo, useRef } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { superFetch } from 'commons'
import { getNrj } from 'store/comex'

export const useExposition = ({ type, start, end }) => {
  const previousData = useRef()
  const nrj = useSelector(getNrj)

  const query = useQuery({
    staleTime: 5 * 60_000,
    queryKey: ['comex-exposition', type, nrj, start, end],
    queryFn: async () => {
      const response = await superFetch({
        url: `depenses/exposition/marches?type=${type}&debut=${start}&fin=${end}&commodite=${nrj}`,
        method: 'POST',
      })
      if (!response.ok) throw new Error(response.statusText)
      const data = await response.json()
      return data
    },
  })

  // keep previous data
  const data = useMemo(() => {
    if (!query.data) return previousData.current
    previousData.current = query.data
    return query.data
  }, [query.data])

  // console.log('useExposition', { query: query.data, data })

  return {
    ...query,
    isLoading: query.isFetching && !data,
    data,
  }
}
