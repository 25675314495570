import * as allAmenities from './allAmenities'

export const toggleDecomposition = (decomposition) => (dispatch) => {
  dispatch({
    type: 'LOAD_CURVE_TOGGLE_DECOMPOSITION',
    decomposition,
  })

  // eslint-disable-next-line
	switch(location.pathname){
    case '/expenses/all-amenities':
      dispatch(allAmenities.fetchPieGraph())
      // dispatch(allAmenities.fetchColGraph());
      break
    case '/expenses/electricity':
      // dispatch(electricity.fetchPieGraph());
      // dispatch(electricity.fetchColGraph());
      break
    case '/expenses/gas':
      // dispatch(gas.fetchPieGraph());
      // dispatch(gas.fetchColGraph());
      break
    default:
      break
  }
}
