import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import keyBy from 'lodash/keyBy'

import { ErrorBoundary } from 'commons'
import { request } from './request'
import { Loader } from './Loader'
import { SitesTable } from './SitesTable'
import { SiteInfos } from './SiteInfos'
import { getCurrentVisiblePeesIds } from 'reducers/currentSelection'

const useSites = () => {
  const perimeter = useSelector(getCurrentVisiblePeesIds)

  const { data } = useQuery(['sites', { perimeter }], () =>
    perimeter && perimeter.length > 0
      ? request({ url: 'sites/filter', method: 'POST', body: perimeter })
      : () => Promise.resolve([])
  )

  return keyBy(data, 'id')
}

export const Sites = () => {
  const [siteId, setSiteId] = React.useState(null)
  const sites = useSites()

  const toggleModal = (value) => () => setSiteId((siteId) => (siteId === null ? value : null))
  const siteName = siteId === null ? null : sites[siteId].SphNom

  return (
    <>
      <SitesTable {...{ sites, toggleModal }} />

      <Modal
        isOpen={siteId !== null}
        fade={true}
        toggle={toggleModal(null)}
        css={{
          maxWidth: 1100,
          '.modal-body': {
            maxHeight: '75vh',
            overflow: 'auto',
          },
        }}
      >
        <ModalHeader toggle={toggleModal(null)}>{siteName}</ModalHeader>
        <ModalBody>
          <ErrorBoundary>
            <Suspense fallback={<Loader />}>{siteId && <SiteInfos id={siteId} />}</Suspense>
          </ErrorBoundary>
        </ModalBody>
      </Modal>
    </>
  )
}
