import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import gtmParts from 'react-google-tag-manager'

import * as user from 'commons'

class GTM extends React.Component {
  componentDidMount() {
    const dataLayerName = this.props.dataLayerName || 'dataLayer'
    const scriptId = this.props.scriptId || 'react-google-tag-manager-gtm'
    const userInfos = this.props.userInfos
    const loggedIn = this.props.loggedIn

    // if (!window[dataLayerName]) {
    //     const gtmScriptNode = document.getElementById(scriptId);
    //     eval(gtmScriptNode.textContent);
    // }

    if (!window[dataLayerName]) {
      const script = document.createElement('script')
      const gtmScriptNode = document.getElementById(scriptId)
      const scriptText = document.createTextNode(gtmScriptNode.textContent)

      script.appendChild(scriptText)
      document.head.appendChild(script)
    }

    if (loggedIn) {
      if (userInfos && userInfos !== null) {
        window[dataLayerName].push({
          userId: userInfos.UsrLogin + '_' + userInfos.UsrUorIdMst,
        })
      }
    }
  }

  render() {
    const gtm = gtmParts({
      id: this.props.gtmId,
      dataLayerName: this.props.dataLayerName || 'dataLayer',
      additionalEvents: this.props.additionalEvents || {},
      previewVariables: this.props.previewVariables || false,
    })

    return (
      <div>
        <div> {gtm.noScriptAsReact()} </div>{' '}
        <div id={this.props.scriptId || 'react-google-tag-manager-gtm'}> {gtm.scriptAsReact()}</div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loggedIn: user.isLoggedIn(state),
  userInfos: user.userDetail(state),
})

GTM.propTypes = {
  gtmId: PropTypes.string.isRequired,
  dataLayerName: PropTypes.string,
  additionalEvents: PropTypes.object,
  previewVariables: PropTypes.string,
  scriptId: PropTypes.string,
}

export default connect(mapStateToProps)(GTM)
