import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
//import { Link } from 'react-router-dom';
import { Table, Thead, Tr, Th, Td } from 'reactable'

const ContractLotPerimetre = ({ perimetre, t }) => (
  <div className="ctr-perim-table">
    <Table className="table table-striped" sortable>
      <Thead>
        <Th column="pee">{t('contracts.contract.perimeterlabels.pee')}</Th>
        <Th column="site">{t('contracts.contract.perimeterlabels.site')}</Th>
        <Th column="siteKey">{t('contracts.contract.perimeterlabels.sitekey')}</Th>
        <Th column="siteAddr">{t('contracts.contract.perimeterlabels.address')}</Th>
        <Th column="sitePc">{t('contracts.contract.perimeterlabels.pc')}</Th>
        <Th column="siteCity">{t('contracts.contract.perimeterlabels.city')}</Th>
        <Th column="siteCountry">{t('contracts.contract.perimeterlabels.country')}</Th>
        <Th column="siteGrd">{t('contracts.contract.perimeterlabels.grd')}</Th>
      </Thead>
      {perimetre.map((i) =>
        i.SitPees.map((j) => (
          <Tr key={j.id}>
            <Td column="pee">{j.PeeClef}</Td>
            <Td column="site">{i.SphNom}</Td>
            <Td column="siteKey">{i.SitClef}</Td>
            <Td column="siteAddr">{i.SphAdresse.AdrAdresse}</Td>
            <Td column="sitePc">{i.SphAdresse.AdrCodePostal}</Td>
            <Td column="siteCity">{i.SphAdresse.AdrVille}</Td>
            <Td column="siteCountry">{i.SphAdresse.AdrPays}</Td>
            <Td column="siteGrd">{j.PeeGrd || ' '}</Td>
          </Tr>
        ))
      )}
    </Table>
  </div>
)

ContractLotPerimetre.propTypes = {
  perimetre: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      SitClef: PropTypes.string.isRequired,
      SphNom: PropTypes.string.isRequired,
      SphAdresse: PropTypes.shape({
        id: PropTypes.number.isRequired,
        AdrAdresse: PropTypes.string.isRequired,
        AdrCodePostal: PropTypes.string.isRequired,
        AdrVille: PropTypes.string.isRequired,
        AdrPays: PropTypes.string.isRequired,
        AdrPaysId: PropTypes.number.isRequired,
      }).isRequired,
      SitPees: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          PeeClef: PropTypes.string.isRequired,
          PeeNrj: PropTypes.string.isRequired,
          PeeSegment: PropTypes.string.isRequired,
          PeeGrd: PropTypes.string,
        })
      ).isRequired,
    })
  ),
}

export default withTranslation()(ContractLotPerimetre)
