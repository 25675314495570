import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'
import HighchartsReact from 'highcharts-react-official'
import { Highstock } from 'helpers/highcharts'

import * as user from 'commons'
import Kpi from '../kpi/Kpi'
import { cotationChart } from '../../helpers/initCharts'
import { rangeSelectorMarket } from '../../helpers/highcharts'
import { getKpiMarketUKBrent, getMarketUKBrentGraph } from '../../reducers/market'
import * as actions from '../../actions/market'

class MarketUKBrent extends Component {
  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    const { fetchKpiMarketUKBrent, fetchMarketUKBrentGraph } = this.props
    fetchKpiMarketUKBrent()
    fetchMarketUKBrentGraph()
  }

  render() {
    const { kpi, graph, lng } = this.props
    return (
      <Col xs="4">
        <Row>
          <Col>
            <Kpi decimal={true} {...kpi} />
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <HighchartsReact
              highcharts={Highstock}
              constructorType="stockChart"
              options={cotationChart(graph, rangeSelectorMarket(lng), '#515BB3')}
              immutable
            />
          </Col>
        </Row>
      </Col>
    )
  }
}

const mapStateToProps = (state) => ({
  kpi: getKpiMarketUKBrent(state),
  graph: getMarketUKBrentGraph(state),
  lng: user.lanId(state),
})

MarketUKBrent.propTypes = {
  kpi: PropTypes.object.isRequired,
  graph: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, actions)(MarketUKBrent)
