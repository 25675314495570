import { updateView } from './views'

export const updatePeriod = (action) => (dispatch) => {
  dispatch({
    type: action,
  })
  dispatch(updateView())
}

export const updateStartDate = (date) => (dispatch) => {
  dispatch({
    type: 'DATE_RANGE_UPDATE_START_DATE',
    date,
  })
  dispatch(updateView())
}

export const updateEndDate = (date) => (dispatch) => {
  dispatch({
    type: 'DATE_RANGE_UPDATE_END_DATE',
    date,
  })
  dispatch(updateView())
}
