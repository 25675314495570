import React, { Component } from 'react'
import { connect, ReactReduxContext } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Button } from 'reactstrap'
import Notifications from 'react-notification-system-redux'

import AddFavoriteModal from './AddFavoriteModal'
import { emptySelection } from '../../../actions/currentSelection'
import { getFavoritePostSuccess, getFavoritePostError } from '../../../reducers/favorites'

class CurrentSelectionFooter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
    }

    this.toggle = this.toggle.bind(this)
  }

  UNSAFE_componentWillReceiveProps({ favoritePostSuccess, favoritePostError }) {
    if (favoritePostSuccess !== this.props.favoritePostSuccess && favoritePostSuccess) {
      this.props.store.dispatch(
        Notifications.success({
          title: 'Favorite has been added successfully !',
          position: 'bl',
          autoDismiss: 4,
        })
      )
    }

    if (favoritePostError !== this.props.favoritePostError && favoritePostError) {
      this.props.store.dispatch(
        Notifications.error({
          title: 'Something went wrong !',
          position: 'bl',
          autoDismiss: 4,
        })
      )
    }
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    })
  }

  render() {
    const { dispatch, emptySelection, t } = this.props

    return (
      <div className="d-flex w-100">
        <Button
          color="link"
          onClick={(e) => {
            e.preventDefault()
            dispatch(emptySelection)
          }}
        >
          <span>{t('sidebar.btn.empty')}</span>
        </Button>
        <Button
          color="link"
          onClick={(e) => {
            e.preventDefault()
            this.toggle()
          }}
        >
          <span>{t('sidebar.btn.addFavorite')}</span>
        </Button>
        <AddFavoriteModal isOpen={this.state.modal} toggle={this.toggle} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  emptySelection: emptySelection(),
  favoritePostSuccess: getFavoritePostSuccess(state),
  favoritePostError: getFavoritePostError(state),
})

export default withTranslation()(
  connect(mapStateToProps)((props) => (
    <ReactReduxContext.Consumer>
      {({ store }) => <CurrentSelectionFooter {...props} store={store} />}
    </ReactReduxContext.Consumer>
  ))
)
