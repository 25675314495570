import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Datetime from 'react-datetime'
import moment from 'moment'

import { dateFormat, dateFormatMonths } from '../../helpers/helpers'
import * as user from 'commons'
import { getDateRange } from '../../reducers/dateRange'
import { updateEndDate } from '../../actions/dateRange'

const DateRangeEndDateGlobal = ({ dispatch, dateRange, location, lng }) => (
  <Datetime
    id="endDate"
    locale={lng}
    closeOnSelect
    timeFormat={false}
    dateFormat={location.pathname === '/market-informations' ? dateFormat() : dateFormatMonths()}
    viewMode={location.pathname === '/market-informations' ? 'days' : 'months'}
    value={moment(dateRange.endDate).toDate()}
    onChange={(date) => {
      if (!moment.isMoment(date)) return
      dispatch(updateEndDate(date))
    }}
  />
)

const mapStateToProps = (state) => ({
  dateRange: getDateRange(state),
  lng: user.lngIso(state),
})

DateRangeEndDateGlobal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  dateRange: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  lng: PropTypes.string.isRequired,
}

export default withRouter(connect(mapStateToProps)(DateRangeEndDateGlobal))
