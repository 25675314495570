import v4 from 'uuid/v4'
import moment from 'moment'
import * as user from 'commons'
import * as kpi from '../../api/kpi.js'
import * as graph from '../../api/graph.js'
import { updateView } from '../views'
import {
  getKpiLoadCurveConsoUuid,
  getKpiLoadCurvePowerMinUuid,
  getKpiLoadCurvePowerMaxUuid,
  getKpiLoadCurveExcessUuid,
  getLoadCurveGraphUuid,
  getStartDate,
  getEndDate,
  getTypeDonnee,
  getLoadCurveGraphUnite,
  getLoadCurveGraphAgregation,
  getLoadCurveGraphPas,
  getLoadCurveGraphEnergy,
  getLoadCurveGraphHrs,
  getSelectedPees,
} from '../../reducers/powerConsumption/loadCurve'

const isErrorStatus = (code) => code < 200 || code >= 300

export const toggleTypeDonnee = (typeDonnee) => (dispatch) => {
  dispatch({
    type: 'LOAD_CURVE_TOGGLE_TYPE_DONNEE',
    typeDonnee,
  })
  dispatch(updateView())
}

export const updatePeriod = (action) => (dispatch) => {
  dispatch({
    type: action,
  })
  dispatch(updateView())
}

export const updateStartDate = (date) => (dispatch) => {
  dispatch({
    type: 'LOAD_CURVE_DATE_RANGE_UPDATE_START_DATE',
    date,
  })
  dispatch(updateView())
}

export const updateEndDate = (date) => (dispatch) => {
  dispatch({
    type: 'LOAD_CURVE_DATE_RANGE_UPDATE_END_DATE',
    date,
  })
  dispatch(updateView())
}

export const fetchKpiConso = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_LOAD_CURVE_KPI_CONSO_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getSelectedPees(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'VolumeCdc',
      type,
      12,
      21,
      '',
      '',
      '',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (
          response &&
          response.headers.get('X-REQUEST-ID') === getKpiLoadCurveConsoUuid(getState())
        ) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_LOAD_CURVE_KPI_CONSO_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_LOAD_CURVE_KPI_CONSO_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchKpiPowerMin = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_LOAD_CURVE_KPI_POWER_MIN_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getSelectedPees(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'PMin',
      type,
      12,
      21,
      '',
      '',
      '',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (
          response &&
          response.headers.get('X-REQUEST-ID') === getKpiLoadCurvePowerMinUuid(getState())
        ) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_LOAD_CURVE_KPI_POWER_MIN_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_LOAD_CURVE_KPI_POWER_MIN_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchKpiPowerMax = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_LOAD_CURVE_KPI_POWER_MAX_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getSelectedPees(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'PMax',
      type,
      12,
      21,
      '',
      '',
      '',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (
          response &&
          response.headers.get('X-REQUEST-ID') === getKpiLoadCurvePowerMaxUuid(getState())
        ) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_LOAD_CURVE_KPI_POWER_MAX_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_LOAD_CURVE_KPI_POWER_MAX_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchKpiExcess = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_LOAD_CURVE_KPI_EXCESS_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getSelectedPees(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return kpi
    .fetchKpi(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'DepassementsPS',
      type,
      12,
      21,
      '',
      '',
      '',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (
          response &&
          response.headers.get('X-REQUEST-ID') === getKpiLoadCurveExcessUuid(getState())
        ) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_LOAD_CURVE_KPI_EXCESS_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_LOAD_CURVE_KPI_EXCESS_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchGraph = () => async (dispatch, getState) => {
  const state = getState()

  const debut = getStartDate(state)
  const fin = getEndDate(state)
  const pas = getLoadCurveGraphPas(state)

  const m1 = moment(debut)
  const m2 = moment(fin)
  const hours = m2.diff(m1, 'hours', true)
  const days = m2.diff(m1, 'days', true)
  const months = m2.diff(m1, 'months', true)

  const correctedPas =
    pas === '2' && months <= 3
      ? '3'
      : pas === '3' && months <= 1
      ? '4'
      : pas === '4' && days <= 1
      ? '5'
      : pas === '5' && hours <= 1
      ? '8'
      : pas

  if (pas !== correctedPas) {
    dispatch({ type: 'LOAD_CURVE_TOGGLE_GRAPH_PAS', value: correctedPas })
  }

  const payload = {
    debut,
    fin,
    ids: getSelectedPees(state),
    typeIds: 'PEE',
    nrjs: [getLoadCurveGraphEnergy(state)],
    typesDonnees: getTypeDonnee(state),
    unite: getLoadCurveGraphUnite(state),
    agregation: getLoadCurveGraphAgregation(state),
    pas: correctedPas,
  }

  payload.hrs = Number(payload.pas) > 4 ? getLoadCurveGraphHrs(state) : 0

  if (payload.sites && payload.sites.length === 0) {
    dispatch({
      type: 'FETCH_LOAD_CURVE_GRAPH_SUCCESS',
      response: null,
    })
    return
  }

  const uuid = v4()

  dispatch({
    type: 'FETCH_LOAD_CURVE_GRAPH_REQUEST',
    uuid,
  })

  try {
    const response = await graph.fetchLoadCurveGraph({ ...payload, uuid })
    if (isErrorStatus(response.status)) throw new Error(`status ${response.status}`)

    const responseUuid = response.headers.get('X-REQUEST-ID')
    const lastUuid = getLoadCurveGraphUuid(getState())
    if (responseUuid !== uuid || lastUuid !== uuid) return

    const body = await response.json()
    dispatch({
      type: 'FETCH_LOAD_CURVE_GRAPH_SUCCESS',
      response: body,
    })
  } catch (error) {
    dispatch({
      type: 'FETCH_LOAD_CURVE_GRAPH_FAILURE',
      message: error.message || 'Something went wrong.',
    })
  }
}

export const updateLoadCurveGraphUnite = (value) => (dispatch) => {
  dispatch({
    type: 'LOAD_CURVE_TOGGLE_GRAPH_UNITE',
    value,
  })
  dispatch(fetchGraph())
}

export const updateLoadCurveGraphAgregation = (value) => (dispatch) => {
  dispatch({
    type: 'LOAD_CURVE_TOGGLE_GRAPH_AGREGATION',
    value,
  })
  dispatch(fetchGraph())
}

export const updateLoadCurveGraphPas = (value) => (dispatch) => {
  dispatch({
    type: 'LOAD_CURVE_TOGGLE_GRAPH_PAS',
    value,
  })
  dispatch(fetchGraph())
}

export const updateLoadCurveGraphEnergy = (value) => (dispatch) => {
  dispatch({
    type: 'LOAD_CURVE_TOGGLE_GRAPH_ENERGY',
    value,
  })
  dispatch(fetchGraph())
}

export const updateLoadCurveGraphHrs = (value) => (dispatch) => {
  dispatch({
    type: 'LOAD_CURVE_TOGGLE_GRAPH_HRS',
    value,
  })
  dispatch(fetchGraph())
}

export const setSelectedPees = (value) => (dispatch) => {
  dispatch({
    type: 'LOAD_CURVE_SET_SELECTED_PEES',
    value,
  })
  dispatch(fetchGraph())
  dispatch(fetchKpiConso())
  dispatch(fetchKpiExcess())
  dispatch(fetchKpiPowerMax())
  dispatch(fetchKpiPowerMin())
}
