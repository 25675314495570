import v4 from 'uuid/v4'
import * as user from 'commons'
import * as kpi from '../api/kpi.js'
import * as graph from '../api/graph.js'
import * as market from '../api/market.js'
import { normalize } from 'normalizr'
import * as schema from './schema'
import {
  getKpiMarketElecUuid,
  getKpiMarketGasUuid,
  getKpiMarketUKBrentUuid,
  getMarketElecGraphUuid,
  getMarketGasGraphUuid,
  getMarketUKBrentGraphUuid,
  getMarketProductsGraphUuid,
  getMarketProductsUuid,
  getMarketProductsIds,
} from '../reducers/market'
import { getStartDate, getEndDate } from '../reducers/dateRange'

export const fetchKpiMarketElec = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_MARKET_KPI_ELEC_REQUEST',
    uuid,
  })

  const lanId = user.lanId(getState())
  const paysId = user.paysId(getState())

  return kpi.fetchKpiMarket(uuid, lanId, 'PDT', 65, paysId).then(
    (response) => {
      if (response.headers.get('X-REQUEST-ID') === getKpiMarketElecUuid(getState())) {
        response.json().then((response) =>
          dispatch({
            type: 'FETCH_MARKET_KPI_ELEC_SUCCESS',
            response,
          })
        )
      }
    },
    (error) => {
      dispatch({
        type: 'FETCH_MARKET_KPI_ELEC_FAILURE',
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchKpiMarketGas = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_MARKET_KPI_GAS_REQUEST',
    uuid,
  })

  const lanId = user.lanId(getState())

  return kpi.fetchKpiMarket(uuid, lanId, 'PDT', 71).then(
    (response) => {
      if (response.headers.get('X-REQUEST-ID') === getKpiMarketGasUuid(getState())) {
        response.json().then((response) =>
          dispatch({
            type: 'FETCH_MARKET_KPI_GAS_SUCCESS',
            response,
          })
        )
      }
    },
    (error) => {
      dispatch({
        type: 'FETCH_MARKET_KPI_GAS_FAILURE',
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchKpiMarketUKBrent = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_MARKET_KPI_UKBRENT_REQUEST',
    uuid,
  })

  const lanId = user.lanId(getState())

  return kpi.fetchKpiMarket(uuid, lanId, 'PDT', 23, 194, 0, 0).then(
    (response) => {
      if (response.headers.get('X-REQUEST-ID') === getKpiMarketUKBrentUuid(getState())) {
        response.json().then((response) =>
          dispatch({
            type: 'FETCH_MARKET_KPI_UKBRENT_SUCCESS',
            response,
          })
        )
      }
    },
    (error) => {
      dispatch({
        type: 'FETCH_MARKET_KPI_UKBRENT_FAILURE',
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchMarketElecGraph = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_MARKET_GRAPH_ELEC_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const lanId = user.lanId(getState())
  const paysId = user.paysId(getState())

  return graph.fetchCotationGraph(uuid, dateDebut, dateFin, 65, 'PDT', 0, 0, paysId, lanId).then(
    (response) => {
      if (response.headers.get('X-REQUEST-ID') === getMarketElecGraphUuid(getState())) {
        response.json().then((response) =>
          dispatch({
            type: 'FETCH_MARKET_GRAPH_ELEC_SUCCESS',
            response,
          })
        )
      }
    },
    (error) => {
      dispatch({
        type: 'FETCH_MARKET_GRAPH_ELEC_FAILURE',
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchMarketGasGraph = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_MARKET_GRAPH_GAS_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const lanId = user.lanId(getState())

  return graph.fetchCotationGraph(uuid, dateDebut, dateFin, 71, 'PDT', 0, 0, '', lanId).then(
    (response) => {
      if (response.headers.get('X-REQUEST-ID') === getMarketGasGraphUuid(getState())) {
        response.json().then((response) =>
          dispatch({
            type: 'FETCH_MARKET_GRAPH_GAS_SUCCESS',
            response,
          })
        )
      }
    },
    (error) => {
      dispatch({
        type: 'FETCH_MARKET_GRAPH_GAS_FAILURE',
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchMarketUKBrentGraph = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_MARKET_GRAPH_UKBRENT_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const lanId = user.lanId(getState())

  return graph.fetchCotationGraph(uuid, dateDebut, dateFin, 23, 'PDT', 0, 0, '', lanId).then(
    (response) => {
      if (response.headers.get('X-REQUEST-ID') === getMarketUKBrentGraphUuid(getState())) {
        response.json().then((response) =>
          dispatch({
            type: 'FETCH_MARKET_GRAPH_UKBRENT_SUCCESS',
            response,
          })
        )
      }
    },
    (error) => {
      dispatch({
        type: 'FETCH_MARKET_GRAPH_UKBRENT_FAILURE',
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const fetchMarketProductsGraph = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_MARKET_PRODUCTS_GRAPH_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getMarketProductsIds(getState()).join(';')
  const lanId = user.lanId(getState())

  return graph
    .fetchCotationGraph(uuid, dateDebut, dateFin, perimetre, 'COT', 12, 21, '', lanId)
    .then(
      (response) => {
        if (
          response &&
          response.headers &&
          response.headers.get('X-REQUEST-ID') === getMarketProductsGraphUuid(getState())
        ) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_MARKET_PRODUCTS_GRAPH_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_MARKET_PRODUCTS_GRAPH_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}

export const fetchMarketProducts = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_MARKET_PRODUCTS_REQUEST',
    uuid,
  })

  const usrId = user.usrId(getState())
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return market.fetchProducts(uuid, usrId, uorIdMst, lanId).then(
    (response) => {
      if (response && response.headers.get('X-REQUEST-ID') === getMarketProductsUuid(getState())) {
        response.json().then((response) => {
          dispatch({
            type: 'FETCH_MARKET_PRODUCTS_SUCCESS',
            response: normalize(response, schema.products),
          })
          dispatch(fetchMarketProductsGraph())
        })
      }
    },
    (error) => {
      dispatch({
        type: 'FETCH_MARKET_PRODUCTS_FAILURE',
        message: error.message || 'Something went wrong.',
      })
    }
  )
}

export const toggleVisibleProduct = (id) => (dispatch) => {
  dispatch({
    type: 'MARKET_PRODUCT_TOGGLE_VISIBILITY',
    id,
  })
}
