import React from 'react'
import { connect } from 'react-redux'
import HighchartsReact from 'highcharts-react-official'
import { Highcharts } from 'helpers/highcharts'
import { Loader } from 'components/common/Loader'

import { getGraphData, getGraphLoading } from 'reducers/expenses/detail'
import createConfig from './detailGraphConfig'
import styles from './Detail.module.scss'

const DetailGraph = (props) => {
  const { data, isLoading } = props
  if (isLoading)
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    )

  if (!data || !data.graphique) return null

  const config = createConfig(data.graphique, { stacked: true })
  return <HighchartsReact highcharts={Highcharts} options={config} />
}

const mapState = (state) => ({
  data: getGraphData(state),
  isLoading: getGraphLoading(state),
})

export default connect(mapState)(DetailGraph)
