import v4 from 'uuid/v4'
import { normalize } from 'normalizr'

import * as schema from './schema'
import * as api from '../api/adminMarket.js'
import {
  getAdminMarketUuid,
  getAdminMarketPostUuid,
  getAdminMarketPutUuid,
  getAdminMarketInfo,
} from '../reducers/adminMarket'
import * as user from 'commons'

export const fetchAdminMarketInfos = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_ADMIN_MARKET_REQUEST',
    uuid,
  })

  return api.fetchAdminMarketInfos(uuid).then(
    (response) => {
      if (response && response.headers.get('X-REQUEST-ID') === getAdminMarketUuid(getState())) {
        response.json().then((response) =>
          dispatch({
            type: 'FETCH_ADMIN_MARKET_SUCCESS',
            response: normalize(response, schema.arrayOfSidebarMarketInfos),
          })
        )
      }
    },
    (error) => {
      dispatch({
        type: 'FETCH_ADMIN_MARKET_FAILURE',
        message: error.Message || 'Something went wrong.',
      })
    }
  )
}

export const delAdminMarketInfos = (id) => (dispatch, getState) => {
  dispatch({
    type: 'DEL_ADMIN_MARKET_REQUEST',
  })

  return api.delAdminMarketInfos(id).then(
    (response) => {
      if (response) {
        response.json().then((response) =>
          dispatch({
            type: 'DEL_ADMIN_MARKET_SUCCESS',
            response,
          })
        )
      }
    },
    (error) => {
      dispatch({
        type: 'DEL_ADMIN_MARKET_FAILURE',
        message: error.Message || 'Something went wrong.',
      })
    }
  )
}

export const putAdminMarketInfos = (info) => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'PUT_ADMIN_MARKET_REQUEST',
    uuid,
  })

  const usrid = user.usrId(getState())
  const oldInfo = getAdminMarketInfo(getState(), info.id)

  return api.putAdminMarketInfos(uuid, usrid, { ...oldInfo, ...info }).then(
    (response) => {
      if (response && response.headers.get('X-REQUEST-ID') === getAdminMarketPutUuid(getState())) {
        response.json().then((response) =>
          dispatch({
            type: 'PUT_ADMIN_MARKET_SUCCESS',
            response,
          })
        )
      }
    },
    (error) => {
      dispatch({
        type: 'PUT_ADMIN_MARKET_FAILURE',
        message: error.Message || 'Something went wrong.',
      })
    }
  )
}

export const postAdminMarketInfos = (info) => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'POST_ADMIN_MARKET_REQUEST',
    uuid,
  })

  const usrid = user.usrId(getState())

  return api.postAdminMarketInfos(uuid, usrid, info).then(
    (response) => {
      if (response && response.headers.get('X-REQUEST-ID') === getAdminMarketPostUuid(getState())) {
        response.json().then((response) =>
          dispatch({
            type: 'POST_ADMIN_MARKET_SUCCESS',
            response,
          })
        )
      }
    },
    (error) => {
      dispatch({
        type: 'POST_ADMIN_MARKET_FAILURE',
        message: error.Message || 'Something went wrong.',
      })
    }
  )
}
