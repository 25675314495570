import { combineReducers } from 'redux'

const electricity = () => {
  const kpiExpTotalUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_EXP_TOTAL_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiExpTotalLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_EXP_TOTAL_REQUEST':
        return true
      case 'FETCH_EXPENSES_ELEC_EXP_TOTAL_SUCCESS':
      case 'FETCH_EXPENSES_ELEC_EXP_TOTAL_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiExpEnergyUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_EXP_ENERGY_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiExpEnergyLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_EXP_ENERGY_REQUEST':
        return true
      case 'FETCH_EXPENSES_ELEC_EXP_ENERGY_SUCCESS':
      case 'FETCH_EXPENSES_ELEC_EXP_ENERGY_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiExpTransportUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_EXP_TRANSPORT_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiExpTransportLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_EXP_TRANSPORT_REQUEST':
        return true
      case 'FETCH_EXPENSES_ELEC_EXP_TRANSPORT_SUCCESS':
      case 'FETCH_EXPENSES_ELEC_EXP_TRANSPORT_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiExpTaxesUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_EXP_TAXES_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiExpTaxesLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_EXP_TAXES_REQUEST':
        return true
      case 'FETCH_EXPENSES_ELEC_EXP_TAXES_SUCCESS':
      case 'FETCH_EXPENSES_ELEC_EXP_TAXES_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiExpAltUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_EXP_ALT_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiExpAltLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_EXP_ALT_REQUEST':
        return true
      case 'FETCH_EXPENSES_ELEC_EXP_ALT_SUCCESS':
      case 'FETCH_EXPENSES_ELEC_EXP_ALT_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiPriceTotalUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_PRICE_TOTAL_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiPriceTotalLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_PRICE_TOTAL_REQUEST':
        return true
      case 'FETCH_EXPENSES_ELEC_PRICE_TOTAL_SUCCESS':
      case 'FETCH_EXPENSES_ELEC_PRICE_TOTAL_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiPriceEnergyUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_PRICE_ENERGY_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiPriceEnergyLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_PRICE_ENERGY_REQUEST':
        return true
      case 'FETCH_EXPENSES_ELEC_PRICE_ENERGY_SUCCESS':
      case 'FETCH_EXPENSES_ELEC_PRICE_ENERGY_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiPriceTransportUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_PRICE_TRANSPORT_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiPriceTransportLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_PRICE_TRANSPORT_REQUEST':
        return true
      case 'FETCH_EXPENSES_ELEC_PRICE_TRANSPORT_SUCCESS':
      case 'FETCH_EXPENSES_ELEC_PRICE_TRANSPORT_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiPriceTaxesUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_PRICE_TAXES_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiPriceTaxesLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_PRICE_TAXES_REQUEST':
        return true
      case 'FETCH_EXPENSES_ELEC_PRICE_TAXES_SUCCESS':
      case 'FETCH_EXPENSES_ELEC_PRICE_TAXES_FAILURE':
        return false
      default:
        return state
    }
  }

  const kpiPriceAltUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_PRICE_ALT_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiPriceAltLoading = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_PRICE_ALT_REQUEST':
        return true
      case 'FETCH_EXPENSES_ELEC_PRICE_ALT_SUCCESS':
      case 'FETCH_EXPENSES_ELEC_PRICE_ALT_FAILURE':
        return false
      default:
        return state
    }
  }

  const pieGraphUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_PIE_GRAPH_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const colGraphUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_COL_GRAPH_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const kpiExpTotal = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_EXP_TOTAL_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiExpEnergy = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_EXP_ENERGY_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiExpTransport = (
    state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } },
    action
  ) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_EXP_TRANSPORT_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiExpTaxes = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_EXP_TAXES_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiExpAlt = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_EXP_ALT_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiPriceTotal = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_PRICE_TOTAL_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiPriceEnergy = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_PRICE_ENERGY_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiPriceTransport = (
    state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } },
    action
  ) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_PRICE_TRANSPORT_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiPriceTaxes = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_PRICE_TAXES_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const kpiPriceAlt = (state = { Principal: { Libelle: '', Valeur: 0, Unite: '' } }, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_PRICE_ALT_SUCCESS':
        return { ...state, ...action.response }
      default:
        return state
    }
  }

  const pieGraph = (state = {}, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_PIE_GRAPH_SUCCESS':
        return action.response
      default:
        return state
    }
  }

  const colGraph = (state = {}, action) => {
    switch (action.type) {
      case 'FETCH_EXPENSES_ELEC_COL_GRAPH_SUCCESS':
        return action.response
      default:
        return state
    }
  }

  return combineReducers({
    kpiExpTotalUuid,
    kpiExpTotalLoading,
    kpiExpEnergyUuid,
    kpiExpEnergyLoading,
    kpiExpTransportUuid,
    kpiExpTransportLoading,
    kpiExpTaxesUuid,
    kpiExpTaxesLoading,
    kpiExpAltUuid,
    kpiExpAltLoading,
    kpiPriceTotalUuid,
    kpiPriceTotalLoading,
    kpiPriceEnergyUuid,
    kpiPriceEnergyLoading,
    kpiPriceTransportUuid,
    kpiPriceTransportLoading,
    kpiPriceTaxesUuid,
    kpiPriceTaxesLoading,
    kpiPriceAltUuid,
    kpiPriceAltLoading,
    pieGraphUuid,
    colGraphUuid,
    kpiExpTotal,
    kpiExpEnergy,
    kpiExpTransport,
    kpiExpTaxes,
    kpiExpAlt,
    kpiPriceTotal,
    kpiPriceEnergy,
    kpiPriceTransport,
    kpiPriceTaxes,
    kpiPriceAlt,
    pieGraph,
    colGraph,
  })
}

export default electricity

export const getKpiExpTotalUuid = (state) => state.expenses.electricity.kpiExpTotalUuid
export const getKpiExpTotalLoading = (state) => state.expenses.electricity.kpiExpTotalLoading
export const getKpiExpEnergyUuid = (state) => state.expenses.electricity.kpiExpEnergyUuid
export const getKpiExpEnergyLoading = (state) => state.expenses.electricity.kpiExpEnergyLoading
export const getKpiExpTransportUuid = (state) => state.expenses.electricity.kpiExpTransportUuid
export const getKpiExpTransportLoading = (state) =>
  state.expenses.electricity.kpiExpTransportLoading
export const getKpiExpTaxesUuid = (state) => state.expenses.electricity.kpiExpTaxesUuid
export const getKpiExpTaxesLoading = (state) => state.expenses.electricity.kpiExpTaxesLoading
export const getKpiExpAltUuid = (state) => state.expenses.electricity.kpiExpAltUuid
export const getKpiExpAltLoading = (state) => state.expenses.electricity.kpiExpAltLoading
export const getKpiPriceTotalUuid = (state) => state.expenses.electricity.kpiPriceTotalUuid
export const getKpiPriceTotalLoading = (state) => state.expenses.electricity.kpiPriceTotalLoading
export const getKpiPriceEnergyUuid = (state) => state.expenses.electricity.kpiPriceEnergyUuid
export const getKpiPriceEnergyLoading = (state) => state.expenses.electricity.kpiPriceEnergyLoading
export const getKpiPriceTransportUuid = (state) => state.expenses.electricity.kpiPriceTransportUuid
export const getKpiPriceTransportLoading = (state) =>
  state.expenses.electricity.kpiPriceTransportLoading
export const getKpiPriceTaxesUuid = (state) => state.expenses.electricity.kpiPriceTaxesUuid
export const getKpiPriceTaxesLoading = (state) => state.expenses.electricity.kpiPriceTaxesLoading
export const getKpiPriceAltUuid = (state) => state.expenses.electricity.kpiPriceAltUuid
export const getKpiPriceAltLoading = (state) => state.expenses.electricity.kpiPriceAltLoading
export const getPieGraphUuid = (state) => state.expenses.electricity.pieGraphUuid
export const getColGraphUuid = (state) => state.expenses.electricity.colGraphUuid
export const getKpiExpTotal = (state) => state.expenses.electricity.kpiExpTotal
export const getKpiExpEnergy = (state) => state.expenses.electricity.kpiExpEnergy
export const getKpiExpTransport = (state) => state.expenses.electricity.kpiExpTransport
export const getKpiExpTaxes = (state) => state.expenses.electricity.kpiExpTaxes
export const getKpiExpAlt = (state) => state.expenses.electricity.kpiExpAlt
export const getKpiPriceTotal = (state) => state.expenses.electricity.kpiPriceTotal
export const getKpiPriceEnergy = (state) => state.expenses.electricity.kpiPriceEnergy
export const getKpiPriceTransport = (state) => state.expenses.electricity.kpiPriceTransport
export const getKpiPriceTaxes = (state) => state.expenses.electricity.kpiPriceTaxes
export const getKpiPriceAlt = (state) => state.expenses.electricity.kpiPriceAlt
export const getPieGraph = (state) => state.expenses.electricity.pieGraph
export const getColGraph = (state) => state.expenses.electricity.colGraph
