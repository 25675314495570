import { combineReducers } from 'redux'

const contracts = () => {
  const contractsListUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_CONTRACTS_LIST_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const contractUuid = (state = {}, action) => {
    switch (action.type) {
      case 'FETCH_CONTRACT_REQUEST':
        const newUuid = {}
        newUuid[action.ctrid] = action.uuid
        return { ...state, ...newUuid }
      default:
        return state
    }
  }

  const metersListUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_METERS_LIST_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const meterUuid = (state = {}, action) => {
    switch (action.type) {
      case 'FETCH_METER_REQUEST':
        const newUuid = {}
        newUuid[action.peeid] = action.uuid
        return { ...state, ...newUuid }
      default:
        return state
    }
  }

  const contractsList = (state = {}, action) => {
    switch (action.type) {
      case 'FETCH_CONTRACTS_LIST_SUCCESS':
        return {
          ...state,
          ...action.response.entities,
          contractsIds: action.response.result,
        }
      default:
        return state
    }
  }

  const contractsDetails = (state = {}, action) => {
    switch (action.type) {
      case 'FETCH_CONTRACT_SUCCESS':
        return {
          ...state,
          ...action.response.entities.contracts,
        }
      default:
        return state
    }
  }

  const metersList = (state = {}, action) => {
    switch (action.type) {
      case 'FETCH_METERS_LIST_SUCCESS':
        return {
          ...state,
          ...action.response.entities,
          metersIds: action.response.result,
        }
      default:
        return state
    }
  }

  const metersDetails = (state = {}, action) => {
    switch (action.type) {
      case 'FETCH_METER_SUCCESS':
        return {
          ...state,
          ...action.response.entities.meters,
        }
      default:
        return state
    }
  }

  return combineReducers({
    contractsListUuid,
    contractUuid,
    metersListUuid,
    meterUuid,
    contractsList,
    contractsDetails,
    metersList,
    metersDetails,
  })
}

export default contracts

export const getContractsListUuid = (state) => state.contracts.contractsListUuid
export const getContractUuid = (state, ctrid) => state.contracts.contractUuid[ctrid]
export const getMetersListUuid = (state) => state.contracts.metersListUuid
export const getMeterUuid = (state, peeid) => state.contracts.meterUuid[peeid]
export const getContractsListIds = (state) => state.contracts.contractsList.contractsIds || []
export const getContractsList = (state) => state.contracts.contractsList.contracts || []
export const getContract = (state, id) => state.contracts.contractsList.contracts[id] || {}
export const getContractDetails = (state, id) => state.contracts.contractsDetails[id] || {}
export const getMetersListIds = (state) => state.contracts.metersList.metersIds || []
export const getMetersList = (state) => state.contracts.metersList.meters || []
export const getMeter = (state, id) => state.contracts.metersList.meters[id] || {}
export const getMeterDetails = (state, id) => state.contracts.metersDetails[id] || {}
