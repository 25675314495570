import React from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'
import { compose, lifecycle, withHandlers } from 'recompose'
import { useTranslation } from 'react-i18next'

import { getCurrentVisiblePeesIds } from 'reducers/currentSelection'

// import { getLongStartDate, getLongEndDate, getEnergy } from '../store/dateRange'
import { getStartDate, getEndDate } from 'reducers/dateRange'

import { fetchQualiteConsoGraph } from 'store/consumption'
import QualiteConsoGraph from './QualiteConsoGraph'
import styles from './QualiteConso.module.scss'

const QualiteConso = ({ typeDonnees, startDate, endDate, energy }) => {
  const { t } = useTranslation()
  
  return (
  <Container fluid className="box p-0 mr-0 ml-0">
    <Row>
      <Col>
        <h3>{t('powerConsumption.dataAvailability')}</h3>
      </Col>
    </Row>
    <Row>
      <Col className={styles.graphContainer}>
        <QualiteConsoGraph startDate={startDate} endDate={endDate} />
      </Col>
    </Row>
  </Container>
  )
}

const mapStateToProps = (state) => ({
  peeIds: getCurrentVisiblePeesIds(state),
  startDate: getStartDate(state),
  endDate: getEndDate(state),
})

const mapDispatchToProps = {
  fetchQualiteConsoGraph,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    fetchGraph: (props) => () => {
      const payload = {
        debut: props.startDate,
        fin: props.endDate,
        ids: props.peeIds,
        typeIds: 'PEE',
        nrjs: props.energy == 'GAZ' ? [1] : [2],
        typesDonnees: [props.typeDonnees],
        unite: 11,
        agregation: '0', // 0 = Compteur
        pas: '5',
      }
      props.fetchQualiteConsoGraph(payload)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchGraph()
    },
    componentDidUpdate(prevProps) {
      if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
        this.props.fetchGraph()
      }
    },
  }),
)(QualiteConso)
