import keyBy from 'lodash/keyBy'
import * as user from 'commons'

import * as api from '../api/pdf'
import { getRootUor } from '../reducers/organisation'
import { getCurrentBillForm, getCurrentBillFormId } from '../reducers/bills'

import download from 'downloadjs'
import { apiConf } from 'commons'
const { superFetch } = apiConf

export const fetchPdfs = () => async (dispatch, getState) => {
  try {
    dispatch({ type: 'PDF_FETCH_REQUEST' })

    const state = getState()

    const bill = getCurrentBillForm(state)

    const resp = await api.fetchPdfs({
      pdl: bill.FacPerimetre.PeeClef,
      uorId: getRootUor(state),
      invoiceNumber: bill.FacClef,
    })
    const data = await resp.json()

    dispatch({
      type: 'PDF_FETCH_SUCCESS',
      payload: keyBy(data, 'id'),
    })
  } catch (error) {
    dispatch({
      type: 'PDF_FETCH_FAILURE',
      message: error.message || 'Something went wrong.',
    })
  }
}

export const postBillPdf = (file) => async (dispatch, getState) => {
  try {
    dispatch({ type: 'PDF_POST_REQUEST' })

    const state = getState()

    const bill = getCurrentBillForm(state)

    const payload = {
      file,
      customerId: user.uorIdMst(state),
      supplierCode: bill.FacFournisseur,
      meterName: bill.FacPerimetre.PeeClef,
      invoiceNumber: bill.FacClef,
      invoiceDate: bill.FacDate,
    }

    const resp = await api.postBillPdf(payload)
    const body = await resp.json()
    const billId = getCurrentBillFormId(getState())

    dispatch({
      type: 'PDF_POST_SUCCESS',
      payload: { body, billId },
    })
    dispatch(fetchPdfs())
  } catch (error) {
    dispatch({
      type: 'PDF_POST_FAILURE',
      message: error.message || 'Something went wrong.',
    })
  }
}

export const deleteBillPdf = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: 'PDF_DELETE_REQUEST' })
    await api.deletePdf(id)

    dispatch({
      type: 'PDF_DELETE_SUCCESS',
    })
    dispatch(fetchPdfs())
  } catch (error) {
    dispatch({
      type: 'PDF_DELETE_FAILURE',
      message: error.message || 'Something went wrong.',
    })
  }
}

export const fetchPdf = async (id) => {
  try {
    const resp = await superFetch({
      url: `pdfs/${id}`,
      platform: 'INVOICE',
    })
    const { status, type, statusText } = resp
    if (status < 200 || status >= 300)
      throw new Error(`${status}${type && ` (${type})`}${statusText && ` - ${statusText}`}`)

    const disposition = resp.headers.get('content-disposition')

    let filename
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRx = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRx.exec(disposition)
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '')
      }
    }

    const blob = await resp.blob()
    const contentType = resp.headers.get('Content-Type')
    download(blob, filename, contentType)
  } catch (error) {
    console.error(error)
  }
}
