const initialState = {
  items: null,
  importResult: null,
  loading: false,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'PDF_FETCH_REQUEST':
    case 'PDF_POST_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      }
    case 'PDF_FETCH_SUCCESS':
      return {
        ...state,
        items: action.payload,
        loading: false,
        error: null,
      }
    case 'PDF_POST_SUCCESS':
      return {
        ...state,
        importResult: action.payload,
        loading: false,
        error: null,
      }
    case 'PDF_FETCH_FAILURE':
    case 'PDF_POST_FAILURE':
      return {
        ...state,
        items: null,
        importResult: null,
        loading: false,
        error: action.message,
      }
    default:
      return state
  }
}

// Selectors
export const getPdfs = (state) => state.pdf.items
