import React from 'react'
import { Table, Label } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { usePee, useToggleAlerteDepassementPS } from './usePee'
import Select from 'components/common/Select'
import { dateFormat } from 'helpers/formatters'
import { Input } from './Input'

const useFta = (listFta) => {
  const [fta, setFta] = React.useState(0)

  const options = React.useMemo(
    () =>
      listFta
        .sort((a, b) => {
          const d1 = moment(a.AchFtaDateDerniereModification, 'DD/MM/YYYY')
          const d2 = moment(b.AchFtaDateDerniereModification, 'DD/MM/YYYY')
          return d1.isBefore(d2) ? 1 : d1.isAfter(d2) ? -1 : 0
        })
        .map((fta, index) => ({
          value: index,
          label: fta.AchFtaDateDerniereModification,
        })),
    [listFta]
  )

  const value = options.find((option) => option.value === fta) || ''
  const onChange = (option) => setFta(option.value)
  return {
    fta,
    selectFtaProps: { options, value, onChange },
  }
}

export const PeeInfos = ({ id }) => {
  const { t } = useTranslation()
  const segmentsDepassement = ['C1', 'C2', 'C3', 'C4' ]

  const { infos, acheminement } = usePee(id)
  const listFta = acheminement[0] ? acheminement[0].AchListeFta : []
  const { fta, selectFtaProps } = useFta(listFta)

  const startDate = (() => {
    if (!infos.DateEntree) return '-'
    const m = moment(infos.DateEntree)
    return m.isValid() ? m.format(dateFormat()) : '-'
  })()

  const endDate = (() => {
    if (!infos.DateSortie) return '-'
    const m = moment(infos.DateSortie)
    return m.isValid() ? m.format(dateFormat()) : '-'
  })()

  const onAlertDepassementChange = (e, infos) => {
    infos.HasAlertDepassementPS = !infos.HasAlertDepassementPS
    useToggleAlerteDepassementPS(infos.id, infos.HasAlertDepassementPS)
  }

  return (
    <div css={{ '>div:not(:last-child)': { marginBottom: '1.5rem' } }}>
      <div>
        <div className="h7">{t('site.pee-details.general-informations')}</div>
        <div
          css={{
            display: 'flex',
            '>*': {
              flex: 1,
              ':not(:last-child)': { marginRight: '1rem' },
            },
          }}
        >
          <div>
            <Label>
              <div>{t('site.pee-details.num')}</div>
              <Input value={infos.PeeClef ?? ''} disabled />
            </Label>
            <Label>
              <div>{t('site.pee-details.commodity')}</div>
              <Input value={infos.PeeNrj ?? ''} disabled />
            </Label>
          </div>
          <div>
            <Label>
              <div>{t('site.pee-details.reseau')}</div>
              <Input value={infos.NomReseau ?? ''} disabled />
            </Label>
            <Label>
              <div>{t('site.pee-details.segment')}</div>
              <Input value={infos.PeeSegment ?? ''} disabled />
            </Label>
          </div>
          <div>
            <Label>
              <div>{t('site.pee-details.active')}</div>
              <Input value={startDate ?? ''} disabled />
            </Label>
            <Label>
              <div>{t('site.pee-details.inactive')}</div>
              <Input value={endDate ?? ''} disabled />
            </Label>
          </div>
        </div>
      </div>

      <div
        css={{
          display: 'flex',
          '>div': { flex: 1, '&:not(:last-child)': { marginRight: '2rem' } },
        }}
      >
        <div>
          <div className="h7">{t('site.pee-details.routing.title')}</div>
          <div>
            <Label>
              <div>{t('site.pee-details.routing.date')}</div>
              <Select
                {...selectFtaProps}
                width={200}
                isClearable={false}
                isSearchable={true}
                isMulti={false}
                isDisabled={selectFtaProps.options.length === 0}
                placeholder=""
              />
            </Label>
            <Label>
              <div>{t('site.pee-details.routing.formule')}</div>
              <Input
                value={(listFta[fta] && listFta[fta].AchFtaLibelle) ?? ''}
                css={{ width: '300px !important' }}
                disabled
              />
            </Label>
          </div>

          {listFta[fta] && listFta[fta].AchFtaParametres.length > 0 && (
            <Table striped bordered size="sm mt-3 mb-4">
              <thead>
                <tr>
                  <th>{t('site.pee-details.routing.option')}</th>
                  <th>{t('site.pee-details.routing.value')}</th>
                </tr>
              </thead>
              <tbody>
                {listFta[fta].AchFtaParametres.map(({ FtaParamLibelle, FtaParamValeur }, index) => (
                  <tr key={index}>
                    <th scope="row">{FtaParamLibelle}</th>
                    <td>{FtaParamValeur}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>

        <div>
          {infos.PeeNrjId == 2 && segmentsDepassement.includes(infos.PeeSegment) ?
          <div style={{ marginBottom: '2em' }}>
            <div className="h7">{t('site.pee-details.alerts.title')}</div>
            <div>
              <Label>
                <input
                    type="checkbox"
                    defaultChecked={infos.HasAlertDepassementPS}
                    onChange={(e) => onAlertDepassementChange(e, infos)}
                />
                <div css={{marginLeft: '1rem', width: '400px !important'}}>{t('site.pee-details.alerts.depassementPS')}</div>
              </Label>
            </div>
          </div> : null }
          {infos.Contrats && (
            <div>
              <div className="h7">{t('site.pee-details.contracts.title')}</div>
              <Table striped bordered size="sm mt-3 mb-4">
                <thead>
                  <tr>
                    <th>{t('site.pee-details.contracts.header')}</th>
                  </tr>
                </thead>
                <tbody>
                  {infos.Contrats.map((contrat) => (
                    <tr key={contrat.id}>
                      <th scope="row" id={contrat.id}>
                        {contrat.CtrNom}
                      </th>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
