import { combineReducers } from 'redux'

import allAmenities from './expenses/allAmenities'
import electricity from './expenses/electricity'
import gas from './expenses/gas'
import decomposition from './expenses/decomposition'
import detail from './expenses/detail'

const expenses = () => {
  const graphUnite = (state = '12', action) => {
    switch (action.type) {
      case 'UPDATE_GRAPH_UNITE':
        return action.value
      default:
        return state
    }
  }

  const depenses = (
    state = {
      data: [],
      isLoading: false,
      error: false,
      agregation: '0',
    },
    action
  ) => {
    switch (action.type) {
      case 'FETCH_DEPENSES_REQUEST':
        return {
          ...state,
          data: [],
          isLoading: true,
          error: false,
        }
      case 'FETCH_DEPENSES_SUCCESS':
        return {
          ...state,
          data: action.data,
          isLoading: false,
          error: false,
        }
      case 'FETCH_DEPENSES_FAILURE':
        return {
          ...state,
          data: [],
          isLoading: false,
          error: true,
        }
      default:
        return state
    }
  }

  return combineReducers({
    allAmenities: allAmenities(),
    electricity: electricity(),
    gas: gas(),
    decomposition,
    graphUnite,
    depenses,
    detail,
  })
}

export default expenses

export const getGraphUnite = (state) => state.expenses.graphUnite
export const getDepenses = (state) => state.expenses.depenses.data
export const getDepensesIsLoading = (state) => state.expenses.depenses.isLoading
export const getDepensesError = (state) => state.expenses.depenses.error
