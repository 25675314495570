import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { RiDownload2Line } from 'react-icons/ri'

import downloadFile from 'helpers/downloadFile'

const formatCSV = (data) =>
  data
    .map((line) =>
      line
        // .map((s) => `"=""${s}"""`)
        .join(';')
    )
    .join('\n')

const CSVButton = ({ filename, createContent }) => {
  return (
    <Button
      style={{ color: 'black', background: '#98D2DD', border: '#98D2DD' }}
      color="primary"
      size="sm"
      onClick={() => {
        downloadFile(filename, formatCSV(createContent()), { type: 'text/csv' })
      }}
      css={{
        svg: {
          transform: 'scale(1.4) translateY(-1px)',
          marginRight: '0.8rem',
        },
      }}
    >
      <RiDownload2Line />
      Export CSV
    </Button>
  )
}

CSVButton.propTypes = {
  filename: PropTypes.string.isRequired,
  createContent: PropTypes.func.isRequired,
}

export default CSVButton
