import React from 'react'
import { withTranslation } from 'react-i18next'
import { Nav, NavItem } from 'reactstrap'

import SidebarNavLink from './SidebarNavLink'

const SidebarNav = ({ t, ...rest }) => (
  <Nav tabs className="row">
    <NavItem className="col p-0">
      <SidebarNavLink tabId="SELECTION" text={t('sidebar.nav.selection')} {...rest} />
    </NavItem>
    <NavItem className="col p-0">
      <SidebarNavLink tabId="FAVORITES" text={t('sidebar.nav.favorites')} {...rest} />
    </NavItem>
  </Nav>
)

export default withTranslation()(SidebarNav)
