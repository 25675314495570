import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ButtonGroup, Button, Table } from 'reactstrap'
import Datetime from 'react-datetime'

import * as actions from '../../actions/adminMarket'
import { getAdminMarketInfos } from '../../reducers/adminMarket'

class AdminMarket extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newPost: {
        InfDate: '',
        InfPays: '',
        InfThematique: '',
        InfTitre: '',
        InfTexte: '',
        InfEstAffichable: 'false',
      },
    }

    this.post = this.post.bind(this)
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    const { fetchAdminMarketInfos } = this.props
    fetchAdminMarketInfos()
  }

  put(id) {
    const { putAdminMarketInfos } = this.props
    putAdminMarketInfos({ id, ...this.state[id] })
  }

  post() {
    const { postAdminMarketInfos } = this.props
    postAdminMarketInfos(this.state.newPost)
  }

  del(id) {
    const { delAdminMarketInfos } = this.props
    delAdminMarketInfos(id)
  }

  onChange(id, name, value) {
    let info = { ...this.state[id] }
    info[name] = value
    this.setState({ ...this.state, [id]: info })
  }

  onChangeNew(name, value) {
    let newPost = { ...this.state.newPost }
    newPost[name] = value
    this.setState({ ...this.state, newPost })
  }

  render() {
    const { adminMarketInfos } = this.props
    return (
      <Table className="table table-striped table-hover admin-marche">
        <thead>
          <tr>
            <th>Affiché</th>
            <th>Date</th>
            <th>Pays</th>
            <th>Thématique</th>
            <th>Titre</th>
            <th>Texte</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {adminMarketInfos.map((i) => (
            <tr
              key={i.id}
              title={
                'date créa: ' +
                i.InfDateCre +
                ' - datemaj: ' +
                i.InfDateMaj +
                ' - usr crea: ' +
                i.InfUsrCreId +
                ' - usr maj: ' +
                i.InfUsrMajId
              }
            >
              <td>
                <input
                  type="checkbox"
                  defaultChecked={i.InfEstAffichable}
                  onChange={(e) => this.onChange(i.id, 'InfEstAffichable', e.target.checked)}
                />
              </td>
              <td>
                <Datetime
                  closeOnSelect
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                  viewMode="days"
                  defaultValue={i.InfDate}
                  onChange={(date) => this.onChange(i.id, 'InfDate', date.format('DD/MM/YYYY'))}
                />
              </td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={i.InfPays}
                  onChange={(e) => this.onChange(i.id, 'InfPays', e.target.value)}
                />
              </td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={i.InfThematique}
                  onChange={(e) => this.onChange(i.id, 'InfThematique', e.target.value)}
                />
              </td>
              <td className="textarea">
                <textarea
                  className="form-control"
                  defaultValue={i.InfTitre}
                  onChange={(e) => this.onChange(i.id, 'InfTitre', e.target.value)}
                />
              </td>
              <td className="textarea">
                <textarea
                  className="form-control"
                  defaultValue={i.InfTexte}
                  onChange={(e) => this.onChange(i.id, 'InfTexte', e.target.value)}
                />
              </td>
              <td>
                <ButtonGroup>
                  <Button color="primary" onClick={() => this.put(i.id)}>
                    <i className="icon-done" />
                  </Button>
                  <Button color="danger" onClick={() => this.del(i.id)}>
                    <i className="icon-cross" />
                  </Button>
                </ButtonGroup>
              </td>
            </tr>
          ))}
          <tr className="new-info">
            <td>
              <input
                type="checkbox"
                onChange={(e) => this.onChangeNew('InfEstAffichable', e.target.checked)}
              />
            </td>
            <td>
              <Datetime
                closeOnSelect
                timeFormat={false}
                dateFormat="DD/MM/YYYY"
                viewMode="days"
                onChange={(date) => this.onChangeNew('InfDate', date.format('DD/MM/YYYY'))}
              />
            </td>
            <td>
              <input
                className="form-control"
                type="text"
                placeholder="Pays"
                onChange={(e) => this.onChangeNew('InfPays', e.target.value)}
              />
            </td>
            <td>
              <input
                className="form-control"
                type="text"
                placeholder="Thématique"
                onChange={(e) => this.onChangeNew('InfThematique', e.target.value)}
              />
            </td>
            <td className="textarea">
              <textarea
                className="form-control"
                defaultValue="Titre"
                onChange={(e) => this.onChangeNew('InfTitre', e.target.value)}
              />
            </td>
            <td className="textarea">
              <textarea
                className="form-control"
                defaultValue="Texte"
                onChange={(e) => this.onChangeNew('InfTexte', e.target.value)}
              />
            </td>
            <td>
              <Button color="info" onClick={() => this.post()}>
                <i className="icon-bigarrow-up" />
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
    )
  }
}

const mapStateToProps = (state) => ({
  adminMarketInfos: getAdminMarketInfos(state),
})

const mapDispatchToProps = (dispatch) => ({
  fetchAdminMarketInfos(state) {
    dispatch(actions.fetchAdminMarketInfos(state))
  },
  delAdminMarketInfos(state) {
    dispatch(actions.delAdminMarketInfos(state))
  },
  putAdminMarketInfos(state) {
    dispatch(actions.putAdminMarketInfos(state))
  },
  postAdminMarketInfos(state) {
    dispatch(actions.postAdminMarketInfos(state))
  },
})

AdminMarket.propTypes = {
  adminMarketInfos: PropTypes.array.isRequired,
  fetchAdminMarketInfos: PropTypes.func.isRequired,
  delAdminMarketInfos: PropTypes.func.isRequired,
  putAdminMarketInfos: PropTypes.func.isRequired,
  postAdminMarketInfos: PropTypes.func.isRequired,
}

AdminMarket = connect(mapStateToProps, mapDispatchToProps)(AdminMarket)

export default AdminMarket
