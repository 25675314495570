import React, { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { useDevise } from './preferences'

export const Devise = () => {
  return (
    <ErrorBoundary FallbackComponent={() => <span>?</span>}>
      <Suspense fallback={<span>...</span>}>
        <DeviseContent />
      </Suspense>
    </ErrorBoundary>
  )
}

const DeviseContent = () => {
  const devise = useDevise()
  return <span>{devise}</span>
}
