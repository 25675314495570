import React from 'react'
import { useSelector } from 'react-redux'
import DropdownTreeSelect from 'react-dropdown-tree-select'

import { getUorTree } from 'reducers/organisation'

export const UorSelect = ({ value, label, onChange, disabled }) => {
  const data = useSelector(getUorTree(value))

  return (
    <DropdownTreeSelect
      mode="radioSelect"
      keepTreeOnSearch
      data={data}
      onChange={(node) => {
        if (value !== node.value) {
          onChange(node.label, node.value)
        }
      }}
      texts={{ placeholder: label }}
      css={{
        width: 200,
        fontSize: 13,
        '.dropdown': {
          width: '100%',
        },
        '.dropdown-trigger': {
          width: '100%',
          borderColor: '#ced4da !important',
          borderRadius: '0.25rem',
          padding: '6px 12px !important',
          '&.arrow.top:after, &.arrow.bottom:after': {
            display: 'none !important',
          },
          '.tag-item': {
            margin: 0,
            '.tag': {
              display: 'none',
            },
            input: {
              border: 0,
              '&:focus::placeholder': {
                color: '#ddd',
              },
              '&::placeholder': {
                color: '#495057',
              },
            },
          },
        },
        '.dropdown-content': {
          padding: '0 !important',
          '.infinite-scroll-component': {
            maxWidth: 400,
            maxHeight: 400,
            overflow: 'auto',
            padding: '4px 8px !important',
            '.node': {
              display: 'flex',
            },
            '.toggle': {
              font: 'normal bold 13px/1 eleneo',
              color: '#555',
              top: 3,
              position: 'relative',
              '&.collapsed::after': {
                content: '"\\6b"',
              },
              '&.toggle.expanded::after': {
                content: '"\\6a"',
              },
            },
          },
        },
      }}
    />
  )
}
