import { useQuery } from 'react-query'
import { request } from './request'

export const usePee = (id) => {
  const { data } = useQuery(id && ['pee', { id }], () =>
    Promise.all(
      [
        request({ url: `pees/${id}?withContrats=true` }),
        request({ url: `listeacheminement`, method: 'POST', body: id }),
      ],
      {
        suspense: true,
      }
    )
  )
  return {
    infos: data?.[0] ?? {},
    acheminement: data?.[1] ?? [],
  }
}

export const useToggleAlerteDepassementPS = (peeId, enabled) => {
    request(
      {
        url: `pees/${peeId}/enable-ps?enable=${enabled}`,
        method: 'PUT',
      },
      false
    )
}