import { combineReducers } from 'redux'

const login = () => {
  const error = (state = false, action) => {
    switch (action.type) {
      case 'USER_LOGIN_SUCCESS':
      case 'RESET_VIEW_TOGGLE':
        return false
      case 'USER_LOGIN_ERROR':
        return action.code
      default:
        return state
    }
  }

  const resetView = (state = false, action) => {
    switch (action.type) {
      case 'PASSWRD_RESET_SUCCESS':
        return false
      case 'RESET_VIEW_TOGGLE':
        return !state
      default:
        return state
    }
  }

  const resetPwd = (state = false, action) => {
    switch (action.type) {
      case 'USER_LOGIN_REQUEST':
      case 'RESET_VIEW_TOGGLE':
        return false
      case 'PASSWRD_RESET_SUCCESS':
        return true
      default:
        return state
    }
  }

  const resetPasswordStatus = (state = '', action) => {
    switch (action.type) {
      case 'RESET_PASSWORD_SUCCESS':
        return 'success'
      case 'RESET_PASSWORD_FAILURE':
        return action.code || 'passwordResetError'
      case 'RESET_PASSWORD_REQUEST':
        return ''
      default:
        return state
    }
  }

  const resetPasswordView = (state = false, action) => {
    switch (action.type) {
      case 'RESET_PASSWORD_SUCCESS':
        return true
      case 'RESET_PASSWORD_VIEW_TOGGLE':
        return false
      default:
        return state
    }
  }

  return combineReducers({
    error,
    resetView,
    resetPwd,
    resetPasswordStatus,
    resetPasswordView,
  })
}

export default login

export const getErrorLogin = (state) => state.login.error
export const getResetView = (state) => state.login.resetView
export const getResetPasswordView = (state) => state.login.resetPasswordView
export const getResetPwd = (state) => state.login.resetPwd
