import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { FormGroup, Label, Input } from 'reactstrap'
import Highlighter from 'react-highlight-words'

import { getPee, getPeePath } from '../../reducers/organisation'
import { getPeeIsOfFilterType, getFilterInput } from '../../reducers/searchPanel'
import { getIsCurrentPee } from '../../reducers/currentSelection'
import { togglePeeInSelection } from '../../actions/currentSelection'
import { getIconClassName } from '../../helpers/icon'

const Pee = ({
  dispatch,
  peeId,
  pee,
  isCurrent,
  togglePeeInSelection,
  peePath,
  peeIsOfFilterType,
  filterInput,
}) => (
  <span>
    {peeIsOfFilterType ? (
      <li>
        <FormGroup check>
          <Label check className={(isCurrent && 'checked') || ''}>
            <Input
              type="checkbox"
              value={peeId}
              checked={isCurrent}
              onChange={(e) => dispatch(togglePeeInSelection)}
            />
            <i className={getIconClassName(pee.PeeNrjId) + ' left-pad'} />
            <span title={peePath}>
              {filterInput ? (
                <Highlighter
                  highlightClassName="bg-primary"
                  searchWords={[filterInput]}
                  textToHighlight={pee.PeeClef}
                />
              ) : (
                pee.PeeClef
              )}
            </span>
          </Label>
        </FormGroup>
      </li>
    ) : null}
  </span>
)

const mapStateToProps = (state, { peeId }) => ({
  pee: getPee(state, peeId),
  isCurrent: getIsCurrentPee(state, peeId),
  togglePeeInSelection: togglePeeInSelection(state, peeId),
  peePath: getPeePath(state, peeId),
  peeIsOfFilterType: getPeeIsOfFilterType(state, peeId),
  filterInput: getFilterInput(state),
})

Pee.propTypes = {
  dispatch: PropTypes.func.isRequired,
  peeId: PropTypes.number.isRequired,
  pee: PropTypes.object.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  togglePeeInSelection: PropTypes.func.isRequired,
  peePath: PropTypes.string.isRequired,
  peeIsOfFilterType: PropTypes.bool.isRequired,
  filterInput: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(Pee)
