import { combineReducers } from 'redux'

const favorites = () => {
  const favoritesUuid = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_FAVORITES_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const postFavoriteUuid = (state = false, action) => {
    switch (action.type) {
      case 'POST_FAVORITE_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const delFavoriteUuid = (state = false, action) => {
    switch (action.type) {
      case 'DEL_FAVORITE_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const favoritesIds = (state = [], action) => {
    switch (action.type) {
      case 'DEL_FAVORITE_SUCCESS':
        return state.filter((e) => e !== action.response)
      case 'POST_FAVORITE_SUCCESS':
        return [...new Set([...state, action.response.result])]
      case 'FETCH_FAVORITES_SUCCESS':
        return [...new Set([...state, ...action.response.result])]
      default:
        return state
    }
  }

  const favoritesList = (state = {}, action) => {
    switch (action.type) {
      case 'DEL_FAVORITE_SUCCESS':
        let newState = { ...state }
        delete newState[action.response]
        return newState
      case 'POST_FAVORITE_SUCCESS':
      case 'FETCH_FAVORITES_SUCCESS':
        return {
          ...state,
          ...action.response.entities.favoritesItem,
        }
      default:
        return state
    }
  }

  const postFavoriteSuccess = (state = false, action) => {
    switch (action.type) {
      case 'POST_FAVORITE_REQUEST':
      case 'POST_FAVORITE_ERROR':
      case 'POST_FAVORITE_FAILURE':
        return false
      case 'POST_FAVORITE_SUCCESS':
        return true
      default:
        return state
    }
  }

  const postFavoriteError = (state = '', action) => {
    switch (action.type) {
      case 'POST_FAVORITE_ERROR':
      case 'POST_FAVORITE_FAILURE':
        return action.message || 'Something went wrong'
      case 'POST_FAVORITE_REQUEST':
      case 'POST_FAVORITE_SUCCESS':
        return ''
      default:
        return state
    }
  }

  const errorMessageFavorites = (state = null, action) => {
    switch (action.type) {
      case 'FETCH_FAVORITES_FAILURE':
        return action.message
      case 'FETCH_FAVORITES_REQUEST':
      case 'FETCH_FAVORITES_SUCCESS':
        return null
      default:
        return state
    }
  }

  return combineReducers({
    favoritesUuid,
    postFavoriteUuid,
    delFavoriteUuid,
    favoritesIds,
    favoritesList,
    postFavoriteSuccess,
    postFavoriteError,
    errorMessageFavorites,
  })
}

export default favorites

export const getFavoritesUuid = (state) => state.favorites.favoritesUuid
export const getPostFavoriteUuid = (state) => state.favorites.postFavoriteUuid
export const getDelFavoriteUuid = (state) => state.favorites.delFavoriteUuid
export const getFavoritesItem = (state, favId) => state.favorites.favoritesList[favId]
export const getFavoritesItemPees = (state, favId) =>
  state.favorites.favoritesList[favId].FavListePee
export const getFavoritesList = (state) =>
  state.favorites.favoritesIds.map((id) => getFavoritesItem(state, id))
export const getCountFavorites = (state) => state.favorites.favoritesIds.length
export const getErrorMessageFavorites = (state) => state.favorites.errorMessageFavorites
export const getFavoritePostSuccess = (state) => state.favorites.postFavoriteSuccess
export const getFavoritePostError = (state) => state.favorites.postFavoriteError
