import React from 'react'
import { Button } from 'reactstrap'
import { connect } from 'react-redux'
import { RiDownload2Line } from 'react-icons/ri'

import { postExtractBillList } from '../../api/bills'

const CsvDownload = ({ billIdList }) => {
  return (
    <Button
      color="primary"
      size="sm"
      onClick={() => {
        postExtractBillList(billIdList)
      }}
      css={{
        svg: {
          transform: 'scale(1.4) translateY(-1px)',
          marginRight: '0.8rem',
        },
      }}
    >
      <RiDownload2Line />
      Export CSV
    </Button>
  )
}

const mapStateToProps = (state) => ({
  billIdList: state.bills.list.billsIds,
})

export default connect(mapStateToProps)(CsvDownload)
