import { combineReducers } from 'redux'
import get from 'lodash/get'
import set from 'lodash/fp/set'

import {
  FETCH_MY_QUOTATIONS_SUCCESS,
  FETCH_MY_QUOTATIONS_FAILURE,
  ADD_MY_QUOTATIONS_SUCCESS,
  DELETE_MY_QUOTATION_SUCCESS,
  RESET_QUOTATION_GRAPH,
  FETCH_QUOTATION_GRAPH_SUCCESS,
  FETCH_QUOTATION_GRAPH_FAILURE,
  FETCH_MY_AVAILABLE_QUOTATIONS_SUCCESS,
  FETCH_MY_AVAILABLE_QUOTATIONS_FAILURE,
  TOGGLE_QUOTATION_BOLLINGER,
  TOGGLE_QUOTATION_TUNNEL,
} from '../actions'

const myAvailableQuotations = (state = [], action) => {
  switch (action.type) {
    case FETCH_MY_AVAILABLE_QUOTATIONS_SUCCESS:
      return action.quotations
    case FETCH_MY_AVAILABLE_QUOTATIONS_FAILURE:
    default:
      return state
  }
}

const userQuotations = (state = null, action) => {
  let newState
  switch (action.type) {
    case FETCH_MY_QUOTATIONS_SUCCESS:
      newState = action.quotations
      return newState
    case ADD_MY_QUOTATIONS_SUCCESS:
      newState = [...state, ...action.quotations]
      return newState
    case DELETE_MY_QUOTATION_SUCCESS:
      newState = state.filter((q) => q.Id !== action.quotationId)
      return newState
    case FETCH_MY_QUOTATIONS_FAILURE:
      return []
    default:
      return state
  }
}

const userQuotationOptions = (state = {}, action) => {
  switch (action.type) {
    case TOGGLE_QUOTATION_BOLLINGER: {
      const b = get(state, [action.id, 'BollingerBand'], false)
      return set([action.id, 'BollingerBand'], !b)(state)
    }
    case TOGGLE_QUOTATION_TUNNEL: {
      const b = get(state, [action.id, 'TunnelMensuel'], false)
      return set([action.id, 'TunnelMensuel'], !b)(state)
    }
    default:
      return state
  }
}

const userQuotationGraph = (state = {}, action) => {
  let newState
  switch (action.type) {
    case FETCH_QUOTATION_GRAPH_SUCCESS:
      newState = action.graph
      return newState
    case RESET_QUOTATION_GRAPH:
      newState = { ...state }
      newState[action.quotationId] = null
      return newState
    case FETCH_QUOTATION_GRAPH_FAILURE:
      newState = { ...state }
      newState[action.quotationId] = {}
      return newState
    default:
      return state
  }
}

export default combineReducers({
  myAvailableQuotations,
  userQuotations,
  userQuotationOptions,
  userQuotationGraph,
})

// Selectors

export const getMarketQuotationGraph = (state) => state.quotations.userQuotationGraph || null

export const getUserQuotationBollingerOption = (id) => (state) =>
  get(state, ['quotations', 'userQuotationOptions', id, 'BollingerBand'], false)

export const getUserQuotationTunnelOption = (id) => (state) =>
  get(state, ['quotations', 'userQuotationOptions', id, 'TunnelMensuel'], false)

export const getUserQuotationsWithOptions = (ids) => (state) => {
  return ids.map((Id) => ({
    Id,
    TunnelMensuel: getUserQuotationTunnelOption(Id)(state),
    BollingerBand: getUserQuotationBollingerOption(Id)(state),
  }))
}
