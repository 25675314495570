import { updateView } from './views'

export const toggleTypeDonnee = (typeDonnee) => (dispatch) => {
  dispatch({
    type: 'TOGGLE_TYPE_DONNEE',
    typeDonnee,
  })

  dispatch(updateView({ updated: 'type' }))
}
