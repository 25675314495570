import React from 'react'
import matchSorter from 'match-sorter'
import { useCombobox } from 'downshift'

export const ComboboxFilter = ({ items, onChange }) => {
  const [inputItems, setInputItems] = React.useState(items)
  React.useEffect(() => setInputItems(items), [items])

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    reset,
    toggleMenu,
    inputValue,
  } = useCombobox({
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      setInputItems(
        matchSorter(items, inputValue, {
          threshold: matchSorter.rankings.CONTAINS,
        })
      )
      setTimeout(() => onChange(inputValue))
    },
  })

  return (
    <>
      <div
        {...getComboboxProps()}
        css={{
          width: '100%',
          display: 'flex',
          position: 'relative',
          input: {
            width: '100%',
            ...(inputValue !== '' && { paddingRight: '23px !important' }),
          },
          button: {
            fontSize: 6,
            width: 16,
            height: 16,
            padding: 0,
            position: 'absolute',
            right: 5,
            top: '50%',
            transform: 'translateY(-50%)',
            border: 0,
            background: 'transparent',
          },
        }}
      >
        <input {...getInputProps()} onFocus={toggleMenu} />

        {inputValue !== '' && (
          <button onClick={reset}>
            <i className="icon-cross" />
          </button>
        )}
      </div>

      <ul
        {...getMenuProps()}
        className="border shadow bg-light"
        css={{
          margin: 0,
          position: 'absolute',
          width: 'calc(100% - 10px)',
          maxHeight: 320,
          overflowX: 'hidden',
          zIndex: 1,
          listStyle: 'none',
          padding: 0,
          ...(!isOpen && { opacity: 0 }),
          li: {
            textAlign: 'left',
            padding: 4,
            whiteSpace: 'normal',
            '&:not(:last-child)': {
              borderBottom: '1px solid #aaaaaa22',
            },
          },
        }}
      >
        {isOpen &&
          inputItems.map((item, index) => (
            <li
              style={highlightedIndex === index ? { backgroundColor: '#bde4ff' } : {}}
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {item}
            </li>
          ))}
      </ul>
    </>
  )
}
