import { combineReducers } from 'redux'

const sidebarMarket = () => {
  const sidebarMarketIds = (state = [], action) => {
    switch (action.type) {
      case 'FETCH_SIDEBAR_MARKET_SUCCESS':
        return [...new Set([...state, ...action.response.result])]
      default:
        return state
    }
  }

  const sidebarMarketUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_SIDEBAR_MARKET_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const errorMessageSidebarMarket = (state = null, action) => {
    switch (action.type) {
      case 'FETCH_SIDEBAR_MARKET_FAILURE':
        return action.message
      case 'FETCH_SIDEBAR_MARKET_REQUEST':
      case 'FETCH_SIDEBAR_MARKET_SUCCESS':
        return null
      default:
        return state
    }
  }

  const sidebarMarketInfos = (state = {}, action) => {
    switch (action.type) {
      case 'FETCH_SIDEBAR_MARKET_SUCCESS':
        if (action.response) {
          return {
            ...state,
            ...action.response.entities.sidebarMarketInfo,
          }
        }
        return state
      default:
        return state
    }
  }

  return combineReducers({
    sidebarMarketInfos,
    sidebarMarketIds,
    sidebarMarketUuid,
    errorMessageSidebarMarket,
  })
}

export default sidebarMarket

export const getSidebarMarketInfo = (state, id) => state.sidebarMarket.sidebarMarketInfos[id]
export const getSidebarMarketInfos = (state) =>
  state.sidebarMarket.sidebarMarketIds.map((id) => getSidebarMarketInfo(state, id))
export const getSidebarMarketUuid = (state) => state.sidebarMarket.sidebarMarketUuid
export const getErrorMessageSidebarMarket = (state) => state.sidebarMarket.errorMessageSidebarMarket
