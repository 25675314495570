import React from 'react'
import { formatPercent } from './format'
import { getColor } from 'helpers/colors'

export const PercentCell = ({ value, digits = 0, index }) => {
  const color1 = index === null ? '#FF000020' : getColor(index, 0.2)
  const color2 = index === null ? '#FF000030' : getColor(index, 0.3)
  return (
    <>
      <div
        className="rounded"
        style={{
          position: 'absolute',
          right: 0,
          top: 0,
          height: 'calc(100% - 4px)',
          width: `calc(${value * 100}% - 4px)`,
          background: `linear-gradient(90deg, ${color1} 0%, ${color2} 100%)`,
          margin: 2,
        }}
      />

      <div
        style={{
          position: 'relative',
          textAlign: 'right',
          textShadow:
            '1px 1px 1px rgba(255, 255, 255, 0.90), -1px 1px 1px rgba(255, 255, 255, 0.90), 1px -1px 1px rgba(255, 255, 255, 0.90), -1px -1px 1px rgba(255, 255, 255, 0.90)',
        }}
      >
        {formatPercent(value, digits)}
      </div>
    </>
  )
}
