export const formatPercent = (n, digits, csv = false) => {
  if (n == null) return csv ? '' : '\u2013'
  return new Intl.NumberFormat('fr-FR', {
    style: csv ? 'decimal' : 'percent',
    useGrouping: !csv,
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(csv ? n * 100 : n)
}

export const formatNoDecimal = (n, csv = false) => {
  if (n == null) return csv ? '' : '\u2013'
  return new Intl.NumberFormat('fr-FR', {
    style: 'decimal',
    useGrouping: !csv,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(n)
}

export const formatDecimal = (n, csv = false) => {
  if (n == null) return csv ? '' : '\u2013'
  return new Intl.NumberFormat('fr-FR', {
    style: 'decimal',
    useGrouping: !csv,
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(n)
}
