import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'reactstrap'

import FavoritesItem from './FavoritesItem'

const FavoritesList = ({ favoritesList, ...rest }) => (
  <Form>
    {favoritesList.map((favoritesItem) => (
      <FavoritesItem
        key={favoritesItem.id}
        favId={favoritesItem.id}
        favNom={favoritesItem.FavNom}
        {...rest}
      />
    ))}
    <FavoritesItem favId={-1} favNom="all" {...rest} />
  </Form>
)

FavoritesList.propTypes = {
  favoritesList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      favNom: PropTypes.string,
    }).isRequired
  ).isRequired,
}

export default FavoritesList
