import { combineReducers } from 'redux'
import { reducer as oidc } from 'redux-oidc'
import { reducer as notifications } from 'react-notification-system-redux'
import { apiStatusReducer, userReducer } from 'commons'

import sidebarMarket from './sidebarMarket'
import favorites from './favorites'
import dateRange from './dateRange'
import typeDonnee from './typeDonnee'
import { typeDonneeFac } from './typeDonnee'
import searchPanel from './searchPanel'
import organisation from './organisation'
import currentSelection from './currentSelection'
import powerConsumption from './powerConsumption'
import consumption from '../store/consumption'
import expenses from './expenses'
import market from './market'
import bills from './bills'
import pdf from './pdf'
import contracts from './contracts'
// import user from './user';
import login from './login'
import adminMarket from './adminMarket'
import countries from './countries'
import hrs from '../store/hrs'
import { comex } from '../store/comex'
import quotations from './quotations'

const appReducers = combineReducers({
  oidc,
  apiStatus: apiStatusReducer,
  user: userReducer,
  notifications,
  dateRange,
  typeDonnee,
  typeDonneeFac,
  searchPanel,
  organisation: organisation(),
  favorites: favorites(),
  sidebarMarket: sidebarMarket(),
  currentSelection,
  powerConsumption: powerConsumption(),
  consumption,
  expenses: expenses(),
  market: market(),
  //	user: user(),
  bills: bills(),
  pdf,
  contracts: contracts(),
  login: login(),
  adminMarket: adminMarket(),
  countries: countries,
  hrs,
  quotations,
  comex,
})

const rootReducer = (state, action) => {
  if (action.type === 'eview/LOAD_PRESISTED_STATE') {
    state = {
      ...action.persistedState,
      oidc: state.oidc,
    }
  }

  return appReducers(state, action)
}

export default rootReducer
