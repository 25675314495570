import React from 'react'
import { connect } from 'react-redux'

import { fetchPdfs } from '../../actions/pdf'
import { getPdfs } from '../../reducers/pdf'
import { getCurrentBillFormId } from '../../reducers/bills'
import BillPdf from './BillPdf'
import BillPdfUpload from './BillPdfUpload'

const BillPdfList = ({ fetchPdfs, pdfs, billId }) => {
  React.useEffect(() => {
    if (billId === 0) return
    fetchPdfs()
  }, [billId, fetchPdfs])

  const list = pdfs ? Object.values(pdfs) : []

  if (list.length === 0) return <BillPdfUpload />

  return (
    <ul>
      {list.map((pdf) => (
        <BillPdf key={pdf.id} id={pdf.id} number={pdf.fileName} />
      ))}
    </ul>
  )
}

const mapState = (state) => ({
  billId: getCurrentBillFormId(state),
  pdfs: getPdfs(state),
})

const mapDispatch = {
  fetchPdfs,
}

export default connect(mapState, mapDispatch)(BillPdfList)
