import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Label, Input } from 'reactstrap'

import { postBillFile } from '../../actions/bills'

class BillFileUpload extends Component {
  constructor(props) {
    super(props)

    this.uploadFile = this.uploadFile.bind(this)
  }

  uploadFile(e) {
    const { dispatch } = this.props
    dispatch(postBillFile(e.target.files[0]))
  }

  render() {
    const { t } = this.props
    return (
      <Label className="custom-file">
        <Input type="file" className="custom-file-input" onChange={(e) => this.uploadFile(e)} />+{' '}
        {t('bills.bill.addFile')}
      </Label>
    )
  }
}

export default withTranslation()(connect()(BillFileUpload))
