import merge from 'lodash/merge'
import { combineReducers } from 'redux'
import moment from 'moment'

const bills = () => {
  const billsListUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_BILLS_LIST_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const billUuid = (state = '', action) => {
    switch (action.type) {
      case 'FETCH_BILL_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const putBillUuid = (state = '', action) => {
    switch (action.type) {
      case 'PUT_BILL_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const postBillFileUuid = (state = '', action) => {
    switch (action.type) {
      case 'BILL_FILE_POST_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const deleteBillFileUuid = (state = '', action) => {
    switch (action.type) {
      case 'BILL_FILE_DELETE_REQUEST':
        return action.uuid
      default:
        return state
    }
  }

  const list = (state = { loading: false, error: null }, action) => {
    switch (action.type) {
      case 'FETCH_BILLS_LIST_REQUEST':
        return {
          ...state,
          loading: true,
          error: null,
        }
      case 'FETCH_BILLS_LIST_SUCCESS':
        return {
          ...state,
          ...action.response.entities,
          billsIds: action.response.result,
          loading: false,
          error: null,
        }
      case 'FETCH_BILLS_LIST_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.message,
        }
      case 'PUT_BILL_SUCCESS':
        let newState = { ...state }
        newState.bill = { ...state.bill }
        newState.bill[action.billId] = {
          ...newState.bill[action.billId],
          FacNoteContenu: action.response.FacNoteContenu,
          FacNoteExiste: action.response.FacNoteExiste,
          FacDate: action.response.FacDate,
          FacDatePaiement: action.response.FacDatePaiement,
        }
        return newState
      default:
        return state
    }
  }

  const sortList = (state = [], action) => {
    switch (action.type) {
      case 'FETCH_BILLS_LIST_SUCCESS':
        return action.response.result || []
      case 'SORT_BILLS_LIST':
        switch (action.sort.col) {
          case 'SphNom':
            return Object.keys(action.list.bill)
              .map((i) => action.list.bill[i])
              .sort((a, b) =>
                a.FacSite.SphNom > b.FacSite.SphNom ? action.sort.dir : -action.sort.dir
              )
              .map((i) => i.id)
          case 'FacClef':
          case 'FacFournisseur':
          case 'FacMontantHTVA':
          case 'FacEtat':
          case 'FacNoteExiste':
            return Object.keys(action.list.bill)
              .map((i) => action.list.bill[i])
              .sort((a, b) =>
                a[action.sort.col] > b[action.sort.col] ? action.sort.dir : -action.sort.dir
              )
              .map((i) => i.id)
          case 'FacNrjId':
            return Object.keys(action.list.bill)
              .map((i) => action.list.bill[i])
              .sort((a, b) =>
                a[action.sort.col] > b[action.sort.col] ? -action.sort.dir : action.sort.dir
              )
              .map((i) => i.id)
          case 'FacDate':
            return Object.keys(action.list.bill)
              .map((i) => action.list.bill[i])
              .sort(
                (a, b) =>
                  moment(a.FacDate, 'DD/MM/YYYY').diff(moment(b.FacDate, 'DD/MM/YYYY')) *
                  action.sort.dir
              )
              .map((i) => i.id)
          case 'FacPee':
            return Object.keys(action.list.bill)
              .map((i) => action.list.bill[i])
              .sort((a, b) =>
                a.FacPerimetre.PeeClef < b.FacPerimetre.PeeClef ? -action.sort.dir : action.sort.dir
              )
              .map((i) => i.id)
          default:
            return action.list.billsIds
        }
      default:
        return state
    }
  }

  const sortState = (state = { col: '', dir: '' }, action) => {
    switch (action.type) {
      case 'SORT_BILLS_LIST':
        return action.sort
      default:
        return state
    }
  }

  const bills = (state = {}, action) => {
    switch (action.type) {
      case 'PUT_BILL_SUCCESS':
      case 'FETCH_BILL_SUCCESS':
        let newState = { ...state }
        newState[action.billId] = action.response
        return newState
      default:
        return state
    }
  }

  const currentBillForm = (state = {}, action) => {
    switch (action.type) {
      case 'BILL_FORM_UPDATE':
        let newState = { ...state }
        return merge(newState, action.update)
      case 'BILL_FORM_UPDATESTATUS':
        return { ...state, FacStatut: parseInt(action.FacStatut, 10) }
      case 'PUT_BILL_SUCCESS':
      case 'FETCH_BILL_SUCCESS':
        return action.response
      case 'BILL_FILE_POST_SUCCESS':
        const docAdd = [...(state.FacDocsJoints || []), ...action.response]
        return { ...state, FacDocsJoints: docAdd }
      case 'BILL_FILE_DELETE_SUCCESS':
        const docDel = state.FacDocsJoints.filter((i) => i.id !== action.fileId)
        return { ...state, FacDocsJoints: docDel }
      default:
        return state
    }
  }

  const billPutSuccess = (state = false, action) => {
    switch (action.type) {
      case 'FETCH_BILL_SUCCESS':
      case 'BILL_FORM_UPDATE':
        return false
      case 'PUT_BILL_SUCCESS':
        return true
      default:
        return state
    }
  }

  const billPutError = (state = '', action) => {
    switch (action.type) {
      case 'PUT_BILL_ERROR':
      case 'PUT_BILL__FAILURE':
        return action.message || 'Something went wrong'
      case 'PUT_BILL_SUCCESS':
      case 'FETCH_BILL_SUCCESS':
      case 'BILL_FORM_UPDATE':
      case 'PUT_BILL_REQUEST':
      case 'FETCH_BILLS_LIST_REQUEST':
      case 'FETCH_BILL_REQUEST':
        return ''
      default:
        return state
    }
  }

  const filters = (state = {}, action) => {
    switch (action.type) {
      case 'SET_BILLS_FILTER':
        return {
          ...state,
          [action.key]: action.value,
        }
      default:
        return state
    }
  }

  return combineReducers({
    billsListUuid,
    billUuid,
    putBillUuid,
    postBillFileUuid,
    deleteBillFileUuid,
    list,
    sortList,
    sortState,
    bills,
    currentBillForm,
    billPutSuccess,
    billPutError,
    filters,
  })
}

export default bills

export const getBillsListUuid = (state) => state.bills.billsListUuid
export const getBillUuid = (state) => state.bills.billUuid
export const getPutBillUuid = (state) => state.bills.putBillUuid
export const getBillsIds = (state) => state.bills.list.billsIds || []
export const getBillsList = (state) => state.bills.list.bill || {}
export const getBillById = (state, id) => state.bills.bills[id] || {}
export const getCurrentBillForm = (state) => state.bills.currentBillForm || {}
export const getCurrentBillFormId = (state) => state.bills.currentBillForm.id || 0
export const getBillPutSuccess = (state) => state.bills.billPutSuccess
export const getBillPutError = (state) => state.bills.billPutError
export const getPostBillFileUuid = (state) => state.bills.postBillFileUuid
export const getDeleteBillFileUuid = (state) => state.bills.deleteBillFileUuid
export const getSortedBillsIds = (state) => state.bills.sortList
export const getSortState = (state) => state.bills.sortState
export const getFilters = (state) => state.bills.filters

export const getBillsLoading = (state) => state.bills.list.loading
export const getBillsError = (state) => state.bills.list.error
