import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Row, Col, Form, FormGroup, Label, Input } from 'reactstrap'
import debounce from 'lodash/debounce'

import { toggleFilterType, countryFilterChange, filterInputChange } from '../../actions/searchPanel'
import { getFilterType, getFilterInput } from '../../reducers/searchPanel'
import { countries } from '../../actions/countries'

class Filtres extends Component {
  componentDidMount() {
    this.props.dispatch(countries())
    this.filterInput = debounce(this.filterInput, 500)
  }

  handleOnChange(e) {
    this.filterInput(e.target.value)
  }

  filterInput(value) {
    this.props.dispatch(filterInputChange(value))
  }

  render() {
    return (
      <div>
        <Row>
          <Col>
            <FormGroup className="text-input">
              <Input
                className={this.props.filterInput === '' ? '' : 'has-value'}
                id="searchFilterText"
                onChange={(e) => this.handleOnChange(e)}
              />
              <Label for="searchFilterText">{this.props.t('panel.placeholder')}</Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label id="country-label" for="country-select">
                {this.props.t('panel.country')}
              </Label>
              <Input
                type="select"
                name="select"
                id="country-select"
                onChange={(e) =>
                  this.props.dispatch(
                    countryFilterChange(
                      e.target.value === 'all' ? e.target.value : parseInt(e.target.value, 10)
                    )
                  )
                }
              >
                <option value="all">{this.props.t('panel.noCountrySelection')}</option>
                {this.props.countries.map((country) => {
                  return (
                    <option key={country.id} value={country.id}>
                      {country.libelle}
                    </option>
                  )
                })}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row className="radio-input-tabs">
          <Col className="d-flex">
            <Form inline>
              <FormGroup className="searchFilterEnergy">
                <Input
                  id="searchFilterEnergyAll"
                  type="radio"
                  name="searchFilterEnergy"
                  checked={this.props.filterType === 'all'}
                  value="all"
                  onChange={() => this.props.dispatch(toggleFilterType('all'))}
                />
                <Label for="searchFilterEnergyAll">{this.props.t('panel.all')}</Label>
              </FormGroup>
              <FormGroup className="searchFilterEnergy">
                <Input
                  id="searchFilterEnergyElec"
                  type="radio"
                  name="searchFilterEnergy"
                  value="2"
                  checked={this.props.filterType === 2}
                  onChange={() => this.props.dispatch(toggleFilterType(2))}
                />
                <Label for="searchFilterEnergyElec">
                  <i className="icon-elec" />
                  <span>{this.props.t('panel.elec')}</span>
                </Label>
              </FormGroup>
              <FormGroup className="searchFilterEnergy">
                <Input
                  id="searchFilterEnergyGaz"
                  type="radio"
                  name="searchFilterEnergy"
                  value="1"
                  checked={this.props.filterType === 1}
                  onChange={() => this.props.dispatch(toggleFilterType(1))}
                />
                <Label for="searchFilterEnergyGaz">
                  <i className="icon-gaz" />
                  <span>{this.props.t('panel.gas')}</span>
                </Label>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </div>
    )
  }
} //({dispatch, filterInput, filterType, t}) =>

const mapStateToProps = (state) => ({
  filterInput: getFilterInput(state),
  filterType: getFilterType(state),
  countries: state.countries,
})

Filtres.propTypes = {
  dispatch: PropTypes.func.isRequired,
  filterInput: PropTypes.string.isRequired,
  filterType: PropTypes.any.isRequired,
  countries: PropTypes.any.isRequired,
}

export default withTranslation()(connect(mapStateToProps)(Filtres))
