import { apiConf as api } from 'commons'

export const fetchContractsList = (uuid, dateDebut, dateFin, perimetre, lanId) => {
  if (!perimetre) {
    return Promise.resolve()
  }

  return api.superFetch({
    url: `listecontratvuectr?date_debut=${dateDebut}&date_fin=${dateFin}&lanId=${lanId}`,
    method: 'POST',
    body: perimetre,
    uuid: uuid,
  })
}

export const fetchContract = (uuid, ctrid, dateDebut, dateFin, lanId) => {
  if (!ctrid) {
    return Promise.resolve()
  }

  return api.superFetch({
    url: `contratvuectr?ctrid=${ctrid}&date_debut=${dateDebut}&date_fin=${dateFin}&lanId=${lanId}`,
    uuid: uuid,
  })
}

export const fetchMetersList = (uuid, dateDebut, dateFin, perimetre, lanId) => {
  if (!perimetre) {
    return Promise.resolve()
  }

  return api.superFetch({
    url: `listecontratvuepee?date_debut=${dateDebut}&date_fin=${dateFin}&lanId=${lanId}`,
    method: 'POST',
    body: perimetre,
    uuid: uuid,
  })
}

export const fetchMeter = (uuid, peeid, dateDebut, dateFin, lanId) => {
  if (!peeid) {
    return Promise.resolve()
  }

  return api.superFetch({
    url: `contratvuepee?peeid=${peeid}&date_debut=${dateDebut}&date_fin=${dateFin}&lanId=${lanId}`,
    uuid: uuid,
  })
}
