import PropTypes from 'prop-types'
import React from 'react'
import { CardText } from 'reactstrap'

import { decimalFormat, noDecimalFormat } from '../../helpers/helpers'
import KpiIndicator from './KpiIndicator'

const KpiSecondary = ({
  Libelle,
  PourcentageEcart,
  Valeur,
  Unite,
  decimal,
  isProgression = true,
}) => (
  <CardText>
    <span className="label">{Libelle}</span>
    <span className="float-right">
      <span className="actual-value">
        {decimal ? decimalFormat(Valeur) : noDecimalFormat(Valeur, 10)}
        <small>{Unite}</small>
      </span>
      {PourcentageEcart ? (
        <span className="percent-value">
          <span className="value">{decimalFormat(PourcentageEcart)}</span>
          <span className="unit">{decimalFormat(PourcentageEcart) !== '-' ? '%' : ''}</span>
          {isProgression && <KpiIndicator pourcentage={PourcentageEcart} />}
        </span>
      ) : null}
    </span>
  </CardText>
)

KpiSecondary.propTypes = {
  Libelle: PropTypes.string.isRequired,
  PourcentageEcart: PropTypes.number,
  Valeur: PropTypes.number,
  Unite: PropTypes.string.isRequired,
  decimal: PropTypes.bool,
}

export default KpiSecondary
