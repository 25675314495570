import throttle from 'lodash/throttle'
import get from 'lodash/get'

const STATE_KEY_NAME = 'eview-state'
const EID_KEY_NAME = 'eview-eid'

const getEid = (state) => get(state, 'oidc.user.profile.eid', null)

const loadState = () => {
  try {
    const eid = localStorage.getItem(EID_KEY_NAME)
    const serializedState = localStorage.getItem(STATE_KEY_NAME)
    if (serializedState === null || eid === null) return null
    const state = JSON.parse(serializedState)
    return { eid, state }
  } catch (error) {
    console.error(error)
    return null
  }
}

const saveState = (eid, state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(EID_KEY_NAME, eid)
    localStorage.setItem(STATE_KEY_NAME, serializedState)
  } catch (err) {
    console.error(err)
  }
}

export const persist = (store) => {
  store.subscribe(
    throttle(() => {
      const state = store.getState()
      const eid = getEid(state)
      if (state.oidc.user && !state.oidc.user.expired) {
        const loadCurve = { ...store.getState().powerConsumption.loadCurve }
        delete loadCurve.loadCurveGraph

        const powerConsumption = {
          ...store.getState().powerConsumption,
          loadCurve,
        }
        delete powerConsumption.conso

        saveState(eid, {
          dateRange: store.getState().dateRange,
          typeDonnee: store.getState().typeDonnee,
          currentSelection: store.getState().currentSelection,
          organisation: store.getState().organisation,
          favorites: store.getState().favorites,
          sidebarMarket: store.getState().sidebarMarket,
          powerConsumption,
          expenses: store.getState().expenses,
        })
      }
    }, 1000)
  )

  const persisted = loadState()

  if (persisted !== null) {
    const { eid, state: persistedState } = persisted

    let unsubscribe = null

    const handleEid = () => {
      const isSameEid = getEid(store.getState()) === eid
      const isCallback = window.location.pathname === '/callback'
      if (isSameEid && !isCallback) {
        unsubscribe()
        store.dispatch({ type: 'eview/LOAD_PRESISTED_STATE', persistedState })
      }
    }

    unsubscribe = store.subscribe(handleEid)
  }
}
