import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { Row, Col, Table, Badge } from 'reactstrap'
import { Fdp } from './Fdp'

const MeterLot = ({
  id,
  LotNrj,
  LotDateDebut,
  LotDateFin,
  LotNom,
  LotType,
  LotTypeFormulePrix,
  LotHorosaisonnier,
  LotVolume,
  LotFournisseur,
  ParametresPrix,
  LotPeeRef,
  t,
}) => {
  return (
    <div>
      <Row>
        <Col>
          <Badge>{LotNom}</Badge>
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <h4>{t('contracts.meter.lot.infos')}</h4>
          <Table bordered>
            <tbody>
              <tr>
                <td>{t('contracts.meter.lot.from')}</td>
                <td>{LotDateDebut}</td>
              </tr>
              <tr>
                <td>{t('contracts.meter.lot.to')}</td>
                <td>{LotDateFin}</td>
              </tr>
              <tr>
                <td>{t('contracts.meter.lot.provider')}</td>
                <td>{LotFournisseur}</td>
              </tr>
              <tr>
                <td>{t('contracts.meter.lot.type')}</td>
                <td>{LotType}</td>
              </tr>
              <tr>
                <td>{t('contracts.meter.lot.formprice')}</td>
                <td>{LotTypeFormulePrix}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col sm="8">
          <h4>{t('contracts.meter.lot.pricetitle')}</h4>
          <Fdp id={id} />
        </Col>
      </Row>
    </div>
  )
}

MeterLot.propTypes = {
  id: PropTypes.number.isRequired,
  LotNrj: PropTypes.string.isRequired,
  LotDateDebut: PropTypes.string.isRequired,
  LotDateFin: PropTypes.string.isRequired,
  LotNom: PropTypes.string.isRequired,
  LotType: PropTypes.string.isRequired,
  LotTypeFormulePrix: PropTypes.string.isRequired,
  LotHorosaisonnier: PropTypes.string.isRequired,
  LotVolume: PropTypes.number.isRequired,
  LotFournisseur: PropTypes.string.isRequired,
  ParametresPrix: PropTypes.arrayOf(
    PropTypes.shape({
      ParamPrixLibelle: PropTypes.string.isRequired,
      ParamPrixValeur: PropTypes.string.isRequired,
    })
  ).isRequired,
  LotPeeRef: PropTypes.object.isRequired,
}

export default withTranslation()(MeterLot)
