import moment from 'moment'

const SET_DATE = 'esite/comex/SET_DATE'
const SET_NRJ = 'esite/comex/SET_NRJ'

export const comex = (
  state = {
    date: moment()
      .startOf('month')
      .format('YYYY-MM'),
    nrj: 2,
  },
  action
) => {
  switch (action.type) {
    case SET_DATE:
      return {
        ...state,
        date: action.payload,
      }
    case SET_NRJ:
      return {
        ...state,
        nrj: action.payload,
      }
    default:
      return state
  }
}

export const setDate = (payload) => ({ type: SET_DATE, payload })
export const setNrj = (payload) => ({ type: SET_NRJ, payload })

export const getDate = (state) => state.comex.date
export const getNrj = (state) => state.comex.nrj
