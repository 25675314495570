import React, { Component } from 'react'
import { Container, Button, FormGroup, Input, Label } from 'reactstrap'
import { withTranslation } from 'react-i18next'

import Security from './Security'
import UserInfos from './UserInfos'

class UserPanel extends Component {
  constructor(props) {
    super(props)
    this.state = this.state = {
      showSecurityPanel: '0',
    }
  }

  setSecurityPanel = (e) => {
    this.setState({ showSecurityPanel: e.target.value })
  }

  render() {
    const { userPanelOpen, setUserPanelOpen } = this.props

    return (
      <div>
        <Container className={['user-panel', userPanelOpen ? 'open' : ''].join(' ')} fluid>
          <div className="radio-input-btn panel-choice">
            <FormGroup>
              <Input
                id="showUser"
                type="radio"
                name="userLan"
                checked={this.state.showSecurityPanel === '0'}
                value="0"
                onChange={this.setSecurityPanel}
              />
              <Label for="showUser" className="btn">
                Infos utilisateurs{' '}
              </Label>
            </FormGroup>
            <FormGroup>
              <Input
                id="showSecurity"
                type="radio"
                name="userLan"
                checked={this.state.showSecurityPanel === '1'}
                value="1"
                onChange={this.setSecurityPanel}
              />
              <Label for="showSecurity" className="btn">
                Sécurité
              </Label>
            </FormGroup>
          </div>
          <Button
            className="close-panel"
            color="light"
            onClick={() => setUserPanelOpen(!userPanelOpen)}
          >
            <i className="icon-cross" />
          </Button>
          {this.state.showSecurityPanel === '0' ? (
            <UserInfos {...this.props} />
          ) : (
            <Security {...this.props} />
          )}
        </Container>
        <div className="user-panel-overlay" onClick={() => setUserPanelOpen(!userPanelOpen)} />
      </div>
    )
  }
}

export default withTranslation()(UserPanel)
