import React, { Component } from 'react'
import { connect } from 'react-redux'
import HighchartsReact from 'highcharts-react-official'
import { Highstock } from 'helpers/highcharts'
import moment from 'moment-timezone'
import { withTranslation } from 'react-i18next'
import isArray from 'lodash/isArray'

import {
  getLoadCurveGraph,
  getLoadCurveGraphUnite,
  getLoadCurveGraphEnergy,
  getLoadCurveGraphHrs,
  getGraphLoading,
  getGraphError,
  getPlotBandsLegend,
} from '../../reducers/powerConsumption/loadCurve'
import * as actions from '../../actions/powerConsumption/loadCurve'

import { getColor } from 'helpers/colors'
import { decimalFormatCsv } from 'helpers/formatters'
import downloadFile from 'helpers/downloadFile'
import { Loader } from 'components/common/Loader'
import styles from './LoadCurve.module.scss'

const getUniteLabel = (unit) =>
  unit === '1' ? 'kW' : unit === '11' ? 'kWh' : unit === '2' ? 'MW' : unit === '12' ? 'MWh' : ''

const createConfig = (graph, unite, hrs, energy, plotBandsLegend) => {
  if (!graph || !graph.series) return { title: { text: '' } }

  const xAxis = {
    type: 'datetime',
  }

  const showHrs = ['', '2'].includes(energy)

  if (showHrs && graph.xAxis) {
    const { plotBands } = graph.xAxis
    xAxis.plotBands = plotBands.map(({ from, to, id }) => ({
      from,
      to,
      color: getColor(id * 3, 0.2),
    }))
  }

  const series = graph.series.map((serie, index) => {
    const base = {
      ...serie,
      showInLegend: true,
      id: index,
      color: getColor(index, 1),
      connectNulls: false,
    }

    const [a, b] = serie.data
    const pointInterval = b.x - a.x
    const DAY = 86400000

    const data =
      pointInterval < DAY
        ? {
            data: serie.data.map((point) => point.y),
            pointStart: serie.data[0].x,
            pointInterval,
          }
        : {
            data: serie.data.map((p) => [p.x, p.y]),
          }

    return {
      ...base,
      ...data,
    }
  })

  return {
    title: {
      text: null,
    },
    xAxis,
    yAxis: {
      title: {
        text: null,
      },
    },
    chart: {
      type: 'line',
      height: '500px',
      zoomType: 'xy',
    },
    time: {
      useUTC: true,
      getTimezoneOffset: (timestamp) => -moment.tz(timestamp, 'Europe/Paris').utcOffset(),
    },
    legend: {
      enabled: true,
    },
    tooltip: {
      xDateFormat: '%d/%m/%Y %H:%M',
      pointFormat: `{point.series.name} : <b>{point.y:.2f} ${getUniteLabel(unite)}</b><br/>`,
      // crosshairs: true,
      // shared: true,
    },
    plotOptions: {
      series: {
        connectNulls: false,
        animation: false,
        lineWidth: 0.7,
        states: {
          hover: {
            lineWidth: 0.7,
          },
        },
        dataGrouping: {
          enabled: false,
        },
      },
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    series,
    exporting: {
      enabled: true,
      sourceWidth: 800,
      sourceHeight: 400,
      buttons: {
        contextButton: {
          menuItems: [
            {
              textKey: 'downloadJPEG',
              onclick: function() {
                this.exportChart({ type: 'image/jpeg' })
              },
            },
            {
              textKey: 'downloadPDF',
              onclick: function() {
                this.exportChart({ type: 'application/pdf' })
              },
            },
            {
              textKey: 'downloadCSV',
              onclick: function() {
                const plotBands = graph.xAxis ? graph.xAxis.plotBands : []
                const { min, max } = this.xAxis[0].getExtremes()
                const showHrs = energy === '2' && !!hrs && plotBands.length > 0

                const head = [
                  '',
                  ...series.map((serie) => serie.name),
                  ...(showHrs ? ['Poste'] : []),
                ].join(';')

                const { data, pointStart, pointInterval } = series[0]
                const body = data.reduce((acc, point, i) => {
                  const x = isArray(point) ? point[0] : pointStart + pointInterval * i

                  if (x < min || x > max) return acc

                  const getPost = () => {
                    const res = plotBands.find(({ from, to }) => x >= from && x <= to)
                    const postId = res ? res.id : '-'
                    const legendRes = plotBandsLegend.find(({ Id }) => postId === Id)
                    const post = legendRes ? legendRes.Nom : '-'
                    return post
                  }

                  const row = [
                    `"${moment(x).format('YYYY-MM-DD HH:mm:ss')}"`,
                    ...series.map(({ data }) => {
                      const point = data[i]
                      const y = isArray(point) ? point[1] : point
                      return `"${decimalFormatCsv(y, 10)}"`
                    }),
                    ...(showHrs ? [getPost()] : []),
                  ].join(';')

                  acc.push(row)

                  return acc
                }, [])

                const content = [head, ...body].join('\n')
                downloadFile('chart.csv', content, { type: 'text/csv' })
              },
            },
          ],
        },
      },
    },
  }
}

class LoadCurveGraph extends Component {
  render() {
    const { graph, unite, energy, hrs, loading, error, t, plotBandsLegend } = this.props

    const isEmpty = !graph || !graph.series || graph.series.length === 0

    const config = isEmpty ? null : createConfig(graph, unite, hrs, energy, plotBandsLegend)

    return (
      <div className={styles.chartContainer}>
        {error
          ? t('global.error')
          : config && (
              <HighchartsReact
                highcharts={Highstock}
                constructorType="stockChart"
                options={config}
              />
            )}

        {loading && (
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  graph: getLoadCurveGraph(state),
  unite: getLoadCurveGraphUnite(state),
  energy: getLoadCurveGraphEnergy(state),
  hrs: getLoadCurveGraphHrs(state),
  loading: getGraphLoading(state),
  error: getGraphError(state),
  plotBandsLegend: getPlotBandsLegend(state),
})

export default withTranslation()(connect(mapStateToProps, actions)(LoadCurveGraph))
