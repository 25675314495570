import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { Row, Col, Button } from 'reactstrap'

import { getContract } from '../../reducers/contracts'
import { fetchContract } from '../../actions/contracts'
import ContractInfos from './ContractInfos'
import { noDecimalFormat } from '../../helpers/helpers'
import { getIconClassName } from '../../helpers/icon'

class Contract extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
  }

  componentDidMount() {
    const { id, match } = this.props
    if (match.params.ctrids && match.params.ctrids.split(';').includes('' + id)) {
      this.fetchContract()
    }
  }

  fetchContract() {
    const { id, dispatch } = this.props
    dispatch(fetchContract(id))
  }

  toggle() {
    const { id, match, history } = this.props
    let to = ''

    if (match.params.ctrids) {
      const params = match.params.ctrids.split(';')
      if (!params.includes('' + id)) {
        this.fetchContract()
      }
      to = params.includes('' + id)
        ? params.filter((e) => e !== '' + id).join(';') ||
          match.url
            .split('/')
            .filter((e) => e !== '' + id)
            .join('/')
        : [...params, id].join(';')
    } else {
      this.fetchContract()
      to = match.url + '/' + id
    }

    history.push(to)
  }

  render() {
    const { id, contract, match, t } = this.props
    const isOpen = match.params.ctrids && match.params.ctrids.split(';').includes('' + id)

    return (
      <Row
        className={[
          'accordion-item',
          contract.CtrNrjId === 2 ? 'elec' : 'gas',
          isOpen ? 'open' : '',
        ].join(' ')}
      >
        <Col>
          <Button onClick={this.toggle} className="d-flex">
            <i className={getIconClassName(contract.CtrNrjId)} />
            {contract.CtrNom}
            <small>
              {t('contracts.contract.from') +
                ' ' +
                contract.CtrDateDebut +
                ' ' +
                t('contracts.contract.to') +
                ' ' +
                contract.CtrDateFin +
                ' - ' +
                ((contract.CtrVolume !== null &&
                  contract.CtrVolume !== undefined &&
                  contract.CtrVolumeUnite !== null &&
                  contract.CtrVolumeUnite !== undefined &&
                  noDecimalFormat(contract.CtrVolume) + ' ' + contract.CtrVolumeUnite + ' - ') ||
                  '') +
                contract.CtrType}
            </small>
            <i
              title={isOpen ? t('contracts.collapse') : t('contracts.expand')}
              className={['ml-auto', 'icon-collapse-' + (isOpen ? 'moins' : 'plus')].join(' ')}
            />
          </Button>
          {isOpen && (
            <div className="current-pees collapse show">
              <ContractInfos id={id} />
            </div>
          )}
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state, { id }) => ({
  contract: getContract(state, id),
})

Contract.propTypes = {
  id: PropTypes.number.isRequired,
  contract: PropTypes.shape({
    id: PropTypes.number.isRequired,
    CtrNom: PropTypes.string.isRequired,
    CtrDateDebut: PropTypes.string.isRequired,
    CtrDateFin: PropTypes.string.isRequired,
    CtrType: PropTypes.string.isRequired,
    CtrVolume: PropTypes.number.isRequired,
    CtrNrjId: PropTypes.number.isRequired,
  }).isRequired,
}

export default withTranslation()(withRouter(connect(mapStateToProps)(Contract)))
