import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import FormDecompositionCheckbox from './FormDecompositionCheckbox'

class FormDecomposition extends Component {
  render() {
    const { t } = this.props
    const decomposition = [
      ['Energie', t('expenses.decomposition.energy'), this.props.toggleEnergy],
      ['Transport', t('expenses.decomposition.transport'), this.props.toggleGrid],
      ['Taxes', t('expenses.decomposition.taxes'), this.props.toggleTaxes],
      ['Autres', t('expenses.decomposition.other'), this.props.toggleOthers],
    ]

    return (
      <div className="expense-graph-legend">
        {decomposition.map(([value, text, onClick]) => (
          <FormDecompositionCheckbox key={value} value={value} text={text} onClick={onClick} />
        ))}
      </div>
    )
  }
}

export default withTranslation()(FormDecomposition)
