import React from 'react'
import { FaChevronDown } from 'react-icons/fa'

import {
  ListboxInput,
  ListboxButton,
  ListboxPopover,
  ListboxList,
  ListboxOption,
} from '@reach/listbox'

const langs = {
  1: { iso: 'fr', label: 'Français' },
  2: { iso: 'gb', label: 'English' },
  4: { iso: 'de', label: 'Deutsch' },
  5: { iso: 'pl', label: 'Polski' },
}

const styles = {
  focusable: {
    outline: '2px solid transparent !important',
    outlineOffset: '2px',
    boxShadow: '0 0 0 0px #fff, 0 0 0 calc(3px + 0px) rgba(59,130,246,0.5), 0 0 rgb(0 0 0 / 0%)',
  },
  option: {
    padding: '0.4rem 0.8rem',
    '.flag-icon': { marginRight: '.5rem' },
  },
}

export const LangSelect = ({ value, onChange }) => {
  return (
    <ListboxInput value={value.toString()} onChange={(value) => onChange(Number(value))}>
      <ListboxButton
        arrow={<FaChevronDown size={10} />}
        className="rounded"
        css={{
          '&:focus': styles.focusable,
          ...styles.option,
        }}
      >
        {langs[value] && (
          <>
            <span className={`flag-icon flag-icon-${langs[value].iso}`} />
            <span>{langs[value].label}</span>
          </>
        )}
      </ListboxButton>

      <ListboxPopover
        className="rounded shadow"
        css={{
          zIndex: 100000,
          '&:focus-within': styles.focusable,
        }}
      >
        <ListboxList>
          {Object.entries(langs).map(([id, { iso, label }]) => (
            <ListboxOption key={id} value={id.toString()} css={styles.option}>
              <span className={`flag-icon flag-icon-${iso}`} />
              <span>{label}</span>
            </ListboxOption>
          ))}
        </ListboxList>
      </ListboxPopover>
    </ListboxInput>
  )
}
