import { apiConf as api } from 'commons'

export const fetchFavorites = (uuid, usrId) => {
  return api.superFetch({
    url: `listefavoris?usrid=${usrId}`,
    uuid: uuid,
  })
}

export const delFavorite = (uuid, favId) => {
  return api.superFetch({
    url: `deletefavori?id=${favId}`,
    uuid: uuid,
    method: 'DELETE',
  })
}

export const postFavorite = (uuid, FavUsrId, FavNom, FavListePee) => {
  const favori = {
    FavNom,
    FavUsrId,
    FavListePee,
  }

  return api.superFetch({
    url: 'creerfavori',
    uuid: uuid,
    method: 'POST',
    body: favori,
  })
}
