import * as powerConsumptionAll from './powerConsumption/allAmenities'
import * as powerConsumptionElectricity from './powerConsumption/electricity'
import * as powerConsumptionGas from './powerConsumption/gas'
import * as loadCurve from './powerConsumption/loadCurve'
import * as expensesAll from './expenses/allAmenities'
import * as expensesElectricity from './expenses/electricity'
import * as expensesGas from './expenses/gas'
import * as market from './market'
import * as bills from './bills'
import * as contracts from './contracts'
import { getCurrentVisibleElecPeesIds } from '../reducers/currentSelection'

export const updateView = ({ updated = '' } = {}) => (dispatch, getState) => {
  /**
   * We update the view only if the filter pqnel is closed
   */
  if (getState().searchPanel.open) {
    return
  }
  // eslint-disable-next-line
	const p = location.pathname;
  switch (true) {
    case p === '/power-consumption/all-amenities':
      dispatch(powerConsumptionAll.fetchKpiAll())
      dispatch(powerConsumptionAll.fetchKpiElec())
      dispatch(powerConsumptionAll.fetchKpiGas())
      dispatch(powerConsumptionAll.fetchGraph())
      break

    case p === '/power-consumption/electricity':
      dispatch(powerConsumptionElectricity.fetchKpiConso())
      dispatch(powerConsumptionElectricity.fetchKpiExpense())
      dispatch(powerConsumptionElectricity.fetchKpiPrice())
      dispatch(powerConsumptionElectricity.fetchGraph(updated === 'type'))
      if (getCurrentVisibleElecPeesIds(getState()).length === 1) {
        dispatch(powerConsumptionElectricity.fetchGraphCumulative())
        dispatch(powerConsumptionElectricity.fetchGraphCumulativeTemporal())
      }
      break

    case p === '/power-consumption/gas':
      dispatch(powerConsumptionGas.fetchKpiConso())
      dispatch(powerConsumptionGas.fetchKpiExpense())
      dispatch(powerConsumptionGas.fetchKpiPrice())
      dispatch(powerConsumptionGas.fetchGraph(updated === 'type'))
      break

    case p === '/power-consumption/load-curve':
      dispatch(loadCurve.fetchKpiConso())
      dispatch(loadCurve.fetchKpiPowerMin())
      dispatch(loadCurve.fetchKpiPowerMax())
      dispatch(loadCurve.fetchKpiExcess())
      dispatch(loadCurve.fetchGraph())
      break
    case p === '/expenses/all-amenities':
      dispatch(expensesAll.fetchKpiExpTotal())
      dispatch(expensesAll.fetchKpiExpElec())
      dispatch(expensesAll.fetchKpiExpGas())
      dispatch(expensesAll.fetchKpiPriceTotal())
      dispatch(expensesAll.fetchKpiPriceElec())
      dispatch(expensesAll.fetchKpiPriceGas())
      dispatch(expensesAll.fetchPieGraph())
      break
    case p === '/expenses/electricity':
      dispatch(expensesElectricity.fetchKpiExpTotal())
      dispatch(expensesElectricity.fetchKpiExpEnergy())
      dispatch(expensesElectricity.fetchKpiExpTransport())
      dispatch(expensesElectricity.fetchKpiExpTaxes())
      dispatch(expensesElectricity.fetchKpiExpAlt())
      dispatch(expensesElectricity.fetchKpiPriceTotal())
      dispatch(expensesElectricity.fetchKpiPriceEnergy())
      dispatch(expensesElectricity.fetchKpiPriceTransport())
      dispatch(expensesElectricity.fetchKpiPriceTaxes())
      dispatch(expensesElectricity.fetchKpiPriceAlt())
      dispatch(expensesElectricity.fetchPieGraph())
      dispatch(expensesElectricity.fetchColGraph())
      break
    case p === '/expenses/gas':
      dispatch(expensesGas.fetchKpiExpTotal())
      dispatch(expensesGas.fetchKpiExpEnergy())
      dispatch(expensesGas.fetchKpiExpTransport())
      dispatch(expensesGas.fetchKpiExpTaxes())
      dispatch(expensesGas.fetchKpiExpAlt())
      dispatch(expensesGas.fetchKpiPriceTotal())
      dispatch(expensesGas.fetchKpiPriceEnergy())
      dispatch(expensesGas.fetchKpiPriceTransport())
      dispatch(expensesGas.fetchKpiPriceTaxes())
      dispatch(expensesGas.fetchKpiPriceAlt())
      dispatch(expensesGas.fetchPieGraph())
      dispatch(expensesGas.fetchColGraph())
      break
    case p === '/market-informations':
      dispatch(market.fetchMarketElecGraph())
      dispatch(market.fetchMarketGasGraph())
      dispatch(market.fetchMarketUKBrentGraph())
      dispatch(market.fetchMarketProductsGraph())
      break
    case p === '/bills':
      dispatch(bills.fetchBillsList())
      break
    case /^\/contracts\/energy-meter(?!.)|^\/contracts\/energy-meter(?=\/\d+(?!\w))/.test(p):
      dispatch(contracts.fetchMetersList())
      break
    case /^\/contracts(?!.)|^\/contracts(?=\/\d+(?!\w))/.test(p):
      dispatch(contracts.fetchContractsList())
      break
    default:
      break
  }
}
