import uniq from 'lodash/uniq'

export const adaptPageSizeOptions = (data = []) =>
  uniq([10, 20, 25, 50, data.length]).filter((size) => size <= data.length && size <= 100)

export const translations = (t) => ({
  rowsText: t('reactTable.rowsText'),
  ofText: t('reactTable.ofText'),
  previousText: t('reactTable.previousText'),
  nextText: t('reactTable.nextText'),
  loadingText: t('reactTable.loadingText'),
  noDataText: t('reactTable.noDataText'),
})
