import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Label, Input } from 'reactstrap'

import { postFavorite } from '../../../actions/favorites'

class AddFavoriteModal extends Component {
  constructor(props) {
    super(props)
    this.state = { FavNom: '' }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(value) {
    this.setState({ FavNom: value })
  }

  handleSubmit(e) {
    e.preventDefault()
    const { toggle, addFavorite } = this.props

    addFavorite(this.state.FavNom)
    toggle()
  }

  render() {
    const { toggle, isOpen } = this.props

    return (
      <Modal autoFocus={false} toggle={toggle} isOpen={isOpen}>
        <Form onSubmit={this.handleSubmit}>
          <ModalHeader toggle={toggle}>Ajouter aux favoris</ModalHeader>
          <ModalBody>
            <Label>Nom du favoris</Label>
            <Input onChange={(e) => this.handleChange(e.target.value)} />
          </ModalBody>
          <ModalFooter>
            <Button
              color="link"
              onClick={(e) => {
                e.preventDefault()
                toggle()
              }}
            >
              Annuler
            </Button>
            <Button color="primary" type="submit">
              Ajouter
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  addFavorite(state) {
    dispatch(postFavorite(state))
  },
})

AddFavoriteModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  addFavorite: PropTypes.func.isRequired,
}

export default connect(null, mapDispatchToProps)(AddFavoriteModal)
