import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as actions from '../../actions/contracts'
import { getMetersListIds } from '../../reducers/contracts'
import Meter from './Meter'

class ContractsMetersList extends Component {
  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    const { fetchMetersList } = this.props
    fetchMetersList()
  }

  render() {
    const { metersIds } = this.props
    return (
      <section className="accordion">
        {metersIds.map((id) => (
          <Meter key={id} id={id} />
        ))}
      </section>
    )
  }
}

const mapStateToProps = (state) => ({
  metersIds: getMetersListIds(state),
})

ContractsMetersList.propTypes = {
  metersIds: PropTypes.array.isRequired,
  fetchMetersList: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, actions)(ContractsMetersList)
