import React from "react";
import matchSorter from "match-sorter";
import uniqBy from "lodash/uniqBy";
import {ComboboxFilter} from "../sites/ComboboxFilter";
import {useTranslation} from "react-i18next";
import range from "lodash/range";
import {translations} from "../../helpers/react-table";
import ReactTable from "react-table";
import moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const getPageSizeOptions = (total) =>
    [10, 15].concat(
        range(7)
            .map((i) => i * 5 + 20)
            .filter((n) => n <= total)
    )

export const ConsentementsTable = ({grdfRows}) => {
    const {t} = useTranslation()
    const ref = React.useRef(null)

    const columns = React.useMemo(
        () =>
            [
                {
                    Header: t('consents.site'),
                    accessor: 'nomSite',
                },
                {
                    Header: t('consents.pce'),
                    accessor: 'peeClef',
                },
                {
                    Header: t('consents.state'),
                    accessor: 'etatDroitAcces',
                },
                {
                    Header: t('consents.startDate'),
                    accessor: 'dateDebutDroitAcces',
                    Cell: ({ value }) => value ? moment(value).format('YYYY-MM-DD') : "",
                    Filter: ({ filter, onChange }) => (
                        <Datetime
                            value={filter ? moment(filter.value) : null}
                            onChange={date => {
                                const value = date && moment.isMoment(date) ? date.format("YYYY-MM-DD") : null;
                                onChange(value);
                            }}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                            inputProps={{ placeholder: 'Select a start date' }}
                            closeOnSelect={true}
                        />
                    ),
                    filterMethod: (filter, row) => {
                        if (!filter.value) return true;
                        const rowDate = moment(row.dateDebutDroitAcces).format("YYYY-MM-DD");
                        const filterDate = moment(filter.value).format("YYYY-MM-DD");
                        return rowDate >= filterDate;
                    }
                },
                {
                    Header: t('consents.endDate'),
                    accessor: 'dateFinDroitAcces',
                    Cell: ({ value }) => value ? moment(value).format('YYYY-MM-DD') : "",
                    Filter: ({ filter, onChange }) => (
                        <Datetime
                            value={filter ? moment(filter.value) : null}
                            onChange={date => {
                                const value = date && moment.isMoment(date) ? date.format("YYYY-MM-DD") : null;
                                onChange(value);
                            }}
                            timeFormat={false}
                            inputProps={{ placeholder: 'Select an end date' }}
                            closeOnSelect={true}
                        />
                    ),
                    filterMethod: (filter, row) => {
                        if (!filter.value) return true;
                        const rowDate = moment(row.dateFinDroitAcces);
                        const filterDate = moment(filter.value);
                        return rowDate.isSameOrBefore(filterDate, 'day');
                    }
                },
                {
                    Header: t('consents.informativeData'),
                    accessor: 'perimDonneesInformatives',
                },
                {
                    Header: t('consents.publishedData'),
                    accessor: 'perimDonneesPubliees'
                },
                {
                    Header: t('consents.technicalData'),
                    accessor: 'perimDonneesTechniques',
                },
                {
                    Header: t('consents.contractualData'),
                    accessor: 'perimDonneesContractuelles',
                },
            ].map((column) => {
                // Check if the column is not one of the date columns
                if (column.accessor !== 'dateDebutDroitAcces' && column.accessor !== 'dateFinDroitAcces') {
                    return {
                        ...column,
                        filterAll: true,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {
                                keys: [column.id || column.accessor],
                                threshold: matchSorter.rankings.CONTAINS,
                            }),
                        Filter: ({ onChange, column }) => {
                            const data = ref.current ? ref.current.getResolvedState().sortedData : [];
                            const items = uniqBy(data, column.accessor).map(item => item[column.accessor]);
                            return <ComboboxFilter items={items} onChange={onChange}/>;
                        }
                    };
                }

                return column;
            }),
        [t]
    )

    return (
        <>
            <ReactTable
                ref={ref}
                data={grdfRows}
                columns={columns}
                defaultPageSize={15}
                pageSizeOptions={getPageSizeOptions(grdfRows.length)}
                {...translations(t)}
                className="shadow-sm -striped -highlight"
                css={{
                    fontSize: 13,
                    '.rt-thead.-filters .rt-th': {
                        overflow: 'unset',
                    },
                }}
                filterable
            />
        </>
    )
}
