import React, { useMemo, useRef } from 'react'
import ReactTable from 'react-table'
import { useProjection } from './useProjection'
import { LoaderCenter, TableLoader } from 'components/common/Loader'
import mapValues from 'lodash/mapValues'
import { formatPercent, formatNoDecimal, formatDecimal } from './format'
import CSVButton from '../common/CSVButton'
import { PercentCell } from './PercentCell'

export const Projection = () => {
  const tableRef = useRef()
  const query = useProjection()

  const columns = useMemo(() => {
    if (!query.data) return []
    return [
      {
        Header: '',
        accessor: 'title',
        width: 240,
        Cell: (props) => (
          <div
            css={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontSize: props.original.type === 'title' ? '1.1em' : '1em',
              color: props.original.type === 'title' ? 'var(--primary)' : 'inherit',
            }}
            title={`${props.value} (${props.original.description})`}
          >
            <span style={{ fontWeight: props.original.type === 'title' ? 'bold' : 'normal' }}>
              {props.value}
            </span>
            {'\u2000'}
            <small className="text-muted">{props.original.description}</small>
          </div>
        ),
      },
      ...Object.keys(query.data).map((pays, index) => ({
        Header: pays,
        title: pays,
        accessor: pays,
        Cell: (props) => {
          switch (props.original.type) {
            case 'decimal':
              return <div className="text-right">{formatDecimal(props.value)}</div>
            case 'nodecimal':
              return <div className="text-right">{formatNoDecimal(props.value)}</div>
            case 'percent':
              return <PercentCell value={props.value} digits={1} index={1} />
            default:
              return props.value
          }
        },
      })),
    ]
  }, [query.data])

  const data = useMemo(() => {
    if (!query.data) return []
    return [
      { title: 'CAL 2023', type: 'decimal', ...mapValues(query.data, 'CalY1') },
      {
        title: 'Evolutions / Moyenne annuelle à date',
        type: 'percent',
        ...mapValues(query.data, 'EvolutionYTDY1'),
      },
      {
        title: 'Evolutions / Moyenne 12 mois glissants',
        type: 'percent',
        ...mapValues(query.data, 'Evolution12MoisY1'),
      },
      { title: 'CAL 2024', type: 'decimal', ...mapValues(query.data, 'CalY2') },
      {
        title: 'Evolutions / Moyenne annuelle à date',
        type: 'percent',
        ...mapValues(query.data, 'EvolutionYTDY2'),
      },
      {
        title: 'Evolutions / Moyenne 12 mois glissants',
        type: 'percent',
        ...mapValues(query.data, 'Evolution12MoisY2'),
      },
    ]
  }, [query.data])

  if (query.isLoading) return <LoaderCenter />

  const createCSVContent = () => {
    const { data, columns } = tableRef.current.getResolvedState()
    const paysList = columns.slice(1).map((col) => col.title)
    const header = ['', ...paysList]
    const body = data.map((item) => [
      item.title,
      ...paysList.map((pays) => {
        const value = item[pays]
        if (item.type === 'percent') return formatPercent(value, true)
        if (item.type === 'nodecimal') return formatNoDecimal(value, true)
        if (item.type === 'decimal') return formatDecimal(value, true)
        return value
      }),
    ])
    return [header, ...body]
  }

  if (!data || data.length === 0) return null

  return (
    <div>
      <span css={{ position: 'absolute', top: 0, right: 0 }}>
        <CSVButton filename={'Prix de marché.csv'} createContent={createCSVContent} />
      </span>

      <ReactTable
        ref={tableRef}
        data={data}
        columns={columns}
        sortable={false}
        showPagination={false}
        loading={query.isFetching}
        LoadingComponent={TableLoader}
        minRows={0}
        pageSize={data.length}
        css={{
          '.rt-thead': {
            zIndex: 10,
          },
          '.rt-th': {
            padding: '8px 10px !important',
          },
          '.rt-td': {
            position: 'relative',
          },
          '.rt-th:nth-of-type(even), .rt-td:nth-of-type(even)': {
            backgroundColor: 'rgba(0,0,0,0.04)',
          },
        }}
      />
    </div>
  )
}
