import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

// React Commons components
import {
  Logout,
  Callback,
  AccessDenied,
  SilentRedirectPage,
  userManager,
  Version,
  isLoggedIn,
} from 'commons'

// COMPONENTS
import GTM from '../views/GTM'
import Template from '../template/Template'
// import Version from '../version/Version'

// Configu
import * as env from '../../helpers/env'

// Helpers
import { customerTheme } from '../../helpers/themes'
// import { isAbsolute } from 'path'

class Root extends Component {
  state = {
    isRedirecting: false,
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.onRouteChanged(prevProps)
    }

    if (
      this.props.location.pathname &&
      !this.props.location.pathname.startsWith('/callback') &&
      !this.props.location.pathname.startsWith('/silent_renew')
    ) {
      if (
        (!this.props.user || (!this.props.user && this.props.user.expired === true)) &&
        this.props.isLoadingUser === false
      ) {
        if (this.state.isRedirecting === false) {
          this.setState({ isRedirecting: true })
          userManager.signinRedirect()
        }
      }
    }
  }

  onRouteChanged(prevProps) {
    const { dispatch } = this.props
    dispatch({
      type: 'PAGE_CHANGED',
      route: this.props.location.pathname,
      referrer: prevProps.location.pathname,
    })
  }

  isAllowed() {
    if (this.props.user && this.props.user.profile && this.props.user.profile.role) {
      if (Array.isArray(this.props.user.profile.role)) {
        return this.props.user.profile.role.some((role) => env.requiredRoles.includes(role))
      } else {
        return env.requiredRoles.includes(this.props.user.profile.role)
      }
    }

    return false
  }

  isBasicAllowed() {
    if (this.props.user && this.props.user.profile && this.props.user.profile.role) {
      if (Array.isArray(this.props.user.profile.role)) {
        return this.props.user.profile.role.some((role) => env.basicRoles.includes(role))
      } else {
        return env.basicRoles.includes(this.props.user.profile.role)
      }
    }

    return false
  }

  isSitesAllowed() {
    if (this.props.user && this.props.user.profile && this.props.user.profile.role) {
      if (Array.isArray(this.props.user.profile.role)) {
        return this.props.user.profile.role.some((role) => env.otherRoles.includes(role))
      } else {
        return env.otherRoles.includes(this.props.user.profile.role)
      }
    }

    return false
  }

  redirectPage() {
    if (this.isSitesAllowed() && !this.isBasicAllowed()) return '/sites'
    else return '/power-consumption/all-amenities'
  }

  render() {
    return (
      <div className={this.props.theme}>
        <GTM gtmId="GTM-MN7MGBT" />
        {!!(this.props.user && !this.props.user.expired) ? (
          this.isAllowed() ? (
            <>
              <Switch>
                <Route exact path="/silent_renew" component={SilentRedirectPage} />
                <Route exact path="/" render={() => <Redirect to={this.redirectPage()} push />} />
                <Route path="/logout" component={Logout} />
                <Route path="/callback" component={Callback} />
                <Route path="/:path" component={Template} />
              </Switch>
              <Version />
            </>
          ) : (
            <Switch>
              <Route path="/" component={AccessDenied} />
            </Switch>
          )
        ) : (
          <Switch>
            <Route exact path="/silent_renew" component={SilentRedirectPage} />
            <Route path="/" component={Callback} />
          </Switch>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLoadingUser: state.oidc && state.oidc.isLoadingUser,
    loggedIn: isLoggedIn(state),
    user: state.oidc && state.oidc.user,
    theme: customerTheme(state),
  }
}

export default withTranslation()(withRouter(connect(mapStateToProps)(Root)))
