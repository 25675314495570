import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Row, Col, CardGroup, Alert } from 'reactstrap'
import HighchartsReact from 'highcharts-react-official'
import { Highcharts } from 'helpers/highcharts'

import { initColumnChart, initBarChart } from '../../helpers/initCharts'
import {
  getKpiElecConso,
  getKpiElecExpense,
  getKpiElecPrice,
  getKpiElecConsoLoading,
  getKpiElecExpenseLoading,
  getKpiElecPriceLoading,
  getElecGraph,
  getElecGraphCumulative,
  getElecGraphCumulativeTemporal,
  getElecGraphAgregation,
  getElecGraphTooMuchPees,
} from '../../reducers/powerConsumption/electricity'
import { getCurrentVisibleElecPeesIds } from '../../reducers/currentSelection'
import * as actions from '../../actions/powerConsumption/electricity'
import Kpi from '../kpi/Kpi'
import FormGraph from './FormGraph'
import ConsoTable from './ConsoTable'
import { getTypeDonnee } from '../../reducers/typeDonnee'
import QualiteConso from './QualiteConso/QualiteConso'

class Electricity extends Component {
  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    const {
      fetchKpiConso,
      fetchKpiExpense,
      fetchKpiPrice,
      fetchGraph,
      currentVisibleElecPeesIds,
      fetchGraphCumulative,
      fetchGraphCumulativeTemporal,
    } = this.props
    fetchKpiConso()
    fetchKpiExpense()
    fetchKpiPrice()
    fetchGraph()
    if (currentVisibleElecPeesIds.length === 1) {
      fetchGraphCumulative()
      fetchGraphCumulativeTemporal()
    }
  }

  render() {
    const {
      type,
      currentVisibleElecPeesIds,
      kpiConso,
      kpiExpense,
      kpiPrice,
      kpiConsoLoading,
      kpiExpenseLoading,
      kpiPriceLoading,
      graph,
      graphCumulative,
      graphCumulativeTemporal,
      agregation,
      tooMuchPees,
      updateElectricityGraphAgregation,
      t,
    } = this.props
    const config = initColumnChart(graph, false, type)

    return (
      <div>
        {currentVisibleElecPeesIds.length ? (
          <div>
            <section className="pb-0">
              <Row>
                <Col xs="9">
                  <CardGroup>
                    <Kpi decimal={false} {...kpiConso} loading={kpiConsoLoading} />
                    <Kpi decimal={false} {...kpiExpense} loading={kpiExpenseLoading} />
                    <Kpi decimal={true} {...kpiPrice} loading={kpiPriceLoading} />
                  </CardGroup>
                </Col>
              </Row>
            </section>
            <section>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <h5 className="text-center">{t('powerConsumption.graphTitle')}</h5>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGraph
                        agregation={agregation}
                        handleAgregationChange={updateElectricityGraphAgregation}
                        tooMuchPees={tooMuchPees}
                      />
                    </Col>
                  </Row>

                  <Row noGutters>
                    <Col>
                      <HighchartsReact highcharts={Highcharts} options={config} immutable={true} />
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col>
                      <ConsoTable energy="ELE" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </section>
            {currentVisibleElecPeesIds.length === 1 && (
              <section>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <h5 className="text-center">{t('powerConsumption.elec.barChart')}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGraph showAgregationInput={false} />
                      </Col>
                    </Row>
                    <Row noGutters>
                      <Col>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={initBarChart(graphCumulative)}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <h5 className="text-center">{t('powerConsumption.elec.colChart')}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGraph showAgregationInput={false} />
                      </Col>
                    </Row>
                    <Row noGutters>
                      <Col>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={initColumnChart(graphCumulativeTemporal, true)}
                          immutable={true}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </section>
            )}
            <section>
              <Row noGutters>
                <Col>
                  <QualiteConso energy="ELE" typeDonnees="Mesure" />
                </Col>
              </Row>
            </section>
          </div>
        ) : (
          <section>
            <Alert color="warning" className="mb-0">
              {t('errors.noElecSelection')}
            </Alert>
          </section>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  currentVisibleElecPeesIds: getCurrentVisibleElecPeesIds(state),
  kpiConso: getKpiElecConso(state),
  kpiExpense: getKpiElecExpense(state),
  kpiPrice: getKpiElecPrice(state),
  kpiConsoLoading: getKpiElecConsoLoading(state),
  kpiExpenseLoading: getKpiElecExpenseLoading(state),
  kpiPriceLoading: getKpiElecPriceLoading(state),
  graph: getElecGraph(state),
  graphCumulative: getElecGraphCumulative(state),
  graphCumulativeTemporal: getElecGraphCumulativeTemporal(state),
  agregation: getElecGraphAgregation(state),
  tooMuchPees: getElecGraphTooMuchPees(state),
  type: getTypeDonnee(state),
})

export default withTranslation()(connect(mapStateToProps, actions)(Electricity))
