import { apiConf as api } from 'commons'

export const fetchAdminMarketInfos = (uuid) => {
  return api.superFetch({
    url: 'listeinformationsmarche?afficherNonVisibles=true&nbinfo=999',
    uuid: uuid,
  })
}

export const delAdminMarketInfos = (id) => {
  return api.superFetch({
    url: `deleteinfomarche?id=${id}`,
    method: 'DELETE',
  })
}

export const putAdminMarketInfos = (uuid, usrid, info) => {
  return api.superFetch({
    url: `saveinfomarche?id=${info.id}&usrid=${usrid}`,
    method: 'PUT',
    body: info,
    uuid: uuid,
  })
}

export const postAdminMarketInfos = (uuid, usrid, info) => {
  return api.superFetch({
    url: `creerinformationsmarche?usrid=${usrid}`,
    method: 'POST',
    body: info,
    uuid: uuid,
  })
}
