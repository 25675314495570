import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Input } from 'reactstrap'

import { getDateRange } from '../../reducers/powerConsumption/loadCurve'
import { updatePeriod } from '../../actions/powerConsumption/loadCurve'

const DateRangePeriodLoadCurve = ({ dispatch, dateRange, t }) => (
  <Input
    id="period"
    type="select"
    value={dateRange.period}
    onChange={(e) => dispatch(updatePeriod(e.target.value))}
  >
    <option value="LOAD_CURVE_DATE_RANGE_LAST_WEEK">{t('header.daterange.lastWeek')}</option>
    <option value="LOAD_CURVE_DATE_RANGE_LAST_MONTH">{t('header.daterange.lastMonth')}</option>
    <option value="LOAD_CURVE_DATE_RANGE_LAST_3_MONTHS">{t('header.daterange.last3months')}</option>
    <option value="LOAD_CURVE_DATE_RANGE_CUSTOM">{t('header.daterange.custom')}</option>
  </Input>
)

const mapStateToProps = (state) => ({
  dateRange: getDateRange(state),
})

DateRangePeriodLoadCurve.propTypes = {
  dispatch: PropTypes.func.isRequired,
  dateRange: PropTypes.object.isRequired,
}

export default withTranslation()(connect(mapStateToProps)(DateRangePeriodLoadCurve))
