import { store } from '../index'
import * as user from 'commons'

export const decimalFormat = (x, nbDec = 2) => {
  let val
  switch (user.lanId(store.getState())) {
    case 2:
      val = parseFloat(x)
        .toFixed(nbDec, 10)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      return val !== 'NaN' && val !== null && val !== undefined ? val : '-'
    case 1:
    default:
      val = parseFloat(x)
        .toFixed(nbDec, 10)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        .replace('.', ',')
      return val !== 'NaN' && val !== null && val !== undefined ? val : '-'
  }
}

export const noDecimalFormat = (x) => {
  let val
  switch (user.lanId(store.getState())) {
    case 2:
      val = parseFloat(x)
        .toFixed(0, 10)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      return val !== 'NaN' && val !== null && val !== undefined ? val : '-'
    case 1:
    default:
      val = parseFloat(x)
        .toFixed(0, 10)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        .replace('.', ',')
      return val !== 'NaN' && val !== null && val !== undefined ? val : '-'
  }
}

export const strDecimalFormat = (x) => {
  let val
  switch (user.lanId(store.getState())) {
    case 2:
      val = parseFloat(x)
        .toFixed(0, 10)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      return val !== 'NaN' && val !== null && val !== undefined ? val : x
    case 1:
    default:
      val = parseFloat(x)
        .toFixed(0, 10)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        .replace('.', ',')
      return val !== 'NaN' && val !== null && val !== undefined ? val : x
  }
}

export const dateFormat = () => {
  switch (user.lanId(store.getState())) {
    case 2:
      return 'YYYY-MM-DD'
    case 1:
    default:
      return 'DD/MM/YYYY'
  }
}

export const dateFormatMonths = () => {
  switch (user.lanId(store.getState())) {
    case 2:
      return 'YYYY-MM'
    case 1:
    default:
      return 'MM/YYYY'
  }
}
