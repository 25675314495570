import * as user from 'commons'

export const typeDonneeFac = (state = ['FacturePrevu', 'Facture', 'FactureN1', 'Budget'], action) => {
  const defaultState = ['FacturePrevu', 'Facture', 'FactureN1', 'Budget']
  switch (action.type) {
    case 'TOGGLE_TYPE_DONNEE':
      if(defaultState.indexOf(action.typeDonnee) === -1)
        return state
      return state.indexOf(action.typeDonnee) === -1
        ? [...state, action.typeDonnee]
        : state.filter((typeDonnee) => typeDonnee !== action.typeDonnee)
    default:
      return state
  }
}

const typeDonnee = (state = ['FacturePrevu', 'Budget'], action) => {
  // Remove facture and factureN1
  const defaultState = ['FacturePrevu', 'Budget']
  const excludedTypes = state.filter(x => !defaultState.includes(x))
  switch (action.type) {
    case 'TOGGLE_TYPE_DONNEE':
      if(defaultState.indexOf(action.typeDonnee) === -1)
        return state
      return state.indexOf(action.typeDonnee) === -1
        ? [...state, action.typeDonnee]
        : state.filter((typeDonnee) => typeDonnee !== action.typeDonnee)
    default:
      return state
  }
}

export default typeDonnee

export const getIsTypeDonneeChecked = (state, value) => user.hasInvoice(state) ? state.typeDonneeFac.indexOf(value) !== -1 : state.typeDonnee.indexOf(value) !== -1
export const getTypeDonnee = (state) => {
  if(user.hasInvoice(state)) {
    return state.typeDonneeFac
  } 
  else {
    return state.typeDonnee.filter(x => !['Facture', 'FactureN1'].includes(x))
  } 
} 
