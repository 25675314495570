import { getPeesIds, getSitesIds, getUorsIds } from './organisation'

const searchPanel = (state = { filterType: 'all', countryFilter: 'all' }, action) => {
  switch (action.type) {
    case 'OPEN_SEARCH_PANEL':
      return {
        ...state,
        open: true,
      }
    case 'CLOSE_SEARCH_PANEL':
      return {
        ...state,
        filterInput: '',
        open: false,
      }
    case 'TOGGLE_SEARCH_PANEL':
      return {
        ...state,
        open: !state.open,
      }
    case 'TOGGLE_FILTER_TYPE':
      return {
        ...state,
        filterType: action.filterType,
      }
    case 'COUNTRY_FILTER_CHANGE':
      return {
        ...state,
        countryFilter: action.countryFilter,
      }
    case 'FILTER_INPUT_CHANGE':
      return {
        ...state,
        filterInput: action.filterInput,
      }
    default:
      return state
  }
}

export default searchPanel

export const getSearchPanelOpen = (state) => state.searchPanel.open || false

export const getFilterType = (state) => state.searchPanel.filterType || 'all'

export const getCountryFilter = (state) => state.searchPanel.countryFilter || 'all'

export const getPeeIsOfFilterType = (state, peeId) =>
  (state.searchPanel.filterType === 'all' ||
    state.searchPanel.filterType ===
      state.organisation.organisationEntities.pees[peeId].PeeNrjId) &&
  (state.searchPanel.countryFilter === 'all' ||
    state.searchPanel.countryFilter === state.organisation.organisationEntities.pees[peeId].IdPays)

export const getSiteHasPeeOfFilterType = (state, siteId) =>
  (state.searchPanel.filterType === 'all' && state.searchPanel.countryFilter === 'all') ||
  (state.organisation.organisationEntities.sites &&
    state.organisation.organisationEntities.sites[siteId] &&
    state.organisation.organisationEntities.sites[siteId].SitPees &&
    state.organisation.organisationEntities.sites[siteId].SitPees.filter((peeId) =>
      getPeeIsOfFilterType(state, peeId)
    ).length > 0) ||
  false

export const getUorHasPeeOfFilterType = (state, uorId) => {
  const uor = state.organisation.organisationEntities.uors[uorId]
  const sites =
    (uor &&
      uor.UorSites &&
      uor.UorSites.filter((siteId) => getSiteHasPeeOfFilterType(state, siteId)).length > 0) ||
    false
  const children =
    (uor &&
      uor.UorEnfants &&
      uor.UorEnfants.filter((siteId) => getUorHasPeeOfFilterType(state, siteId)).length > 0) ||
    false
  return (
    (state.searchPanel.filterType === 'all' && state.searchPanel.countryFilter === 'all') ||
    sites ||
    children
  )
}

export const getFilterInput = (state) => state.searchPanel.filterInput || ''

export const getFilteredPees = (state) => {
  const peesIds = getPeesIds(state)
  return peesIds.filter(
    (peeId) =>
      getPeeIsOfFilterType(state, peeId) &&
      state.organisation.organisationEntities.pees[peeId].PeeClef &&
      state.organisation.organisationEntities.pees[peeId].PeeClef.toLowerCase().indexOf(
        state.searchPanel.filterInput.toLowerCase()
      ) > -1
  )
}

export const getFilteredSites = (state) => {
  const sitesIds = getSitesIds(state)
  return sitesIds.filter(
    (siteId) =>
      getSiteHasPeeOfFilterType(state, siteId) &&
      state.organisation.organisationEntities.sites[siteId].SphNom.toLowerCase().indexOf(
        state.searchPanel.filterInput.toLowerCase()
      ) > -1
  )
}

export const getFilteredUors = (state) => {
  const uorsIds = getUorsIds(state)
  return uorsIds.filter(
    (uorId) =>
      getUorHasPeeOfFilterType(state, uorId) &&
      state.organisation.organisationEntities.uors[uorId].UorNom.toLowerCase().indexOf(
        state.searchPanel.filterInput.toLowerCase()
      ) > -1
  )
}
