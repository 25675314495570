import { combineReducers } from 'redux'
import v4 from 'uuid/v4'
import get from 'lodash/get'

import { apiConf } from 'commons'
const { superFetch } = apiConf

const isErrorStatus = (code) => code < 200 || code >= 300

const api = {
  fetchConsoMensuelleGraph: ({ uuid, ...payload }) =>
    superFetch({
      url: 'sites/graph/consomensuelle',
      method: 'POST',
      body: payload,
      uuid,
    }),

  fetchLoadCurveGraph: ({ uuid, ...payload }) =>
    superFetch({
      url: 'sites/graph/cdc',
      method: 'POST',
      body: payload,
      uuid,
    }),

  fetchMonotoneGraph: ({ uuid, ...payload }) =>
    superFetch({
      url: 'sites/graph/monotone',
      method: 'POST',
      body: payload,
      uuid,
    }),

  fetchHeatMapGraph: ({ uuid, ...payload }) =>
    superFetch({
      url: 'sites/graph/heatmap',
      method: 'POST',
      body: payload,
      uuid,
    }),

  fetchQualiteConsoGraph: ({ uuid, ...payload }) =>
    superFetch({
      url: 'sites/graph/qualiteconso',
      method: 'POST',
      body: payload,
      uuid,
    }),

  fetchReferentielHrs: ({ uuid }) =>
    superFetch({
      url: 'referentiel/hrs',
      uuid,
    }),
}

const unites = {
  1: 'kW',
  2: 'MW',
  3: 'GW',
  11: 'kWh',
  12: 'MWh',
  13: 'GWh',
}

// Actions
const SET_GLOBAL_ACTIVE_TAB = 'esite/consumption/SET_GLOBAL_ACTIVE_TAB'
const SET_DETAILED_ACTIVE_TAB = 'esite/consumption/SET_DETAILED_ACTIVE_TAB'
const SET_CONSUMPTION_GRAPH_AGREGATION = 'esite/consumption/SET_CONSUMPTION_GRAPH_AGREGATION'
const SET_CONSUMPTION_GRAPH_UNITE = 'esite/consumption/SET_CONSUMPTION_GRAPH_UNITE'
const SET_CONSUMPTION_GRAPH_TYPE = 'esite/consumption/SET_CONSUMPTION_GRAPH_TYPE'
const SET_CONSUMPTION_GRAPH_COMPARE = 'esite/consumption/SET_CONSUMPTION_GRAPH_COMPARE'
const SET_LOAD_CURVE_GRAPH_AGREGATION = 'esite/consumption/SET_LOAD_CURVE_GRAPH_AGREGATION'
const SET_LOAD_CURVE_GRAPH_UNITE = 'esite/consumption/SET_LOAD_CURVE_GRAPH_UNITE'
const SET_LOAD_CURVE_GRAPH_TYPE = 'esite/consumption/SET_LOAD_CURVE_GRAPH_TYPE'
const SET_LOAD_CURVE_GRAPH_COMPARE = 'esite/consumption/SET_LOAD_CURVE_GRAPH_COMPARE'
const SET_LOAD_CURVE_GRAPH_STEP = 'esite/consumption/SET_LOAD_CURVE_GRAPH_STEP'
const SET_LOAD_CURVE_GRAPH_HRS = 'esite/consumption/SET_LOAD_CURVE_GRAPH_HRS'
const SET_LOAD_CURVE_GRAPH_BOOST = 'esite/consumption/SET_LOAD_CURVE_GRAPH_BOOST'
const SET_MONOTONE_GRAPH_UNITE = 'esite/consumption/SET_MONOTONE_GRAPH_UNITE'
const SET_MONOTONE_GRAPH_TYPE = 'esite/consumption/SET_MONOTONE_GRAPH_TYPE'
const SET_MONOTONE_GRAPH_COMPARE = 'esite/consumption/SET_MONOTONE_GRAPH_COMPARE'
const SET_MONOTONE_GRAPH_STEP = 'esite/consumption/SET_MONOTONE_GRAPH_STEP'
const FETCH_CONSUMPTION_GRAPH_REQUEST = 'esite/consumption/FETCH_CONSUMPTION_GRAPH_REQUEST'
const FETCH_CONSUMPTION_GRAPH_SUCCESS = 'esite/consumption/FETCH_CONSUMPTION_GRAPH_SUCCESS'
const FETCH_CONSUMPTION_GRAPH_FAILURE = 'esite/consumption/FETCH_CONSUMPTION_GRAPH_FAILURE'
const FETCH_LOAD_CURVE_GRAPH_REQUEST = 'esite/consumption/FETCH_LOAD_CURVE_GRAPH_REQUEST'
const FETCH_LOAD_CURVE_GRAPH_SUCCESS = 'esite/consumption/FETCH_LOAD_CURVE_GRAPH_SUCCESS'
const FETCH_LOAD_CURVE_GRAPH_FAILURE = 'esite/consumption/FETCH_LOAD_CURVE_GRAPH_FAILURE'
const FETCH_MONOTONE_GRAPH_REQUEST = 'esite/consumption/FETCH_MONOTONE_GRAPH_REQUEST'
const FETCH_MONOTONE_GRAPH_SUCCESS = 'esite/consumption/FETCH_MONOTONE_GRAPH_SUCCESS'
const FETCH_MONOTONE_GRAPH_FAILURE = 'esite/consumption/FETCH_MONOTONE_GRAPH_FAILURE'
const FETCH_HEATMAP_GRAPH_REQUEST = 'esite/consumption/FETCH_HEATMAP_GRAPH_REQUEST'
const FETCH_HEATMAP_GRAPH_SUCCESS = 'esite/consumption/FETCH_HEATMAP_GRAPH_SUCCESS'
const FETCH_HEATMAP_GRAPH_FAILURE = 'esite/consumption/FETCH_HEATMAP_GRAPH_FAILURE'
const FETCH_QUALITE_CONSO_GRAPH_REQUEST = 'esite/consumption/FETCH_QUALITE_CONSO_GRAPH_REQUEST'
const FETCH_QUALITE_CONSO_GRAPH_SUCCESS = 'esite/consumption/FETCH_QUALITE_CONSO_GRAPH_SUCCESS'
const FETCH_QUALITE_CONSO_GRAPH_FAILURE = 'esite/consumption/FETCH_QUALITE_CONSO_GRAPH_FAILURE'
const FETCH_REFERENTIEL_HRS_REQUEST = 'esite/consumption/FETCH_REFERENTIEL_HRS_REQUEST'
const FETCH_REFERENTIEL_HRS_SUCCESS = 'esite/consumption/FETCH_REFERENTIEL_HRS_SUCCESS'
const FETCH_REFERENTIEL_HRS_FAILURE = 'esite/consumption/FETCH_REFERENTIEL_HRS_FAILURE'
const SET_PEE_ID = 'esite/consumption/SET_PEE'
const SET_POSTES_UNIT = 'esite/consumption/SET_POSTES_UNIT'

// Reducer
const activeTabs = (state = { global: 0, detailed: 0 }, action) => {
  switch (action.type) {
    case SET_GLOBAL_ACTIVE_TAB:
      return {
        ...state,
        global: action.nav,
      }
    case SET_DETAILED_ACTIVE_TAB:
      return {
        ...state,
        detailed: action.nav,
      }
    default:
      return state
  }
}

const consumptionGraph = (
  state = {
    graph: null,
    isFetching: false,
    error: false,
    agregation: '2',
    unite: '11',
    type: 'Mesure',
    compare: [],
    uuid: null,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_CONSUMPTION_GRAPH_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        uuid: action.uuid,
      }
    case FETCH_CONSUMPTION_GRAPH_SUCCESS:
      return {
        ...state,
        graph: action.graph,
        isFetching: false,
        error: false,
        uuid: null,
      }
    case FETCH_CONSUMPTION_GRAPH_FAILURE:
      return {
        ...state,
        graph: null,
        isFetching: false,
        error: true,
        uuid: null,
      }
    case SET_CONSUMPTION_GRAPH_AGREGATION:
      return {
        ...state,
        agregation: action.agregation,
      }
    case SET_CONSUMPTION_GRAPH_UNITE:
      return {
        ...state,
        unite: action.unite,
      }
    case SET_CONSUMPTION_GRAPH_TYPE:
      return {
        ...state,
        type: action.dataType,
      }
    case SET_CONSUMPTION_GRAPH_COMPARE:
      return {
        ...state,
        compare: action.compare,
      }
    default:
      return state
  }
}

const loadCurveGraph = (
  state = {
    graph: null,
    isFetching: false,
    error: false,
    agregation: '1',
    unite: '11',
    type: 'Mesure',
    compare: [],
    step: '5',
    uuid: null,
    hrs: null,
    boost: true,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_LOAD_CURVE_GRAPH_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        uuid: action.uuid,
      }
    case FETCH_LOAD_CURVE_GRAPH_SUCCESS:
      return {
        ...state,
        graph: action.graph,
        isFetching: false,
        error: false,
        uuid: null,
      }
    case FETCH_LOAD_CURVE_GRAPH_FAILURE:
      return {
        ...state,
        graph: null,
        isFetching: false,
        error: true,
        uuid: null,
      }
    case SET_LOAD_CURVE_GRAPH_AGREGATION:
      return {
        ...state,
        agregation: action.agregation,
      }
    case SET_LOAD_CURVE_GRAPH_UNITE:
      return {
        ...state,
        unite: action.unite,
      }
    case SET_LOAD_CURVE_GRAPH_TYPE:
      return {
        ...state,
        type: action.dataType,
      }
    case SET_LOAD_CURVE_GRAPH_COMPARE:
      return {
        ...state,
        compare: action.compare,
      }
    case SET_LOAD_CURVE_GRAPH_STEP:
      return {
        ...state,
        step: action.step,
      }
    case SET_LOAD_CURVE_GRAPH_HRS:
      return {
        ...state,
        hrs: action.hrs,
      }
    case SET_LOAD_CURVE_GRAPH_BOOST:
      return {
        ...state,
        boost: action.boost,
      }
    default:
      return state
  }
}

const monotoneGraph = (
  state = {
    graph: null,
    isFetching: false,
    error: false,
    unite: '11',
    type: 'Mesure',
    compare: [],
    step: '5',
    uuid: null,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_MONOTONE_GRAPH_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        uuid: action.uuid,
      }
    case FETCH_MONOTONE_GRAPH_SUCCESS:
      return {
        ...state,
        graph: action.graph,
        isFetching: false,
        error: false,
        uuid: null,
      }
    case FETCH_MONOTONE_GRAPH_FAILURE:
      return {
        ...state,
        graph: null,
        isFetching: false,
        error: true,
        uuid: null,
      }
    case SET_MONOTONE_GRAPH_UNITE:
      return {
        ...state,
        unite: action.unite,
      }
    case SET_MONOTONE_GRAPH_TYPE:
      return {
        ...state,
        type: action.dataType,
      }
    case SET_MONOTONE_GRAPH_COMPARE:
      return {
        ...state,
        compare: action.compare,
      }
    case SET_MONOTONE_GRAPH_STEP:
      return {
        ...state,
        step: action.step,
      }
    default:
      return state
  }
}

const heatMapGraph = (
  state = {
    graph: null,
    isFetching: false,
    error: false,
    unite: '11',
    type: 'Mesure',
    compare: [],
    step: '5',
    uuid: null,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_HEATMAP_GRAPH_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        uuid: action.uuid,
      }
    case FETCH_HEATMAP_GRAPH_SUCCESS:
      return {
        ...state,
        graph: action.graph,
        isFetching: false,
        error: false,
        uuid: null,
      }
    case FETCH_HEATMAP_GRAPH_FAILURE:
      return {
        ...state,
        graph: null,
        isFetching: false,
        error: true,
        uuid: null,
      }
    default:
      return state
  }
}

const qualiteConsoGraph = (
  state = {
    graph: null,
    isFetching: false,
    error: false,
    unite: '11',
    type: 'Mesure',
    compare: [],
    step: '5',
    uuid: null,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_QUALITE_CONSO_GRAPH_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        uuid: action.uuid,
      }
    case FETCH_QUALITE_CONSO_GRAPH_SUCCESS:
      return {
        ...state,
        graph: action.graph,
        isFetching: false,
        error: false,
        uuid: null,
      }
    case FETCH_QUALITE_CONSO_GRAPH_FAILURE:
      return {
        ...state,
        graph: null,
        isFetching: false,
        error: true,
        uuid: null,
      }
    default:
      return state
  }
}

const referentielHrs = (
  state = {
    data: null,
    isFetching: false,
    error: false,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_REFERENTIEL_HRS_REQUEST:
      return {
        data: null,
        isFetching: true,
        error: false,
      }
    case FETCH_REFERENTIEL_HRS_SUCCESS:
      return {
        data: action.data,
        isFetching: false,
        error: false,
      }
    case FETCH_REFERENTIEL_HRS_FAILURE:
      return {
        data: action.data,
        isFetching: false,
        error: true,
      }
    default:
      return state
  }
}

const selectedPee = (state = null, action) => {
  switch (action.type) {
    case SET_PEE_ID:
      return action.payload
    default:
      return state
  }
}

const postesUnit = (state = '12', action) => {
  switch (action.type) {
    case SET_POSTES_UNIT:
      return action.payload
    default:
      return state
  }
}

export default combineReducers({
  activeTabs,
  consumptionGraph,
  loadCurveGraph,
  monotoneGraph,
  heatMapGraph,
  qualiteConsoGraph,
  referentielHrs,
  selectedPee,
  postesUnit,
})

// Selectors
export const getGlobalActiveTab = (state) => state.consumption.activeTabs.global
export const getDetailedActiveTab = (state) => state.consumption.activeTabs.detailed

export const getConsumptionGraph = (state) => state.consumption.consumptionGraph.graph
export const getConsumptionGraphIsFetching = (state) =>
  state.consumption.consumptionGraph.isFetching
export const getConsumptionGraphError = (state) => state.consumption.consumptionGraph.error
export const getConsumptionGraphAgregation = (state) =>
  state.consumption.consumptionGraph.agregation
export const getConsumptionGraphUniteId = (state) => state.consumption.consumptionGraph.unite
export const getConsumptionGraphUnite = (state) => unites[getConsumptionGraphUniteId(state)]
export const getConsumptionGraphType = (state) => state.consumption.consumptionGraph.type
export const getConsumptionGraphCompare = (state) => state.consumption.consumptionGraph.compare
export const getConsumptionGraphUuid = (state) => state.consumption.consumptionGraph.uuid

export const getLoadCurveGraph = (state) => state.consumption.loadCurveGraph.graph
export const getLoadCurveGraphIsFetching = (state) => state.consumption.loadCurveGraph.isFetching
export const getLoadCurveGraphError = (state) => state.consumption.loadCurveGraph.error
export const getLoadCurveGraphAgregation = (state) => state.consumption.loadCurveGraph.agregation
export const getLoadCurveGraphUniteId = (state) => state.consumption.loadCurveGraph.unite
export const getLoadCurveGraphUnite = (state) => unites[getLoadCurveGraphUniteId(state)]
export const getLoadCurveGraphType = (state) => state.consumption.loadCurveGraph.type
export const getLoadCurveGraphCompare = (state) => state.consumption.loadCurveGraph.compare
export const getLoadCurveGraphStep = (state) => state.consumption.loadCurveGraph.step
export const getLoadCurveGraphHrs = (state) => state.consumption.loadCurveGraph.hrs
export const getLoadCurveGraphBoost = (state) => state.consumption.loadCurveGraph.boost
export const getLoadCurveGraphUuid = (state) => state.consumption.loadCurveGraph.uuid

export const getPlotBandsLegend = (state) =>
  get(state, 'consumption.loadCurveGraph.graph.xAxis.plotBandsLegend', false)

export const getMonotoneGraph = (state) => state.consumption.monotoneGraph.graph
export const getMonotoneGraphIsFetching = (state) => state.consumption.monotoneGraph.isFetching
export const getMonotoneGraphError = (state) => state.consumption.monotoneGraph.error
export const getMonotoneGraphUniteId = (state) => state.consumption.monotoneGraph.unite
export const getMonotoneGraphType = (state) => state.consumption.monotoneGraph.type
export const getMonotoneGraphCompare = (state) => state.consumption.monotoneGraph.compare
export const getMonotoneGraphStep = (state) => state.consumption.monotoneGraph.step
export const getMonotoneGraphUuid = (state) => state.consumption.monotoneGraph.uuid

export const getHeatMapGraph = (state) => state.consumption.heatMapGraph.graph
export const getHeatMapGraphIsFetching = (state) => state.consumption.heatMapGraph.isFetching
export const getHeatMapGraphError = (state) => state.consumption.heatMapGraph.error
export const getHeatMapGraphUuid = (state) => state.consumption.heatMapGraph.uuid

export const getQualiteConsoGraph = (state) => state.consumption.qualiteConsoGraph.graph
export const getQualiteConsoGraphIsFetching = (state) =>
  state.consumption.qualiteConsoGraph.isFetching
export const getQualiteConsoGraphError = (state) => state.consumption.qualiteConsoGraph.error
export const getQualiteConsoGraphUuid = (state) => state.consumption.qualiteConsoGraph.uuid

export const getReferentielHrs = (state) => state.consumption.referentielHrs

export const getSelectedPee = (state) => state.consumption.selectedPee
export const getPostesUnit = (state) => state.consumption.postesUnit

// Action Creators
export const setConsumptionGraphAgregation = (agregation) => ({
  type: SET_CONSUMPTION_GRAPH_AGREGATION,
  agregation,
})
export const setConsumptionGraphUnite = (unite) => ({
  type: SET_CONSUMPTION_GRAPH_UNITE,
  unite,
})
export const setConsumptionGraphType = (dataType) => ({
  type: SET_CONSUMPTION_GRAPH_TYPE,
  dataType,
})
export const setConsumptionGraphCompare = (compare) => ({
  type: SET_CONSUMPTION_GRAPH_COMPARE,
  compare,
})

export const setLoadCurveGraphAgregation = (agregation) => ({
  type: SET_LOAD_CURVE_GRAPH_AGREGATION,
  agregation,
})
export const setLoadCurveGraphUnite = (unite) => ({
  type: SET_LOAD_CURVE_GRAPH_UNITE,
  unite,
})
export const setLoadCurveGraphType = (dataType) => ({
  type: SET_LOAD_CURVE_GRAPH_TYPE,
  dataType,
})
export const setLoadCurveGraphCompare = (compare) => ({
  type: SET_LOAD_CURVE_GRAPH_COMPARE,
  compare,
})
export const setLoadCurveGraphStep = (step) => ({
  type: SET_LOAD_CURVE_GRAPH_STEP,
  step,
})
export const setLoadCurveGraphHrs = (hrs) => ({
  type: SET_LOAD_CURVE_GRAPH_HRS,
  hrs,
})

export const setLoadCurveGraphBoost = (boost) => ({
  type: SET_LOAD_CURVE_GRAPH_BOOST,
  boost,
})

export const setMonotoneGraphUnite = (unite) => ({
  type: SET_MONOTONE_GRAPH_UNITE,
  unite,
})
export const setMonotoneGraphType = (dataType) => ({
  type: SET_MONOTONE_GRAPH_TYPE,
  dataType,
})
export const setMonotoneGraphCompare = (compare) => ({
  type: SET_MONOTONE_GRAPH_COMPARE,
  compare,
})
export const setMonotoneGraphStep = (step) => ({
  type: SET_MONOTONE_GRAPH_STEP,
  step,
})

export const setSelectedPee = (peeId) => ({ type: SET_PEE_ID, payload: peeId })
export const setPostesUnit = (unit) => ({ type: SET_POSTES_UNIT, payload: unit })

// Thunks
export const setGlobalActiveTab = (nav) => (dispatch, getState) => {
  const prevNav = getGlobalActiveTab(getState())
  if (prevNav === nav) return
  dispatch({ type: SET_GLOBAL_ACTIVE_TAB, nav })
}

export const setDetailedActiveTab = (nav) => (dispatch, getState) => {
  const prevNav = getDetailedActiveTab(getState())
  if (prevNav === nav) return
  dispatch({ type: SET_DETAILED_ACTIVE_TAB, nav })
}

export const fetchConsumptionGraph = (payload) => async (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: FETCH_CONSUMPTION_GRAPH_REQUEST,
    uuid,
  })

  try {
    const response = await api.fetchConsoMensuelleGraph({ ...payload, uuid })
    if (isErrorStatus(response.status)) throw new Error(`status ${response.status}`)

    const responseUuid = response.headers.get('X-REQUEST-ID')
    const lastUuid = getConsumptionGraphUuid(getState())
    if (responseUuid !== uuid || lastUuid !== uuid) return

    const graph = await response.json()
    dispatch({
      type: FETCH_CONSUMPTION_GRAPH_SUCCESS,
      graph,
    })
  } catch (error) {
    dispatch({
      type: FETCH_CONSUMPTION_GRAPH_FAILURE,
      message: error.message || 'Something went wrong.',
    })
  }
}

export const fetchLoadCurveGraph = (payload) => async (dispatch, getState) => {
  if (payload.ids && payload.ids.length === 0) {
    dispatch({
      type: FETCH_LOAD_CURVE_GRAPH_SUCCESS,
      graph: null,
    })
    return
  }

  const uuid = v4()

  dispatch({
    type: FETCH_LOAD_CURVE_GRAPH_REQUEST,
    uuid,
  })

  try {
    const response = await api.fetchLoadCurveGraph({ ...payload, uuid })
    if (isErrorStatus(response.status)) throw new Error(`status ${response.status}`)

    const responseUuid = response.headers.get('X-REQUEST-ID')
    const lastUuid = getLoadCurveGraphUuid(getState())
    if (responseUuid !== uuid || lastUuid !== uuid) return

    const graph = await response.json()
    dispatch({
      type: FETCH_LOAD_CURVE_GRAPH_SUCCESS,
      graph,
    })
  } catch (error) {
    dispatch({
      type: FETCH_LOAD_CURVE_GRAPH_FAILURE,
      message: error.message || 'Something went wrong.',
    })
  }
}

export const fetchMonotoneGraph = (payload) => async (dispatch, getState) => {
  if (payload.sites && payload.sites.length === 0) {
    dispatch({
      type: FETCH_MONOTONE_GRAPH_SUCCESS,
      graph: null,
    })
    return
  }

  const uuid = v4()

  dispatch({
    type: FETCH_MONOTONE_GRAPH_REQUEST,
    uuid,
  })

  try {
    const response = await api.fetchMonotoneGraph({ ...payload, uuid })
    if (isErrorStatus(response.status)) throw new Error(`status ${response.status}`)

    const responseUuid = response.headers.get('X-REQUEST-ID')
    const lastUuid = getMonotoneGraphUuid(getState())
    if (responseUuid !== uuid || lastUuid !== uuid) return

    const graph = await response.json()
    dispatch({
      type: FETCH_MONOTONE_GRAPH_SUCCESS,
      graph,
    })
  } catch (error) {
    dispatch({
      type: FETCH_MONOTONE_GRAPH_FAILURE,
      message: error.message || 'Something went wrong.',
    })
  }
}

export const fetchHeatMapGraph = (payload) => async (dispatch, getState) => {
  if (payload.sites && payload.sites.length === 0) {
    dispatch({
      type: FETCH_HEATMAP_GRAPH_SUCCESS,
      graph: null,
    })
    return
  }

  const uuid = v4()

  dispatch({
    type: FETCH_HEATMAP_GRAPH_REQUEST,
    uuid,
  })

  try {
    const response = await api.fetchHeatMapGraph({ ...payload, uuid })
    if (isErrorStatus(response.status)) throw new Error(`status ${response.status}`)

    const responseUuid = response.headers.get('X-REQUEST-ID')
    const lastUuid = getHeatMapGraphUuid(getState())
    if (responseUuid !== uuid || lastUuid !== uuid) return

    const graph = await response.json()
    dispatch({
      type: FETCH_HEATMAP_GRAPH_SUCCESS,
      graph,
    })
  } catch (error) {
    dispatch({
      type: FETCH_HEATMAP_GRAPH_FAILURE,
      message: error.message || 'Something went wrong.',
    })
  }
}

export const fetchQualiteConsoGraph = (payload) => async (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: FETCH_QUALITE_CONSO_GRAPH_REQUEST,
    uuid,
  })

  try {
    const response = await api.fetchQualiteConsoGraph({ ...payload, uuid })
    if (isErrorStatus(response.status)) throw new Error(`status ${response.status}`)

    const responseUuid = response.headers.get('X-REQUEST-ID')
    const lastUuid = getQualiteConsoGraphUuid(getState())
    if (responseUuid !== uuid || lastUuid !== uuid) return

    const graph = await response.json()
    console.log(graph)
    dispatch({
      type: FETCH_QUALITE_CONSO_GRAPH_SUCCESS,
      graph,
    })
  } catch (error) {
    dispatch({
      type: FETCH_QUALITE_CONSO_GRAPH_FAILURE,
      message: error.message || 'Something went wrong.',
    })
  }
}

export const fetchReferentielHrs = () => async (dispatch, getState) => {
  if (getReferentielHrs(getState()).data !== null) return

  const uuid = v4()

  dispatch({
    type: FETCH_REFERENTIEL_HRS_REQUEST,
    uuid,
  })

  try {
    const response = await api.fetchReferentielHrs({ uuid })
    if (isErrorStatus(response.status)) throw new Error(`status ${response.status}`)

    const responseUuid = response.headers.get('X-REQUEST-ID')
    if (responseUuid !== uuid) return

    const data = await response.json()
    dispatch({
      type: FETCH_REFERENTIEL_HRS_SUCCESS,
      data,
    })
  } catch (error) {
    dispatch({
      type: FETCH_REFERENTIEL_HRS_FAILURE,
      message: error.message || 'Something went wrong.',
    })
  }
}
