import React from 'react'
import { Route } from 'react-router-dom'

import BillsList from '../bills/BillsList'
import VisibleBill from '../bills/VisibleBill'

const BillsView = () => (
  <div>
    <BillsList />
    <Route path="/bills/:billId" component={VisibleBill} />
  </div>
)

export default BillsView
