export const highchartsEn = {
  lang: {
    loading: 'Loading...',
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    shortMonths: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    decimalPoint: ',',
    numericSymbols: ['k', 'M', 'G', 'T', 'P', 'E'],
    resetZoom: 'Reset zoom',
    resetZoomTitle: 'Reset zoom level 1:1',
    thousandsSep: ' ',
    rangeSelectorZoom: 'Zoom: ',
    rangeSelectorFrom: 'From',
    rangeSelectorTo: 'To',
    downloadJPEG: 'Download JPEG image',
    downloadPDF: 'Download PDF document',
    downloadCSV: 'Download CSV document',
    downloadXLS: 'Download XLS document',
    contextButtonTitle: 'Export chart',
  },
  credits: {
    enabled: false,
  },
  rangeSelector: {
    inputDateFormat: '%b %e, %Y',
    inputEditDateFormat: '%Y-%m-%d',
    inputDateParser: function(value) {
      value = value.split('/')
      return Date.UTC(parseInt(value[2], 10), parseInt(value[1], 10) - 1, parseInt(value[0], 10))
    },
    buttons: [
      {
        type: 'week',
        count: 1,
        text: '1s',
      },
      {
        type: 'month',
        count: 1,
        text: '1m',
      },
      {
        type: 'month',
        count: 6,
        text: '6m',
      },
      {
        type: 'year',
        count: 1,
        text: '1y',
      },
      {
        type: 'all',
        count: 1,
        text: 'All',
      },
    ],
  },
  tooltip: {
    dateTimeLabelFormats: {
      millisecond: '%A, %b %e, %H:%M:%S.%L',
      second: '%A, %b %e, %H:%M:%S',
      minute: '%A, %b %e, %H:%M',
      hour: '%A, %b %e, %H:%M',
      day: '%A, %b %e, %Y',
      week: 'Week from %A, %b %e, %Y',
      month: '%B %Y',
      year: '%Y',
    },
  },
  exporting: {
    csv: {
      itemDelimiter: ';',
    },
    buttons: {
      contextButton: {
        menuItems: [
          {
            textKey: 'downloadJPEG',
            onclick: function() {
              this.exportChart({
                type: 'image/jpeg',
              })
            },
          },
          {
            textKey: 'downloadPDF',
            onclick: function() {
              this.exportChart({
                type: 'application/pdf',
              })
            },
            separator: false,
          },
          {
            textKey: 'downloadCSV',
            onclick: function() {
              this.downloadCSV()
            },
            separator: false,
          },
        ],
      },
    },
  },
}

export const rangeSelectorMarketEn = {
  rangeSelector: {
    enabled: true,
    inputEnabled: false,
    selected: 5,
    buttons: [
      {
        type: 'week',
        count: 1,
        text: '1s',
      },
      {
        type: 'month',
        count: 1,
        text: '1m',
      },
      {
        type: 'month',
        count: 3,
        text: '3m',
      },
      {
        type: 'month',
        count: 6,
        text: '6m',
      },
      {
        type: 'month',
        count: 12,
        text: '1y',
      },
      {
        type: 'all',
        text: 'All',
      },
    ],
  },
  navigator: {
    maskFill: 'rgba(49, 172, 234, .1)',
  },
}

export const rangeSelectorLoadCurveEn = {
  rangeSelector: {
    enabled: true,
    inputEnabled: false,
    selected: 5,
    buttons: [
      {
        type: 'day',
        count: 1,
        text: '1d',
      },
      {
        type: 'week',
        count: 1,
        text: '1s',
      },
      {
        type: 'month',
        count: 1,
        text: '1m',
      },
      {
        type: 'month',
        count: 6,
        text: '6m',
      },
      {
        type: 'month',
        count: 12,
        text: '1y',
      },
      {
        type: 'all',
        text: 'All',
      },
    ],
  },
  xAxis: {
    range: 1000 * 3600 * 24 * 30,
  },
  navigator: {
    maskFill: 'rgba(49, 172, 234, .1)',
  },
}
