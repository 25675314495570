import React from 'react'
import { Button } from 'reactstrap'
import { CallbackComponent } from 'redux-oidc'
import { withTranslation } from 'react-i18next'

import userManager from '../../security/userManager'
import Loader from '../widgets/Loader'
import './Callback.scss'

const refresh = () => (window.location.href = '/')

const Callback = ({ t }) => {
  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={() => (window.location.href = '/')}
      errorCallback={(error) => console.error('Login CallbackError', error.message)}
    >
      <div className="callback-container">
        <Loader center={false} />

        <div className="callback-refresh">
          <Button color="link" onClick={refresh}>
            {t('access.redirection')}
          </Button>
        </div>
      </div>
    </CallbackComponent>
  )
}

export default withTranslation()(Callback)
