const initialKpi = {
  Principal: {
    Libelle: '',
    Valeur: 0,
    Unite: '',
  },
}

const initialState = {
  kpiConso: initialKpi,
  kpiConsoUuid: null,
  kpiExpense: initialKpi,
  kpiExpenseUuid: null,
  kpiPrice: initialKpi,
  kpiPriceUuid: null,
  graph: {
    uuid: null,
    data: null,
    loading: false,
    error: false,
  },
}

const detail = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_DETAIL_KPI_CONSO_REQUEST':
      return {
        ...state,
        // kpiConso: null,
        kpiConsoUuid: action.uuid,
      }
    case 'FETCH_DETAIL_KPI_EXPENSE_REQUEST':
      return {
        ...state,
        // kpiExpense: null,
        kpiExpenseUuid: action.uuid,
      }
    case 'FETCH_DETAIL_KPI_PRICE_REQUEST':
      return {
        ...state,
        // kpiPrice: null,
        kpiPriceUuid: action.uuid,
      }
    case 'FETCH_DETAIL_KPI_CONSO_SUCCESS':
      return {
        ...state,
        kpiConso: action.response,
      }
    case 'FETCH_DETAIL_KPI_EXPENSE_SUCCESS':
      return {
        ...state,
        kpiExpense: action.response,
      }
    case 'FETCH_DETAIL_KPI_PRICE_SUCCESS':
      return {
        ...state,
        kpiPrice: action.response,
      }
    case 'FETCH_DETAIL_GRAPH_REQUEST':
      return {
        ...state,
        graph: {
          ...state.graph,
          uuid: action.uuid,
          data: null,
          loading: true,
          error: false,
        },
      }
    case 'FETCH_DETAIL_GRAPH_SUCCESS':
      return {
        ...state,
        graph: {
          ...state.graph,
          data: action.data,
          loading: false,
          error: false,
        },
      }
    case 'FETCH_DETAIL_GRAPH_FAILURE':
      return {
        ...state,
        graph: {
          ...state.graph,
          data: null,
          loading: false,
          error: true,
        },
      }
    default:
      return state
  }
}

export default detail

// Selectors
export const getKpiConso = (state) => state.expenses.detail.kpiConso
export const getKpiConsoUuid = (state) => state.expenses.detail.kpiConsoUuid
export const getKpiExpense = (state) => state.expenses.detail.kpiExpense
export const getKpiExpenseUuid = (state) => state.expenses.detail.kpiExpenseUuid
export const getKpiPrice = (state) => state.expenses.detail.kpiPrice
export const getKpiPriceUuid = (state) => state.expenses.detail.kpiPriceUuid

export const getGraphData = (state) => state.expenses.detail.graph.data
export const getGraphError = (state) => state.expenses.detail.graph.error
export const getGraphLoading = (state) => state.expenses.detail.graph.loading
export const getGraphUuid = (state) => state.expenses.detail.graph.uuid
