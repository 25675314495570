import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from 'reactstrap'

import {
  getQualiteConsoGraph,
  getQualiteConsoGraphIsFetching,
  getQualiteConsoGraphError,
} from 'store/consumption'

import styles from './QualiteConso.module.scss'
import createOptions from './options'
import Chart from 'components/common/Chart'
// import { getUor } from 'store'
import { getUor } from 'reducers/organisation'

const QualiteConsoGraph = ({ graph, startDate, endDate, error, isLoading, uor }) => {
  const { t } = useTranslation()

  console.table(graph)

  const options = React.useMemo(() => {
    return createOptions(graph, startDate, endDate, uor)
  }, [endDate, graph, startDate, uor])

  const isEmpty = !graph || !graph.graphMensuel || graph.graphMensuel.length === 0

  return (
    <Container fluid>
      <Row>
        <Col>
          <Chart
            id="quality"
            error={error}
            isEmpty={isEmpty}
            isLoading={isLoading}
            options={options}
            immutable={true}
            minHeight={200}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.legend}>
            <div className={styles.legendItem}>{t('global.consumption.data.all')}</div>
            <div className={styles.legendItem}>{t('global.consumption.data.partial')}</div>
            <div className={styles.legendItem}>{t('global.consumption.data.no')}</div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  graph: getQualiteConsoGraph(state),
  isLoading: getQualiteConsoGraphIsFetching(state),
  error: getQualiteConsoGraphError(state),
  uor: getUor(state),
})

export default connect(mapStateToProps)(QualiteConsoGraph)
