import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, CardGroup } from 'reactstrap'

import Kpi from 'components/kpi/Kpi'
import { getGraphData } from 'reducers/expenses/detail'

const DetailKpi = ({ data }) => {
  if (!data) return null
  const [conso, expense, price] = data.kpis

  return (
    <Row>
      <Col xs="9">
        <CardGroup>
          <Kpi decimal={false} {...conso} />
          <Kpi decimal={false} {...expense} />
          <Kpi decimal={true} {...price} />
        </CardGroup>
      </Col>
    </Row>
  )
}

const mapState = (state) => ({
  data: getGraphData(state),
})

export default connect(mapState)(DetailKpi)
