import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { Table, Thead, Tr, Th, Td } from 'reactable'
import moment from 'moment'

import { decimalFormat, dateFormat } from '../../helpers/helpers'

const ContractLotPriceFix = ({ transactions, t }) => (
  <div className="ctr-lot-table">
    <Table className="table table-striped" sortable>
      <Thead>
        <Th column="date">{t('contracts.contract.lots.date')}</Th>
        <Th column="product">{t('contracts.contract.lots.product')}</Th>
        <Th column="vol">{t('contracts.contract.lots.vol')}</Th>
        <Th column="price">{t('contracts.contract.lots.price')}</Th>
      </Thead>
      {transactions.map((i) => (
        <Tr key={i.id}>
          <Td column="date">{moment(i.TrsDate).format(dateFormat())}</Td>
          <Td column="product">{i.TrsProduit}</Td>
          <Td column="vol">{`${decimalFormat(i.TrsVolume, 0)} ${i.TrsVolumeUnite}`}</Td>
          <Td column="price">{`${decimalFormat(i.TrsPrix)} ${i.TrsUnite}`}</Td>
        </Tr>
      ))}
    </Table>
  </div>
)

ContractLotPriceFix.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      TrsDate: PropTypes.string.isRequired,
      TrsPrix: PropTypes.number.isRequired,
      TrsProduit: PropTypes.string.isRequired,
      // TrsVolume: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ),
}

export default withTranslation()(ContractLotPriceFix)
