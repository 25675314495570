import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Button } from 'reactstrap'

import CurrentPee from './CurrentPee'
import { toggleSiteOpen } from '../../../actions/currentSelection'
import { getSiteCurrentPees, getIsSiteOpen } from '../../../reducers/currentSelection'

const CurrentSite = function({ site, siteCurrentPees, isSiteOpen, toggleSiteOpen, dispatch, t }) {
  return (
    <div className="current-site">
      <Button
        className="d-flex w-100"
        color="link"
        onClick={(e) => {
          e.preventDefault()
          dispatch(toggleSiteOpen)
        }}
      >
        <span className="w-100" title={site}>
          {site}
        </span>
        <i
          title={isSiteOpen ? t('sidebar.current.collapse') : t('sidebar.current.expand')}
          className={isSiteOpen ? 'icon-collapse-moins' : 'icon-collapse-plus'}
        />
      </Button>

      {isSiteOpen && (
        <div className="current-pees collapse show">
          <ul>
            {Object.keys(siteCurrentPees).map((peeId) => (
              <CurrentPee key={peeId} pee={siteCurrentPees[peeId]} />
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state, { siteId }) => {
  const pees = getSiteCurrentPees(state, siteId)
  return {
    siteCurrentPees: Object.keys(pees).reduce((acc, id) => {
      return {
        ...acc,
        [id]: {
          id,
          PeeClef: pees[id].PeeClef,
          PeeNrjId: pees[id].PeeNrjId,
        },
      }
    }, {}),
    isSiteOpen: getIsSiteOpen(state, siteId),
    toggleSiteOpen: toggleSiteOpen(siteId),
  }
}

CurrentSite.propTypes = {
  site: PropTypes.string,
  siteCurrentPees: PropTypes.object.isRequired,
  isSiteOpen: PropTypes.bool.isRequired,
  toggleSiteOpen: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default withTranslation()(connect(mapStateToProps)(CurrentSite))
