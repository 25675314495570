import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'
import { ErrorBoundary } from 'commons'

import UserPanel from './UserPanel'
import TopNav from '../topNav/TopNav'
import Views from '../route/Views'
import SideBar from '../sidebar/Sidebar'
import SearchPanel from '../searchPanel/SearchPanel'
import Header from '../header/Header'
import Notifs from '../notifications/Notifs'

const Template = ({ location }) => {
  const [userPanelOpen, setUserPanelOpen] = React.useState(false)
  const matchMarket = useRouteMatch('/market-informations')
  const matchComex = useRouteMatch('/comex')

  return (
    <Container fluid>
      <Row>
        <Notifs />

        <SearchPanel />

        <UserPanel userPanelOpen={userPanelOpen} setUserPanelOpen={setUserPanelOpen} />

        <Col className="sidebar-col" tag="aside">
          <SideBar />
        </Col>

        <Col className="main-col">
          <Row>
            <TopNav userPanelOpen={userPanelOpen} setUserPanelOpen={setUserPanelOpen} />
          </Row>

          <Row className={`main-header${matchMarket ? ' not-sticky' : ''}`}>
            {!matchComex && <Header />}
          </Row>

          <Row>
            <Col>
              <ErrorBoundary>
                <Views />
              </ErrorBoundary>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default withTranslation()(Template)
