import React, { Component } from 'react'
import { Row, Col, PopoverBody, Button, Popover, Input } from 'reactstrap'
import { Table, Thead, Tr, Th, Td } from 'reactable'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { fetchMyAvailableQuotations, addQuotationsForUser } from '../../../actions/quotations'

class QuotationMarketPopover extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filteredAvailableQuotations: props.availableQuotations,
      selectedQuotations: [],
      searchFilter: '',
    }
  }

  componentDidMount() {
    this.props.fetchAvailableQuotations()
  }

  UNSAFE_componentWillReceiveProps(newProps, oldProps) {
    if (!oldProps.availableQuotations || oldProps.availableQuotations.length === 0) {
      this.setState({ filteredAvailableQuotations: newProps.availableQuotations })
    }
  }

  toggleQuotation = (quotation, shouldToggleOn) => () => {
    if (shouldToggleOn) {
      this.setState({ selectedQuotations: [...this.state.selectedQuotations, quotation] })
    } else {
      this.setState({
        selectedQuotations: this.state.selectedQuotations.filter((p) => p.id !== quotation.id),
      })
    }
  }

  addQuotations = (e) => {
    e.preventDefault()
    this.props.addQuotations(this.state.selectedQuotations)
    this.props.togglePopover()
    this.setState({ selectedQuotations: [] })
  }

  onSearchChange = (e) => {
    const searchFilter = e.target.value.toLowerCase()
    const searchStrings = searchFilter.split(' ')
    let filteredAvailableQuotations = [...this.props.availableQuotations]
    searchStrings.forEach((str) => {
      if (str !== '') {
        filteredAvailableQuotations = filteredAvailableQuotations.filter((quotation) => {
          return (
            quotation.CotProduit.PdtNom.toLowerCase().includes(str) ||
            quotation.CotMarche.toLowerCase().includes(str) ||
            quotation.CotPays.toLowerCase().includes(str) ||
            quotation.CotProduit.PdtNrj.toLowerCase().includes(str)
          )
        })
      }
    })
    this.setState({ filteredAvailableQuotations, searchFilter })
  }

  render() {
    return (
      <Popover
        className="quotation-popover"
        placement="left"
        isOpen={this.props.isOpen}
        target="addProduct"
        toggle={this.props.togglePopover}
      >
        <PopoverBody>
          <form onSubmit={this.addQuotations}>
            <Row>
              <Col>
                <label className="title">{this.props.t('market.quotationsPopover.search')}</label>
                <Input
                  value={this.state.value}
                  type="text"
                  name="searchBox"
                  onChange={this.onSearchChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  className="table table-striped table-bordered mt-4"
                  sortable={['product', 'market', 'country', 'year']}
                  itemsPerPage={15}
                >
                  <Thead>
                    <Th column="select" className="quotation-selection">
                      {' '}
                    </Th>
                    <Th column="nrj">{this.props.t('market.alerts.commodity')}</Th>
                    <Th column="product">{this.props.t('market.alerts.product')}</Th>
                    <Th column="market">{this.props.t('market.alerts.market')}</Th>
                    <Th column="country">{this.props.t('market.alerts.country')}</Th>
                    <Th column="year">{this.props.t('market.alerts.year')}</Th>
                  </Thead>
                  {this.state.filteredAvailableQuotations.map((i, index) => {
                    const isSelected =
                      this.state.selectedQuotations.findIndex((p) => p.id === i.id) !== -1
                    return (
                      <Tr key={index}>
                        <Td column="select" className="quotation-selection" value={isSelected}>
                          <Input
                            type="checkbox"
                            checked={isSelected}
                            onChange={this.toggleQuotation(i, !isSelected)}
                          />
                        </Td>
                        <Td column="nrj">{i.CotProduit.PdtNrj}</Td>
                        <Td column="product" value={i.CotProduit.PdtNom}>
                          <strong>{i.CotProduit.PdtNom}</strong>
                        </Td>
                        <Td column="market">{i.CotMarche}</Td>
                        <Td column="country">{i.CotPays}</Td>
                        <Td column="year">{i.CotAnnee}</Td>
                      </Tr>
                    )
                  })}
                </Table>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button className="float-right rounded" color="primary" type="submit">
                  {`${this.props.t('market.quotationsPopover.add')} (${
                    this.state.selectedQuotations.length
                  })`}
                </Button>
              </Col>
            </Row>
          </form>
        </PopoverBody>
      </Popover>
    )
  }
}

const mapStateToProps = (state) => ({
  availableQuotations: state.quotations.myAvailableQuotations,
})

const mapDispatchToProps = (dispatch) => ({
  fetchAvailableQuotations: () => {
    dispatch(fetchMyAvailableQuotations())
  },
  addQuotations: (quotations) => {
    dispatch(addQuotationsForUser(quotations))
  },
})

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(QuotationMarketPopover)
