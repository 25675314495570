import keyBy from 'lodash/keyBy'
import { useQuery } from 'react-query'
import { request } from './request'

export const useCountries = (id) => {
  const { data } = useQuery('pays', () => request({ url: 'pays' }), {
    staleTime: 86400000,
    suspense: true,
  })
  return keyBy(data, 'id')
}
