import v4 from 'uuid/v4'
import * as kpi from '../../api/kpi.js'
import * as graph from '../../api/graph.js'
import {
  getKpiAllUuid,
  getKpiElecUuid,
  getKpiGasUuid,
  getGraphUuid,
} from '../../reducers/powerConsumption/allAmenities'
import { getStartDate, getEndDate } from '../../reducers/dateRange'
import {
  getCurrentVisiblePeesIds,
  getCurrentVisibleElecPeesIds,
  getCurrentVisibleGasPeesIds,
} from '../../reducers/currentSelection'
import { getTypeDonnee } from '../../reducers/typeDonnee'
import * as user from 'commons'

export const fetchKpiAll = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_ALL_AMENITIES_KPI_ALL_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisiblePeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  if (perimetre) {
    return kpi
      .fetchKpi(
        uuid,
        dateDebut,
        dateFin,
        perimetre,
        'Volume',
        type,
        12,
        21,
        '',
        'Total',
        'Commodite',
        uorIdMst,
        lanId
      )
      .then(
        (response) => {
          if (response && response.headers.get('X-REQUEST-ID') === getKpiAllUuid(getState())) {
            response.json().then((response) =>
              dispatch({
                type: 'FETCH_ALL_AMENITIES_KPI_ALL_SUCCESS',
                response,
              })
            )
          }
        },
        (error) => {
          dispatch({
            type: 'FETCH_ALL_AMENITIES_KPI_ALL_FAILURE',
            message: error.message || 'Something went wrong.',
          })
        }
      )
  } else {
    dispatch({
      type: 'FETCH_ALL_AMENITIES_KPI_ALL_NONE',
      response: 'empty',
    })
  }
}

export const fetchKpiElec = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_ALL_AMENITIES_KPI_ELEC_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisibleElecPeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  if (perimetre) {
    return kpi
      .fetchKpi(
        uuid,
        dateDebut,
        dateFin,
        perimetre,
        'Volume',
        type,
        12,
        21,
        '',
        '2',
        'Commodite',
        uorIdMst,
        lanId
      )
      .then(
        (response) => {
          if (response && response.headers.get('X-REQUEST-ID') === getKpiElecUuid(getState())) {
            response.json().then((response) =>
              dispatch({
                type: 'FETCH_ALL_AMENITIES_KPI_ELEC_SUCCESS',
                response,
              })
            )
          }
        },
        (error) => {
          dispatch({
            type: 'FETCH_ALL_AMENITIES_KPI_ELEC_FAILURE',
            message: error.message || 'Something went wrong.',
          })
        }
      )
  } else {
    dispatch({
      type: 'FETCH_ALL_AMENITIES_KPI_ELEC_NONE',
      response: 'empty',
    })
  }
}

export const fetchKpiGas = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_ALL_AMENITIES_KPI_GAS_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisibleGasPeesIds(getState()).join(';')
  const type = getTypeDonnee(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  if (perimetre) {
    return kpi
      .fetchKpi(
        uuid,
        dateDebut,
        dateFin,
        perimetre,
        'Volume',
        type,
        12,
        21,
        '',
        '1',
        'Commodite',
        uorIdMst,
        lanId
      )
      .then(
        (response) => {
          if (response && response.headers.get('X-REQUEST-ID') === getKpiGasUuid(getState())) {
            response.json().then((response) =>
              dispatch({
                type: 'FETCH_ALL_AMENITIES_KPI_GAS_SUCCESS',
                response,
              })
            )
          }
        },
        (error) => {
          dispatch({
            type: 'FETCH_ALL_AMENITIES_KPI_GAS_FAILURE',
            message: error.message || 'Something went wrong.',
          })
        }
      )
  } else {
    dispatch({
      type: 'FETCH_ALL_AMENITIES_KPI_GAS_NONE',
    })
  }
}

export const fetchGraph = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: 'FETCH_ALL_AMENITIES_GRAPH_REQUEST',
    uuid,
  })

  const dateDebut = getStartDate(getState())
  const dateFin = getEndDate(getState())
  const perimetre = getCurrentVisiblePeesIds(getState()).join(';')
  const uorIdMst = user.uorIdMst(getState())
  const lanId = user.lanId(getState())

  return graph
    .fetchNontemporalGraph(
      uuid,
      dateDebut,
      dateFin,
      perimetre,
      'Volume',
      'Facture',
      '',
      '',
      '',
      '',
      'Commodite',
      uorIdMst,
      lanId
    )
    .then(
      (response) => {
        if (response && response.headers.get('X-REQUEST-ID') === getGraphUuid(getState())) {
          response.json().then((response) =>
            dispatch({
              type: 'FETCH_ALL_AMENITIES_GRAPH_SUCCESS',
              response,
            })
          )
        }
      },
      (error) => {
        dispatch({
          type: 'FETCH_ALL_AMENITIES_GRAPH_FAILURE',
          message: error.message || 'Something went wrong.',
        })
      }
    )
}
